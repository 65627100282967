import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
    ChangeEvent,
    Fragment,
    KeyboardEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDebounce } from "../../../hooks";

import {
    autoUpdate,
    flip,
    offset,
    shift,
    size,
    useFloating,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import { FormattedOption } from "./FormattedOption";
import Fuse from "fuse.js";

export type MultiSelectInputProps<T> = {
    /** If set, will render a hidden input in the form with the value */
    name?: string;

    options?: T[];

    /** For use as an uncontrolled input */
    defaultValue?: T[];

    /** For use as a controlled input */
    value?: T[] | null;

    onChange?: (value: T[] | null) => void;
    onBlur?: () => void;
    disabled?: boolean;
    hasError?: boolean;
    placeholder?: string;

    // async

    /**
     * If provided, will be called (with debounce) when the user enters a new search query.
     * The result will be merged with the provided options once the promise is resolved.
     */
    fetchOptions?: (search: string) => Promise<T[]>;
    /** An override to show the loading spinner if fetching data manually. */
    isLoading?: boolean;

    /** Formats the value shown in the input based on the option. Must be a string (as it's an input element.) */
    formatDisplayValue?: (option: T) => React.ReactNode;
    /** Formats the option in the option list. Can be any valid react component. */
    formatOption?: (option: T) => React.ReactNode;

    /**
     * Any fields that should be considered when filtering
     */
    filterFields?: Array<keyof T>;

    /**
     * Provide this function to group options into categories.
     * The key is the category name (displayed as a separator), and the value
     * are the options in that group
     */
    groupOptions?: (options: T[]) => Record<string, T[]>;

    // creatable

    /** If true, the user can add new values to options[] */
    isCreatable?: boolean;
    /** A function that takes the string value from the input, and converts it into the new option */
    formatCreatableValue?: (v: string) => T;
    /** If provided, the placeholder that will be rendered on the create option */
    creatablePlaceholder?: (v: string, isInvalid: boolean) => string;
    /** If provided, will validate the intended create value on keypress, and disable if not valid. */
    isCreateValueValid?: (v: string) => boolean;

    /** If provided, will perform a function on query string change */
    onSearchQueryChanged?: (v: string) => void;

    /**
     * Determine if an option should be disabled dynamically.
     * If an option is disabled but in the value list, it will not be able to be unselected.
     * If an option is disabled but not yet selected, it will not be selectable.
     */
    isOptionValid?: (option: T) => boolean;

    className?: string;
    selectedClassName?: string;
    /** Customizes "empty" options state message */
    emptyMessage?: string;
};

export function MultiSelectInput<T>({
    name,
    options: initialOptions,
    defaultValue,
    value,
    onChange,
    onBlur,
    disabled,
    hasError,
    placeholder = "Start Typing...",
    fetchOptions,
    isLoading: manualIsLoading = false,
    formatDisplayValue = (v: T) => `${v}`,
    formatOption = (v: T) => <FormattedOption label={`${v}`} />,
    filterFields,
    groupOptions = (opts) => ({ "": opts }),
    isCreatable,
    formatCreatableValue = (v) => v as T,
    creatablePlaceholder = (v) => `Create "${v}"`,
    isCreateValueValid,
    onSearchQueryChanged,
    className,
    selectedClassName,
    isOptionValid = (_) => true,
    emptyMessage,
}: MultiSelectInputProps<T>) {
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce(query, 500);
    const [isLoading, setIsLoading] = useState(manualIsLoading);
    const [options, setOptions] = useState(initialOptions || []);
    const [inputFocused, setInputFocused] = useState(false);
    const inputEl = useRef<HTMLInputElement>(null);

    const searchIndex = useRef<Fuse<T>>(
        new Fuse(
            options,
            filterFields
                ? {
                      keys: (filterFields as string[]) || [],
                  }
                : undefined
        )
    );

    const handleFetchOptions = useCallback(
        async (query: string) => {
            if (!fetchOptions) {
                return [];
            }
            setIsLoading(true);
            const fetchedOpts = await fetchOptions(query);
            setIsLoading(false);

            return fetchedOpts;
        },
        [fetchOptions, setOptions, initialOptions, setIsLoading]
    );

    useEffect(() => {
        onSearchQueryChanged?.(query);
    }, [query]);

    // Merge options with async results
    useEffect(() => {
        handleFetchOptions(debouncedQuery).then((asyncOpts) => {
            const io = initialOptions || [];
            const newOpts = [...io, ...asyncOpts];
            setOptions(newOpts);
            searchIndex.current.setCollection(newOpts);
        });
    }, [initialOptions, fetchOptions, setOptions, debouncedQuery]);

    const filterOptions = useCallback(
        (query: string) => {
            let opts = options;

            // Quick and dirty...there's an opportunity to optimize here.
            const serializedValues = value?.map((v) => JSON.stringify(v));

            if (query) {
                opts = searchIndex.current.search(query)?.map((q) => q.item);
            }

            return groupOptions(
                opts.filter(
                    (o) => !serializedValues?.includes(JSON.stringify(o))
                )
            );
        },
        [options, filterFields, groupOptions, value, query]
    );

    const handleChange = useCallback(
        (v: T[]) => {
            setQuery("");
            if (v.length === 0) {
                // reset input width if empty
                if (inputEl.current) {
                    inputEl.current.style.width = "";
                }
            }
            onChange?.([...new Set(v)]);
        },
        [onChange, inputEl.current]
    );

    useEffect(() => {
        setIsLoading(manualIsLoading);
    }, [manualIsLoading]);

    const { refs, context, floatingStyles } = useFloating({
        open: true,
        placement: "bottom-start",
        strategy: "fixed",
        middleware: [
            offset(10),
            shift(),
            flip(),
            size({
                apply({ elements }) {
                    // Do things with the data, e.g.
                    Object.assign(elements.floating.style, {
                        maxWidth: `${
                            elements.reference.getBoundingClientRect().width
                        }px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const checkIsDisabled = (
        ev:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | ChangeEvent<HTMLInputElement>
    ) => {
        return ev.currentTarget.matches(":disabled");
    };

    const role = useRole(context, { role: "menu" });
    const { getReferenceProps, getFloatingProps } = useInteractions([role]);

    return (
        <Combobox
            name={name}
            value={value || []}
            onChange={handleChange}
            defaultValue={defaultValue}
            disabled={disabled}
            nullable
            multiple
            aria-invalid={hasError ? "true" : "false"}
        >
            {({ open, activeOption }) => (
                <div className={classNames("relative")}>
                    <div
                        onClick={(ev) => {
                            if (checkIsDisabled(ev)) {
                                return;
                            }
                            ev.preventDefault();
                            inputEl.current?.focus();
                        }}
                        className={classNames(
                            className,
                            "flex min-h-[40px] w-full flex-wrap items-center ",
                            "group-disabled/fieldset:bg-cycle-gray/10 group-disabled/fieldset:pointer-events-none group-disabled/fieldset:cursor-not-allowed ",
                            // focus
                            "focus-within:ring-cycle-blue focus-within:border-transparent focus-within:ring-2",
                            "text-cycle-gray peer rounded-md border border-inherit outline-none",
                            //dark
                            "dark:text-white",
                            // disabled dark
                            "group-disabled/fieldset:dark:bg-black group-disabled/fieldset:dark:text-opacity-50",
                            { "focus-within:ring-cycle-blue": !hasError },
                            {
                                "ring-error border-transparent ring-2":
                                    !!hasError,
                            },
                            // disabled - props
                            disabled
                                ? " bg-cycle-gray/10 !pointer-events-none  border-transparent dark:bg-black dark:text-opacity-50 "
                                : "dark:bg-cycle-gray-accent  dark:text-cycle-white-accent bg-white dark:border-none"
                        )}
                        ref={refs.setReference}
                        {...getReferenceProps()}
                    >
                        <ul className="b-4 flex flex-wrap items-center pl-2">
                            {value?.map((v) => (
                                <li
                                    key={JSON.stringify(v)}
                                    className={classNames(
                                        "bg-cycle-gray/20  gap space-between flex items-center rounded-md p-1 !text-xs",
                                        "dark:bg-black/50",
                                        "max-w-36 overflow-hidden text-ellipsis whitespace-nowrap",
                                        "my-1 mr-2 !h-[1.75rem]",
                                        selectedClassName
                                    )}
                                >
                                    <div className="flex h-full items-center">
                                        {formatDisplayValue(v)}
                                    </div>
                                    <a
                                        className={classNames(
                                            "!disabled:pointer-events-none inline-flex w-full cursor-pointer pl-2",
                                            disabled &&
                                                "text-cycle-gray pointer-events-none",
                                            !isOptionValid?.(v) && "hidden"
                                        )}
                                        onClick={() => {
                                            handleChange(
                                                value.filter((o) => o !== v)
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className={classNames(
                                                "hover:text-error !disabled:pointer-events-none"
                                            )}
                                            icon={faTimes}
                                        />
                                    </a>
                                </li>
                            ))}
                            <li key="input" className="relative inline-flex">
                                <Combobox.Input
                                    ref={inputEl}
                                    className="dark:text-cycle-white inline w-1 overflow-hidden whitespace-nowrap rounded-md border-0 bg-transparent px-0 focus:ring-0"
                                    displayValue={() => ""}
                                    autoComplete="off"
                                    onFocus={() => setInputFocused(true)}
                                    onBlur={() => {
                                        if (inputEl.current) {
                                            inputEl.current.style.width = "";
                                        }
                                        setInputFocused(false);

                                        setTimeout(() => {
                                            setQuery("");
                                            onBlur?.();
                                        }, 200);
                                    }}
                                    onKeyDown={(
                                        ev: KeyboardEvent<HTMLInputElement>
                                    ) => {
                                        switch (ev.key) {
                                            case "Space":
                                            case " ": // Space
                                            case "Enter":
                                            case "Tab":
                                                ev.preventDefault();
                                                if (!activeOption) {
                                                    return;
                                                }

                                                let opt: T = activeOption as T; // this type is wrong - with multiselect activeOption is still just a single option

                                                if (
                                                    isCreatable &&
                                                    ev.currentTarget.value
                                                ) {
                                                    opt = formatCreatableValue(
                                                        ev.currentTarget.value
                                                    );
                                                }
                                                const curValue = (value ||
                                                    []) as T[];

                                                handleChange([
                                                    ...curValue,
                                                    opt,
                                                ]);

                                                break;
                                            case "Backspace":
                                                // only remove a tag if
                                                // input is empty
                                                if (query === "" && value) {
                                                    handleChange(
                                                        value.slice(0, -1)
                                                    );
                                                }

                                                break;
                                        }
                                    }}
                                    onChange={(
                                        ev: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (inputEl.current) {
                                            const length =
                                                ev.currentTarget.value.length *
                                                10;
                                            inputEl.current.style.width = length
                                                ? `${length}px`
                                                : "";
                                        }

                                        setQuery(ev.currentTarget.value);
                                    }}
                                    value={query}
                                />
                                {/* 
                                    "faked" placeholder in order to keep the actual input el small 
                                    This helps to prevent the input from adding an extra line due to width of the input itself
                                */}
                                <div className="text-cycle-gray/75 dark:text-cycle-gray-light/40 flex items-center tracking-tight">
                                    {(!value || value?.length === 0) &&
                                    query === "" ? (
                                        <>{placeholder}</>
                                    ) : null}
                                </div>
                            </li>
                        </ul>
                        <Combobox.Button
                            className={classNames(
                                "absolute inset-y-0 right-0 top-1/3 flex items-center pb-4 pr-2"
                            )}
                        >
                            <FontAwesomeIcon
                                icon={isLoading ? faSpinner : faChevronDown}
                                spin={isLoading}
                                className={classNames(
                                    isLoading
                                        ? "text-cycle-blue"
                                        : "text-cycle-gray",
                                    "h-4 w-4"
                                )}
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>

                    <Transition
                        show={open || inputFocused}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Combobox.Options
                            static
                            className="dark:bg-cycle-gray-accent z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            ref={refs.setFloating}
                            style={{ ...floatingStyles, position: "fixed" }}
                            {...getFloatingProps()}
                        >
                            <>
                                {options.length === 0 && query.length === 0 && (
                                    <div
                                        className={`dark:text-cycle-gray-light/60 relative cursor-default select-none px-4 py-2`}
                                    >
                                        <div>Start typing...</div>
                                    </div>
                                )}
                                {isCreatable && query?.length > 0 ? (
                                    <Combobox.Option
                                        onMouseDown={(
                                            e: React.MouseEvent<HTMLLIElement>
                                        ) => {
                                            e.preventDefault();

                                            let opt: T = formatCreatableValue(
                                                query
                                            ) as T;

                                            const curValue = (value ||
                                                []) as T[];

                                            handleChange([...curValue, opt]);
                                        }}
                                        disabled={
                                            isCreateValueValid
                                                ? !isCreateValueValid(query)
                                                : false
                                        }
                                        className={({ active }) =>
                                            `relative cursor-default select-none px-4 py-2 ${
                                                active
                                                    ? "bg-cycle-blue text-cycle-white"
                                                    : "text-cycle-gray-accent dark:text-cycle-gray-light"
                                            }`
                                        }
                                        value={formatCreatableValue(query)}
                                    >
                                        <div>
                                            {creatablePlaceholder(
                                                query,
                                                !isCreateValueValid?.(query) ||
                                                    false
                                            )}
                                        </div>
                                    </Combobox.Option>
                                ) : null}

                                {!isCreatable &&
                                Object.values(filterOptions(query)).flat()
                                    ?.length === 0 ? (
                                    <Combobox.Option
                                        disabled
                                        value={null}
                                        className={() =>
                                            `text-cycle-gray-accent dark:text-cycle-gray-light relative min-h-[40px] cursor-default select-none px-4 py-2 text-base tracking-normal`
                                        }
                                    >
                                        {emptyMessage || "No options..."}
                                    </Combobox.Option>
                                ) : null}

                                {Object.entries(filterOptions(query))?.map(
                                    ([group, options], idx) => {
                                        return (
                                            <Fragment key={`${group}-${idx}`}>
                                                {group !== "" && (
                                                    <div className="bg-cycle-gray-light dark:bg-cycle-black-accent p-2">
                                                        {group}
                                                    </div>
                                                )}
                                                {options?.map((o) => (
                                                    <Combobox.Option
                                                        disabled={
                                                            !isOptionValid?.(o)
                                                        }
                                                        className={({
                                                            active,
                                                            disabled,
                                                        }) =>
                                                            classNames(
                                                                `hover:bg-cycle-blue tracking normal relative min-h-[40px] cursor-default select-none pl-6 pr-4 text-base tracking-normal`,
                                                                active
                                                                    ? "!bg-cycle-blue text-cycle-white"
                                                                    : "text-cycle-gray-accent dark:text-cycle-white",
                                                                disabled &&
                                                                    "bg-cycle-gray-light text-cycle-gray/50 hover:bg-cycle-gray-light/80 cursor-not-allowed dark:bg-black"
                                                            )
                                                        }
                                                        key={JSON.stringify(o)}
                                                        value={o}
                                                    >
                                                        <div className="w-full">
                                                            {formatOption(o)}
                                                        </div>
                                                    </Combobox.Option>
                                                ))}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </>
                        </Combobox.Options>
                    </Transition>
                </div>
            )}
        </Combobox>
    );
}
