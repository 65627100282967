import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { forwardRef } from "react";

export type TextAreaInputProps = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
> & {
    rows?: number;
    /**
     * Adds a box after the input field containing the specified string.
     * Useful for adding additional information to a TextAreaInput.
     */
    suffix?: string;
    /**
     * A FontAwesome Icon
     */
    icon?: IconProp;
    /**
     * Sets the TextAreaInput into an error state, usually indicated by a red border.
     */
    error?: boolean;
};

export const TextAreaInput = forwardRef<
    HTMLTextAreaElement,
    TextAreaInputProps
>(function TextAreaInput(
    { rows = 2, suffix, icon, error, className, ...inputProps },
    ref
) {
    const inputComponent = (
        <textarea
            aria-invalid={error ? "true" : "false"}
            ref={ref}
            rows={rows}
            className={classNames(
                className,
                "border-cycle-gray-light text-cycle-gray peer w-full min-w-0 rounded-md  border px-4 py-2 outline-none",
                // light disabled
                "disabled:bg-cycle-gray/10 disabled:cursor-not-allowed",
                // focus
                "focus:ring-cycle-blue focus:border-transparent focus:ring-2",
                // dark
                "dark:bg-cycle-gray-accent dark:text-cycle-white-accent dark:border-transparent",
                // dark disabled
                "dark:disabled:bg-black dark:disabled:text-opacity-50",

                {
                    "rounded-r-none border-r-0": !!suffix || !!icon,
                },
                { "focus:ring-cycle-blue": !error },
                {
                    "ring-error border-transparent ring-2": !!error,
                }
            )}
            {...inputProps}
        />
    );

    if (!suffix && !icon) {
        return inputComponent;
    }

    return (
        <div className="relative flex" data-testid="textInputWrapper">
            {inputComponent}
            {suffix || icon ? (
                <div
                    data-testid="textInputSuffix"
                    className={classNames(
                        { "bg-mist": !!suffix },
                        { "bg-white": !!icon },
                        "peer-disabled:bg-cycle-gray peer-disabled:cursor-not-allowed",
                        {
                            "ring-error focus:ring-cycle-blue border-transparent px-2 py-1 text-sm ring-2 focus:ring-2":
                                !!error,
                        },
                        /**
                         * Adding ring focus to the div tag when there is an icon or suffix specified.
                         */
                        "peer-focus:ring-cycle-blue rounded-r-sm border border-l-0 px-2 py-1 text-sm peer-focus:border-transparent peer-focus:ring-2"
                    )}
                >
                    {suffix}
                    {icon ? <FontAwesomeIcon icon={icon} /> : null}
                </div>
            ) : null}
        </div>
    );
});
