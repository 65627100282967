import { ComponentType, ElementType, useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    Placement,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    arrow,
    FloatingArrow,
} from "@floating-ui/react";
import classNames from "classnames";

type TooltipProps = {
    className?: string;
    popupClassName?: string;
    children: React.ReactNode;
    message: React.ReactNode;
    /**
     * Accepts disabled prop
     * Tooltip will not render if disabled
     */
    disabled?: boolean;
    placement?: Placement;
    as?: ComponentType | ElementType;
};

/**
 * # Tooltip Component
 * Provides extra information about the item it's wrapping
 * that appears when hovered over.
 */
export function Tooltip({
    children,
    message,
    disabled,
    className,
    popupClassName,
    placement = "top",
    as: Component = "div",
}: TooltipProps) {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        strategy: "fixed",
        placement: placement,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role,
    ]);

    return (
        <>
            <Component
                ref={refs.setReference}
                {...getReferenceProps()}
                className={classNames(
                    className,
                    disabled ? "cursor-auto " : ""
                )}
            >
                {children}
            </Component>
            {isOpen && !disabled && (
                <div
                    ref={refs.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: "max-content",
                    }}
                    className={classNames(
                        popupClassName,
                        "bg-cycle-gray animate-fade-in-fast text-cycle-gray-light z-40 whitespace-nowrap rounded-lg stroke-1 px-4 py-2 text-center text-xs"
                    )}
                    {...getFloatingProps()}
                >
                    {message}
                    <FloatingArrow
                        className="fill-cycle-gray"
                        ref={arrowRef}
                        context={context}
                    />
                </div>
            )}
        </>
    );
}
