import {
    GetRolesApiArg,
    Role,
    useGetHubMembershipQuery,
    useGetRolesQuery,
} from "~/services/cycle";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { ResourceComboBox } from "../common/forms";

type RoleSelectProps<MULTI extends boolean = false> = {
    value: MULTI extends true ? Array<string> : string | undefined;
    isNullable?: boolean;
    disabled?: boolean;
    onChange: MULTI extends true
        ? (ids: string[], roles: Role[]) => void
        : (id: string | undefined, role: Role | undefined) => void;
    /** If provided, will validate the option, and disable if not valid. */
    skip?: boolean;
    placeholder?: string;
    isOptionValid?: (r: Role) => boolean;
    filterResult?: (es: Role[]) => Role[];
    filter?: GetRolesApiArg["filter"];
    required?: boolean;
    className?: string;
    multiple?: MULTI;
};

export function RolesSelect<MULTI extends boolean = false>({
    value,
    onChange,
    skip,
    multiple,
    isNullable,
    placeholder,
    disabled,
    filter,
    filterResult = (envs) => envs,
    isOptionValid,
    className,
}: RoleSelectProps<MULTI>) {
    // TODO - there is a risk that the environment set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific environment
    // if not initially returned and then merge them.
    const { currentData: roles, error } = useGetRolesQuery(
        {
            sort: ["-rank"],
            page: {
                number: 1,
                size: 100,
            },
            filter,
        },
        { skip: skip }
    );

    const { data: self, error: selfError } = useGetHubMembershipQuery({
        include: ["roles"],
    });

    const viewerRank =
        self?.includes?.roles?.[self?.data?.role_id || ""]?.rank || 0;

    if (error) {
        throw error;
    }
    if (selfError) {
        throw selfError;
    }

    return (
        <ResourceComboBox
            className={className}
            filterFields={["id", "name"]}
            value={value}
            multiple={multiple}
            onChange={onChange}
            isNullable={isNullable}
            placeholder={placeholder || "No Role Selected"}
            disabled={disabled}
            resources={filterResult(roles?.data || [])}
            formatDisplayValue={(e) => {
                if (!e) return "";

                return e.name || "";
            }}
            formatOption={(e) => {
                return (
                    <FormattedOption label={e.name}>
                        <div className="flex gap-2"></div>
                    </FormattedOption>
                );
            }}
            isOptionValid={(r) => {
                if (isOptionValid) {
                    return isOptionValid(r);
                }

                return r?.rank <= viewerRank;
            }}
        />
    );
}
