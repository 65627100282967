import { IconDefinition, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import React, { FC, PropsWithChildren } from "react";

type StyledFormDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
    /**
     * Whether or not to render a transparent overlay.
     *
     * @default true
     */
    hasBackdrop?: boolean;
    title?: React.ReactNode;
    description?: React.ReactNode;
    className?: string;
    panelClassName?: string;
    sectionClassName?: string;
    contentClassName?: string;
    bodyClassName?: string;
    aside?: React.ReactNode;
    titleInline?: React.ReactNode;
    icon?: IconDefinition;
};

export const StyledFormDialog: FC<PropsWithChildren<StyledFormDialogProps>> = ({
    isOpen,
    onClose = () => null,
    title,
    description,
    className,
    panelClassName,
    sectionClassName,
    contentClassName,
    bodyClassName,
    children,
    aside,
    titleInline,
    icon,
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className={classNames("relative z-50 ", className)}
        >
            <div
                className="fixed inset-0 bg-black/30 backdrop-blur-[2px]"
                aria-hidden="true"
            />
            <div className="fixed inset-0 flex flex-col items-center ">
                <Dialog.Panel
                    className={classNames(
                        panelClassName,
                        "relative flex min-h-[70%] w-[70%]  min-w-[75rem] overflow-y-hidden rounded-b-lg p-16"
                    )}
                >
                    <button
                        onClick={onClose}
                        className={classNames(
                            "bg-cycle-gray text-cycle-white absolute top-3 right-16 flex h-10 w-10 items-center justify-center rounded-full opacity-90 transition-colors",
                            "hover:text-cycle-blue hover:bg-cycle-black"
                        )}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <section
                        className={classNames(
                            "relative flex h-full w-full grow flex-col rounded-lg bg-white text-left transition-all",
                            "dark:bg-cycle-black-accent overflow-hidden  dark:text-white",
                            sectionClassName
                        )}
                    >
                        <div className="flex h-full flex-row gap-8 ">
                            <div
                                className={classNames(
                                    bodyClassName,
                                    aside ? " w-3/4" : "w-full",
                                    "h-full px-8",
                                    "overflow-auto",
                                    "[&_footer]:dark:bg-cycle-black-accent  [&_footer]:absolute [&_footer]:left-4 [&_footer]:right-4 [&_footer]:bottom-0 [&_footer]:bg-white [&_footer]:pb-4"
                                )}
                            >
                                <div className="dark:bg-cycle-black-accent sticky top-0 z-20 flex justify-between bg-white pt-8 pb-4">
                                    <Dialog.Title className="flex w-full rounded-t-lg text-2xl">
                                        <div className="flex w-full items-center gap-4">
                                            {icon ? (
                                                <FontAwesomeIcon
                                                    icon={icon}
                                                    className="text-cycle-blue"
                                                />
                                            ) : null}
                                            {title}
                                        </div>
                                    </Dialog.Title>
                                    {titleInline}
                                </div>
                                {description && (
                                    <Dialog.Description>
                                        {description}
                                    </Dialog.Description>
                                )}

                                <div
                                    className={classNames(
                                        "pl-1",
                                        "mb-[6rem]",
                                        contentClassName
                                    )}
                                >
                                    {children}
                                </div>
                            </div>

                            {aside ? (
                                <aside
                                    className={classNames(
                                        "h-full w-1/4  py-8 pr-8"
                                    )}
                                >
                                    {aside}
                                </aside>
                            ) : null}
                        </div>
                    </section>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};
