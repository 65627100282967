import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ResourceLayout } from "../layout/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { faUp } from "@fortawesome/pro-solid-svg-icons";

type UpsellProps = {
    title: string;
    icon: string | IconDefinition;
    description: string;
};

export function Upsell({ title, icon, description }: UpsellProps) {
    return (
        <ResourceLayout>
            <div className="mt-12 flex flex-col items-center justify-center">
                <div className="flex justify-center text-center">
                    <h1 className="text-4xl font-extrabold">{title}</h1>
                </div>

                <p className="max-w-[50%] text-center text-xl">{description}</p>

                {typeof icon === "string" ? (
                    <img className="my-10 max-w-xl" src={icon} />
                ) : (
                    <FontAwesomeIcon icon={icon} />
                )}
                <ButtonLink flavor="primary" icon={faUp} to="/hub/plans">
                    Upgrade Hub Now
                </ButtonLink>
            </div>
        </ResourceLayout>
    );
}
