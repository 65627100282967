import { PageControl } from "@cycleplatform/ui/components/pagination";
import { PanelTitle } from "@cycleplatform/ui/components/panels";
import classNames from "classnames";
import React from "react";

type TableHeaderProps = {
    title: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
};

export function TableHeader({ title, children, className }: TableHeaderProps) {
    return (
        <div
            className={classNames(
                className,
                "flex flex-1 items-center justify-between"
            )}
        >
            <PanelTitle title={title} />
            <div className="flex items-center justify-end gap-4">
                {children}
            </div>
        </div>
    );
}
