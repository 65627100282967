import { Switch } from "@headlessui/react";
import classNames from "classnames";

type FormToggleProps<T> = {
    value: T;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    error?: boolean;
    className?: string;
    activeClassName?: string;
};

export const FormToggle = <T,>({
    onChange,
    value,
    disabled,
    error,
    className,
    activeClassName,
}: FormToggleProps<T>) => {
    return (
        <Switch
            aria-invalid={error ? "true" : "false"}
            checked={typeof value === "boolean" ? value : false}
            onChange={(e) => {
                if (!disabled) {
                    onChange(e);
                }
            }}
            className={classNames(
                className,
                "relative inline-flex h-6 w-11 items-center rounded-full",
                value && !disabled && activeClassName,
                value && "bg-cycle-blue",
                !value && "bg-cycle-gray-accent",
                disabled && "cursor-not-allowed bg-opacity-50 ",
                "disabled:cursor-not-allowed disabled:bg-opacity-50",

                {
                    "ring-error border-transparent ring-2": !!error,
                }
            )}
        >
            <span
                className={classNames(
                    value ? "translate-x-6" : "translate-x-1",
                    "inline-block h-4 w-4 transform rounded-full bg-white transition"
                )}
            />
        </Switch>
    );
};
