import { ComponentPropsWithRef, createContext, useEffect } from "react";
import { Button, generateButtonClasses } from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Popover } from "@headlessui/react";
import {
    useFloating,
    offset,
    flip,
    shift,
    autoUpdate,
} from "@floating-ui/react";
import { PushAndHoldButton } from "./PushAndHoldButton";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LoaderButton } from ".";

type PopoverButtonProps = ComponentPropsWithRef<typeof Button> & {
    /** Content of the popout menu activated when clicking the dropdown of the button */
    popout: React.ReactNode;
    panelClassName?: string;
    icon: IconProp;
    isLoading?: boolean;
    pushAndHold?: boolean;
    tooltip?: string;
    onClick?: () => void;
    onClose?: () => void;
};

export const PopoverButtonContext = createContext<{
    isOpen: boolean;
    close: () => void;
}>({ isOpen: false, close: () => null });

export function PopoverButton({
    popout,
    children,
    pushAndHold,
    onClick,
    onClose,
    tooltip,
    isLoading,
    panelClassName,
    ...buttonProps
}: PopoverButtonProps) {
    const { refs, floatingStyles } = useFloating({
        placement: "bottom-end",
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    return (
        <div className={classNames("flex")}>
            {pushAndHold ? (
                <PushAndHoldButton
                    {...buttonProps}
                    onClick={onClick ? onClick : () => null}
                    tooltip={tooltip}
                    isLoading={isLoading}
                    className={classNames(
                        buttonProps.className,
                        "rounded-r-none border-r-0"
                    )}
                >
                    {children}
                </PushAndHoldButton>
            ) : (
                <LoaderButton
                    {...buttonProps}
                    isLoading={isLoading}
                    onClick={onClick}
                    className={classNames(
                        buttonProps.className,
                        "rounded-r-none border-r-0"
                    )}
                >
                    {children}
                </LoaderButton>
            )}

            <Popover className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button
                            disabled={buttonProps.disabled}
                            ref={refs.setReference}
                            className={classNames(
                                generateButtonClasses(buttonProps),
                                buttonProps.flavor === "primary"
                                    ? " border-l-cycle-blue-accent"
                                    : "border-cycle-blue",
                                "flex items-center rounded-lg rounded-l-none border-l !px-2"
                            )}
                        >
                            <span className="">
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </Popover.Button>
                        <PopoverButtonContext.Provider
                            value={{
                                isOpen: open,
                                close,
                            }}
                        >
                            <Popover.Panel
                                ref={refs.setFloating}
                                style={floatingStyles}
                                className={classNames(
                                    "dark:bg-cycle-gray dark:text-cycle-white animate-fade-in-fast a bg-cycle-white z-10 min-w-[25rem] rounded-lg stroke-1 p-4 shadow-lg",
                                    panelClassName
                                )}
                            >
                                {onClose && <OnCloseHandle onClose={onClose} />}{" "}
                                {popout}
                            </Popover.Panel>
                        </PopoverButtonContext.Provider>
                    </>
                )}
            </Popover>
        </div>
    );
}

function OnCloseHandle(props: { onClose: () => void }) {
    useEffect(() => {
        return () => props.onClose();
    }, []);

    return null;
}
