export const flattenObj = (object: Record<string, any>) => {
    const result: Record<string, any> = {};
    for (const i in object) {
        if (typeof object[i] === "object" && !Array.isArray(object[i])) {
            const temp = flattenObj(object[i]);
            for (const j in temp) {
                result[i + "." + j] = temp[j];
            }
        } else {
            result[i] = object[i];
        }
    }
    return result;
};

export function capitalizeWord(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function capitalizeEachWord(words: string) {
    return words
        .split(" ")
        .map((w) => capitalizeWord(w))
        .join(" ");
}

export const paramsSerializer = () => (params: Record<string, unknown>) => {
    const keyVal = Object.entries(flattenObj(params)).map((e) => [e[0], e[1]]);

    const paramKeyTransform = (str: string) => {
        if (!str.includes(".")) {
            return str;
        }
        return str.replace(".", "[") + "]";
    };

    const transform = keyVal.reduce((acc, cur) => {
        if (cur[1] !== undefined) {
            return [...acc, [paramKeyTransform(cur[0]), cur[1]]];
        }
        return acc;
    }, []);

    return new URLSearchParams(Object.fromEntries(transform)).toString();
};

export function getHumanizedNumber(val?: number | null) {
    if (!val) {
        return "";
    }

    return val.toLocaleString();
}

export function formatIdAsName(id: string) {
    return `(...${id.slice(-4)})`;
}

export function checkIsJson(maybeJson: string) {
    try {
        JSON.parse(maybeJson);
    } catch (e) {
        return false;
    }
    return true;
}

export function pluralize(
    arr: unknown[],
    singularWord: string,
    pluralWord: string
) {
    if (arr && arr.length > 1) {
        return pluralWord;
    } else {
        return singularWord;
    }
}

export function getResourcesById<T extends { id: string }>(resources?: T[]) {
    return resources?.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.id]: cur,
        };
    }, {} as Record<string, T>);
}

export function getResourcesByIdentifier<T extends { identifier: string }>(
    resources?: T[]
) {
    return resources?.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.identifier]: cur,
        };
    }, {} as Record<string, T>);
}
