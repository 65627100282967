import { $trace } from "@cycleplatform/core/util/log";
import logo from "@cycleplatform/ui/images/cycle-gear-black.svg";

export function pushCycleNotification(
    title: string,
    message: string,
    tag?: string
) {
    $trace(`pushing browser notification: ${title}`);
    return new Notification(title, {
        body: message,
        icon: logo,
        badge: logo,
        tag,
    });
}

export function pushCycleNotificationIfEnabled(
    title: string,
    message: string,
    tag?: string
) {
    if (Notification.permission !== "granted") {
        return;
    }
    return pushCycleNotification(title, message, tag);
}
