import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { generateButtonClasses } from "../buttons";
import { TextInput, TextInputProps } from "./TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CopyInputProps = TextInputProps & {
    value?: string;
    wrapperClassName?: string;
    buttonClassName?: string;
    prefixClassName?: string;
    suffixClassName?: string;
};

export function CopyInput({
    value,
    prefix,
    className,
    prefixClassName,
    suffixClassName,
    wrapperClassName,
    buttonClassName,
    ref: _ref,
    ...textInputProps
}: CopyInputProps) {
    return (
        <div
            className={classNames(
                wrapperClassName,
                " group/copy-input min-h-[40px]"
            )}
        >
            <div
                className={classNames(
                    "border-cycle-gray-light dark:border-cycle-blue flex rounded-md text-base",
                    "hover:text-cycle-white",
                    "group-focus-within/copy-input:ring-cycle-blue group-focus-within/copy-input:border-transparent group-focus-within/copy-input:ring-2"
                )}
            >
                <TextInput
                    {...textInputProps}
                    className={classNames(
                        className,
                        "!rounded-r-none border-r-0 !py-0",
                        "!ring-0"
                    )}
                    value={value}
                    readOnly
                    prefix={prefix}
                    prefixClassName={classNames(prefixClassName)}
                    suffixClassName={classNames(suffixClassName)}
                />
                {/* Needs to be a "fake" button to prevent it from being disabled in a disabled fieldset */}
                <div
                    role="button"
                    tabIndex={1}
                    className={classNames(
                        buttonClassName,
                        generateButtonClasses({
                            full: false,
                            flavor: "default",
                            className: buttonClassName,
                            disabled: false,
                        }),
                        "border-cycle-gray-light dark:border-cycle-blue min-h-[40px] rounded-l-none px-4 text-sm",
                        "hover:text-cycle-white",
                        "group-focus-within/copy-input:ring-cycle-blue group-focus-within/copy-input:border-transparent group-focus-within/copy-input:ring-2"
                    )}
                    onClick={() => {
                        navigator.clipboard.writeText(value || "");
                    }}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </div>
            </div>
        </div>
    );
}
