import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import localForage from "localforage";
import { z } from "zod";
import { RootState } from "~/store";
import merge from "deepmerge";
import {
    $error,
    $warn,
    CycleLogLevel,
    setCycleLogger,
} from "@cycleplatform/core/util/log";
import type { DeepPartial } from "@cycleplatform/core/util/types";

/**
 * Settings locally stored in the user's browser.
 *
 * ## WARNING
 * Changing the shape of the App Settings object could cause the user's settings to be erased.
 * Care should be taken when changing anything about this object.
 */
const AppSettings = z.object({
    environments: z.object({
        listMode: z.enum(["grid", "table"]),
    }),
    hubs: z.object({
        activeId: z.union([z.string(), z.undefined()]),
    }),
    /** The theme of the app. Auto means it's set to the user's OS preferences */
    theme: z.enum(["dark", "light", "auto"]),
    logLevel: z.nativeEnum(CycleLogLevel),
});

type AppSettings = z.infer<typeof AppSettings>;

const initialState: AppSettings = {
    environments: {
        listMode: "grid",
    },
    hubs: {
        activeId: undefined,
    },
    theme: "auto",
    logLevel: CycleLogLevel.None,
};

/**
 * Webapp settings, local to the computer they're viewing on (for now)
 */
const appSettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        resetAppSettings: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(loadAppSettings.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            return { ...action.payload };
        });
        builder.addCase(saveAppSettings.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            return { ...action.payload };
        });
    },
});

export const loadAppSettings = createAsyncThunk("settings/load", async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const hubId = searchParams.get("hub");

    localForage.config({ storeName: "cycle" });

    const sessionActiveHub = sessionStorage.getItem("activeHub");

    const rawSettings = await localForage.getItem<unknown>("settings");
    const parsedSettings = AppSettings.safeParse(rawSettings);

    let settings = initialState;

    if (parsedSettings.success) {
        settings = parsedSettings.data;
    } else {
        $warn(
            `Unable to parse app settings from local database: ${parsedSettings.error.message}`
        );
    }

    applyAppTheme(settings.theme);
    setCycleLogger(settings.logLevel);

    return {
        ...settings,
        hubs: {
            // prioritize url params id, then session storage activeId, then local storage activeId
            activeId: hubId || sessionActiveHub || settings.hubs.activeId,
        },
    };
});

export const saveAppSettings = createAsyncThunk(
    "settings/save",
    async (settings: DeepPartial<AppSettings>, thunkApi) => {
        localForage.config({ storeName: "cycle" });

        const state = thunkApi.getState() as RootState;
        const mergedState = merge(state.appSettings, settings) as AppSettings;

        // Set session storage ID - if set, this is the portals' source of truth (see loadAppSettings)
        try {
            sessionStorage.setItem(
                "activeHub",
                mergedState.hubs.activeId || ""
            );
        } catch {
            $error("Unable to save active hub id to session storage");
        }

        return localForage.setItem<AppSettings>("settings", mergedState);
    }
);

export const setAppTheme = createAsyncThunk(
    "settings/setTheme",
    async (payload: AppSettings["theme"], { dispatch }) => {
        applyAppTheme(payload);
        dispatch(saveAppSettings({ theme: payload }));
    }
);

export const setLogLevel = createAsyncThunk(
    "settings/setLogLevel",
    async (payload: AppSettings["logLevel"], { dispatch }) => {
        setCycleLogger(payload);
        dispatch(saveAppSettings({ logLevel: payload }));
    }
);

export const applyAppTheme = (theme: AppSettings["theme"]) => {
    if (
        theme === "dark" ||
        (theme === "auto" &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
};

export const selectAppliedTheme = (state: RootState) => {
    switch (state.appSettings.theme) {
        case "auto":
            return window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "dark"
                : "light";
        default:
            return state.appSettings.theme;
    }
};

// Action creators are generated for each case reducer function
export const {
    actions: { resetAppSettings },
    reducer: appSettingsReducer,
} = appSettingsSlice;
