import { useAppSelector } from "~/hooks";
import { TierPlan, useGetBillingTiersQuery } from "~/services/cycle";
import { selectActiveHub } from "../slice";

export function useTierFeature(
    feature: keyof TierPlan["advanced_features"]
): boolean | undefined {
    const { data: tiers, error } = useGetBillingTiersQuery();
    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id || null;

    const tier = tiers?.data?.find((t) => t.id === currentTierId);

    if (error) {
        throw error;
    }

    if (!tier) {
        return undefined;
    }

    return tier.advanced_features[feature];
}
