import classNames from "classnames";
import { ComponentType, ElementType } from "react";

type SkeletonTextProps = {
    className?: string;
    as?: ComponentType | ElementType;
    size?: "sm" | "md" | "lg" | "full";
};

export function SkeletonText({
    className,
    size = "full",
    as: Component = "div",
}: SkeletonTextProps) {
    return (
        <Component
            className={classNames(
                className,
                {
                    "w-24": size === "sm",
                    "w-40": size === "md",
                    "w-72": size === "lg",
                    "w-full": size === "full",
                },
                "from-cycle-gray-light via-cycle-gray/60 to-cycle-gray-light animate-shimmer my-2 h-2 self-center rounded-lg bg-gradient-to-r bg-[length:1000px_100%] opacity-50"
            )}
        />
    );
}
