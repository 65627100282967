import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { PageControl } from "@cycleplatform/ui/components/pagination";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { TableHeader } from "@cycleplatform/ui/components/tables";
import { NavigateFunction } from "react-router-dom";

export const tableFallbackWrapper = (header: string) => {
    return (children: React.ReactNode) => {
        return (
            <Panel>
                <TableHeader title={header}>
                    <PageControl
                        page={{
                            size: 1,
                            number: 1,
                            urlParam: "page",
                        }}
                        itemCount={0}
                    />
                </TableHeader>
                <PanelContent>{children}</PanelContent>
            </Panel>
        );
    };
};

export const dialogFallbackWrapper = (
    nav: NavigateFunction,
    header?: string
) => {
    return (children: React.ReactNode) => {
        return (
            <StyledFormDialog
                title={header}
                isOpen={true}
                onClose={() => {
                    nav(
                        window.location.href
                            .split(window.location.origin)[1]
                            .split("?")[0]
                    );
                }}
            >
                <div className="absolute left-0 right-0 top-1/4 rounded ">
                    {children}
                </div>
            </StyledFormDialog>
        );
    };
};

export const panelFallbackWrapper = (header: string) => {
    return (children: React.ReactNode) => {
        return (
            <Panel>
                <PanelTitle title={header}></PanelTitle>
                <PanelContent>{children}</PanelContent>
            </Panel>
        );
    };
};
