import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "~/hooks";
import { selectAuthToken } from "~/modules/auth/slice";
import { useGetTokenMutation } from "~/services/auth";
import { $info } from "@cycleplatform/core/util/log";
import { SplashScreen } from "./SplashScreen";
import { router } from "~/routes/Routes";

type ProtectedRouteProps = {
    capabilities?: string[];
    redirectPath?: string;
};

export function ProtectedRoute({
    redirectPath = "/logout",
}: ProtectedRouteProps) {
    // Use the fixed cache key for this mutation to see if we are still loading one.
    const [, { isLoading }] = useGetTokenMutation({
        fixedCacheKey: "authToken",
    });
    const token = useAppSelector(selectAuthToken);

    useEffect(() => {
        if (!token && !isLoading) {
            $info(`no access token...redirecting to ${redirectPath}`);
            // Add desired route to URL for redirect after succesful login
            const currentUrl = new URL(window.location.href);
            const currentRoute = encodeURIComponent(
                `${currentUrl.pathname}${currentUrl.search}`
            );
            const path =
                currentUrl.pathname !== "/"
                    ? `${redirectPath}?redirect=${currentRoute}`
                    : redirectPath;
            // https://github.com/remix-run/react-router/issues/7634#issuecomment-1306650156
            // useNavigate causes the component it's used in to rerender each time it's used.
            // Since this is a layout component, that was causing the whole app to rerender
            // any time you navigated, causing huge performance issues. This method gets around that.
            router.navigate(path, { replace: true });
            return;
        }
    }, [token, redirectPath, isLoading]);

    if (!token) {
        return <SplashScreen message="Loading Route..." />;
    }

    return <Outlet />;
}
