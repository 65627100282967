import classNames from "classnames";

type StyledTableRowProps = {
    className?: string;
    children: React.ReactNode;
};

export function StyledTableHead({ children, className }: StyledTableRowProps) {
    return (
        <thead
            className={classNames(
                className,
                "border-b border-[rgba(0,0,0,0.04)]",
                "dark:border-[rgba(255,255,255,0.04)]",
                "z-10"
            )}
        >
            <tr className="h-[3rem]">{children}</tr>
        </thead>
    );
}
