import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import {
    faExclamationCircle,
    faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

type InfoPanelProps = {
    type?: "info" | "alert" | "warning" | "error" | "success";
    omitIcon?: boolean;
    className?: string;
    children: React.ReactNode;
};

export function InfoPanel({
    children,
    omitIcon,
    className,
    type = "info",
}: InfoPanelProps) {
    const icon =
        type === "info"
            ? faInfoCircle
            : type === "warning" || type === "alert"
            ? faWarning
            : faExclamationCircle;
    return (
        <div
            className={classNames(
                className,
                "my-2 flex items-center gap-2 rounded-lg border p-2 text-sm",
                {
                    "border-warning-accent [&>svg]:text-warning-accent  border":
                        type === "alert",
                    "border-warning ": type === "warning",
                    "border-cycle-blue ": type === "info",
                    "border-error ": type === "error",
                    "border-success ": type === "success",
                }
            )}
        >
            {!omitIcon ? (
                <FontAwesomeIcon
                    icon={icon}
                    className={classNames("mr-0", {
                        "text-warning-accent": type === "alert",
                        "text-warning": type === "warning",
                        "text-cycle-blue ": type === "info",
                        "text-error ": type === "error",
                        "text-success ": type === "success",
                    })}
                />
            ) : null}{" "}
            <span>{children}</span>
        </div>
    );
}
