import { $warn, $error } from "@cycleplatform/core/util/log";
import * as Sentry from "@sentry/browser";
import { UseFormSetError } from "react-hook-form";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { isCycleApiError } from "./helpers";

export const RHF_GLOBAL_ERROR = "root._error" as const;

type RhfConfigType = {
    mode: "onBlur";
    reValidateMode: "onChange";
};
export const rhfConfig: RhfConfigType = {
    mode: "onBlur",
    reValidateMode: "onChange",
};

export const handleSubmitError =
    (
        // We truly don't care about the value of the record here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError?: UseFormSetError<any>,
        options?: {
            /** A string provided if the main form values are under a different path */
            pathPrefix?: string;
            sourceOverride?: Record<string, string>;
        }
    ) =>
    (error: unknown) => {
        if (isCycleApiError(error)) {
            switch (error.status) {
                case 422: {
                    const err = convertCycleFieldErrorToRhfError(
                        error.data.error,
                        options?.pathPrefix
                    );
                    if (!err) {
                        const value = formatCycleError(error.data.error);
                        $warn("field error submitting form: ", error);
                        setError?.(RHF_GLOBAL_ERROR as never, {
                            message: value,
                        });
                    } else {
                        const fieldOverride =
                            options?.sourceOverride?.[err.field] || err.field;
                        $warn(
                            `field error submitting form on field ${fieldOverride}: `,
                            error
                        );
                        setError?.(fieldOverride as never, {
                            message: err.value,
                        });
                    }

                    return;
                }
                case 500:
                case 501:
                case 502:
                    $error(
                        "Error submitting form. Exception captured in Sentry.",
                        error
                    );
                    Sentry.captureException(error);
                    setError?.(RHF_GLOBAL_ERROR as never, {
                        message: formatCycleError(error.data.error, true),
                    });
                    return;
                default:
                    $error("error submitting form:", error);
                    setError?.(RHF_GLOBAL_ERROR as never, {
                        message: formatCycleError(error.data.error, true),
                    });
                    return;
            }
        }

        if (error instanceof Error) {
            $error(`error submitting form: ${error.message}`);
            setError?.(RHF_GLOBAL_ERROR as never, { message: error.message });
            return;
        }

        $error("error submitting form:", error);

        setError?.(RHF_GLOBAL_ERROR as never, {
            message: "An unknown error occurred. Please try again.",
        });
    };

export function convertCycleFieldErrorToRhfError(
    error: components["schemas"]["Error"],
    pathPrefix?: string
) {
    if (!error.source) {
        return undefined;
    }

    // slice(2) skips "", "data" from the string "/data/xyz"
    let field = error.source.split("/").slice(2).join(".");
    if (pathPrefix) {
        field = `${pathPrefix}.${field}`;
    }
    const value = formatCycleError(error);
    $warn("field error submitting form:", error.code, value);
    return { field, value };
}

export function formatCycleError(
    error: components["schemas"]["Error"],
    withCode?: boolean
) {
    return `${error.title}${error.detail ? ` - ${error.detail}` : ""}${
        withCode ? `(${error.code})` : ""
    }`;
}
