import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "~/services/auth";
import { RootState } from "~/store";

type AuthState = {
    // The active auth token
    token?: string;
};

const initialState: AuthState = {};

/**
 * We're keeping track of our own token for more reliability.
 * Using the auth API + a fixed storage key can lead to issues
 * if for example, the caching algorithm determines nobody is listening
 * anymore and decides to remove the cached results. This way, we guarantee
 * that we are in control of the auth token.
 */
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<{ token?: string }>) => {
            state.token = action.payload.token;
        },
    },
    // If we fetch a token, save it in our easier to manage store.
    extraReducers(builder) {
        builder.addMatcher(
            authApi.endpoints.getToken.matchFulfilled,
            (state, action) => {
                state.token = action.payload.token;
            }
        );
    },
});

export const selectAuthToken = (state: RootState) => state.auth.token;

// Action creators are generated for each case reducer function
export const {
    actions: { setAuthToken },
    reducer: authReducer,
} = authSlice;
