// Determines base URLs depending on which cluster this portal is running in.
// If the environment variables are provided, those will take precedence.
import { PORTAL_API_DOMAIN_OVERRIDES } from "./portal";
import { setSplashScreen } from "~/modules/system/slice";

export const clusterUrls = {
    CYCLE_API_URL: import.meta.env.VITE_CYCLE_API_URL,
    CYCLE_AUTH_URL: import.meta.env.VITE_CYCLE_AUTH_URL,
    CYCLE_HUB_NOTIFICATION_PIPE_URL: import.meta.env
        .VITE_CYCLE_HUB_NOTIFICATION_PIPE_URL,
    CYCLE_ACCOUNT_NOTIFICATION_PIPE_URL: import.meta.env
        .VITE_CYCLE_ACCOUNT_NOTIFICATION_PIPE_URL,
    fetchedTime: null as Date | null,
};

export const getThinClientUrl = () =>
    PORTAL_API_DOMAIN_OVERRIDES[window.location.hostname as "localhost"] || "";

export const getClusterUrls = async () => {
    // Import dynamically to avoid circular dependency on store
    // authAPI also uses getClusterURLs so this is neccessary if we want to use dispatch
    const { store } = await import("~/store");

    if (clusterUrls.fetchedTime) {
        return clusterUrls;
    }

    if (
        clusterUrls.CYCLE_API_URL &&
        clusterUrls.CYCLE_AUTH_URL &&
        clusterUrls.CYCLE_HUB_NOTIFICATION_PIPE_URL
    ) {
        // If we set the variables manually, allow them to override
        // (but only if all are present.)
        clusterUrls.fetchedTime = new Date();
        return clusterUrls;
    }

    const clusterDomainsEndpoint = `${getThinClientUrl()}/cluster-domains`;

    return fetch(clusterDomainsEndpoint)
        .then((res) => {
            if (!res.ok) {
                throw new Error(
                    `Error fetching cluster URLs. Received ${res.status}`
                );
            }
            return res.json();
        })
        .then((resp) => {
            clusterUrls.CYCLE_API_URL = resp?.data?.api
                ? `https://${resp.data.api}/`
                : clusterUrls.CYCLE_API_URL;

            clusterUrls.CYCLE_AUTH_URL = resp?.data?.auth
                ? `https://${resp.data.auth}`
                : clusterUrls.CYCLE_AUTH_URL;

            clusterUrls.CYCLE_HUB_NOTIFICATION_PIPE_URL = resp?.data?.api
                ? `wss://${resp.data.api}/v1/hubs/current/notifications`
                : clusterUrls.CYCLE_HUB_NOTIFICATION_PIPE_URL;

            clusterUrls.CYCLE_ACCOUNT_NOTIFICATION_PIPE_URL = resp?.data?.api
                ? `wss://${resp.data.api}/v1/account/notifications`
                : clusterUrls.CYCLE_ACCOUNT_NOTIFICATION_PIPE_URL;

            clusterUrls.fetchedTime = new Date();

            return clusterUrls;
        })
        .catch(() => {
            store.dispatch(
                setSplashScreen({
                    visible: true,
                    message: "Attempting to connect to the platform...",
                })
            );
            return clusterUrls;
        });
};
