import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { HelpInfoCircle } from "../help";

export function FormFieldLabel({
    label,
    required,
    help,
    tooltip,
    labelClassName,
    className,
}: {
    label?: string;
    required?: boolean;
    help?: string;
    tooltip?: React.ReactNode;
    labelClassName?: string;
    className?: string;
}) {
    return (
        <div
            className={classNames(
                "flex items-center text-sm font-semibold ",
                className
            )}
        >
            {label ? (
                <span
                    className={classNames(
                        "whitespace-nowrap uppercase leading-3",
                        !label &&
                            "invisible after:select-none after:content-['*']",
                        labelClassName
                    )}
                >
                    {label}
                </span>
            ) : null}
            {required ? (
                <FontAwesomeIcon
                    icon={faAsterisk}
                    className="text-error  ml-2 text-[10px]"
                />
            ) : null}
            {help ? (
                <HelpInfoCircle message={help} className="ml-2 py-0" />
            ) : null}

            {tooltip ? <div className="ml-2">{tooltip}</div> : null}
        </div>
    );
}
