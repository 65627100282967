import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PanelContent } from "../../panels";
import classNames from "classnames";
import { skeletonStyles } from "../../loaders/skeleton/skeletonStyle";

type EmptyResourceProps = {
    className?: string;
    title: string;
    details?: string;
    icon?: IconDefinition;
    imageUrl?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    isLoading?: boolean;
    stretch?: boolean;
};

/**
 * A panel used when a data set is returned properly, but is empty.
 */
export function EmptyResource({
    title,
    details,
    className,
    icon,
    children,
    footer,
    isLoading,
    stretch,
}: EmptyResourceProps) {
    return (
        <PanelContent className={className} stretch={stretch}>
            <div
                className={classNames(
                    "flex w-full flex-col items-center justify-center gap-2 py-4",
                    isLoading && skeletonStyles
                )}
            >
                <div className="flex items-center gap-4">
                    {icon && (
                        <FontAwesomeIcon
                            className="text-cycle-blue text-2xl"
                            icon={icon}
                        />
                    )}
                    <h2 className="text-2xl">{title}</h2>
                </div>
                {details && <span>{details}</span>}
                {!isLoading ? <>{children}</> : null}
            </div>
            {footer}
        </PanelContent>
    );
}
