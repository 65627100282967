import { cloneElement, FC, PropsWithChildren, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { router } from "~/routes/Routes";
import { useGetHubsQuery } from "~/services/cycle";

type VerifyHubRouteProps = PropsWithChildren<{
    requiresHubs: boolean;
    capabilities?: string[];
    /** Wraps the authorized route (e.g. a menu) */
    wrapperComponent?: React.ReactElement;
}>;

function VerifyHubRoute({ requiresHubs }: VerifyHubRouteProps) {
    const { data: hubs } = useGetHubsQuery({});

    const availHubs = useMemo(
        () => hubs?.data?.filter((h) => h.state.current !== "deleted"),
        [hubs?.data]
    );

    // If Route requies hubs, and there are no hubs, redirect to create hub
    // if Route requires no hubs, and there are hubs, redirect to root
    useEffect(() => {
        if (requiresHubs && availHubs && !availHubs.length) {
            router.navigate("/create-hub", { replace: true });
            return;
        }
        if (!requiresHubs && availHubs && availHubs.length) {
            router.navigate("/", { replace: true });
            return;
        }
    }, [availHubs, router, requiresHubs]);

    return <Outlet />;
}

export default VerifyHubRoute;
