import classNames from "classnames";
import { forwardRef } from "react";

type PanelProps = {
    className?: string;
    id?: string;
    children: React.ReactNode;
};

export const Panel = forwardRef<HTMLDivElement, PanelProps>(function Panel(
    { className, id, children },
    ref
) {
    return (
        <div
            className={classNames("flex w-full flex-wrap", className)}
            id={id}
            ref={ref}
        >
            {children}
        </div>
    );
});
