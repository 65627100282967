import { $warn } from "@cycleplatform/core/util/log";
import { ResourceWithId, ResourceWithIdOrIdentifier } from "~/modules/resource";

export function resourcesToResourceById<T extends ResourceWithId>(
    resources: T[]
) {
    return resources.reduce(
        (resourceById, resource) => ({
            ...resourceById,
            [resource.id]: resource,
        }),
        {} as Record<string, T>
    );
}

export function resourcesToResourceByHybridIdentifier<
    T extends ResourceWithIdOrIdentifier
>(resources: T[]) {
    return resources.reduce((resourceById, resource) => {
        if (!resource?.id) {
            $warn(
                "the resource passed to resource combo box is missing an id - failed to select"
            );
            return resourceById;
        }
        resourceById[resource.id] = resource;
        if (resource.identifier) {
            resourceById[resource.identifier] = resource;
        }
        return resourceById;
    }, {} as Record<string, T>);
}
