import classNames from "classnames";

type SectionDisabledControlProps = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    heightFull?: boolean;
};

/**
 * Designed for top level disabling of a whole section of the portal.
 * The group/fieldset is used as a group to style/disable form elements that are not "real" buttons.  For example, links
 * styled as buttons will not respond to the "disabled" state natively because links do not have a disabled state
 * Ex: When an environment is disabled, this fieldset can be used to disable all of the children forms/buttons
 */
export function SectionDisabledControl({
    children,
    className,
    disabled,
    heightFull,
}: SectionDisabledControlProps) {
    return (
        <fieldset
            disabled={disabled}
            className={classNames(
                "group/fieldset  w-full",
                className,
                heightFull && "h-full"
            )}
        >
            {children}
        </fieldset>
    );
}
