import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type StyledDataTableProps = React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
>;

export const StyledDataTable: FC<PropsWithChildren<StyledDataTableProps>> = ({
    children,
    className,
    ...props
}) => {
    return (
        <div className="dark:border-cycle-gray w-full">
            <table
                {...props}
                className={classNames(
                    className,
                    "w-full table-auto rounded-lg text-left"
                )}
            >
                {children}
            </table>
        </div>
    );
};
