import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type StyledTableRowProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
>;

export const StyledTableRow: FC<PropsWithChildren<StyledTableRowProps>> = ({
    children,
    className,
    ...props
}) => {
    return (
        <tr
            {...props}
            className={classNames(
                className,
                "last:margin-none h-14 border-b last:border-none",
                "mr-4"
            )}
        >
            {children}
        </tr>
    );
};
