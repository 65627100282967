import { RootState } from "~/store";
import { ResourceCountSummary, cycleApi } from "../../../__generated";
import { HubNotificationMessage } from "@cycleplatform/core/notifications";
import { $trace, $warn } from "@cycleplatform/core/util/log";

/**
 * ## Description
 *
 * Updates the resource state counts of sub-resources inline to reduce the number of API requests needed
 * to keep these tallies, due to the speed and number at which these state changes can occur.
 *
 * ## Potential Innaccuracies
 * There are a couple situations that could throw these counts out of sync.
 * - If the notification websocket is disconnected (this should be remedied when it reconnects, however)
 * - If the order of state changes received over the notification socket is incorrect. This most likely will
 * not occur.
 * - If the root query holding these talies is updated at the same time a large volume of state changes is occurring.
 * This may cause innaccuracies due to the socket updating much faster than we receive the API response. This can happen
 * when tabbing in and out of the browser during the time an env/container start or stop job is occurring, but should be remedied
 * when the user refocuses the window. It's not going to occur very often and is easily remedied, but if it does happen more than
 * intended, we will need to devise a way to counteract it, perhaps by keeping the resource state counts in a manually controlled slice.
 *
 * @param counts The resource counts slice we intend to manipulate
 * @param message The notification message.
 * @param state The current store state.
 * @param includeDeleted Whether the query associated with this resource state count object includes deleted
 * items (via a filter, usually). It will then consider that a valid state in the block instead of manipulating the total.
 * @returns An array of thunk actions containing Immer patches for all relevant resource changes,
 * which can be directly dispatched.
 */
export function updateResourceStateCounts(
    counts: ResourceCountSummary,
    message: HubNotificationMessage,
    includeDeleted?: boolean
) {
    if (!message.object.state) {
        return counts;
    }

    if (message.object.state === "deleted" && !includeDeleted) {
        // If deleted is not part of the object, then we just need to reduce the total
        // and not try to subtract it from the state
        counts.total--;
    } else {
        if (counts.state[message.object.state] === undefined) {
            counts.state[message.object.state] = 0;
        }

        if (message.object.state === "new") {
            counts.total++;
        }

        // add one to the new state
        counts.state[message.object.state]++;
    }

    if (
        !message.object.state_previous ||
        counts.state[message.object.state_previous] === undefined
    ) {
        return;
    }

    // subtract one from the previous state

    counts.state[message.object.state_previous]--;
}

/**
 * ## Description
 *
 * Patches the container state counts of all environments with the required meta field.
 * @param state The current store state.
 * @param message The notification message.
 * @returns An array of thunk actions containing Immer patches for all relevant resource changes,
 * which can be directly dispatched.
 */
export function patchEnvironmentContainerStateCounts(
    state: RootState,
    message: HubNotificationMessage
) {
    const queries = cycleApi.util.selectInvalidatedBy(state, [
        "Environment.ContainerStateCounts",
    ]);
    return queries
        .filter((q) => q.endpointName === "getEnvironments")
        .map((q) => {
            // TODO maybe make this more generic. Will only work with getEnvironments for now.
            return cycleApi.util.updateQueryData(
                q.endpointName as "getEnvironments",
                q.originalArgs,
                (environments) => {
                    environments.data?.forEach((e) => {
                        if (
                            !message.object.state ||
                            !message.context.environments?.includes(e.id) ||
                            !e.meta?.containers_count
                        ) {
                            return;
                        }
                        $trace(
                            `updating state counts for environment "${e.name}": ${message.object.state} + 1 / ${message.object.state_previous} - 1`
                        );
                        updateResourceStateCounts(
                            e.meta.containers_count,
                            message,
                            q.originalArgs?.filter?.state?.includes("deleted")
                        );
                    });
                }
            );
        });
}

/**
 * ## Description
 *
 * Patches the instance state counts of all containers with the required meta field.
 * @param state The current store state.
 * @param message The notification message.
 * @returns An array of thunk actions containing Immer patches for all relevant resource changes,
 * which can be directly dispatched.
 */
export function patchContainerInstanceStateCounts(
    state: RootState,
    message: HubNotificationMessage
) {
    const queries = cycleApi.util.selectInvalidatedBy(state, [
        "Container.InstanceStateCounts",
    ]);

    return queries
        .filter((q) =>
            ["getContainers", "getContainer"].includes(q.endpointName)
        )
        .map((q) => {
            // TODO maybe make this more generic. Will only work with getContainers/getContainer for now.
            switch (q.endpointName) {
                case "getContainers":
                    return cycleApi.util.updateQueryData(
                        q.endpointName,
                        q.originalArgs,
                        (containers) => {
                            containers.data?.forEach((c) => {
                                if (
                                    !message.object.state ||
                                    !message.context.containers?.includes(
                                        c.id
                                    ) ||
                                    !c.meta?.instances_count
                                ) {
                                    return;
                                }
                                $trace(
                                    `updating instance state counts for container "${c.name}": ${message.object.state} + 1 / ${message.object.state_previous} - 1`
                                );
                                updateResourceStateCounts(
                                    c.meta.instances_count,
                                    message,
                                    q.originalArgs?.filter?.state?.includes(
                                        "deleted"
                                    )
                                );
                            });
                        }
                    );
                case "getContainer":
                    return cycleApi.util.updateQueryData(
                        q.endpointName,
                        q.originalArgs,
                        (container) => {
                            if (
                                !message.object.state ||
                                !container.data?.meta?.instances_count ||
                                !message.context.containers?.includes(
                                    container.data.id
                                )
                            ) {
                                return;
                            }
                            $trace(
                                `updating instance state counts for container "${container.data.name}": ${message.object.state} + 1 / ${message.object.state_previous} - 1`
                            );
                            updateResourceStateCounts(
                                container.data.meta.instances_count,
                                message,
                                q.originalArgs?.filter?.state?.includes(
                                    "deleted"
                                )
                            );
                        }
                    );
            }
        });
}
