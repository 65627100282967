import ReactDOM from "react-dom/client";
import App from "./App";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import * as React from "react";
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
    dsn: "https://6f8b9cf611c0449aae1e8e56d5fe1346@o1376640.ingest.sentry.io/6685842",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    beforeSend: (event) => {
        if (process.env.NODE_ENV === "development") {
            return null;
        }
        return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);
