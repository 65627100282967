import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CycleApiError, CycleApiErrorResponse } from "~/services/helpers";
import { RootState } from "~/store";

type FormState = {
    currentForm:
        | {
              steps: Record<string, unknown>[];
              error?: { step: number; message?: string; source?: string };
          }
        | undefined;
};

const initialState: FormState = {
    currentForm: undefined,
};

const formSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        initializeForm: (state) => {
            state.currentForm = { steps: [] };
        },

        terminateForm: (state) => {
            state.currentForm = undefined;
        },

        addStepData: (
            state,
            action: PayloadAction<{
                data: Record<string, unknown>;
                idx: number;
            }>
        ) => {
            if (!state.currentForm) {
                return;
            }
            const { data, idx } = action.payload;

            if (idx >= 0) {
                state.currentForm.steps[idx] = data; // Directly replace the item at the index
            }
        },

        setError: (
            state,
            action: PayloadAction<{
                data: CycleApiErrorResponse;
            }>
        ) => {
            if (!state.currentForm) {
                return;
            }
            const { data } = action.payload;
            const steps = [...state.currentForm.steps];

            const errSource =
                data.data.error.source?.split("/")?.reverse()[0] || "";

            const stepIdx = steps.findIndex((step) => errSource in step);

            if (stepIdx !== -1) {
                state.currentForm.error = {
                    step: stepIdx,
                    message: data.data.error.detail,
                    source: errSource,
                };
            } else {
                state.currentForm.error = undefined;
            }
        },

        clearError: (state) => {
            if (!state.currentForm) {
                return;
            }
            state.currentForm.error = undefined;
        },
    },
});

export const getCurrentForm = (state: RootState) => {
    return state.form.currentForm;
};
// Action creators are generated for each case reducer function
export const {
    actions: {
        initializeForm,
        terminateForm,
        addStepData,
        setError,
        clearError,
    },
    reducer: formSliceReducer,
} = formSlice;
