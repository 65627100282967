import { AclResource, VerifyFn } from "@cycleplatform/core/modules/acls/util";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { useVerifyAccess } from "~/modules/access";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { Capability } from "~/services/cycle";

type AccessControlledSectionCommonProps = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    heightFull?: boolean;
};

type AccessControlledSectionCapsProps = {
    capability: Capability | Capability[];
};

type AccessControlledSectionAclProps<T extends AclResource> = {
    aclResource: T | undefined;
    verifyFn: VerifyFn<T>;
};

export type AccessControlledSectionProps<T extends AclResource | Capability> =
    T extends AclResource
        ? AccessControlledSectionAclProps<T>
        : T extends Capability
        ? AccessControlledSectionCapsProps
        : never;

export function AccessControlledSection<T extends AclResource | Capability>(
    props: AccessControlledSectionCommonProps & AccessControlledSectionProps<T>
) {
    const capabilityError = useCapability(
        "capability" in props ? props.capability : []
    );
    const accessError = useVerifyAccess(
        "aclResource" in props ? props.aclResource : undefined,
        "verifyFn" in props ? props.verifyFn : () => undefined
    );

    return (
        <SectionDisabledControl
            disabled={props.disabled || !!capabilityError || !!accessError}
            className={props.className}
            heightFull={props.heightFull}
        >
            {props.children}
        </SectionDisabledControl>
    );
}
