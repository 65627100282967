import classNames from "classnames";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FormFieldLabel } from "./FormFieldLabel";

type FormFieldProps = {
    htmlFor?: string;
    label?: string;
    error?: string;
    required?: boolean;
    className?: string;
    children?: React.ReactNode;
    help?: string;
    center?: boolean;
    leftAlignError?: boolean;
};

export function FormField({
    label,
    error,
    required,
    children,
    className,
    help,
    leftAlignError,
}: FormFieldProps) {
    return (
        <div
            className={classNames(
                className,
                label ? "mb-4 mt-2" : "py-2",
                "relative  w-full min-w-0",
                "[&>.checkbox-container]:h-[40px] [&>.checkbox-container]:pl-4",

                "[&input[type=checkbox]]:bg-error"
            )}
        >
            <label className="inline">
                <div
                    className={classNames(
                        "flex items-center text-sm font-semibold ",
                        label && "pb-1"
                    )}
                >
                    <FormFieldLabel
                        className="pb-2"
                        label={label}
                        required={required}
                        help={help}
                    />
                </div>
            </label>
            {children}
            <div className="absolute w-full">
                <FieldErrorMessage
                    message={error}
                    className={classNames(
                        "relative",
                        !leftAlignError && "text-end"
                    )}
                />
            </div>
        </div>
    );
}
