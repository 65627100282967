import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { faUserLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { Capability } from "~/services/cycle";
import classNames from "classnames";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    AccessError,
    AclResource,
    VerifyFn,
} from "@cycleplatform/core/modules/acls/util";
import { useVerifyAccess } from "~/modules/access";

type AccessControlOverlayCommonProps = {
    children: React.ReactNode;
    className?: string;
};

type AccessControlOverlayCapsProps = {
    capability: Capability | Capability[];
};

type AccessControlOverlayAclProps<T extends AclResource> = {
    aclResource: T | undefined;
    verifyFn: VerifyFn<T>;
};

type AccessControlOverlayProps<T extends AclResource | Capability> =
    T extends AclResource
        ? AccessControlOverlayAclProps<T>
        : T extends Capability
        ? AccessControlOverlayCapsProps
        : never;

export function AccessControlOverlay<T extends AclResource | Capability>(
    props: AccessControlOverlayCommonProps & AccessControlOverlayProps<T>
) {
    const [tooltip, setTooltip] = useState<string>();
    const [isAnimating, setShouldAnimate] = useState(false);
    const capabilityError = useCapability(
        "capability" in props ? props.capability : []
    );

    const accessError = useVerifyAccess(
        "aclResource" in props ? props.aclResource : undefined,
        "verifyFn" in props ? props.verifyFn : () => undefined
    );
    useEffect(() => {
        if ("capability" in props) {
            setTooltip(capabilityError?.message);
        } else {
            setTooltip(accessError?.message);
        }
    }, [accessError, capabilityError]);

    return (
        <Tooltip
            disabled={!tooltip}
            message={tooltip}
            className={classNames(
                "relative",
                (!!accessError || !!capabilityError) &&
                    "[&_*]:!border-transparent "
            )}
        >
            <SectionDisabledControl
                disabled={!!accessError || !!capabilityError}
            >
                {props.children}
            </SectionDisabledControl>
            {("capability" in props && !!capabilityError) ||
            (!!accessError && accessError instanceof AccessError) ? (
                <div
                    onMouseOver={() => {
                        if (!isAnimating) {
                            setShouldAnimate(true);
                            setTimeout(() => setShouldAnimate(false), 500);
                        }
                    }}
                    className={classNames(
                        "!border-none",
                        "text-cycle-gray dark:text-cycle-white bg-cycle-gray-light/80 dark:bg-cycle-gray/80 absolute inset-0  flex cursor-not-allowed items-center justify-center rounded-md text-base",
                        props.className
                    )}
                >
                    <FontAwesomeIcon shake={isAnimating} icon={faUserLock} />
                </div>
            ) : null}
        </Tooltip>
    );
}
