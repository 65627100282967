import classNames from "classnames";
import { forwardRef } from "react";

export type TextInputProps = Omit<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >,
    "prefix"
> & {
    /**
     * Sets the TextInput into an error state, usually indicated by a red border.
     */
    error?: boolean;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    prefixClassName?: string;
    suffixClassName?: string;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    function TextInput(
        {
            error,
            prefix,
            suffix,
            className,
            prefixClassName,
            suffixClassName,
            ...inputProps
        },
        ref
    ) {
        const input = (
            <input
                aria-invalid={error ? "true" : "false"}
                ref={ref}
                className={classNames(
                    className,
                    "border-cycle-gray-light text-cycle-gray peer min-h-[40px] w-full min-w-0  border px-4 outline-none",
                    // light disabled
                    "disabled:bg-cycle-gray/10 disabled:cursor-not-allowed",
                    // focus
                    "focus:ring-cycle-blue focus:border-transparent focus:ring-2",
                    // dark
                    "dark:bg-cycle-gray-accent dark:text-cycle-white-accent dark:border-transparent",
                    // dark disabled
                    "dark:disabled:bg-black dark:disabled:text-opacity-50",
                    prefix && "rounded-r-md border-l-0",
                    suffix && "rounded-l-md border-r-0",
                    !prefix && !suffix && "rounded-md",
                    { "focus:ring-cycle-blue": !error },
                    {
                        "ring-error border-transparent ring-2": !!error,
                    },
                    "read-only:bg-cycle-white-accent read-only:cursor-not-allowed dark:read-only:bg-black"
                )}
                onWheel={(e) => e.currentTarget.blur()}
                {...inputProps}
            />
        );

        if (!prefix && !suffix) {
            return input;
        }

        return (
            <div className="group flex min-w-0 grow ">
                {prefix && (
                    <div
                        className={classNames(
                            prefixClassName,
                            "min-h-[40px] text-sm",
                            "bg-cycle-gray-light dark:bg-cycle-gray dark:text-cycle-gray-light group-focus-within:ring-cycle-blue text-cycle-gray-accent flex  min-w-[3rem] grow items-center justify-center rounded-l-md px-2 group-focus-within:ring-2"
                        )}
                    >
                        {prefix}
                    </div>
                )}

                {input}

                {suffix && (
                    <div
                        className={classNames(
                            suffixClassName,
                            "min-h-[40px] text-sm",
                            "bg-cycle-gray-light dark:bg-cycle-gray dark:text-cycle-gray-light group-focus-within:ring-cycle-blue text-cycle-gray-accent flex h-[40px] min-w-[8rem]  grow  items-center  justify-center rounded-r-md px-2 group-focus-within:ring-2"
                        )}
                    >
                        {suffix}
                    </div>
                )}
            </div>
        );
    }
);
