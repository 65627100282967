import { useTierFeature } from "~/modules/hubs/permissions/useTierFeature";
import { TierPlan } from "~/services/cycle";

type FeatureLimitedProps = {
    feature: keyof TierPlan["advanced_features"];
    render: React.ReactNode;
    children?: React.ReactNode;
};

export function FeatureLimited({
    feature,
    children,
    render,
}: FeatureLimitedProps) {
    const hasFeat = useTierFeature(feature);

    if (hasFeat === undefined) {
        return null;
    }

    if (hasFeat) {
        return <>{children}</>;
    }

    return <>{render}</>;
}
