import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { components } from "@cycleplatform/core/modules/api/__generated";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === "object" && error != null && "status" in error;
}

export type CycleApiError = FetchBaseQueryError & {
    data: Required<components["schemas"]["ErrorEnvelope"]>;
};
/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isCycleApiError(error: unknown): error is CycleApiError {
    return (
        isFetchBaseQueryError(error) &&
        typeof error.data === "object" &&
        error.data !== null &&
        "error" in error.data &&
        "code" in (error.data as { error: object }).error
    );
}

/**
 * Checks to see if the Error is due to
 * - a hub mismatch (x-hub-id does not match hub of requested resource)
 * - a missing hub
 * Returns boolean
 */
export function isHubIdError(error: CycleApiError) {
    return (
        error.data.error.code === "403.mismatch" ||
        (error.data.error.code === "403.permissions" &&
            error.data.error.title?.toLocaleLowerCase().includes("x-hub-id"))
    );
}
