import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

/**
 * ## Description
 * A useful form hook that manages updating a form value when the underlying data 
 * it depends on changes. This will only refresh the form fields if the form has not been
 * touched yet.
 * 
 * This can later be expanded to support showing a message to the user when the background form has 
 * been edited and most likely will be in conflict with their edits.
 * 
 * ## Usage
 * 
 * Use this hook when you have a form that depends on data that may be changed in the background,
 * or when the data may come in asynchronously from the backend, but the form has already rendered.
 * 
 * ## Example
 * This is an example of usage from the environment update form
 * 
 * ```js
 * useKeepFormCurrent(form, environment, (env) => ({
        name: env.name,
        about: {
            description: env.about.description,
            favorite: env.about.favorite,
        },
    }));
```
 *
 * @param form the form that will be updated
 * @param dep the resource or value to watch for changes
 * @param calculateValues a callback that will return a new set of default values to reset the form to
 * based on the dep parameter
 */
export function useKeepFormCurrent<T extends FieldValues, K>(
    form: UseFormReturn<T>,
    dep: K,
    calculateValues: (dep: NonNullable<K>) => T
) {
    return useEffect(() => {
        if (!dep || form.formState.isDirty) {
            return;
        }
        const values = calculateValues(dep);
        if (!values) {
            return;
        }
        // Hard reset, then reset with values
        // If this step is skipped, it is possible that there will be some cross contamination
        // example: if there are a lot of optional properties in a struct, the undefined values of
        // one form will not overwrite a defined value from the preveious form
        form.reset({} as T);
        form.reset(values);
    }, [dep]);
}
