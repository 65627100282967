import {
    AccountNotificationMessage,
    HubNotificationMessage,
} from "@cycleplatform/core/notifications";
import { Draft } from "@reduxjs/toolkit";
import { TagDescription } from "@reduxjs/toolkit/query";
import {
    IncludedResourcesWithIdState,
    ResourceWithIdState,
} from "~/modules/resource";
import { CycleApiTag } from "../../tags";
import { cycleApi } from "~/services/cycle";
import { RootState } from "~/store";
import { $warn } from "@cycleplatform/core/util/log";

export function patchResourceError<T extends ResourceWithIdState>(
    resource: T,
    error: string | undefined
) {
    if (!error) {
        resource.state.error = undefined;
    } else {
        resource.state.error = { message: error };
    }
    return resource;
}

export function bulkPatchResourceError<T extends ResourceWithIdState>(
    resources: T[] | Draft<T>[],
    id: string,
    error: string | undefined
) {
    if (!resources || resources?.length === 0) {
        return resources;
    }

    resources.forEach((r) => {
        if (r.id !== id) {
            return;
        }

        patchResourceError(r, error);
    });

    return resources;
}

export function bulkPatchIncludedResourceErrors<
    T extends IncludedResourcesWithIdState
>(includes: T, id: string, error: string | undefined) {
    if (includes[id] === undefined) {
        return includes;
    }

    patchResourceError(includes[id] as ResourceWithIdState, error);
    return includes;
}

type MaybeDrafted<T> = T | Draft<T>;
export function patchCacheEntryResourceError<T extends ResourceWithIdState>(
    query: MaybeDrafted<{
        data?: T | T[] | undefined;
        includes?: Record<string, IncludedResourcesWithIdState>;
    }>,
    id: string,
    error: string | undefined,
    includesKey?: string
) {
    if (includesKey && query.includes?.[includesKey]) {
        query.includes[includesKey] = bulkPatchIncludedResourceErrors(
            query.includes[includesKey],
            id,
            error
        );

        return query;
    }

    if (Array.isArray(query.data)) {
        query.data = bulkPatchResourceError(query.data, id, error);
        return query;
    }

    if (!query.data) {
        return query;
    }

    query.data = patchResourceError(query.data, error);

    return query;
}

/**
 * ## Description
 *
 * Similar to `patchAllCacheEntryResourceStates`, except it patches the error state of a resource.
 * By passing in an `*.error_reset` type notification, it will clear out the error from the state.
 * @param tags An array of query tags that should be checked for updates. These are the same tags that would
 * be passed into an `invalidateTags` call.
 * @param api An instance of the `cycleApi`.
 * @param message The notification message.
 * @param state The current store state.
 * @param includesKey Where to look in an 'includes' block for the resource in a given query. If found,
 * it will do an `includes` update on that cache instead of modifying the main resources.
 * @returns An array of thunk actions containing Immer patches for all relevant resource changes,
 * which can be directly dispatched.
 */
export function patchAllCacheEntryErrors(
    tags: Array<TagDescription<CycleApiTag>>,
    api: typeof cycleApi,
    message: HubNotificationMessage | AccountNotificationMessage,
    state: RootState,
    includesKey?: string
) {
    if (!message.topic.includes(".error")) {
        $warn(
            `Attempting to patch resource errors using a non-error topic: ${message.topic}`
        );
    }

    const patches: ReturnType<typeof api.util.updateQueryData>[] = [];

    const queries = api.util.selectInvalidatedBy(state, tags);

    queries.forEach((q) => {
        patches.push(
            api.util.updateQueryData(
                q.endpointName as "getEnvironments",
                q.originalArgs,
                (resources) => {
                    patchCacheEntryResourceError(
                        resources,
                        message.object.id,
                        message.object.error,
                        includesKey
                    );
                }
            )
        );
    });

    return patches;
}
