import { Button } from "../buttons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FC, PropsWithChildren, ComponentPropsWithRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ThreeDots } from "react-loading-icons";
import { rgbStringToHex } from "@cycleplatform/core/util/colors";
import { brandColors } from "tailwindcss-config/colors";

export type LoaderButtonProps = ComponentPropsWithRef<typeof Button> & {
    icon: IconDefinition;
    isLoading?: boolean;
    loaderClassName?: string;
    loaderSvgProps?: React.SVGProps<SVGSVGElement>;
};

export const LoaderButton: FC<PropsWithChildren<LoaderButtonProps>> = ({
    isLoading,
    icon,
    children,
    className,
    loaderClassName,
    loaderSvgProps,
    ...buttonProps
}) => {
    return (
        <Button
            {...buttonProps}
            className={classNames(
                className,
                "relative",
                isLoading && "pointer-events-none"
            )}
        >
            {isLoading ? (
                <div
                    className={classNames(
                        "fill pointer-events-none absolute inset-0 flex items-center justify-center",
                        buttonProps.flavor === "discard" && "bg-error/50"
                    )}
                >
                    <ThreeDots
                        fill={
                            buttonProps.flavor === "primary" ||
                            buttonProps.flavor === "discard"
                                ? rgbStringToHex(
                                      brandColors["cycle-white"]["DEFAULT"]
                                  )
                                : rgbStringToHex(
                                      brandColors["cycle-blue"]["DEFAULT"]
                                  )
                        }
                        fillOpacity={1}
                        strokeOpacity={0}
                        height="0.5rem"
                        className={loaderClassName}
                        {...loaderSvgProps}
                    />
                </div>
            ) : null}
            <span
                className={classNames(
                    isLoading && "invisible",
                    "flex items-center justify-center gap-2"
                )}
            >
                {icon ? <FontAwesomeIcon icon={icon} /> : null} {children}
            </span>
        </Button>
    );
};
