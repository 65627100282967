import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cycleApi } from "~/services/cycle";
import { RootState } from "~/store";
import { loadAppSettings } from "../settings/slice";
import { createSelector } from "@reduxjs/toolkit";

type HubState = {
    activeId?: string;
};

const initialState: HubState = {};

const hubsSlice = createSlice({
    name: "hubs",
    initialState,
    reducers: {
        setActiveHub: (state, action: PayloadAction<{ hubId?: string }>) => {
            state.activeId = action.payload.hubId;
        },
    },
    extraReducers(builder) {
        // Once settings are loaded from the browser, set active id here.
        // It may be necessary to ensure this is only loaded once by adding a flag
        // to the hub state.
        builder.addCase(loadAppSettings.fulfilled, (state, { payload }) => {
            state.activeId = payload.hubs.activeId;
        }),
            // Attempt to load the active hub once hubs have been fetched.
            // First it checks the store for the active hub id that was stored there,
            // and searches the API response for getHubs to see if there's one that matches.
            // If so, great. If not, it defaults to the first hub in the list. In the case there are no
            // hubs, it stays undefined, and the portal will go to the hub create screen.
            builder.addMatcher(
                cycleApi.endpoints.getHubs.matchFulfilled,
                (state, { payload }) => {
                    // Filter out deleted or deleting hubs
                    // A user will never be redirected to a hub in these states
                    const availHubs = payload.data?.filter(
                        (h) => h.state.current !== "deleted"
                    );
                    if (!availHubs || availHubs.length === 0) {
                        state.activeId = undefined;
                        return;
                    }

                    const selectedHub = availHubs.find(
                        (h) => h.id === state.activeId
                    );

                    if (selectedHub) {
                        // if we have it, and it matches, just bail here.
                        return;
                    }

                    // Otherwise, set it to the first one in the array
                    state.activeId = availHubs[0]?.id;
                }
            );
    },
});

export const selectActiveHubId = (state: RootState) => state.hubs.activeId;
export const selectHubs = cycleApi.endpoints.getHubs.select({});

export const selectActiveHub = createSelector(
    selectActiveHubId,
    selectHubs,
    (activeHubId, hubs) => hubs.data?.data?.find((h) => h.id === activeHubId)
);

// Action creators are generated for each case reducer function
export const {
    actions: { setActiveHub },
    reducer: hubsReducer,
} = hubsSlice;
