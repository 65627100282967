import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import { Link, useSearchParams } from "react-router-dom";

type PageControlProps = {
    page: { size: number; number: number; urlParam: string };
    /**
     * The number of items currently on the list.
     * Used to determine if we're on the last page or not.
     */
    itemCount?: number;
};

export const PageControl: FC<PageControlProps> = ({ page, itemCount }) => {
    const [searchParams] = useSearchParams();
    const nextPageParams = new URLSearchParams(searchParams);
    nextPageParams.set(page.urlParam, (page.number + 1).toString());
    const prevPageParams = new URLSearchParams(searchParams);
    prevPageParams.set(page.urlParam, (page.number - 1).toString());

    return (
        <div className="dark:text-cycle-white-accent flex items-center gap-1">
            <div
                className={classNames({
                    // Trick to allow pointer-events-none on link & cursor not allowed.
                    "cursor-not-allowed": page.number === 1,
                })}
            >
                <Link
                    to={{
                        search: prevPageParams.toString(),
                    }}
                    className={classNames(
                        "text-cycle-blue transition-colors",
                        "hover:text-cycle-blue-accent",
                        {
                            "text-cycle-gray pointer-events-none cursor-not-allowed":
                                page.number === 1,
                        }
                    )}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Link>
            </div>
            <div className="w-16 select-none text-center text-sm">
                Page <strong>{page.number}</strong>
            </div>
            <div
                className={classNames({
                    // Trick to allow pointer-events-none on link & cursor not allowed.
                    "cursor-not-allowed":
                        itemCount !== undefined && itemCount < page.size,
                })}
            >
                <Link
                    to={{
                        search: nextPageParams.toString(),
                    }}
                    className={classNames(
                        "text-cycle-blue transition-colors",
                        "hover:text-cycle-blue-accent",
                        {
                            "text-cycle-gray pointer-events-none":
                                itemCount !== undefined &&
                                itemCount < page.size,
                        }
                    )}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Link>
            </div>
        </div>
    );
};
