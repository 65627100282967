import {
    PageAside,
    PageBody,
    PageContent,
} from "@cycleplatform/ui/components/page";
import {
    Cluster,
    useDeleteClusterMutation,
    useUpdateClusterAccessMutation,
} from "~/services/cycle";
import { useOutletContext } from "react-router-dom";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { ResourceAclUpdate } from "~/components/common/acl";
import { manageAccessFn } from "@cycleplatform/core/modules/acls/util";

export default function ClusterSettings() {
    const { cluster } = useOutletContext<{ cluster?: Cluster }>();

    const [deleteCluster] = useDeleteClusterMutation();
    const [updateClusterAccess] = useUpdateClusterAccessMutation();
    return (
        <PageBody>
            <PageContent>
                <Panel>
                    <PanelTitle title="Cluster Info" />
                    <PanelContent>
                        <FormField label="id">
                            <CopyInput value={cluster?.id} />
                        </FormField>
                        <FormField label="identifier">
                            <CopyInput value={cluster?.identifier} />
                        </FormField>
                    </PanelContent>
                </Panel>
            </PageContent>
            <PageAside>
                <ResourceAclUpdate
                    resourceType="cluster"
                    aclResource={cluster}
                    verifyFn={manageAccessFn("clusters-manage")}
                    onSubmit={async (data) => {
                        return updateClusterAccess({
                            clusterId: cluster?.id || "",
                            body: { ...data },
                        }).unwrap();
                    }}
                />
                <DeleteResource
                    accessControl={{
                        aclResource: cluster,
                        verifyFn: manageAccessFn("clusters-manage"),
                    }}
                    resourceType="cluster"
                    resourceName={cluster?.identifier}
                    onSubmit={async () => {
                        return deleteCluster({
                            clusterId: cluster?.id || "",
                        }).unwrap();
                    }}
                />
            </PageAside>
        </PageBody>
    );
}
