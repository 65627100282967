import { useFormContext } from "react-hook-form";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";

/** React Hook Form global error rendered as red text that automatically monitors for `errors.root._error */
export function RhfFormError({
    name,
    className,
}: {
    name: string;
    className?: string;
}) {
    const {
        formState: { errors },
    } = useFormContext();

    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
                <FieldErrorMessage
                    className={classNames("relative", className)}
                    message={message}
                />
            )}
        />
    );
}
