import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type PageBodyProps = {
    className?: string;
};

export const PageBody: FC<PropsWithChildren<PageBodyProps>> = ({
    className,
    children,
}) => {
    return (
        <section
            className={classNames(className, "mb-16 flex  flex-1 items-start ")}
        >
            {children}
        </section>
    );
};
