import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    Checkbox,
    FormToggle,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    SkeletonButton,
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { faCirclesOverlap } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError } from "../../forms/util";
import { Acl, Role, useGetRolesQuery } from "~/services/cycle";
import { RolesSelect } from "../../roles/RolesSelect";
import { rhfConfig } from "~/components/forms/util";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { AclForm, createAclSubmitBody, getAclDefaultValues } from "./helpers";
import { AclResource, VerifyFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { AccessControlOverlay } from "../buttons";
import { CycleErrorBoundary } from "../errors";
import { panelFallbackWrapper } from "../errors/FallbackWrappers";
import { useKeepFormCurrent } from "../forms";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";

type ResourceAclProps = {
    onSubmit: (data?: { acl: Acl | null | undefined }) => Promise<unknown>;
    aclResource: AclResource | undefined;
    verifyFn: VerifyFn<AclResource>;
    resourceType: string;
};

export function ResourceAclUpdate({ ...props }: ResourceAclProps) {
    return (
        <Panel>
            {props.aclResource ? (
                <CycleErrorBoundary
                    fallbackWrapper={panelFallbackWrapper("Access Controls")}
                >
                    <AccessControlledSection
                        aclResource={props.aclResource}
                        verifyFn={props.verifyFn}
                    >
                        <ResourceAclForm {...props} />
                    </AccessControlledSection>
                </CycleErrorBoundary>
            ) : (
                <PanelContent>
                    <SkeletonHeader />
                    <SkeletonText size="lg" />
                    <SkeletonText size="lg" />
                    <SkeletonText size="md" />
                    <div className="flex justify-end">
                        <SkeletonButton size="md" />
                    </div>
                </PanelContent>
            )}
        </Panel>
    );
}

function ResourceAclForm({ ...props }: ResourceAclProps) {
    const form = useForm<AclForm>({
        defaultValues: getAclDefaultValues(props?.aclResource?.acl),
        ...rhfConfig,
    });
    const {
        register,
        setError,
        control,
        formState: { isDirty, isSubmitting },
    } = form;

    useKeepFormCurrent(form, props.aclResource, (r) =>
        getAclDefaultValues(r.acl)
    );
    const roleIds = useWatch({ name: "roleIds", control });
    const enabled = useWatch({ name: "enabled", control });

    const { data: roles } = useGetRolesQuery({
        sort: ["-rank"],
        page: {
            number: 1,
            size: 100,
        },
    });

    const roleMap = useMemo(() => {
        return roles?.data?.reduce((acc, cur) => {
            return { ...acc, [cur.id]: cur };
        }, {} as Record<string, Role>);
    }, [roles]);

    const onSubmit = (data: AclForm) => {
        return props
            .onSubmit(createAclSubmitBody(data, "update"))
            .catch(handleSubmitError(setError));
    };

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <PanelTitle
                title={`Access Controls`}
                className="flex justify-between"
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <FormToggle {...field} />
                    )}
                    control={control}
                    name={`enabled`}
                />
            </PanelTitle>

            <PanelContent>
                {enabled ? (
                    <>
                        <RhfFormField label="roles" name="role">
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <RolesSelect {...field} multiple={true} />
                                )}
                                control={control}
                                name="roleIds"
                            />
                        </RhfFormField>

                        <div className="relative max-h-[25rem] overflow-y-auto">
                            {roleIds && roleIds.length ? (
                                <StyledDataTable>
                                    <StyledTableHead className="dark:bg-cycle-black sticky top-0 bg-white ">
                                        <StyledHeaderCell>
                                            Role
                                        </StyledHeaderCell>
                                        <StyledHeaderCell className="w-[8rem]">
                                            <div className="flex items-center gap-2">
                                                View
                                                <HelpInfoCircle
                                                    message={`Provides ability to fetch and view this ${props.resourceType}.`}
                                                />
                                            </div>
                                        </StyledHeaderCell>
                                        <StyledHeaderCell className="w-[8rem]">
                                            <div className="flex items-center gap-2">
                                                Modify
                                                <HelpInfoCircle
                                                    message={`Provides the ability to update this ${props.resourceType} and it's child resources.`}
                                                />
                                            </div>
                                        </StyledHeaderCell>
                                        <StyledHeaderCell className="w-[8rem]">
                                            <div className="flex items-center gap-2">
                                                Manage
                                                <HelpInfoCircle
                                                    message={`Provides ability to delete and manage ACLs for this ${props.resourceType}.`}
                                                />
                                            </div>
                                        </StyledHeaderCell>
                                    </StyledTableHead>

                                    <tbody>
                                        {roleIds.map((r, idx) => (
                                            <StyledTableRow key={r}>
                                                <StyledCell>
                                                    {roleMap?.[r]?.name}
                                                </StyledCell>
                                                <StyledCell>
                                                    <Checkbox
                                                        {...register(
                                                            `roles.${idx}.view`
                                                        )}
                                                    />
                                                </StyledCell>
                                                <StyledCell>
                                                    <Checkbox
                                                        {...register(
                                                            `roles.${idx}.modify`
                                                        )}
                                                    />
                                                </StyledCell>
                                                <StyledCell>
                                                    <Checkbox
                                                        {...register(
                                                            `roles.${idx}.manage`
                                                        )}
                                                    />
                                                </StyledCell>
                                            </StyledTableRow>
                                        ))}
                                    </tbody>
                                </StyledDataTable>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <EmptyResource
                        className="flex h-60 items-center justify-center border-none"
                        icon={faCirclesOverlap}
                        title="Access Control Disabled"
                    >
                        <p className="text-center">
                            This resource is not using access control, but it
                            can be enabled using the toggle above.
                        </p>
                    </EmptyResource>
                )}

                <PanelFooter className="flex-wrap">
                    <RhfGlobalFormError />
                    <AccessControlOverlay
                        aclResource={props.aclResource}
                        verifyFn={props.verifyFn}
                    >
                        <PushAndHoldButton
                            isLoading={isSubmitting}
                            disabled={!isDirty}
                            type="button"
                            flavor="primary"
                            tooltip={`Hold to Update Access`}
                            onClick={form.handleSubmit(onSubmit)}
                            icon={faCirclesOverlap}
                        >
                            Update Access
                        </PushAndHoldButton>
                    </AccessControlOverlay>
                </PanelFooter>
            </PanelContent>
        </RhfFormProvider>
    );
}
