import { FC } from "react";

type CircleProgressProps = {
    percent: number;
    className?: string;
};

export const CircleLoader: FC<CircleProgressProps> = ({
    percent,
    className,
}) => {
    const radius = 10;
    const circumference = radius * 2 * Math.PI;
    const percentage = circumference - (percent / 100) * circumference;
    return (
        <svg width="24" height="24">
            <circle
                className={className}
                style={{
                    // transition:
                    //     percent === 0 ? "none" : "0.35s stroke-dashoffset",
                    transform: "rotate(-90deg)",
                    transformOrigin: "50% 50%",
                    strokeDasharray: `${circumference} ${circumference}`,
                    strokeDashoffset: percentage,
                }}
                stroke="white"
                strokeWidth="2"
                fill="transparent"
                r={radius}
                cx={radius + 2}
                cy={radius + 2}
            />
        </svg>
    );
};
