import classNames from "classnames";

type FormattedOptionsProps = {
    label: React.ReactNode;
    detail?: React.ReactNode;
    disabled?: boolean;
    /**
     * Any children of the React Compnent will be displayed on the RHS and vertically centered
     */
    children?: React.ReactNode;
};

export function FormattedOption({
    label,
    detail,
    disabled,
    children,
}: FormattedOptionsProps) {
    return (
        <div
            className={classNames(
                "flex min-h-[42px] w-full items-center justify-between",
                disabled && "text-cycle-gray/50"
            )}
        >
            <div
                className={classNames(children ? "w-[80%]" : "w-[99%]", "p-2")}
            >
                <div
                    className={classNames(
                        "justify-between truncate",
                        "max-w-[99%]"
                    )}
                >
                    <strong>{label}</strong>
                </div>
                {detail && <div className="text-sm">{detail}</div>}
            </div>
            <div>{children}</div>
        </div>
    );
}
