import classNames from "classnames";

type SkeletonButtonProps = {
    className?: string;
    size?: "sm" | "md" | "lg" | "full";
};

export function SkeletonButton({
    className,
    size = "full",
}: SkeletonButtonProps) {
    return (
        <div
            className={classNames(
                className,
                {
                    "w-8": size === "sm",
                    "w-32": size === "md",
                    "w-40": size === "lg",
                    "w-full": size === "full",
                },
                "from-cycle-gray-light via-cycle-gray/60 to-cycle-gray-light animate-shimmer h-10 self-center rounded-lg bg-gradient-to-r bg-[length:1000px_100%] opacity-50"
            )}
        />
    );
}
