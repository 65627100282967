import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { TypedStartListening } from "@reduxjs/toolkit";
import { saveAppSettings } from "./modules/settings";
import { AppDispatch, RootState } from "./store";
import { $error, $info } from "@cycleplatform/core/util/log";
import {
    setHubNotificationsConnected,
    pushNotification,
} from "./modules/notifications/slice";
import { cycleApi } from "./services/cycle";
import { setRunningJobIds } from "./modules/jobs/slice";
import { pushCycleNotificationIfEnabled } from "./modules/notifications/browser";

// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
    listenerMiddleware.startListening as AppStartListening;

// Listener for saving the hub ID to browser storage whenever it's changed.
// Uses the predicate method to check the state for the active ID to swap.
// Since the active ID can change when new hubs are fetched, or when setActiveId is
// dispatched, it's safer to monitor the value than every possible action that could
// change it.
startAppListening({
    predicate: (_, currentState, previousState) =>
        currentState.hubs.activeId !== previousState.hubs.activeId,
    effect: async (_, listenerApi) => {
        $info(`Saved hub ${listenerApi.getState().hubs.activeId} to settings`);
        listenerApi.dispatch(
            saveAppSettings({
                hubs: {
                    activeId: listenerApi.getState().hubs.activeId,
                },
            })
        );
    },
});

// Listener for fetching our initial jobs list on pipeline connect.
// Ensures that we are showing an accurate number of jobs in progress
// during a refresh, socket reconnect, or hub change, but otherwise we don't care about them.
// After this list is loaded, we operate exclusively on the notification system to keep an internal
// tally when tracking jobs.
startAppListening({
    actionCreator: setHubNotificationsConnected,
    effect: async (action, listenerApi) => {
        if (action.payload !== true) {
            return;
        }

        const jobsResult = listenerApi.dispatch(
            cycleApi.endpoints.getJobs.initiate({
                sort: ["-id"],
                page: {
                    size: 99,
                    number: 1,
                },
            })
        );

        jobsResult.unsubscribe();

        jobsResult.unwrap().then(
            (r) => {
                $info("retreived initial jobs list");
                const runningJobIds =
                    r?.data
                        ?.filter((j) => {
                            j.state.current === "running";
                        })
                        .map((j) => j.id) || [];

                listenerApi.dispatch(setRunningJobIds(runningJobIds));
            },
            (e) => $error(`failed fetching initial jobs list:`, e)
        );
    },
});

startAppListening({
    actionCreator: pushNotification,
    effect: async (action) => {
        pushCycleNotificationIfEnabled(
            action.payload.title,
            action.payload.message || "",
            `${action.payload.title}${action.payload.message}`
        );
    },
});
