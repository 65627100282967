import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, LinkProps, To } from "react-router-dom";
import { CycleButtonProps, generateButtonClasses } from "./Button";
import classNames from "classnames";
import { Tooltip } from "../tooltip";

type ButtonLinkProps = LinkProps &
    CycleButtonProps & {
        linkComponent?: React.ForwardRefExoticComponent<
            LinkProps & React.RefAttributes<HTMLAnchorElement>
        >;
        ignoreDisabledFieldset?: boolean;
    };

export function isExternalLink(to: To) {
    if (typeof to === "string") {
        return to.includes("http") || to.includes("://");
    }

    return to.pathname?.includes("http") || to.pathname?.includes("://");
}

export function ButtonLink({
    full = false,
    flavor = "default",
    className,
    disabled,
    glow,
    icon,
    children,
    linkComponent,
    tooltip,
    ignoreDisabledFieldset,
    ...props
}: ButtonLinkProps & { tooltip?: string }) {
    const buttonClassNames = generateButtonClasses({
        full,
        flavor,
        className,
        disabled,
        glow,
    });
    const fieldsetClassNames = !ignoreDisabledFieldset
        ? "group-disabled/fieldset:pointer-events-none group-disabled/fieldset:bg-cycle-gray-accent/20 group-disabled/fieldset:text-cycle-gray/50 group-disabled/fieldset:border-cycle-gray-accent/50 group-disabled/fieldset:dark:text-cycle-gray-light group-disabled/fieldset:dark:border-cycle-gray group-disabled/fieldset:dark:bg-cycle-gray-accent/20 group-disabled/fieldset:border"
        : "";

    if (isExternalLink(props.to)) {
        const link = (
            <Tooltip
                as="span"
                message={tooltip || ""}
                disabled={!tooltip}
                className="inline-flex"
            >
                <a
                    {...props}
                    href={
                        typeof props.to === "string"
                            ? props.to
                            : props.to.pathname
                    }
                    className={classNames(
                        buttonClassNames,
                        disabled &&
                            "bg-cycle-gray-accent/20 text-cycle-gray/50 border-cycle-gray-accent/50 dark:text-cycle-gray-light dark:border-cycle-gray dark:bg-cycle-gray-accent/20 relative border",
                        fieldsetClassNames
                    )}
                >
                    {icon ? (
                        <FontAwesomeIcon
                            icon={icon}
                            className={children ? "pr-2" : ""}
                        />
                    ) : null}

                    {children}
                </a>
            </Tooltip>
        );
        if (disabled) {
            // Links don't have disabled - allows us to use pointer event none
            // and still have cursor not allowed
            return (
                <span className="pointer-events-none inline-flex cursor-not-allowed">
                    {link}
                </span>
            );
        }

        return link;
    }

    const Component = linkComponent ? linkComponent : Link;

    const link = (
        <Tooltip
            as="span"
            message={tooltip || ""}
            disabled={!tooltip}
            className="inline-flex"
        >
            <Component
                {...props}
                className={classNames(
                    buttonClassNames,
                    disabled &&
                        "bg-cycle-gray-accent/20 text-cycle-gray/50 border-cycle-gray-accent/50 dark:text-cycle-gray-light dark:border-cycle-gray dark:bg-cycle-gray-accent/20 relative border",
                    fieldsetClassNames
                )}
            >
                {icon ? (
                    <FontAwesomeIcon
                        icon={icon}
                        className={children ? "pr-2" : ""}
                    />
                ) : null}
                {children}
            </Component>
        </Tooltip>
    );

    if (disabled) {
        return (
            <Tooltip
                as="span"
                message={tooltip || ""}
                disabled={!tooltip}
                className="inline-flex"
            >
                <span className="pointer-events-none inline-flex cursor-not-allowed">
                    {link}
                </span>
            </Tooltip>
        );
    }

    return link;
}
