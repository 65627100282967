import { FC } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlockQuestion } from "@fortawesome/pro-solid-svg-icons";

type WrongHubProps = {
    className?: string;
    what?: string;
};

export const WrongHub: FC<WrongHubProps> = ({ className, what }) => {
    return (
        <div
            className={classNames(
                className,
                "flex h-1/2 w-full flex-col items-center justify-center gap-8 p-4"
            )}
        >
            <div className="text-cycle-blue text-[10rem] font-bold">
                <FontAwesomeIcon icon={faBlockQuestion} />
            </div>

            <div className="flex w-full flex-col items-center justify-center gap-2">
                <h2 className="text-xl">
                    Sorry, but this {what || "resource"} is in another hub!
                </h2>
            </div>
        </div>
    );
};
