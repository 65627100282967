import classNames from "classnames";

type PanelFooterProps = {
    children: React.ReactNode;
    className?: string;
};

export function PanelFooter({ className, children }: PanelFooterProps) {
    return (
        <footer
            className={classNames(
                "mt-auto flex justify-end gap-4 border-t pt-4",
                "border-[rgba(0,0,0,0.04)] dark:border-[rgba(255,255,255,0.04)]",
                className
            )}
        >
            {children}
        </footer>
    );
}
