import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobState, JobDescriptor } from "~/services/cycle";
import { RootState } from "~/store";
import { $info, $trace, $warn } from "@cycleplatform/core/util/log";

export type TrackedJob = {
    state: JobState["current"];
    error?: string;
    shouldTrackPercent: boolean;
    percentComplete: number;
    resourceId?: string;
    header?: string;
};

type JobsState = {
    /** Jobs that are currently being tracked by the UI */
    trackedJobs: Record<string, TrackedJob>;
    /** The IDs of jobs that are currently 'running' */
    runningJobIds: string[];
    /** The number of jobs that have completed successfully since the portal loaded */
    successCounter: number;
    /** The number of jobs that have failed since the portal loaded */
    errorCounter: number;

    /**
     * Often Cycle is so fast that a job is finished before our task creating the job returns with our newly created job ID.
     * This is a stopgap to:
     * 1. tell the portal we intend to track a job
     * 2. capture the jobs in the intermediate coming down the pipe as if we were already tracking them
     * 3. clean them out when the pending job tracker count is 0
     */
    pendingTrackers: number;
    /**
     * The jobs accumulated while waiting to get a job ID back (pending trackers > 0)
     */
    accumulatedJobsById: Record<
        string,
        {
            state: JobState["current"];
            error?: string;
        }
    >;
    /**
     * A running list of the most recently completed jobs. This is used to ensure if a rogue state = 'running' update were
     * to come in after a complete (due to network issues, race condition etc) we don't get a job stuck in the 'running' counter.
     */
    recentlyCompletedJobIds: string[];
};

const initialState: JobsState = {
    trackedJobs: {},
    runningJobIds: [],
    successCounter: 0,
    errorCounter: 0,
    pendingTrackers: 0,
    accumulatedJobsById: {},
    recentlyCompletedJobIds: [],
};

const jobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        /**
         * Hard set the IDs of running jobs. Useful for initial load of portal
         * and when the hub pipeline reconnects to ensure we stay in sync.
         */
        setRunningJobIds: (state, action: PayloadAction<string[]>) => {
            state.runningJobIds = action.payload;
        },
        /**
         * when called, tells the jobs system that we should start logging all jobs that come in
         * in case it finishes faster than we get the response
         */
        stateIntentToTrackJob: (state) => {
            $trace("stating intent to track a job");
            state.pendingTrackers = state.pendingTrackers + 1;
        },
        trackJob: (
            state,
            action: PayloadAction<{
                jobId: string;
                shouldTrackPercent?: boolean;
                resourceId?: string;
                header?: string;
            }>
        ) => {
            // decrease our pending trackers by one. this assumes that
            // we stated our intent before firing this action.
            if (state.pendingTrackers > 0) {
                $trace("reducing job tracking intent by one");
                state.pendingTrackers = state.pendingTrackers - 1;
            }

            if (!action.payload.jobId) {
                $warn("attempting to track job without an ID");
                return;
            }

            const isTrackingJob =
                state.trackedJobs[action.payload.jobId] !== undefined;

            if (isTrackingJob) {
                $warn(`Job ${action.payload.jobId} is already being tracked.`);
                return;
            }

            $info(`tracking job: ${action.payload.jobId}`);

            if (state.accumulatedJobsById[action.payload.jobId]) {
                $info(
                    `tracked job ${action.payload.jobId} found in pending list while waiting for job ID`
                );
                state.trackedJobs[action.payload.jobId] = {
                    ...state.accumulatedJobsById[action.payload.jobId],
                    shouldTrackPercent:
                        action.payload.shouldTrackPercent || false,
                    percentComplete: 0,
                    resourceId: action.payload.resourceId,
                    header: action.payload.header,
                };

                // Remove our job
                delete state.accumulatedJobsById[action.payload.jobId];

                // Erase any accumulated jobs when we aren't waiting anymore
                if (state.pendingTrackers === 0) {
                    $trace(
                        "no longer accumulating jobs - clearing out remaining accumulated jobs"
                    );
                    state.accumulatedJobsById = {};
                }
                return;
            }

            state.trackedJobs[action.payload.jobId] = {
                state: "new",
                shouldTrackPercent: action.payload.shouldTrackPercent || false,
                percentComplete: 0,
                resourceId: action.payload.resourceId,
                header: action.payload.header,
            };
        },

        updateJobState: (
            state,
            action: PayloadAction<{
                jobId: string;
                state: JobState["current"];
                error?: string;
            }>
        ) => {
            switch (action.payload.state) {
                case "running":
                    if (
                        state.recentlyCompletedJobIds.includes(
                            action.payload.jobId
                        )
                    ) {
                        break;
                    }
                    state.runningJobIds = [
                        ...new Set([
                            ...state.runningJobIds,
                            action.payload.jobId,
                        ]),
                    ];
                    break;
                case "completed":
                    // Running tally of 5
                    state.recentlyCompletedJobIds = [
                        ...state.recentlyCompletedJobIds.slice(-4),
                        action.payload.jobId,
                    ];
                    state.runningJobIds = state.runningJobIds.filter(
                        (id) => id !== action.payload.jobId
                    );
                    state.successCounter = state.successCounter + 1;
                    break;
                case "error":
                    // Running tally of 5
                    state.recentlyCompletedJobIds = [
                        ...state.recentlyCompletedJobIds.slice(-4),
                        action.payload.jobId,
                    ];
                    state.runningJobIds = state.runningJobIds.filter(
                        (id) => id !== action.payload.jobId
                    );
                    state.errorCounter = state.errorCounter + 1;
                    break;
            }

            if (state.pendingTrackers > 0) {
                $trace(
                    `holding on to received job ${action.payload.jobId} - there are pending trackers that may need it`
                );
                state.accumulatedJobsById[action.payload.jobId] = {
                    ...state.accumulatedJobsById[action.payload.jobId],
                    state: action.payload.state,
                    error: action.payload.error,
                };
            }

            if (state.trackedJobs[action.payload.jobId] === undefined) {
                return;
            }

            const prunedTrackedJobs = Object.entries(state.trackedJobs).reduce(
                (acc, [jobId, job]) => {
                    if (
                        job.state === "completed" ||
                        job.state === "expired" ||
                        job.error
                    ) {
                        return acc;
                    }
                    return { ...acc, [jobId]: job };
                },
                {} as Record<string, TrackedJob>
            );

            // Update our tracked job status too.
            state.trackedJobs = {
                ...prunedTrackedJobs,
                [action.payload.jobId]: {
                    ...state.trackedJobs[action.payload.jobId],
                    state: action.payload.state,
                    error: action.payload.error,
                },
            };
        },
    },
});

export const selectTrackedJobs = (state: RootState) => state.jobs.trackedJobs;
export const selectRunningJobIds = (state: RootState) =>
    state.jobs.runningJobIds;
export const selectSuccessCounter = (state: RootState) =>
    state.jobs.successCounter;
export const selectErrorCounter = (state: RootState) => state.jobs.errorCounter;

// export const selectTrackedJobById = createSelector(
//     [selectTrackedJobs, (_: RootState, jobId: string) => jobId],
//     (trackedJobs, jobId) => trackedJobs[jobId]
// );

// Action creators are generated for each case reducer function
export const {
    actions: {
        trackJob,
        setRunningJobIds,
        updateJobState,
        stateIntentToTrackJob,
    },
    reducer: jobsReducer,
} = jobsSlice;
