import { RootState } from "~/store";
import { cycleApi } from "../__generated";
import { notificationTopicToActions } from "./notifications/handler";
import {
    setAccountNotificationsConnected,
    setHubNotificationsConnected,
} from "~/modules/notifications/slice";
import { $error, $info, $trace, $warn } from "@cycleplatform/core/util/log";
import {
    AccountNotificationMessage,
    HubNotificationMessage,
} from "@cycleplatform/core/notifications";
import { getClusterUrls } from "~/services/cluster";
import {
    PLATFORM_SOCKET_HEARTBEAT_MESSAGE,
    PLATFORM_SOCKET_HEARTBEAT_TIMEOUT,
} from "@cycleplatform/core/modules/websocket";

type NotificationSocketState = {
    data: {
        token?: string;
    };
};

export const notificationEndpoints = cycleApi.injectEndpoints({
    endpoints: (builder) => ({
        connectHubNotifications: builder.query<
            NotificationSocketState,
            { hubId: string | undefined }
        >({
            keepUnusedDataFor: 0,
            providesTags: ["Hub.Notifications"],
            query: ({ hubId }) => ({
                url: "/v1/hubs/current/notifications",
                headers: {
                    "X-HUB-ID": hubId || "",
                },
            }),
            async onCacheEntryAdded(
                args,
                {
                    dispatch,
                    cacheDataLoaded,
                    getCacheEntry,
                    cacheEntryRemoved,
                    getState,
                    updateCachedData,
                }
            ) {
                try {
                    await cacheDataLoaded;

                    const wsUrl = await (
                        await getClusterUrls()
                    ).CYCLE_HUB_NOTIFICATION_PIPE_URL;

                    let pipe: WebSocket | undefined;

                    const connectPipe = () => {
                        if (pipe && pipe.readyState === pipe.OPEN) {
                            // already open
                            return;
                        }
                        const state = getState();
                        const result = cycleApi.endpoints.getHubs.select({})(
                            state
                        );
                        const { data } = result;
                        const token = getCacheEntry().data?.data.token;

                        if (!token) {
                            $error(
                                "[hub notifications]: auth token not present in response"
                            );
                            dispatch(
                                setHubNotificationsConnected(
                                    data?.data?.length ? false : undefined
                                )
                            );

                            return;
                        }
                        return new WebSocket(`${wsUrl}?token=${token}`);
                    };

                    const connectListeners = () => {
                        pipe?.addEventListener("open", openHandler);
                        pipe?.addEventListener("close", closeHandler);
                        pipe?.addEventListener("message", messageHandler);
                        pipe?.addEventListener("error", errorHandler);
                    };

                    const disconnectListeners = () => {
                        pipe?.removeEventListener("open", openHandler);
                        pipe?.removeEventListener("message", messageHandler);
                        pipe?.removeEventListener("close", closeHandler);
                        pipe?.removeEventListener("error", errorHandler);
                    };

                    // Handles disconnect of websocket in a standardized way.
                    // Since each browser is very different, we've standardized ping/pongs on the platform
                    // so that if a client sends the string "heartbeat" to the platform, the platform responds with "heartbeat"
                    // down the pipe. Using this, we can manually check if a socket is no longer receiving data from the platform,
                    // and after a timeout force a reconnect.
                    // Some browsers (chrome/webkit) are buffering socket messages even when the user is completely offline and
                    // attempting to reconnect, sometimes without ever letting us know something happened. This alleviates that problem.
                    let hbTimeout: NodeJS.Timeout;
                    const makeHbTimeout = () => {
                        return setTimeout(() => {
                            $warn(
                                `[hub notifications]: heartbeat timeout - closing socket`
                            );

                            if (pipe?.readyState === 1) {
                                pipe?.close();
                                closeHandler();
                            }
                        }, PLATFORM_SOCKET_HEARTBEAT_TIMEOUT);
                    };

                    let heartbeat: NodeJS.Timer;
                    const openHandler = () => {
                        $info("[hub notifications]: connected");
                        dispatch(setHubNotificationsConnected(true));
                        // This reloads the hub so that on the occasion
                        // that we create a new hub, we bridge the gap between
                        // what may have happened from when it was set to active (notification pipe initiating)
                        // and when we actually connected, depending on how long that takes.
                        // Often times, the billing order is created and confirmed in the time it takes to connect the pipeline,
                        // resulting in a hub with 'null' billing despite having an active plan.
                        // TODO - perhaps we also refresh hub resources here
                        dispatch(
                            cycleApi.util.invalidateTags([
                                { type: "Hub", id: args.hubId },
                            ])
                        );
                        hbTimeout = makeHbTimeout();
                        heartbeat = setInterval(() => {
                            pipe?.send(PLATFORM_SOCKET_HEARTBEAT_MESSAGE);
                        }, 5000);
                    };

                    let reconnectInterval: NodeJS.Timer | undefined = undefined;
                    // If for some reason just the websocket is disconnected,
                    // but not the internet (like the platform going down for an update?)
                    // then we need to internally handle the reconnect. I tried using listeners,
                    // a completely independent slice with thunks, and it ended up being much more complicated.
                    // Using the hook + RTK Query makes most of this simple, with the exception of this reconnect.
                    const createRetryInterval = () => {
                        return setInterval(() => {
                            if (pipe?.readyState === 1) {
                                clearInterval(reconnectInterval);
                                return;
                            }

                            // Invalidate for the hub we're attached to
                            dispatch(
                                cycleApi.util.invalidateTags([
                                    "Hub.Notifications",
                                ])
                            );

                            // Make sure we actually have a token
                            if (
                                getCacheEntry().data?.data.token === undefined
                            ) {
                                return;
                            }

                            if (!pipe) {
                                pipe = connectPipe();
                                connectListeners();
                            }
                        }, 15_000);
                    };

                    const closeHandler = () => {
                        $warn("[hub notifications]: disconnected");
                        clearInterval(reconnectInterval);

                        const state = getState();
                        const result = cycleApi.endpoints.getHubs.select({})(
                            state
                        );
                        const { data } = result;

                        dispatch(
                            setHubNotificationsConnected(
                                data?.data?.length ? false : undefined
                            )
                        );

                        updateCachedData(
                            (draft) => (draft.data.token = undefined)
                        );

                        disconnectListeners();
                        clearInterval(heartbeat);
                        clearTimeout(hbTimeout);

                        pipe = undefined;

                        const cache = getCacheEntry();
                        if (!cache) {
                            $trace(
                                "[hub notifications]: cache is gone, not attempting reconnect"
                            );
                        }
                        reconnectInterval = createRetryInterval();
                    };

                    const errorHandler = (ev: Event) => {
                        $error(
                            `[hub notifications]: ${JSON.stringify(
                                ev,
                                null,
                                2
                            )}`
                        );
                    };

                    const messageHandler = async (message: MessageEvent) => {
                        if (
                            typeof message.data === "string" &&
                            message.data === PLATFORM_SOCKET_HEARTBEAT_MESSAGE
                        ) {
                            clearTimeout(hbTimeout);
                            hbTimeout = makeHbTimeout();
                            return;
                        }

                        $trace(`[hub notifications]: ${message.data}`);
                        const notification: HubNotificationMessage = JSON.parse(
                            message.data
                        );
                        notificationTopicToActions(
                            notification,
                            cycleApi,
                            getState as () => RootState,
                            dispatch
                        );
                    };

                    try {
                        pipe = connectPipe();
                    } catch {
                        reconnectInterval = createRetryInterval();
                    }

                    connectListeners();

                    await cacheEntryRemoved;
                    // Purposeful disconnect here

                    if (pipe) {
                        pipe.close();
                        // For some reason, some browsers don't emit the onclose handler until a close is received
                        // from the server. We can force it here.
                        closeHandler();
                        // Since this is a purposeful disconnect, ensure we don't accidentally try to reconnect
                        clearInterval(reconnectInterval);
                        disconnectListeners();
                    }

                    $info(
                        "[hub notifications]: socket closed due to unsubscribe"
                    );
                } catch {
                    // if cacheEntryRemoved resolved before cacheDataLoaded,
                    // cacheDataLoaded throws
                }
            },
        }),
        connectAccountNotifications: builder.query<
            NotificationSocketState,
            null
        >({
            keepUnusedDataFor: 0,
            providesTags: ["Account.Notifications"],
            query: () => ({
                url: "/v1/account/notifications",
            }),
            async onCacheEntryAdded(
                _args,
                {
                    dispatch,
                    cacheDataLoaded,
                    getCacheEntry,
                    cacheEntryRemoved,
                    getState,
                    updateCachedData,
                }
            ) {
                try {
                    await cacheDataLoaded;

                    const wsUrl = await (
                        await getClusterUrls()
                    ).CYCLE_ACCOUNT_NOTIFICATION_PIPE_URL;

                    let pipe: WebSocket | undefined;

                    const connectPipe = () => {
                        if (pipe && pipe.readyState === pipe.OPEN) {
                            // already open
                            return;
                        }
                        const token = getCacheEntry().data?.data.token;

                        if (!token) {
                            $error(
                                "[account notifications]: auth token not present in response"
                            );
                            dispatch(setAccountNotificationsConnected(false));

                            return;
                        }
                        return new WebSocket(`${wsUrl}?token=${token}`);
                    };

                    const connectListeners = () => {
                        pipe?.addEventListener("open", openHandler);
                        pipe?.addEventListener("close", closeHandler);
                        pipe?.addEventListener("message", messageHandler);
                        pipe?.addEventListener("error", errorHandler);
                    };

                    const disconnectListeners = () => {
                        pipe?.removeEventListener("open", openHandler);
                        pipe?.removeEventListener("message", messageHandler);
                        pipe?.removeEventListener("close", closeHandler);
                        pipe?.removeEventListener("error", errorHandler);
                    };

                    // Handles disconnect of websocket in a standardized way.
                    // Since each browser is very different, we've standardized ping/pongs on the platform
                    // so that if a client sends the string "heartbeat" to the platform, the platform responds with "heartbeat"
                    // down the pipe. Using this, we can manually check if a socket is no longer receiving data from the platform,
                    // and after a timeout force a reconnect.
                    // Some browsers (chrome/webkit) are buffering socket messages even when the user is completely offline and
                    // attempting to reconnect, sometimes without ever letting us know something happened. This alleviates that problem.
                    let hbTimeout: NodeJS.Timeout;
                    const makeHbTimeout = () => {
                        return setTimeout(() => {
                            $warn(
                                `[account notifications]: heartbeat timeout - closing socket`
                            );

                            if (pipe?.readyState === 1) {
                                pipe?.close();
                                closeHandler();
                            }
                        }, PLATFORM_SOCKET_HEARTBEAT_TIMEOUT);
                    };

                    let heartbeat: NodeJS.Timer;
                    const openHandler = () => {
                        $info("[account notifications]: connected");
                        dispatch(setAccountNotificationsConnected(true));
                        hbTimeout = makeHbTimeout();
                        heartbeat = setInterval(() => {
                            pipe?.send(PLATFORM_SOCKET_HEARTBEAT_MESSAGE);
                        }, 5000);
                    };

                    let reconnectInterval: NodeJS.Timer | undefined = undefined;

                    const createReconnectInterval = () => {
                        // If for some reason just the websocket is disconnected,
                        // but not the internet (like the platform going down for an update?)
                        // then we need to internally handle the reconnect. I tried using listeners,
                        // a completely independent slice with thunks, and it ended up being much more complicated.
                        // Using the hook + RTK Query makes most of this simple, with the exception of this reconnect.
                        return setInterval(() => {
                            if (pipe?.readyState === 1) {
                                clearInterval(reconnectInterval);
                                return;
                            }

                            // Invalidate for the account we're attached to
                            dispatch(
                                cycleApi.util.invalidateTags([
                                    "Account.Notifications",
                                ])
                            );

                            // Make sure we actually have a token
                            if (
                                getCacheEntry().data?.data.token === undefined
                            ) {
                                return;
                            }

                            if (!pipe) {
                                pipe = connectPipe();
                                connectListeners();
                            }
                        }, 15_000);
                    };
                    const closeHandler = () => {
                        $warn("[account notifications]: disconnected");
                        clearInterval(reconnectInterval);

                        dispatch(setAccountNotificationsConnected(false));
                        updateCachedData(
                            (draft) => (draft.data.token = undefined)
                        );

                        disconnectListeners();
                        clearInterval(heartbeat);
                        clearTimeout(hbTimeout);

                        pipe = undefined;

                        reconnectInterval = createReconnectInterval();
                    };

                    const errorHandler = (ev: Event) => {
                        $error(
                            `[account notifications]: ${JSON.stringify(
                                ev,
                                null,
                                2
                            )}`
                        );
                    };

                    const messageHandler = async (message: MessageEvent) => {
                        if (
                            typeof message.data === "string" &&
                            message.data === PLATFORM_SOCKET_HEARTBEAT_MESSAGE
                        ) {
                            clearTimeout(hbTimeout);
                            hbTimeout = makeHbTimeout();
                            return;
                        }

                        $trace(`[account notifications]: ${message.data}`);
                        const notification: AccountNotificationMessage =
                            JSON.parse(message.data);
                        notificationTopicToActions(
                            notification,
                            cycleApi,
                            getState as () => RootState,
                            dispatch
                        );
                    };

                    try {
                        pipe = connectPipe();
                    } catch {
                        reconnectInterval = createReconnectInterval();
                    }
                    connectListeners();

                    await cacheEntryRemoved;
                    // Purposeful disconnect here

                    if (pipe) {
                        pipe.close();
                        // For some reason, some browsers don't emit the onclose handler until a close is received
                        // from the server. We can force it here.
                        closeHandler();
                        // Since this is a purposeful disconnect, ensure we don't accidentally try to reconnect
                        clearInterval(reconnectInterval);
                        disconnectListeners();
                    }

                    $info(
                        "[account notifications]: socket closed due to unsubscribe"
                    );
                } catch {
                    // if cacheEntryRemoved resolved before cacheDataLoaded,
                    // cacheDataLoaded throws
                }
            },
        }),
    }),
});

export const {
    useConnectHubNotificationsQuery,
    useConnectAccountNotificationsQuery,
} = notificationEndpoints;
export { notificationEndpoints as cycleApiNotifications };
