import classNames from "classnames";

type StyledHeaderCellProps = {
    className?: string;
    children?: React.ReactNode;
};

export function StyledHeaderCell({
    children,
    className,
}: StyledHeaderCellProps) {
    return (
        <th
            className={classNames(
                className,
                "select-none py-2 first:pl-4 last:pr-4"
            )}
        >
            {children}
        </th>
    );
}
