import classNames from "classnames";

type FullContentLayoutProps = {
    children: React.ReactNode;
    className?: string;
};

/**
 * Layout for pages where the content takes up the full space to the right of the sidebar.
 * Handles proper background color, full height & width, and flex layout.
 */
export function FullContentLayout({
    children,
    className,
}: FullContentLayoutProps) {
    return (
        <section
            className={classNames(
                className,
                "bg-cycle-white-accent flex w-full min-w-0",
                "dark:bg-cycle-black-accent"
            )}
        >
            {children}
        </section>
    );
}
