import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMessageExclamation,
    faSquareExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { capitalizeWord } from "@cycleplatform/core/util";

type PermissionDeniedProps = {
    className?: string;
    title?: string;
    detail?: string;
};

export function InvalidState({
    className,
    title,
    detail,
}: PermissionDeniedProps) {
    return (
        <div
            className={classNames(
                className,
                "flex h-1/2 w-full flex-col items-center justify-center gap-8 p-4"
            )}
        >
            <FontAwesomeIcon
                className="text-error text-[100px]"
                icon={faSquareExclamation}
            />

            <div className="flex w-full flex-col items-center justify-center gap-2">
                <h2 className="text-2xl">{title || "Invalid State"}</h2>
                {detail ? (
                    <p>
                        {detail
                            .split(" ")
                            .map((w, idx) =>
                                idx === 0 ? capitalizeWord(w) : w
                            )
                            .join(" ")}
                    </p>
                ) : null}
            </div>
        </div>
    );
}
