import classNames from "classnames";
import { FullContentLayout } from "../FullContentLayout";

type ResourceNavLayoutProps = {
    className?: string;
    children: React.ReactNode;
};

/**
 * Establishes single column layout
 */
export function ResourceLayout({
    children,
    className,
}: ResourceNavLayoutProps) {
    return (
        <FullContentLayout
            className={classNames(
                "min-w-[80rem] flex-col overflow-y-auto p-4",
                className
            )}
        >
            <div>{children}</div>
        </FullContentLayout>
    );
}
