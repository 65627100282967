import { $warn } from "@cycleplatform/core/util/log";
import {
    IncludedResourcesWithIdState,
    ResourceWithIdState,
} from "~/modules/resource";
import {
    AccountNotificationMessage,
    HubNotificationMessage,
} from "@cycleplatform/core/notifications";
import { Draft } from "@reduxjs/toolkit";
import { TagDescription } from "@reduxjs/toolkit/query";
import { CycleApiTag } from "../../tags";
import { cycleApi } from "~/services/cycle";
import { RootState } from "~/store";

function patchResourceState<T extends ResourceWithIdState>(
    resource: T,
    newState: string
) {
    resource.state = {
        ...resource.state,
        current: newState,
        changed: new Date().toISOString(),
    };

    return resource;
}

function bulkPatchResourceState<T extends ResourceWithIdState>(
    resources: T[] | Draft<T>[],
    id: string,
    newState: string
) {
    if (!resources || resources?.length === 0) {
        return resources;
    }

    resources.forEach((r) => {
        if (r.id !== id) {
            return;
        }
        patchResourceState(r, newState);
    });

    return resources;
}

function bulkPatchIncludedResourceStates<
    T extends IncludedResourcesWithIdState
>(includes: T, id: string, newState: string) {
    if (includes[id] === undefined) {
        return includes;
    }

    patchResourceState(includes[id] as ResourceWithIdState, newState);

    return includes;
}

type MaybeDrafted<T> = T | Draft<T>;
function patchCacheEntryResourceState<T extends ResourceWithIdState>(
    query: MaybeDrafted<{
        data?: T | T[] | undefined;
        includes?: Record<string, IncludedResourcesWithIdState>;
    }>,
    id: string,
    newState: string,
    options?: {
        includesKey?: string;
        shouldUpdate?: (data: { state: { current: string } }) => boolean;
    }
) {
    if (options?.includesKey && query.includes?.[options.includesKey]) {
        query.includes[options.includesKey] = bulkPatchIncludedResourceStates(
            query.includes[options.includesKey],
            id,
            newState
        );

        return query;
    }

    if (Array.isArray(query.data)) {
        query.data = bulkPatchResourceState(query.data, id, newState);
        return query;
    }

    if (!query.data) {
        return query;
    }

    if (options?.shouldUpdate && !options.shouldUpdate(query.data)) {
        $warn(
            "skipping update of resource state due to options filter",
            query.data
        );
        return query;
    }

    query.data = patchResourceState(query.data, newState);

    return query;
}

function patchResourceHealth<T extends ResourceWithIdState>(
    resource: T,
    healthStatus: "unknown" | "healthy" | "unhealthy"
) {
    resource.state.health = {
        healthy:
            healthStatus === "unknown"
                ? null
                : healthStatus === "healthy"
                ? true
                : false,
        updated: new Date().toISOString(),
    };

    return resource;
}

function bulkPatchResourceHealth<T extends ResourceWithIdState>(
    resources: T[] | Draft<T>[],
    id: string,
    healthStatus: "unknown" | "healthy" | "unhealthy"
) {
    if (!resources || resources?.length === 0) {
        return resources;
    }

    resources.forEach((r) => {
        if (r.id !== id) {
            return;
        }
        patchResourceHealth(r, healthStatus);
    });

    return resources;
}

function patchCacheEntryResourceHealth<T extends ResourceWithIdState>(
    query: MaybeDrafted<{
        data?: T | T[] | undefined;
        includes?: Record<string, IncludedResourcesWithIdState>;
    }>,
    id: string,
    healthStatus: "unknown" | "healthy" | "unhealthy",
    options?: {
        shouldUpdate?: (data: { state: { current: string } }) => boolean;
    }
) {
    if (Array.isArray(query.data)) {
        query.data = bulkPatchResourceHealth(query.data, id, healthStatus);
        return query;
    }

    if (!query.data) {
        return query;
    }

    if (options?.shouldUpdate && !options.shouldUpdate(query.data)) {
        $warn(
            "skipping update of resource state health due to options filter",
            query.data
        );
        return query;
    }

    query.data = patchResourceHealth(query.data, healthStatus);

    return query;
}

/**
 * ## Description
 *
 * A function capable of inlining cache updates from a notification without making an additional request.
 * State information is passed in every notification, and for the specialized `state.changed` events, it is
 * possible to directly manipulate the cache for all relevant queries in order to quickly and effectively
 * reflect the server state. This is especially important when considering the frequency in which state
 * can change on the platform, particularly for jobs and container instances. In cases where thousands of
 * containers are deployed, this could lead to many frequent updates and cause slow-downs, network saturation,
 * etc.
 *
 * Also detects and automatically patches instance state health changes.
 *
 * ## How it works
 *
 * This function works by finding all relevant cache tags that a state update would otherwise 'invalidate',
 * and iterates over each of them to identify which one of three ways the resource is cached:
 *
 * - A single cached resource
 * - An array of cached resources
 * - An `includes` resource as part of another query
 *
 * For each query, it utilizes the correct method to update the cache object. If an `includesKey` is passed,
 * it first checks the includes object of a query to see if that key is present. If the key is found, the
 * state patch is applied to the includes instead of the main resource object.
 *
 * @param tags An array of query tags that should be checked for updates. These are the same tags that would
 * be passed into an `invalidateTags` call.
 * @param api An instance of the `cycleApi`.
 * @param message The notification message.
 * @param state The current store state.
 * @param options Available options to modify how the resource state functionality works
 * @param options.includesKey Where to look in an 'includes' block for the resource in a given query. If found,
 * it will do an `includes` update on that cache instead of modifying the main resources.
 * @param options.shouldUpdate A function that can be provided which will receive the state object of the
 * resource to be updated as a parameter, and returns a boolean. If it returns true, the update will commence. If false,
 * the update will not be applied. This is useful for ensuring past states are not overridden, such as jobs in a 'complete'
 * state then receiving a 'running' due to network issues or race conditions.
 * @returns An array of thunk actions containing Immer patches for all relevant resource changes,
 * which can be directly dispatched.
 */
export function patchAllCacheEntryResourceStates(
    tags: Array<TagDescription<CycleApiTag>>,
    api: typeof cycleApi,
    message: HubNotificationMessage | AccountNotificationMessage,
    state: RootState,
    options?: {
        includesKey?: string;
        shouldUpdate?: (data: { state: { current: string } }) => boolean;
        /** sets the healthy status of the state. if undefined, does nothing. */
        healthStatus?: "unknown" | "healthy" | "unhealthy";
    }
) {
    const patches: ReturnType<typeof api.util.updateQueryData>[] = [];

    const queries = api.util.selectInvalidatedBy(state, tags);

    queries.forEach((q) => {
        patches.push(
            api.util.updateQueryData(
                q.endpointName as "getEnvironments",
                q.originalArgs,
                (resources) => {
                    if (!message.object.state) {
                        if (message.annotations?.health) {
                            patchCacheEntryResourceHealth(
                                resources,
                                message.object.id,
                                message.annotations.health,
                                options
                            );
                        }
                        return;
                    }
                    patchCacheEntryResourceState(
                        resources,
                        message.object.id,
                        message.object.state,
                        options
                    );
                }
            )
        );
    });

    return patches;
}
