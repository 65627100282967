import classNames from "classnames";
import { ComponentPropsWithRef, useCallback, useRef, useState } from "react";
import { Tooltip } from "../tooltip";
import { CircleLoader } from "../loaders";
import { LoaderButton } from "./LoaderButton";

type PushAndHoldButtonProps = ComponentPropsWithRef<typeof LoaderButton> & {
    holdDuration?: number;
    onClick: () => void;
    tooltip?: string;
    tooltipClassName?: string;
    /** Can be used to change color of circle, e.g. stroke-cycle-blue */
    circleClassName?: string;
};

export function PushAndHoldButton({
    holdDuration = 500,
    tooltip = "click and hold",
    onClick,
    circleClassName,
    tooltipClassName,
    ...loaderButtonProps
}: PushAndHoldButtonProps) {
    const [percent, setPercent] = useState(0);
    const timerId = useRef<number>();
    const timestamp = useRef<number>();

    const checkIsDisabled = (
        ev:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.TouchEvent<HTMLButtonElement>
            | React.KeyboardEvent<HTMLButtonElement>
    ) => {
        return ev.currentTarget.matches(":disabled");
    };

    const pressDownHandler = (
        ev:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.TouchEvent<HTMLButtonElement>
            | React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (checkIsDisabled(ev)) {
            return;
        }
        ev.preventDefault();
        timestamp.current = Date.now();
        requestAnimationFrame(timer);
    };

    const releaseHandler = useCallback(
        (
            ev:
                | React.MouseEvent<HTMLButtonElement, MouseEvent>
                | React.TouchEvent<HTMLButtonElement>
                | React.KeyboardEvent<HTMLButtonElement>
        ) => {
            if (checkIsDisabled(ev)) {
                return;
            }
            ev.preventDefault();
            if (!timerId.current) {
                return;
            }
            cancelAnimationFrame(timerId.current);
            timestamp.current = undefined;
            setPercent(0);
        },
        []
    );

    const timer = () => {
        const elapsed = timestamp.current ? Date.now() - timestamp.current : 0;
        if (elapsed < holdDuration) {
            timerId.current = requestAnimationFrame(timer);
            setPercent((elapsed / holdDuration) * 100);
            return;
        }

        setPercent(0);
        onClick();
    };

    const contextMenuHandler = useCallback(
        (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (checkIsDisabled(ev)) {
                return;
            }
            ev.preventDefault();
            ev.stopPropagation();
            return false;
        },
        []
    );

    return (
        <div className={classNames("relative flex")}>
            <Tooltip message={tooltip} popupClassName={tooltipClassName}>
                <LoaderButton
                    type="button"
                    onMouseDown={pressDownHandler}
                    onMouseUp={releaseHandler}
                    onMouseLeave={releaseHandler}
                    onTouchStart={pressDownHandler}
                    onContextMenu={contextMenuHandler}
                    onTouchEnd={releaseHandler}
                    onKeyDown={(ev) => {
                        switch (ev.key) {
                            case " ": // SPACE
                            case "Enter":
                                // If held down, this event is fired multiple times.
                                // This property tells us if it's the same 'keydown' event.
                                if (ev.repeat) {
                                    return;
                                }
                                ev.preventDefault();
                                pressDownHandler(ev);
                        }
                    }}
                    onKeyUp={(ev) => {
                        switch (ev.key) {
                            case " ": // SPACE
                            case "Enter":
                                releaseHandler(ev);
                        }
                    }}
                    {...loaderButtonProps}
                />
                <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
                    <CircleLoader
                        percent={percent}
                        className={classNames(circleClassName, {
                            "stroke-cycle-white":
                                loaderButtonProps.flavor === "primary" ||
                                loaderButtonProps.flavor === "default",
                            "stroke-cycle-black":
                                loaderButtonProps.flavor === "discard",
                        })}
                    />
                </div>
            </Tooltip>
        </div>
    );
}
