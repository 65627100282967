import classNames from "classnames";

type FieldErrorMessageProps = {
    message: string | undefined;
    className?: string;
};

/**
 * A form input field error message, usually shown below the input.
 */
export function FieldErrorMessage({
    message,
    className,
}: FieldErrorMessageProps) {
    if (!message) return null;
    return (
        <p
            role="alert"
            className={classNames(
                className,
                "text-error text-sm font-semibold"
            )}
        >
            {message}
        </p>
    );
}
