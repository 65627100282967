import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type PageContentProps = {
    className?: string;
};

/**
 * Creates a section with standardized margins intended for the main content of a page, below the header
 * and potentially with an aside bar. The "main" content of a page under the header.
 *
 * ## Example
 *
 */
export const PageContent: FC<PropsWithChildren<PageContentProps>> = ({
    className,
    children,
}) => {
    return (
        <section
            className={classNames(
                className,
                "flex flex-1 flex-wrap items-start gap-4"
            )}
        >
            {children}
        </section>
    );
};
