import { Account } from "../cycle";
import { paramsSerializer } from "@cycleplatform/core/util/format";
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    retry,
} from "@reduxjs/toolkit/query/react";
import { getClusterUrls } from "../cluster";
import { $warn } from "@cycleplatform/core/util/log";

export type StagingAccount = {
    data: Account;
};
export type PostLeadArg = {
    name: {
        first: string;
        last: string;
    };
    email: string;
    recaptcha?: string | null;
};
export type UpdateLeadArg = {
    staging_account_id: string;
    lead: NewLead;
};
export type NewLead = {
    organization: LeadOrganization;
    individual: LeadIndividual;
    qualifiers: LeadQualifiers;
    notes: Record<string, string>;
};
export type LeadIndividual = {
    role: string;
    phone?: string;
};
export type LeadOrganization = {
    name: string;
    size: number;
    industry?: string;
};

export type LeadQualifiers = {
    uses: LeadUses;
    num_developers?: number;
    num_devops?: number;
    goals: string[];
};

export type LeadUses = {
    containers?: boolean;
    cloud?: boolean;
    on_prem?: boolean;
    vms?: boolean;
    bare_metal?: boolean;
    providers: string[];
};

export type CompleteAccountArg = {
    staging_account_id: string;
    password: string;
};
export type PostScheduleArg = {
    staging_account_id: string;
    preferred_demo_time: Date;
    demo_time_zone: string;
};

export type PostScheduleRes = {
    data: boolean;
};

export interface VerifyEmailArg {
    token?: string;
    staging_account_id: string;
}

const baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
    const baseUrls = await getClusterUrls();
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: baseUrls.CYCLE_API_URL,
        paramsSerializer: paramsSerializer(),
    });
    return rawBaseQuery(args, WebApi, extraOptions);
};

export const signupApi = createApi({
    reducerPath: "signupApi",
    baseQuery: baseQuery,
    endpoints: (build) => ({
        createLead: build.mutation<StagingAccount, PostLeadArg>({
            query: (queryArg) => ({
                url: "v1/signup/lead",
                credentials: "include",
                method: "POST",
                body: queryArg,
            }),
        }),
        updateLead: build.mutation<StagingAccount, UpdateLeadArg>({
            query: (queryArg) => ({
                url: `v1/signup/lead/${queryArg.staging_account_id}`,
                method: "PATCH",
                body: queryArg,
            }),
        }),
        completeLead: build.mutation<StagingAccount, CompleteAccountArg>({
            query: (queryArg) => ({
                url: "/v1/signup/lead/complete",
                credentials: "include",
                method: "POST",
                body: queryArg,
            }),
        }),
        scheduleLead: build.mutation<PostScheduleRes, PostScheduleArg>({
            query: (queryArg) => ({
                url: "/v1/signup/lead/schedule-demo",
                credentials: "include",
                method: "POST",
                body: queryArg,
            }),
        }),
        verifyEmail: build.mutation<StagingAccount, VerifyEmailArg>({
            query: (queryArg) => {
                const verifyUrl = queryArg.token
                    ? `/v1/signup/email/verify`
                    : `/v1/signup/email/resend`;

                return {
                    url: verifyUrl,
                    credentials: "include",
                    method: "POST",
                    body: queryArg,
                };
            },
        }),
    }),
});

export const {
    useCreateLeadMutation,
    useUpdateLeadMutation,
    useCompleteLeadMutation,
    useScheduleLeadMutation,
    useVerifyEmailMutation,
} = signupApi;
