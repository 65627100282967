import * as React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export type ButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    CycleButtonProps;

export type CycleButtonProps = {
    /**
     * The type of button.
     * @default default
     */

    flavor?: "default" | "primary" | "discard";
    /**
     * If true, the button will extend the full width of the container.
     * Otherwise, it will match the width of the content.
     * @default false
     */
    full?: boolean;
    className?: string;
    disabled?: boolean;
    glow?: boolean;
    children?: React.ReactNode;
    icon?: IconDefinition;
};

/**
 * Generates a consistent style for block elements to appear as buttons
 */
export function generateButtonClasses({
    full = false,
    flavor = "default",
    className,
    disabled,
    glow,
}: CycleButtonProps) {
    let flavorStyles = "";

    switch (flavor) {
        case "default":
            flavorStyles = classNames(
                "border border-cycle-blue text-cycle-blue bg-transparent shadow-cycle-blue enabled:hover:border-cycle-blue-accent hover:text-cycle-black hover:bg-cycle-blue",
                // default dark
                "dark:text-cycle-white enabled:dark:hover:text-cycle-gray",
                //
                "focus:ring focus:dark:ring-cycle-blue-accent"
            );
            break;
        case "primary":
            flavorStyles = classNames(
                "bg-cycle-blue text-cycle-white shadow-cycle-blue hover:text-cycle-black",
                // primary dark
                "focus:dark:ring-offset-cycle-blue"
            );
            break;
        case "discard":
            flavorStyles = classNames(
                "border-error text-error enabled:hover:border-error hover:bg-transparent enabled:hover:text-cycle-white enabled:hover:bg-error border bg-transparent shadow-cycle-blue",
                // discard dark
                "dark:text-cycle-white enabled:dark:hover:text-cycle-black"
            );
    }

    return classNames(
        className,
        "px-4 rounded-md py-2 whitespace-nowrap font-semibold transition outline-none text-center select-none uppercase tracking-normal",
        "focus:ring focus:dark:ring-cycle-blue-accent",
        full && "w-full",
        glow && "shadow-glow",
        flavorStyles,
        // Global Disabled
        disabled && "cursor-not-allowed disabled:cursor-not-allowed",
        // disabled states
        "disabled:bg-cycle-gray-accent/20 disabled:text-cycle-gray/50 disabled:!border-cycle-gray-accent/50 border",
        // dark disabled states
        "disabled:dark:text-cycle-gray-light disabled:dark:border-cycle-gray disabled:dark:bg-cycle-gray-accent/20"
    );
}

export const Button: React.FC<ButtonProps> = ({
    full = false,
    flavor = "default",
    className,
    disabled,
    children,
    icon,
    ...props
}) => {
    return (
        <button
            // Default to button typing so we don't accidentally submit forms.
            type="button"
            {...props}
            disabled={disabled}
            className={generateButtonClasses({
                full,
                flavor,
                className,
                disabled,
            })}
        >
            {icon ? (
                <FontAwesomeIcon
                    icon={icon}
                    className={children ? "pr-2" : ""}
                />
            ) : null}
            {children}
        </button>
    );
};
