import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

type PageAsideProps = {
    className?: string;
};

export const PageAside: FC<PropsWithChildren<PageAsideProps>> = ({
    children,
    className,
}) => {
    return (
        <aside
            className={classNames(
                className,
                "flex w-1/2 flex-wrap gap-4 pl-4",
                "dark:border-cycle-black-accent"
            )}
        >
            {children}
        </aside>
    );
};
