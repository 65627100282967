// Maintaining a separate list here - need to keep both updated.
// Unfortunately we can't use this one in the tailwind config settings directly
// due to a difference in module types (es6 vs commonjs) and my lack of patience
// trying to work out a way to allow it to interop.
export const brandColors = {
    "cycle-blue": {
        DEFAULT: "rgb(42, 167, 255)",
        accent: "rgb(32, 121, 184)",
    },
    "cycle-black": {
        DEFAULT: "rgb(18, 18, 21)",
        accent: "rgb(30, 30, 33)",
    },
    "cycle-gray": {
        DEFAULT: "rgb(72, 72, 75)",
        accent: "rgb(45, 45, 48)",
        light: "rgb(240, 240, 243)",
    },
    "cycle-white": {
        DEFAULT: "rgb(250, 250, 253)",
        accent: "rgb(247, 247, 250)",
    },
    "cycle-purple": {
        DEFAULT: "rgb(174, 55, 255)",
    },
    "cycle-alt": {
        DEFAULT: "rgb(255, 255, 255)",
    },
    success: {
        DEFAULT: "rgb(34, 211, 104)",
        accent: "rgb(252, 255, 41)",
    },
    warning: {
        DEFAULT: "rgb(255, 198, 41)",
        accent: "rgb(242, 255, 97)",
    },
    error: {
        DEFAULT: "rgb(211, 34, 53)",
        accent: "rgb(255, 97, 97)",
    },
};
