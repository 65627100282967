import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SystemState = {
    splashScreen: {
        message?: string;
        visible: boolean;
    };
};

const initialState: SystemState = {
    splashScreen: {
        visible: true,
    },
};

const systemSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        setSplashScreenVisible: (state, action: PayloadAction<boolean>) => {
            state.splashScreen.visible = action.payload;
        },
        setSplashScreenMessage: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.splashScreen.message = action.payload;
        },
        setSplashScreen: (
            state,
            action: PayloadAction<SystemState["splashScreen"]>
        ) => {
            state.splashScreen = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    actions: {
        setSplashScreen,
        setSplashScreenMessage,
        setSplashScreenVisible,
    },
    reducer: systemReducer,
} = systemSlice;
