import { FC } from "react";
import classNames from "classnames";

type NotFoundProps = {
    className?: string;
    what?: string;
};

export const NotFound: FC<NotFoundProps> = ({ className, what }) => {
    return (
        <div
            className={classNames(
                className,
                "flex h-1/2 w-full flex-col items-center justify-center gap-8 p-4"
            )}
        >
            <div className="text-cycle-blue text-[100px] font-bold">404</div>

            <div className="flex w-full flex-col items-center justify-center gap-2">
                <h2 className="text-xl">
                    Cycle is unable to locate this {what || "resource"}.
                </h2>
            </div>
        </div>
    );
};
