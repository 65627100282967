import { useEffect, useState } from "react";

/**
 * Adds a delay before updating a value. Used in search/functions based on user input.
 *
 * # Example
 * ```
 *   const [searchTerm, setSearchTerm] = useState("");
 *   const debouncedSearchTerm = useDebounce(searchTerm, 500);
 * ```
 * @param value the value you want to debounce
 * @param delay the amount of time to wait before updating (in milliseconds)
 * @returns the value, but debounced
 */
export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
