import classNames from "classnames";
import { forwardRef, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import "./RhfSliderInput.css";

type RhfSliderInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    min?: string | number;
    max?: string | number;
    numTicks?: number;
    unit?: string;
    className?: string;
    /**
     * Sets the TextInput into an error state, usually indicated by a red border.
     */
    error?: boolean;
    disabled?: boolean;
};

export const RhfSliderInput = forwardRef<HTMLInputElement, RhfSliderInputProps>(
    function SliderInput(
        {
            min = 0,
            max = 100,
            numTicks = 10,
            unit,
            error,
            className,
            disabled,
            ...inputProps
        },
        ref
    ) {
        const { watch } = useFormContext();
        const value = watch(inputProps?.name || "");

        const numMin = Number(min);
        const numMax = Number(max);
        const [val, setVal] = useState(value);

        const inputComponent = (
            <input
                {...inputProps}
                ref={ref}
                aria-invalid={error ? "true" : "false"}
                type="range"
                min={min}
                max={max}
                disabled={disabled}
                onInput={(e) => setVal((e.target as HTMLInputElement).value)}
                style={
                    {
                        "--value": val,
                        "--min": min,
                        "--max": max,
                    } as React.CSSProperties
                }
                className={classNames(
                    className,
                    "styled-slider slider-progress",
                    "w-full appearance-none bg-transparent"
                )}
            />
        );

        const calculateTicks = useCallback(() => {
            const ticks: number[] = [];
            const increment = (numMax - numMin) / numTicks;

            for (let i = 0; i <= numTicks; i++) {
                ticks.push(numMin + i * increment);
            }

            return ticks;
        }, [min, max, numTicks]);

        const percent = ((value - numMin) / (numMax - numMin)) * 100;

        return (
            <div>
                <div className="mt-4 w-full">
                    <div className="relative pt-1">
                        {inputComponent}
                        <div className="pointer-events-none absolute left-0 right-12 pt-[0.25rem] font-bold">
                            <div
                                style={{ left: `calc(${percent.toString()}%)` }}
                                className={classNames(
                                    "absolute h-12 w-12 text-center",
                                    "border-cycle-blue pointer-events-none -bottom-2"
                                )}
                            >
                                {value}
                                {unit}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-3 mb-2 flex justify-between">
                    {calculateTicks().map((t) => (
                        <Tick number={t} key={t} unit={unit} />
                    ))}
                </div>
            </div>
        );
    }
);

type TickProps = {
    number: number;
    unit?: string;
};

const Tick = ({ number, unit }: TickProps) => {
    return (
        <div className="w-6 text-center">
            <div className="pointer-events-none text-center text-[8px]">|</div>
            {number}
            {unit}{" "}
        </div>
    );
};
