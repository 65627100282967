import {
    getDay,
    isBefore,
    format,
    subHours,
    roundToNearestMinutes,
    differenceInMilliseconds,
} from "date-fns";

export function formatDateString(
    date: Date | string,
    dateFormat: string = "PP HH:mm",
    zeroTimeStringMessage = ""
) {
    const d = new Date(date);

    if (isNaN(d.getTime()) || date === zeroTimeString) {
        return zeroTimeStringMessage;
    }

    return format(d, dateFormat);
}

export const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
};

export function convertTZ(date: Date, tzString: string) {
    // Note - this only works from local TZ to foreign TZ.  It can convert foreign to local
    return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
            "en-US",
            { timeZone: tzString }
        )
    );
}

export const zeroTimeString = "0001-01-01T00:00:00Z";
export function getTimeZoneString() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function isZeroTime(time: string) {
    return time === zeroTimeString;
}

export const getHumanizedTime = (
    time: string,
    options?: {
        granularity?: "ms" | "s" | "m" | "h" | "d";
        formatStr?: string;
        humanizeUpTo?: Date;
        significantFigures?: number;
    }
) => {
    const defaultOpts = {
        granularity: "s" as const,
        formatStr: DateFormats["standardShort"],
        humanizeUpTo: subHours(new Date(), 24),
        significantFigures: 2,
    };
    const {
        granularity = defaultOpts.granularity,
        formatStr = defaultOpts.formatStr,
        humanizeUpTo = defaultOpts.humanizeUpTo,
        significantFigures = defaultOpts.significantFigures,
    } = options || defaultOpts;

    const shouldHumanize = time
        ? humanizeUpTo && isBefore(humanizeUpTo, new Date(time))
        : false;

    if (!time || isZeroTime(time)) {
        return "";
    } else if (shouldHumanize) {
        const diff = differenceInMilliseconds(new Date(), new Date(time));

        if (diff < 60000) {
            return "less than 1m ago";
        } else {
            return `${
                granularity === "m" ||
                granularity === "h" ||
                granularity === "d"
                    ? "about "
                    : ""
            }${getHumanizedDuration(
                diff,
                granularity,
                significantFigures
            )} ago`;
        }
    } else {
        return format(new Date(time), formatStr);
    }
};

export const DateFormats = {
    /** Aug 21 1992 */
    standardShort: "MMM d yyyy",

    /** August 21st, 1992 */
    fullDate: "MMMM do, yyyy",

    /** 2018-08-21 */
    dateNumeric: "yyyy-mm-dd",
};

export const TimeFormats = {
    /** 9:13pm */
    standard: "h:mma",

    /** 21:13 */
    millitaryTime: "HH:mm",

    /** 21:13:00 */
    fullTime: "HH:mm:ss",
};

export const DateTimeFormats = {
    standard: `${DateFormats.standardShort} @ ${TimeFormats.standard}`,
    RFC3339: "yyyy-MM-dd'T'HH:mm:ssxxx",
};

export function formatRangeToNearestMinute() {
    return format(
        roundToNearestMinutes(new Date(), {
            roundingMethod: "ceil",
        }),
        DateTimeFormats.RFC3339
    );
}

export function formatRangeStartTimeForPeriod(period: string) {
    switch (period) {
        case "2h":
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    2
                ),
                DateTimeFormats.RFC3339
            );
        case "4h":
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    4
                ),
                DateTimeFormats.RFC3339
            );
        case "12h":
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    12
                ),
                DateTimeFormats.RFC3339
            );
        case "24h":
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    24
                ),
                DateTimeFormats.RFC3339
            );
        case "1w":
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    168
                ),
                DateTimeFormats.RFC3339
            );
        case "72h":
        default:
            return format(
                subHours(
                    roundToNearestMinutes(new Date(), {
                        roundingMethod: "ceil",
                    }),
                    72
                ),
                DateTimeFormats.RFC3339
            );
    }
}
/**
 *
 * @param durationInMs Is the duration value in number of milliseconds
 * @param granularity Is the smallest time unit that will be displayed.
 * If "s" is chosen, the smallest value will be seconds
 * @param significantFigures Is the max number of time units that will be displays.
 * If 2 is chosen, the output will only be two largest units of time.  1m 10s 100ms => 1m 10s
 * @returns A humanized string in the format 23h 40m 10s
 */
export function getHumanizedDuration(
    durationInMs: number | undefined,
    granularity: "ms" | "s" | "m" | "h" | "d" = "s",
    significantFigures = 2
) {
    const unitRank = {
        d: 0,
        h: 1,
        m: 2,
        s: 3,
        ms: 4,
    };
    if (!durationInMs || durationInMs < 0) {
        return "";
    }
    const msPerSecond = 1000;
    const msPerMinute = msPerSecond * 60;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    let ms = durationInMs;

    const days =
        unitRank[granularity] === 0
            ? Math.ceil(ms / msPerDay)
            : Math.floor(ms / msPerDay);

    ms %= msPerDay;

    const hours =
        unitRank[granularity] >= 1
            ? unitRank[granularity] === 1
                ? Math.ceil(ms / msPerHour)
                : Math.floor(ms / msPerHour)
            : undefined;

    ms %= msPerHour;

    const min =
        unitRank[granularity] >= 2
            ? unitRank[granularity] === 2
                ? Math.ceil(ms / msPerMinute)
                : Math.floor(ms / msPerMinute)
            : undefined;

    ms %= msPerMinute;

    const sec =
        unitRank[granularity] >= 3
            ? unitRank[granularity] === 3
                ? Math.ceil(ms / msPerSecond)
                : Math.floor(ms / msPerSecond)
            : undefined;

    ms = unitRank[granularity] >= 4 ? ms % msPerSecond : 0;

    const stringArray = [
        days ? `${days}d` : undefined,
        hours ? `${hours}h` : undefined,
        min ? `${min}m` : undefined,
        sec ? `${sec}s` : undefined,
        ms ? `${ms}ms` : undefined,
    ].filter((s) => s !== undefined);

    return stringArray.slice(0, significantFigures).join(" ");
}

export function parseDuration(durationString: string): number | Error {
    const matches = durationString.match(/(\d+\.?\d*)(h|m|s|ms|us|µs|ns)?/g);

    if (!matches) {
        return new Error("Invalid duration string");
    }

    let durationInMs = 0;

    for (const match of matches) {
        const value = parseFloat(match);
        const unit = match.match(/[a-zµ]+/)?.[0];

        switch (unit) {
            case "h":
                durationInMs += value * 60 * 60 * 1000;
                break;
            case "m":
                durationInMs += value * 60 * 1000;
                break;
            case "s":
                durationInMs += value * 1000;
                break;
            case "ms":
                durationInMs += value;
                break;
            case "us":
            case "µs":
                durationInMs += value / 1000;
                break;
            case "ns":
                durationInMs += value / 1000000;
                break;
            default:
                return new Error("Unknown unit: " + unit);
        }
    }

    return durationInMs;
}
