/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
    // eslint-disable-next-line no-var
    var cycleLogger:
        | ((
              level: CycleLogLevel,
              message?: any,
              ...optionalParams: any[]
          ) => void)
        | undefined;
}

export function setCycleLogger(level: CycleLogLevel) {
    globalThis.cycleLogger = function (
        msgLevel: CycleLogLevel,
        message?: any,
        ...optionalParams: any[]
    ) {
        if (level > msgLevel) {
            return;
        }
        console.log(message, ...optionalParams);
    };
}

export enum CycleLogLevel {
    Trace = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    None = 5,
}

const infoStyles = [
    "color: #fff",
    "background-color: rgb(1, 135, 230)",
    "padding: 2px 4px",
    "border-radius: 2px",
    "font-weight: bold",
].join(";");

export function $info(msg: string, ...optionalParams: any[]) {
    if (globalThis.cycleLogger) {
        globalThis.cycleLogger(
            CycleLogLevel.Info,
            `%cINFO%c${new Date().toISOString()}%c ${msg}`,
            infoStyles,
            traceStyles,
            "color:rgb(42, 167, 255)",
            ...optionalParams
        );
    }
}

const errorStyles = [
    "color: #fff",
    "background-color: rgb(211, 34, 53)",
    "padding: 2px 4px",
    "border-radius: 2px",
    "font-weight: bold",
].join(";");

export function $error(msg: string, ...optionalParams: any[]) {
    if (globalThis.cycleLogger) {
        globalThis.cycleLogger(
            CycleLogLevel.Error,
            `%cERROR%c${new Date().toISOString()}%c ${msg}`,
            errorStyles,
            traceStyles,
            errorStyles,
            ...optionalParams
        );
    }
}

const warningStyles = [
    "color: #fff",
    "background-color: rgb(246, 103, 51)",
    "padding: 2px 4px",
    "border-radius: 2px",
    "font-weight: bold",
].join(";");

export function $warn(msg: string, ...optionalParams: any[]) {
    if (globalThis.cycleLogger) {
        globalThis.cycleLogger(
            CycleLogLevel.Warn,
            `%cWARN%c${new Date().toISOString()}%c ${msg}`,
            warningStyles,
            traceStyles,
            "color:rgb(246, 103, 51)",
            ...optionalParams
        );
    }
}

const traceStyles = [
    "color: rgb(115, 115, 127)",
    "background-color: rgb(31, 31, 40)",
    "padding: 2px 4px",
    "border-radius: 2px",
    "font-weight: bold",
].join(";");

export function $trace(msg: string, ...optionalParams: any[]) {
    if (globalThis.cycleLogger) {
        globalThis.cycleLogger(
            CycleLogLevel.Trace,
            `%cTRACE ${new Date().toISOString()}%c ${msg}`,
            traceStyles,
            "color:rgb(115, 115, 127)",
            ...optionalParams
        );
    }
}
