import classNames from "classnames";

export type PanelContentProps = {
    children: React.ReactNode;
    className?: string;
    stretch?: boolean;
};

export function PanelContent({
    className,

    stretch,
    children,
}: PanelContentProps) {
    return (
        <div
            className={classNames(
                "dark:bg-cycle-black  w-full rounded-lg border border-[rgba(0,0,0,0.04)] bg-white  dark:border-[rgba(255,255,255,0.04)]",
                "[&>div>table]:p-0",
                className
            )}
        >
            <div className={classNames(!stretch && "m-4")}>{children}</div>
        </div>
    );
}
