/** Converts `rgb(1,2,3)` to hex (e.g. #fff) */
export function rgbStringToHex(rgb: string) {
    const spl = rgb.split("(")[1]?.split(")")[0]?.split(",");
    if (!spl) {
        return "";
    }
    return (
        "#" +
        spl
            .map(function (x) {
                //For each array element
                x = parseInt(x).toString(16); //Convert to a base16 string
                return x.length == 1 ? "0" + x : x; //Add zero if we get only one character
            })
            .join("")
    );
}
