import { FC } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageExclamation } from "@fortawesome/pro-solid-svg-icons";

type ResourceErrorProps = {
    className?: string;
    title?: string;
    details: string;
};

export const ResourceError: FC<ResourceErrorProps> = ({
    className,
    details,
}) => {
    return (
        <div
            className={classNames(
                className,
                "flex h-1/2 w-full flex-col items-center justify-center gap-8 p-4"
            )}
        >
            <FontAwesomeIcon
                className="text-error text-[100px]"
                icon={faMessageExclamation}
            />

            <div className="flex w-full flex-col items-center justify-center gap-2">
                <h2 className="text-2xl">Cycle encountered an error.</h2>
                <span className="text-sm">{details}</span>
            </div>
        </div>
    );
};
