import { AppDispatch } from "~/store";
import { cycleApi } from "../../__generated";
import type { CycleApiTag } from "../tags";
import { $trace } from "@cycleplatform/core/util/log";

const debouncedTimeouts: Record<string, NodeJS.Timeout> = {};

/**
 *
 */
export function debouncedInvalidateTag(
    tag: { id: string; type: CycleApiTag },
    dispatch: AppDispatch,
    delay = 1500
) {
    const id = JSON.stringify(tag);
    clearTimeout(debouncedTimeouts[id]);

    debouncedTimeouts[id] = setTimeout(() => {
        $trace(`issuing debounced tag invalidation for tag ${id}`);
        dispatch(cycleApi.util.invalidateTags([tag]));
    }, delay);
}
