import classNames from "classnames";

type StyledCellProps = {
    className?: string;
    children?: React.ReactNode;
    colSpan?: number;
};

export function StyledCell({ children, className, colSpan }: StyledCellProps) {
    return (
        <td
            className={classNames(className, "first:pl-4 last:pr-4")}
            colSpan={colSpan}
        >
            {children}
        </td>
    );
}
