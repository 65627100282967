type SplashScreenProps = {
    message?: string;
};

export function SplashScreen({ message }: SplashScreenProps) {
    return (
        <div className="bg-cycle-black fixed inset-0 flex items-center justify-center">
            <div className="relative">
                <img
                    src="/cycle-gear-white.svg"
                    className="w-96 animate-pulse"
                />
                {message && (
                    <span className="animate-fade-in absolute -bottom-10 left-0 right-0 text-center text-lg tracking-widest">
                        {message}
                    </span>
                )}
            </div>
        </div>
    );
}
