import classNames from "classnames";
import { forwardRef } from "react";

type SliderInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    className?: string;
    /**
     * Sets the TextInput into an error state, usually indicated by a red border.
     */
    error?: boolean;
};

export const SliderInput = forwardRef<HTMLInputElement, SliderInputProps>(
    function SliderInput({ error, className, ...inputProps }, ref) {
        const inputComponent = (
            <input
                {...inputProps}
                ref={ref}
                aria-invalid={error ? "true" : "false"}
                type="range"
                className={classNames(
                    className,
                    "form-range h-6 w-full appearance-none bg-transparent p-0 focus:shadow-none focus:outline-none focus:ring-0"
                )}
            />
        );

        return (
            <>
                <div className="w-full">
                    <div className="relative pt-1">{inputComponent}</div>
                </div>

                <div className="text-center text-xl">{inputProps.value}</div>
            </>
        );
    }
);
