import classNames from "classnames";

type SkeletonHeaderProps = {
    className?: string;
    size?: "sm" | "md" | "lg";
};

export function SkeletonHeader({
    className,
    size = "sm",
}: SkeletonHeaderProps) {
    return (
        <div className="flex">
            {" "}
            <div
                className={classNames(
                    className,
                    {
                        "w-24": size === "sm",
                        "w-40": size === "md",
                        "w-72": size === "lg",
                    },
                    "from-cycle-gray-light via-cycle-gray/60 to-cycle-gray-light animate-shimmer h-6 self-center rounded-lg bg-gradient-to-r bg-[length:1000px_100%] opacity-50"
                )}
            ></div>
            <span className="invisible text-3xl leading-[3rem]">_</span>
        </div>
    );
}
