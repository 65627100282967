import { applyCycleApiTagging } from "./tags";
import { applyEndpointTransforms, InstanceTelemStreamData } from "./transforms";

applyCycleApiTagging();

const { useGetInstanceTelemetryStreamAuthQuery } = applyEndpointTransforms();

export { useGetInstanceTelemetryStreamAuthQuery as useTransformedInstanceTelemetryStream };

export * from "./notifications";
export * from "./tags";
export type { InstanceTelemStreamData };
