import { cycleApi as api } from "../base";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
            query: () => ({ url: `/v1/account` }),
        }),
        updateAccount: build.mutation<
            UpdateAccountApiResponse,
            UpdateAccountApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteAccount: build.mutation<
            DeleteAccountApiResponse,
            DeleteAccountApiArg
        >({
            query: () => ({ url: `/v1/account`, method: "DELETE" }),
        }),
        getAccountLogins: build.query<
            GetAccountLoginsApiResponse,
            GetAccountLoginsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/logins`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        changePassword: build.mutation<
            ChangePasswordApiResponse,
            ChangePasswordApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/password`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        resetPassword: build.mutation<
            ResetPasswordApiResponse,
            ResetPasswordApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/reset-password`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getAccountInvites: build.query<
            GetAccountInvitesApiResponse,
            GetAccountInvitesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/invites`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        updateAccountInvite: build.mutation<
            UpdateAccountInviteApiResponse,
            UpdateAccountInviteApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/invites/${queryArg.inviteId}`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        getAccountMemberships: build.query<
            GetAccountMembershipsApiResponse,
            GetAccountMembershipsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/memberships`,
                params: {
                    include: queryArg.include,
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getTwoFactorAuthSetup: build.mutation<
            GetTwoFactorAuthSetupApiResponse,
            GetTwoFactorAuthSetupApiArg
        >({
            query: () => ({ url: `/v1/account/2fa/setup`, method: "GET" }),
        }),
        enableTwoFactorAuth: build.mutation<
            EnableTwoFactorAuthApiResponse,
            EnableTwoFactorAuthApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/2fa/setup`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        disableTwoFactorAuth: build.mutation<
            DisableTwoFactorAuthApiResponse,
            DisableTwoFactorAuthApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/2fa/disable`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        recoverTwoFactorAuth: build.mutation<
            RecoverTwoFactorAuthApiResponse,
            RecoverTwoFactorAuthApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/account/2fa/recover`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getAnnouncements: build.query<
            GetAnnouncementsApiResponse,
            GetAnnouncementsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/announcements`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                    filter: queryArg.filter,
                },
            }),
        }),
        getCredits: build.query<GetCreditsApiResponse, GetCreditsApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/credits`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getCredit: build.query<GetCreditApiResponse, GetCreditApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/credits/${queryArg.creditsId}`,
            }),
        }),
        getInvoices: build.query<GetInvoicesApiResponse, GetInvoicesApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/invoices`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                    meta: queryArg.meta,
                    filter: queryArg.filter,
                },
            }),
        }),
        getInvoice: build.query<GetInvoiceApiResponse, GetInvoiceApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/invoices/${queryArg.invoiceId}`,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        createInvoiceJob: build.mutation<
            CreateInvoiceJobApiResponse,
            CreateInvoiceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/invoices/${queryArg.invoiceId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getBillingMethods: build.query<
            GetBillingMethodsApiResponse,
            GetBillingMethodsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        createBillingMethod: build.mutation<
            CreateBillingMethodApiResponse,
            CreateBillingMethodApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getBillingMethod: build.query<
            GetBillingMethodApiResponse,
            GetBillingMethodApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods/${queryArg.methodId}`,
            }),
        }),
        updateBillingMethod: build.mutation<
            UpdateBillingMethodApiResponse,
            UpdateBillingMethodApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods/${queryArg.methodId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteBillingMethod: build.mutation<
            DeleteBillingMethodApiResponse,
            DeleteBillingMethodApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods/${queryArg.methodId}`,
                method: "DELETE",
            }),
        }),
        createBillingMethodJob: build.mutation<
            CreateBillingMethodJobApiResponse,
            CreateBillingMethodJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/methods/${queryArg.methodId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getOrders: build.query<GetOrdersApiResponse, GetOrdersApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/orders`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        createOrder: build.mutation<CreateOrderApiResponse, CreateOrderApiArg>({
            query: (queryArg) => ({
                url: `/v1/billing/orders`,
                method: "POST",
                body: queryArg.body,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        getBillingOrder: build.query<
            GetBillingOrderApiResponse,
            GetBillingOrderApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/orders/${queryArg.orderId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        updateBillingOrder: build.mutation<
            UpdateBillingOrderApiResponse,
            UpdateBillingOrderApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/orders/${queryArg.orderId}`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        createOrderJob: build.mutation<
            CreateOrderJobApiResponse,
            CreateOrderJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/orders/${queryArg.orderId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getBillingSupportPlans: build.query<
            GetBillingSupportPlansApiResponse,
            GetBillingSupportPlansApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/plans/support`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getBillingTiers: build.query<
            GetBillingTiersApiResponse,
            GetBillingTiersApiArg
        >({
            query: () => ({ url: `/v1/billing/plans/tiers` }),
        }),
        getBillingServices: build.query<
            GetBillingServicesApiResponse,
            GetBillingServicesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/services`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getBillingService: build.query<
            GetBillingServiceApiResponse,
            GetBillingServiceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/services/${queryArg.servicesId}`,
            }),
        }),
        getBillingOverages: build.query<
            GetBillingOveragesApiResponse,
            GetBillingOveragesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/billing/services/overages`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getContainers: build.query<
            GetContainersApiResponse,
            GetContainersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createContainer: build.mutation<
            CreateContainerApiResponse,
            CreateContainerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getContainer: build.query<GetContainerApiResponse, GetContainerApiArg>({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        updateContainer: build.mutation<
            UpdateContainerApiResponse,
            UpdateContainerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteContainer: build.mutation<
            DeleteContainerApiResponse,
            DeleteContainerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}`,
                method: "DELETE",
            }),
        }),
        getContainerSummary: build.query<
            GetContainerSummaryApiResponse,
            GetContainerSummaryApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/summary`,
            }),
        }),
        createContainerJob: build.mutation<
            CreateContainerJobApiResponse,
            CreateContainerJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getContainerServers: build.query<
            GetContainerServersApiResponse,
            GetContainerServersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/servers`,
                params: {
                    meta: queryArg.meta,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getCompatibleServers: build.query<
            GetCompatibleServersApiResponse,
            GetCompatibleServersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/servers/usable`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getCompatibleImages: build.query<
            GetCompatibleImagesApiResponse,
            GetCompatibleImagesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/compatible-images`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getInstances: build.query<GetInstancesApiResponse, GetInstancesApiArg>({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createInstances: build.mutation<
            CreateInstancesApiResponse,
            CreateInstancesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        deleteContainerInstances: build.mutation<
            DeleteContainerInstancesApiResponse,
            DeleteContainerInstancesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances`,
                method: "DELETE",
            }),
        }),
        getInstance: build.query<GetInstanceApiResponse, GetInstanceApiArg>({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        deleteInstance: build.mutation<
            DeleteInstanceApiResponse,
            DeleteInstanceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}`,
                method: "DELETE",
            }),
        }),
        generateInstanceSshCredentials: build.mutation<
            GenerateInstanceSshCredentialsApiResponse,
            GenerateInstanceSshCredentialsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/ssh`,
                method: "GET",
            }),
        }),
        expireInstanceSshCredentials: build.mutation<
            ExpireInstanceSshCredentialsApiResponse,
            ExpireInstanceSshCredentialsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/ssh`,
                method: "DELETE",
            }),
        }),
        createInstanceJob: build.mutation<
            CreateInstanceJobApiResponse,
            CreateInstanceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getInstanceVolumes: build.query<
            GetInstanceVolumesApiResponse,
            GetInstanceVolumesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/volumes`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getInstanceTelemetryReport: build.query<
            GetInstanceTelemetryReportApiResponse,
            GetInstanceTelemetryReportApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/telemetry/resources/report`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getInstanceTelemetryStreamAuth: build.query<
            GetInstanceTelemetryStreamAuthApiResponse,
            GetInstanceTelemetryStreamAuthApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/telemetry/resources/stream`,
            }),
        }),
        getInstanceConsoleStreamAuth: build.query<
            GetInstanceConsoleStreamAuthApiResponse,
            GetInstanceConsoleStreamAuthApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/instances/${queryArg.instanceId}/console`,
            }),
        }),
        createFunctionJob: build.mutation<
            CreateFunctionJobApiResponse,
            CreateFunctionJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/functions/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getContainerBackups: build.query<
            GetContainerBackupsApiResponse,
            GetContainerBackupsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/backups`,
                params: {
                    include: queryArg.include,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getContainerBackup: build.query<
            GetContainerBackupApiResponse,
            GetContainerBackupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/backups/${queryArg.backupId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        deleteContainerBackup: build.mutation<
            DeleteContainerBackupApiResponse,
            DeleteContainerBackupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/backups/${queryArg.backupId}`,
                method: "DELETE",
            }),
        }),
        createContainerBackupJob: build.mutation<
            CreateContainerBackupJobApiResponse,
            CreateContainerBackupJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/backups/${queryArg.backupId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getContainerBackupLogs: build.query<
            GetContainerBackupLogsApiResponse,
            GetContainerBackupLogsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/containers/${queryArg.containerId}/backups/${queryArg.backupId}/logs`,
            }),
        }),
        getDnsZones: build.query<GetDnsZonesApiResponse, GetDnsZonesApiArg>({
            query: (queryArg) => ({
                url: `/v1/dns/zones`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createDnsZone: build.mutation<
            CreateDnsZoneApiResponse,
            CreateDnsZoneApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getDnsZone: build.query<GetDnsZoneApiResponse, GetDnsZoneApiArg>({
            query: (queryArg) => ({ url: `/v1/dns/zones/${queryArg.zoneId}` }),
        }),
        updateDnsZone: build.mutation<
            UpdateDnsZoneApiResponse,
            UpdateDnsZoneApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        deleteDnsZone: build.mutation<
            DeleteDnsZoneApiResponse,
            DeleteDnsZoneApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}`,
                method: "DELETE",
            }),
        }),
        updateDnsZoneAccess: build.mutation<
            UpdateDnsZoneAccessApiResponse,
            UpdateDnsZoneAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/access`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        createDnsZoneJob: build.mutation<
            CreateDnsZoneJobApiResponse,
            CreateDnsZoneJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getDnsZoneRecords: build.query<
            GetDnsZoneRecordsApiResponse,
            GetDnsZoneRecordsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/records`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createDnsZoneRecord: build.mutation<
            CreateDnsZoneRecordApiResponse,
            CreateDnsZoneRecordApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/records`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        updateDnsZoneRecord: build.mutation<
            UpdateDnsZoneRecordApiResponse,
            UpdateDnsZoneRecordApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/records/${queryArg.recordId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteDnsZoneRecord: build.mutation<
            DeleteDnsZoneRecordApiResponse,
            DeleteDnsZoneRecordApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/records/${queryArg.recordId}`,
                method: "DELETE",
            }),
        }),
        createDnsZoneRecordJob: build.mutation<
            CreateDnsZoneRecordJobApiResponse,
            CreateDnsZoneRecordJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/zones/${queryArg.zoneId}/records/${queryArg.recordId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getTlsGenerationAttempts: build.query<
            GetTlsGenerationAttemptsApiResponse,
            GetTlsGenerationAttemptsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/tls/attempts`,
                params: {
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        lookupTlsCertificate: build.query<
            LookupTlsCertificateApiResponse,
            LookupTlsCertificateApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/dns/tls/certificates/lookup`,
                params: {
                    domain: queryArg.domain,
                    wildcard: queryArg.wildcard,
                },
            }),
        }),
        getEnvironments: build.query<
            GetEnvironmentsApiResponse,
            GetEnvironmentsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createEnvironment: build.mutation<
            CreateEnvironmentApiResponse,
            CreateEnvironmentApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getEnvironmentMonitoringTiers: build.query<
            GetEnvironmentMonitoringTiersApiResponse,
            GetEnvironmentMonitoringTiersApiArg
        >({
            query: () => ({ url: `/v1/environments/monitoring-tiers` }),
        }),
        getEnvironment: build.query<
            GetEnvironmentApiResponse,
            GetEnvironmentApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        updateEnvironment: build.mutation<
            UpdateEnvironmentApiResponse,
            UpdateEnvironmentApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteEnvironment: build.mutation<
            DeleteEnvironmentApiResponse,
            DeleteEnvironmentApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}`,
                method: "DELETE",
            }),
        }),
        updateEnvironmentAccess: build.mutation<
            UpdateEnvironmentAccessApiResponse,
            UpdateEnvironmentAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        createEnvironmentJob: build.mutation<
            CreateEnvironmentJobApiResponse,
            CreateEnvironmentJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getEnvironmentSummary: build.query<
            GetEnvironmentSummaryApiResponse,
            GetEnvironmentSummaryApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/summary`,
            }),
        }),
        getEnvironmentDeployments: build.query<
            GetEnvironmentDeploymentsApiResponse,
            GetEnvironmentDeploymentsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/deployments`,
            }),
        }),
        exportStack: build.mutation<ExportStackApiResponse, ExportStackApiArg>({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/export/stack`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getScopedVariables: build.query<
            GetScopedVariablesApiResponse,
            GetScopedVariablesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/scoped-variables`,
                params: {
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createScopedVariable: build.mutation<
            CreateScopedVariableApiResponse,
            CreateScopedVariableApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/scoped-variables`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getScopedVariable: build.query<
            GetScopedVariableApiResponse,
            GetScopedVariableApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/scoped-variables/${queryArg.scopedVariableId}`,
            }),
        }),
        updateScopedVariable: build.mutation<
            UpdateScopedVariableApiResponse,
            UpdateScopedVariableApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/scoped-variables/${queryArg.scopedVariableId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteScopedVariable: build.mutation<
            DeleteScopedVariableApiResponse,
            DeleteScopedVariableApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/scoped-variables/${queryArg.scopedVariableId}`,
                method: "DELETE",
            }),
        }),
        createDiscoveryServiceJob: build.mutation<
            CreateDiscoveryServiceJobApiResponse,
            CreateDiscoveryServiceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/discovery/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getLoadBalancerService: build.query<
            GetLoadBalancerServiceApiResponse,
            GetLoadBalancerServiceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/lb`,
            }),
        }),
        createLoadBalancerServiceJob: build.mutation<
            CreateLoadBalancerServiceJobApiResponse,
            CreateLoadBalancerServiceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/lb/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getLoadBalancerTelemetryReport: build.query<
            GetLoadBalancerTelemetryReportApiResponse,
            GetLoadBalancerTelemetryReportApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/lb/telemetry/report`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getLoadBalancerLatestTelemetryReport: build.query<
            GetLoadBalancerLatestTelemetryReportApiResponse,
            GetLoadBalancerLatestTelemetryReportApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/lb/telemetry/latest`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getLoadBalancerTelemetryLatestControllers: build.query<
            GetLoadBalancerTelemetryLatestControllersApiResponse,
            GetLoadBalancerTelemetryLatestControllersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/lb/telemetry/latest-controllers`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        createSchedulerServiceJob: build.mutation<
            CreateSchedulerServiceJobApiResponse,
            CreateSchedulerServiceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/scheduler/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getVpnService: build.query<
            GetVpnServiceApiResponse,
            GetVpnServiceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn`,
            }),
        }),
        getVpnLogins: build.query<GetVpnLoginsApiResponse, GetVpnLoginsApiArg>({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn/logins`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getVpnUsers: build.query<GetVpnUsersApiResponse, GetVpnUsersApiArg>({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn/users`,
            }),
        }),
        createVpnUser: build.mutation<
            CreateVpnUserApiResponse,
            CreateVpnUserApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn/users`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        deleteVpnUser: build.mutation<
            DeleteVpnUserApiResponse,
            DeleteVpnUserApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn/users/${queryArg.userId}`,
                method: "DELETE",
            }),
        }),
        createVpnServiceJob: build.mutation<
            CreateVpnServiceJobApiResponse,
            CreateVpnServiceJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/environments/${queryArg.environmentId}/services/vpn/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getHubs: build.query<GetHubsApiResponse, GetHubsApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs`,
                params: {
                    page: queryArg.page,
                    filter: queryArg.filter,
                },
            }),
        }),
        createHub: build.mutation<CreateHubApiResponse, CreateHubApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getHub: build.query<GetHubApiResponse, GetHubApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current`,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        updateHub: build.mutation<UpdateHubApiResponse, UpdateHubApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteHub: build.mutation<DeleteHubApiResponse, DeleteHubApiArg>({
            query: () => ({ url: `/v1/hubs/current`, method: "DELETE" }),
        }),
        getHubCapabilities: build.query<
            GetHubCapabilitiesApiResponse,
            GetHubCapabilitiesApiArg
        >({
            query: () => ({ url: `/v1/hubs/capabilities` }),
        }),
        getHubUsage: build.query<GetHubUsageApiResponse, GetHubUsageApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/usage`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getHubActivity: build.query<
            GetHubActivityApiResponse,
            GetHubActivityApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/activity`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getApiKeys: build.query<GetApiKeysApiResponse, GetApiKeysApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/api-keys`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        createApiKey: build.mutation<
            CreateApiKeyApiResponse,
            CreateApiKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/api-keys`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getApiKey: build.query<GetApiKeyApiResponse, GetApiKeyApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/api-keys/${queryArg.apikeyId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        updateApiKey: build.mutation<
            UpdateApiKeyApiResponse,
            UpdateApiKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/api-keys/${queryArg.apikeyId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteApiKey: build.mutation<
            DeleteApiKeyApiResponse,
            DeleteApiKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/api-keys/${queryArg.apikeyId}`,
                method: "DELETE",
            }),
        }),
        getIntegration: build.query<
            GetIntegrationApiResponse,
            GetIntegrationApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations/${queryArg.integrationId}`,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        updateIntegration: build.mutation<
            UpdateIntegrationApiResponse,
            UpdateIntegrationApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations/${queryArg.integrationId}`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        deleteIntegration: build.mutation<
            DeleteIntegrationApiResponse,
            DeleteIntegrationApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations/${queryArg.integrationId}`,
                method: "DELETE",
            }),
        }),
        getIntegrations: build.query<
            GetIntegrationsApiResponse,
            GetIntegrationsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations`,
                params: {
                    page: queryArg.page,
                    meta: queryArg.meta,
                    filter: queryArg.filter,
                },
            }),
        }),
        createIntegration: build.mutation<
            CreateIntegrationApiResponse,
            CreateIntegrationApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations`,
                method: "POST",
                body: queryArg.body,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        getAvailableIntegrations: build.query<
            GetAvailableIntegrationsApiResponse,
            GetAvailableIntegrationsApiArg
        >({
            query: () => ({ url: `/v1/hubs/current/integrations/available` }),
        }),
        createIntegrationJob: build.mutation<
            CreateIntegrationJobApiResponse,
            CreateIntegrationJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/integrations/${queryArg.integrationId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getHubMembers: build.query<
            GetHubMembersApiResponse,
            GetHubMembersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/members`,
                params: {
                    include: queryArg.include,
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getHubMembership: build.query<
            GetHubMembershipApiResponse,
            GetHubMembershipApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/membership`,
                params: {
                    include: queryArg.include,
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        getHubMember: build.query<GetHubMemberApiResponse, GetHubMemberApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/members/${queryArg.memberId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        updateHubMember: build.mutation<
            UpdateHubMemberApiResponse,
            UpdateHubMemberApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/members/${queryArg.memberId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteHubMember: build.mutation<
            DeleteHubMemberApiResponse,
            DeleteHubMemberApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/members/${queryArg.memberId}`,
                method: "DELETE",
            }),
        }),
        getHubMemberAccount: build.query<
            GetHubMemberAccountApiResponse,
            GetHubMemberAccountApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/members/account/${queryArg.accountId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        getHubInvites: build.query<
            GetHubInvitesApiResponse,
            GetHubInvitesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/invites`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                },
            }),
        }),
        createHubInvite: build.mutation<
            CreateHubInviteApiResponse,
            CreateHubInviteApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/invites`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        deleteHubInvite: build.mutation<
            DeleteHubInviteApiResponse,
            DeleteHubInviteApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/hubs/current/invites/${queryArg.inviteId}`,
                method: "DELETE",
            }),
        }),
        getHubNotificationSocketAuth: build.query<
            GetHubNotificationSocketAuthApiResponse,
            GetHubNotificationSocketAuthApiArg
        >({
            query: () => ({ url: `/v1/hubs/current/notifications` }),
        }),
        getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/roles`,
                params: {
                    sort: queryArg.sort,
                    filter: queryArg.filter,
                    page: queryArg.page,
                    meta: queryArg.meta,
                },
            }),
        }),
        createRole: build.mutation<CreateRoleApiResponse, CreateRoleApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/roles`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/roles/${queryArg.roleId}`,
                params: {
                    meta: queryArg.meta,
                },
            }),
        }),
        updateRole: build.mutation<UpdateRoleApiResponse, UpdateRoleApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/roles/${queryArg.roleId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteRole: build.mutation<DeleteRoleApiResponse, DeleteRoleApiArg>({
            query: (queryArg) => ({
                url: `/v1/hubs/current/roles/${queryArg.roleId}`,
                method: "DELETE",
            }),
        }),
        getDefaultHubRoles: build.query<
            GetDefaultHubRolesApiResponse,
            GetDefaultHubRolesApiArg
        >({
            query: () => ({ url: `/v1/hubs/current/roles/defaults` }),
        }),
        getImages: build.query<GetImagesApiResponse, GetImagesApiArg>({
            query: (queryArg) => ({
                url: `/v1/images`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createImage: build.mutation<CreateImageApiResponse, CreateImageApiArg>({
            query: (queryArg) => ({
                url: `/v1/images`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getImage: build.query<GetImageApiResponse, GetImageApiArg>({
            query: (queryArg) => ({
                url: `/v1/images/${queryArg.imageId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        updateImage: build.mutation<UpdateImageApiResponse, UpdateImageApiArg>({
            query: (queryArg) => ({
                url: `/v1/images/${queryArg.imageId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteImage: build.mutation<DeleteImageApiResponse, DeleteImageApiArg>({
            query: (queryArg) => ({
                url: `/v1/images/${queryArg.imageId}`,
                method: "DELETE",
            }),
        }),
        getImageBuildLog: build.query<
            GetImageBuildLogApiResponse,
            GetImageBuildLogApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/${queryArg.imageId}/build-log`,
            }),
        }),
        createImagesJob: build.mutation<
            CreateImagesJobApiResponse,
            CreateImagesJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        createImageJob: build.mutation<
            CreateImageJobApiResponse,
            CreateImageJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/${queryArg.imageId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getImageSources: build.query<
            GetImageSourcesApiResponse,
            GetImageSourcesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createImageSource: build.mutation<
            CreateImageSourceApiResponse,
            CreateImageSourceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getImageSource: build.query<
            GetImageSourceApiResponse,
            GetImageSourceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources/${queryArg.sourceId}`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                },
            }),
        }),
        updateImageSource: build.mutation<
            UpdateImageSourceApiResponse,
            UpdateImageSourceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources/${queryArg.sourceId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteImageSource: build.mutation<
            DeleteImageSourceApiResponse,
            DeleteImageSourceApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources/${queryArg.sourceId}`,
                method: "DELETE",
            }),
        }),
        updateImageSourceAccess: build.mutation<
            UpdateImageSourceAccessApiResponse,
            UpdateImageSourceAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/images/sources/${queryArg.sourceId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        getInfrastructureSummary: build.query<
            GetInfrastructureSummaryApiResponse,
            GetInfrastructureSummaryApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/summary`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getDeploymentStrategies: build.query<
            GetDeploymentStrategiesApiResponse,
            GetDeploymentStrategiesApiArg
        >({
            query: () => ({ url: `/v1/infrastructure/deployment-strategies` }),
        }),
        getAutoScaleGroups: build.query<
            GetAutoScaleGroupsApiResponse,
            GetAutoScaleGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups`,
                params: {
                    page: queryArg.page,
                    include: queryArg.include,
                    filter: queryArg.filter,
                },
            }),
        }),
        createAutoScaleGroup: build.mutation<
            CreateAutoScaleGroupApiResponse,
            CreateAutoScaleGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getAutoScaleGroup: build.query<
            GetAutoScaleGroupApiResponse,
            GetAutoScaleGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups/${queryArg.groupId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        updateAutoScaleGroup: build.mutation<
            UpdateAutoScaleGroupApiResponse,
            UpdateAutoScaleGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups/${queryArg.groupId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteAutoScaleGroup: build.mutation<
            DeleteAutoScaleGroupApiResponse,
            DeleteAutoScaleGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups/${queryArg.groupId}`,
                method: "DELETE",
            }),
        }),
        updateAutoScaleGroupAccess: build.mutation<
            UpdateAutoScaleGroupAccessApiResponse,
            UpdateAutoScaleGroupAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/auto-scale/groups/${queryArg.groupId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        getProviderServers: build.query<
            GetProviderServersApiResponse,
            GetProviderServersApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/providers/${queryArg.providerVendor}/servers`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                    filter: queryArg.filter,
                },
            }),
        }),
        getProviderLocations: build.query<
            GetProviderLocationsApiResponse,
            GetProviderLocationsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/providers/${queryArg.providerVendor}/locations`,
                params: {
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getClusters: build.query<GetClustersApiResponse, GetClustersApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters`,
                params: {
                    page: queryArg.page,
                },
            }),
        }),
        createCluster: build.mutation<
            CreateClusterApiResponse,
            CreateClusterApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getCluster: build.query<GetClusterApiResponse, GetClusterApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters/${queryArg.clusterId}`,
            }),
        }),
        updateCluster: build.mutation<
            UpdateClusterApiResponse,
            UpdateClusterApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters/${queryArg.clusterId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteCluster: build.mutation<
            DeleteClusterApiResponse,
            DeleteClusterApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters/${queryArg.clusterId}`,
                method: "DELETE",
            }),
        }),
        updateClusterAccess: build.mutation<
            UpdateClusterAccessApiResponse,
            UpdateClusterAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/clusters/${queryArg.clusterId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        getServers: build.query<GetServersApiResponse, GetServersApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createServer: build.mutation<
            CreateServerApiResponse,
            CreateServerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getServer: build.query<GetServerApiResponse, GetServerApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}`,
                params: {
                    include: queryArg.include,
                    meta: queryArg.meta,
                },
            }),
        }),
        updateServer: build.mutation<
            UpdateServerApiResponse,
            UpdateServerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteServer: build.mutation<
            DeleteServerApiResponse,
            DeleteServerApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}`,
                method: "DELETE",
                params: {
                    options: queryArg.options,
                },
            }),
        }),
        getServerTelemetry: build.query<
            GetServerTelemetryApiResponse,
            GetServerTelemetryApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}/telemetry`,
                params: {
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getServerInstances: build.query<
            GetServerInstancesApiResponse,
            GetServerInstancesApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}/instances`,
                params: {
                    page: queryArg.page,
                    include: queryArg.include,
                },
            }),
        }),
        createServerJob: build.mutation<
            CreateServerJobApiResponse,
            CreateServerJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getServerTags: build.query<
            GetServerTagsApiResponse,
            GetServerTagsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/tags`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getServerUsage: build.query<
            GetServerUsageApiResponse,
            GetServerUsageApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}/usage`,
            }),
        }),
        getServerConsole: build.query<
            GetServerConsoleApiResponse,
            GetServerConsoleApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/servers/${queryArg.serverId}/console`,
            }),
        }),
        getInfrastructureIpPools: build.query<
            GetInfrastructureIpPoolsApiResponse,
            GetInfrastructureIpPoolsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/ips/pools`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getIpPool: build.query<GetIpPoolApiResponse, GetIpPoolApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/ips/pools/${queryArg.poolId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        deleteIpPool: build.mutation<
            DeleteIpPoolApiResponse,
            DeleteIpPoolApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/infrastructure/ips/pools/${queryArg.poolId}`,
                method: "DELETE",
            }),
        }),
        getPoolIPs: build.query<GetPoolIPsApiResponse, GetPoolIPsApiArg>({
            query: (queryArg) => ({
                url: `/v1/infrastructure/ips/pools/${queryArg.poolId}/ips`,
            }),
        }),
        getJobs: build.query<GetJobsApiResponse, GetJobsApiArg>({
            query: (queryArg) => ({
                url: `/v1/jobs`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        getJob: build.query<GetJobApiResponse, GetJobApiArg>({
            query: (queryArg) => ({ url: `/v1/jobs/${queryArg.jobId}` }),
        }),
        getLatestJobs: build.query<
            GetLatestJobsApiResponse,
            GetLatestJobsApiArg
        >({
            query: () => ({ url: `/v1/jobs/latest` }),
        }),
        getMetrics: build.query<GetMetricsApiResponse, GetMetricsApiArg>({
            query: (queryArg) => ({
                url: `/v1/monitoring/metrics`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        generateAggregatedMetrics: build.query<
            GenerateAggregatedMetricsApiResponse,
            GenerateAggregatedMetricsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/monitoring/metrics/aggregate`,
                method: "POST",
                body: queryArg.body,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
            query: (queryArg) => ({
                url: `/v1/monitoring/events`,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        generateAggregatedEvents: build.query<
            GenerateAggregatedEventsApiResponse,
            GenerateAggregatedEventsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/monitoring/events/aggregate`,
                method: "POST",
                body: queryArg.body,
                params: {
                    filter: queryArg.filter,
                },
            }),
        }),
        aggregateLogs: build.mutation<
            AggregateLogsApiResponse,
            AggregateLogsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/monitoring/logs/aggregate`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getMonitoringMonitors: build.query<
            GetMonitoringMonitorsApiResponse,
            GetMonitoringMonitorsApiArg
        >({
            query: () => ({ url: `/v1/monitoring/monitors` }),
        }),
        getPipelines: build.query<GetPipelinesApiResponse, GetPipelinesApiArg>({
            query: (queryArg) => ({
                url: `/v1/pipelines`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createPipeline: build.mutation<
            CreatePipelineApiResponse,
            CreatePipelineApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getPipeline: build.query<GetPipelineApiResponse, GetPipelineApiArg>({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        updatePipeline: build.mutation<
            UpdatePipelineApiResponse,
            UpdatePipelineApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deletePipeline: build.mutation<
            DeletePipelineApiResponse,
            DeletePipelineApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}`,
                method: "DELETE",
            }),
        }),
        updatePipelineAccess: build.mutation<
            UpdatePipelineAccessApiResponse,
            UpdatePipelineAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        getPipelineRuns: build.query<
            GetPipelineRunsApiResponse,
            GetPipelineRunsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/runs`,
                params: {
                    sort: queryArg.sort,
                    include: queryArg.include,
                },
            }),
        }),
        getPipelineRun: build.query<
            GetPipelineRunApiResponse,
            GetPipelineRunApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/runs/${queryArg.runId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        createPipelineJob: build.mutation<
            CreatePipelineJobApiResponse,
            CreatePipelineJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        clonePipeline: build.mutation<
            ClonePipelineApiResponse,
            ClonePipelineApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/clone`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        triggerPipeline: build.mutation<
            TriggerPipelineApiResponse,
            TriggerPipelineApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/trigger`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getPipelineTriggerKeys: build.query<
            GetPipelineTriggerKeysApiResponse,
            GetPipelineTriggerKeysApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/keys`,
                params: {
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createPipelineTriggerKey: build.mutation<
            CreatePipelineTriggerKeyApiResponse,
            CreatePipelineTriggerKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/keys`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getPipelineTriggerKey: build.query<
            GetPipelineTriggerKeyApiResponse,
            GetPipelineTriggerKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/keys/${queryArg.triggerKeyId}`,
            }),
        }),
        updatePipelineTriggerKey: build.mutation<
            UpdatePipelineTriggerKeyApiResponse,
            UpdatePipelineTriggerKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/keys/${queryArg.triggerKeyId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deletePipelineTriggerKey: build.mutation<
            DeletePipelineTriggerKeyApiResponse,
            DeletePipelineTriggerKeyApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/pipelines/${queryArg.pipelineId}/keys/${queryArg.triggerKeyId}`,
                method: "DELETE",
            }),
        }),
        getNetworks: build.query<GetNetworksApiResponse, GetNetworksApiArg>({
            query: (queryArg) => ({
                url: `/v1/sdn/networks`,
                params: {
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createNetwork: build.mutation<
            CreateNetworkApiResponse,
            CreateNetworkApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/sdn/networks`,
                method: "POST",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        getNetwork: build.query<GetNetworkApiResponse, GetNetworkApiArg>({
            query: (queryArg) => ({
                url: `/v1/sdn/networks/${queryArg.networkId}`,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        updateNetwork: build.mutation<
            UpdateNetworkApiResponse,
            UpdateNetworkApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/sdn/networks/${queryArg.networkId}`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        deleteNetwork: build.mutation<
            DeleteNetworkApiResponse,
            DeleteNetworkApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/sdn/networks/${queryArg.networkId}`,
                method: "DELETE",
            }),
        }),
        updateNetworkAccess: build.mutation<
            UpdateNetworkAccessApiResponse,
            UpdateNetworkAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/sdn/networks/${queryArg.networkId}/access`,
                method: "PATCH",
                body: queryArg.body,
                params: {
                    include: queryArg.include,
                },
            }),
        }),
        createNetworkJob: build.mutation<
            CreateNetworkJobApiResponse,
            CreateNetworkJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/sdn/networks/${queryArg.networkId}/tasks`,
                method: "POST",
                body: queryArg.reconfigureSdnNetwork,
            }),
        }),
        getSearchIndex: build.query<
            GetSearchIndexApiResponse,
            GetSearchIndexApiArg
        >({
            query: () => ({ url: `/v1/search/index` }),
        }),
        getStacks: build.query<GetStacksApiResponse, GetStacksApiArg>({
            query: (queryArg) => ({
                url: `/v1/stacks`,
                params: {
                    meta: queryArg.meta,
                    include: queryArg.include,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createStack: build.mutation<CreateStackApiResponse, CreateStackApiArg>({
            query: (queryArg) => ({
                url: `/v1/stacks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getStack: build.query<GetStackApiResponse, GetStackApiArg>({
            query: (queryArg) => ({ url: `/v1/stacks/${queryArg.stackId}` }),
        }),
        updateStack: build.mutation<UpdateStackApiResponse, UpdateStackApiArg>({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        deleteStack: build.mutation<DeleteStackApiResponse, DeleteStackApiArg>({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}`,
                method: "DELETE",
            }),
        }),
        updateStackAccess: build.mutation<
            UpdateStackAccessApiResponse,
            UpdateStackAccessApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/access`,
                method: "PATCH",
                body: queryArg.body,
            }),
        }),
        createStackJob: build.mutation<
            CreateStackJobApiResponse,
            CreateStackJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/tasks`,
                method: "POST",
                body: queryArg.pruneStackBuilds,
            }),
        }),
        lookupStackBuild: build.query<
            LookupStackBuildApiResponse,
            LookupStackBuildApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/builds/${queryArg.buildId}`,
            }),
        }),
        getStackBuilds: build.query<
            GetStackBuildsApiResponse,
            GetStackBuildsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/builds`,
                params: {
                    meta: queryArg.meta,
                    filter: queryArg.filter,
                    sort: queryArg.sort,
                    page: queryArg.page,
                },
            }),
        }),
        createStackBuild: build.mutation<
            CreateStackBuildApiResponse,
            CreateStackBuildApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/builds`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        getStackBuild: build.query<
            GetStackBuildApiResponse,
            GetStackBuildApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/builds/${queryArg.buildId}`,
            }),
        }),
        deleteStackBuild: build.mutation<
            DeleteStackBuildApiResponse,
            DeleteStackBuildApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/builds/${queryArg.buildId}`,
                method: "DELETE",
            }),
        }),
        createStackBuildJob: build.mutation<
            CreateStackBuildJobApiResponse,
            CreateStackBuildJobApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/stacks/${queryArg.stackId}/builds/${queryArg.buildId}/tasks`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        lookupIdentifier: build.query<
            LookupIdentifierApiResponse,
            LookupIdentifierApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/utils/resource/lookup`,
                params: {
                    identifier: queryArg.identifier,
                    "desired-component": queryArg["desired-component"],
                },
            }),
        }),
        lookupComponents: build.query<
            LookupComponentsApiResponse,
            LookupComponentsApiArg
        >({
            query: (queryArg) => ({
                url: `/v1/utils/components/lookup`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as cycleApi };
export type GetAccountApiResponse = /** status 200 Returns the Account. */ {
    data?: Account;
};
export type GetAccountApiArg = void;
export type UpdateAccountApiResponse =
    /** status 200 Returns the updated Account. */ {
        data: Account;
    };
export type UpdateAccountApiArg = {
    body: {
        /** The new name on the Account. */
        name?: {
            first?: string;
            last?: string;
        };
        /** If true, Cycle employees will have the ability, upon consent, to access the account for support purposes. This access will be logged. */
        allow_support_login?: boolean;
    };
};
export type DeleteAccountApiResponse =
    /** status 202 Returns a job descriptor with information about the status of the Account deletion. */ {
        data: JobDescriptor;
    };
export type DeleteAccountApiArg = void;
export type GetAccountLoginsApiResponse =
    /** status 200 Returns a list of login records associated with this Account. */ {
        data: (AccountEmployeeLogin | AccountPasswordLogin)[];
    };
export type GetAccountLoginsApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type ChangePasswordApiResponse = /** status 200 Returns an Account. */ {
    data: Account;
};
export type ChangePasswordApiArg = {
    body: {
        /** Current Password */
        current: string;
        /** New Password */
        new: string;
    };
};
export type ResetPasswordApiResponse =
    /** status 200 Returns a simple object containing a success boolean. */ {
        data: {
            success: boolean;
        };
    };
export type ResetPasswordApiArg = {
    body:
        | {
              email: {
                  address: string;
              };
          }
        | {
              token: string;
              password: string;
          };
};
export type GetAccountInvitesApiResponse =
    /** status 200 Returns a list of pending Hub Memberships. */ {
        data: HubMembership[];
        includes?: HubMembershipsIncludes;
    };
export type GetAccountInvitesApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "capabilities"[];
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type UpdateAccountInviteApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
        includes?: HubMembershipsIncludes;
    };
export type UpdateAccountInviteApiArg = {
    /** The ID of the given Invite. */
    inviteId: string;
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
    body: {
        /** If true, the Invite will be accepted and the associated Account will join the Hub. */
        accept?: boolean;
        /** If true, the Invite will be declined and the associated Account will NOT join the Hub. */
        decline?: boolean;
    };
};
export type GetAccountMembershipsApiResponse =
    /** status 200 Returns a list of Hub Memberships associated with the Account. */ {
        data: HubMembership[];
        includes?: HubMembershipsIncludes;
    };
export type GetAccountMembershipsApiArg = {
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetTwoFactorAuthSetupApiResponse =
    /** status 200 Returns necessary information for configuring two-factor auth for the Account. */ {
        data?: TwoFactorAuthSetup;
    };
export type GetTwoFactorAuthSetupApiArg = void;
export type EnableTwoFactorAuthApiResponse =
    /** status 200 Returns the recovery codes needed in case two-factor auth is no longer possible. */ {
        data: TwoFactorAuthRecovery;
    };
export type EnableTwoFactorAuthApiArg = {
    body: {
        /** The token used to authenticate the two-factor setup. */
        token: string;
    };
};
export type DisableTwoFactorAuthApiResponse =
    /** status 200 Returns the Account. */ {
        data: Account;
    };
export type DisableTwoFactorAuthApiArg = {
    body: {
        /** A valid existing two-factor auth token, for verification. */
        token: string;
    };
};
export type RecoverTwoFactorAuthApiResponse =
    /** status 200 Returns details for setting up two-factor auth. */ {
        data: TwoFactorAuthSetup;
    };
export type RecoverTwoFactorAuthApiArg = {
    body: {
        /** The user's email */
        email: string;
        password: string;
        recovery_codes: string[];
        token?: string;
    };
};
export type GetAnnouncementsApiResponse =
    /** status 200 List of announcements. */ {
        data: Announcement[];
    };
export type GetAnnouncementsApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** Get only Announcements that have been resolved, or only those that have not. */
        resolved?: "true" | "false";
        /** The start date from when to fetch the Announcements */
        range?: "hour" | "day" | "week" | "month" | "year";
    };
};
export type GetCreditsApiResponse =
    /** status 200 Returns a collection of credit resources. */ {
        data: BillingCreditYml[];
    };
export type GetCreditsApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the credit's current state.
         */
        state?: string;
        /** Use a text-based search to filter the credits. */
        search?: string;
    };
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetCreditApiResponse =
    /** status 200 Returns a credit resource. */ {
        data: BillingCreditYml;
    };
export type GetCreditApiArg = {
    /** The ID for a given credit. */
    creditsId: string;
};
export type GetInvoicesApiResponse =
    /** status 200 Returns a list of Invoices. */ {
        data: BillingInvoice[];
    };
export type GetInvoicesApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "due"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search for the specified text on supported fields.
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the invoice's current state.
         */
        state?: string;
        /** The start date from when to pull the invoices */
        "range-start"?: DateTime;
        /** The end date from when to pull the invoices */
        "range-end"?: DateTime;
    };
};
export type GetInvoiceApiResponse =
    /** status 200 Returns an invoice resource. */ {
        data: BillingInvoice;
    };
export type GetInvoiceApiArg = {
    /** The ID of the invoice. */
    invoiceId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "due"[];
};
export type CreateInvoiceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateInvoiceJobApiArg = {
    /** The ID of the invoice. */
    invoiceId: string;
    body: {
        /** The name of the action to perform. */
        action: "pay";
    };
};
export type GetBillingMethodsApiResponse =
    /** status 200 returns a collection of billing methods. */ {
        data: BillingMethod[];
    };
export type GetBillingMethodsApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateBillingMethodApiResponse =
    /** status 201 Returns a billing method. */ {
        data: BillingMethod;
    };
export type CreateBillingMethodApiArg = {
    /** Parameters for creating a new billing method. */
    body: {
        /** A name for the billing method. */
        name: string;
        /** A boolean where true represents a billing method being primary. */
        primary: boolean;
        address: BillingMethodAddress;
        credit_card?: {
            name: string;
            number: string;
            cvv: string;
            expiration: {
                month: number;
                year: number;
            };
        } | null;
        us_bank_acct?: {
            /** The name of the account holder. */
            account_holder_name: string;
            /** Whether this account is owned by a business or an individual. */
            account_holder_type: "individual" | "company";
            /** The routing number of the bank where the account lives. */
            routing_number: string;
            /** The number of the bank account. */
            account_number: string;
        } | null;
    };
};
export type GetBillingMethodApiResponse =
    /** status 200 Returns a billing method resource. */ {
        data: BillingMethod;
    };
export type GetBillingMethodApiArg = {
    /** The ID of the billing method. */
    methodId: string;
};
export type UpdateBillingMethodApiResponse =
    /** status 200 Returns a billing method resource. */ {
        data: BillingMethod;
    };
export type UpdateBillingMethodApiArg = {
    /** The ID of the billing method. */
    methodId: string;
    body: {
        /** A name for the billing method. */
        name?: string;
        /** A boolean where true represents a billing method being primary. */
        primary?: boolean;
    };
};
export type DeleteBillingMethodApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteBillingMethodApiArg = {
    /** The ID of the billing method. */
    methodId: string;
};
export type CreateBillingMethodJobApiResponse =
    /** status 202 Returns a job descriptor. */ {
        data: JobDescriptor;
    };
export type CreateBillingMethodJobApiArg = {
    /** The ID of the method. */
    methodId: string;
    body: {
        /** The name of the action to perform. */
        action: "verify";
        contents: {
            micro_deposits: number[];
        };
    };
};
export type GetOrdersApiResponse =
    /** status 200 Returns a list of Billing Orders. */ {
        data: Order[];
        includes?: BillingOrderIncludes;
    };
export type GetOrdersApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    meta?: "due"[];
    include?: "promo_codes"[];
};
export type CreateOrderApiResponse =
    /** status 201 Returns a Billing Order. */ {
        data: BillingOrder;
        includes?: BillingOrderIncludes;
    };
export type CreateOrderApiArg = {
    meta?: "due"[];
    include?: "promo_codes"[];
    /** Parameters for creating a new order */
    body: {
        /** ID associated with the tier plan */
        tier_plan_id?: string;
        /** ID associated with the support plan */
        support_plan_id?: string;
        /** String that defines term length */
        term_length?: "once" | "monthly" | "yearly";
        /** String that defines a promo code */
        promo_code?: string;
    };
};
export type GetBillingOrderApiResponse =
    /** status 200 Returns a Billing Order */ {
        data: Order;
        includes?: BillingOrderIncludes;
    };
export type GetBillingOrderApiArg = {
    /** The ID of the billing order. */
    orderId: string;
    meta?: "due"[];
    include?: "promo_codes"[];
};
export type UpdateBillingOrderApiResponse =
    /** status 200 Returns a Billing Order. */ {
        data: Order;
        includes?: BillingOrderIncludes;
    };
export type UpdateBillingOrderApiArg = {
    /** The ID of the billing order. */
    orderId: string;
    meta?: "due"[];
    include?: "promo_codes"[];
    body: {
        /** ID associated with the tier plan */
        tier_plan_id?: string;
        /** ID associated with the support plan */
        support_plan_id?: string;
        /** String that defines term length */
        term_length?: "once" | "monthly" | "yearly";
        /** String that defines a promo code */
        promo_code?: string;
    };
};
export type CreateOrderJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateOrderJobApiArg = {
    /** The ID of the requested Order */
    orderId: string;
    body: {
        /** The name of the action to perform */
        action: "confirm";
    };
};
export type GetBillingSupportPlansApiResponse =
    /** status 200 Returns available support plans. */ {
        data: SupportPlan[];
    };
export type GetBillingSupportPlansApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetBillingTiersApiResponse =
    /** status 200 Returns a list of available Billing Tiers. */ {
        data: TierPlan[];
    };
export type GetBillingTiersApiArg = void;
export type GetBillingServicesApiResponse =
    /** status 200 Returns a list of Billing Services. */ {
        data: BillingService[];
    };
export type GetBillingServicesApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetBillingServiceApiResponse =
    /** status 200 Returns the Hub's specified Billing Service. */ {
        data: BillingService;
    };
export type GetBillingServiceApiArg = {
    /** The ID of the Billing Service. */
    servicesId: string;
};
export type GetBillingOveragesApiResponse =
    /** status 200 Returns Billing Overages. */ {
        data: BillingOverage[];
    };
export type GetBillingOveragesApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetContainersApiResponse =
    /** status 200 Returns a list of Containers. */ {
        data: Container[];
        includes?: ContainerIncludes;
    };
export type GetContainersApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("instances_count" | "domains" | "ips")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "images"
        | "stack_builds"
        | "stacks"
        | "environments"
    )[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[creator]=account-ID` filter for containers matching a particular creator, such as `account-ID` or `environment-ID`
         */
        creator?: string;
        /** `filter[identifier]=value` List only those containers matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search containers for a value associated with a field on the given container(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the container's current state.
         */
        state?: string;
        /** `filter[service]=value` service filtering will allow you to filter by service type: `loadbalancer`, `discovery`, `vpn`.
         */
        service?: string;
        /** `filter[public_network]=value` public network filtering will allow you to filter by the containers network settings: `enabled`, `disabled`, `egress-only`.
         */
        public_network?: string;
        /** `filter[image]=ID` image filtering by ID.  Submit the ID of the image you wish to filter for and the return will be any containers currently using the image.
         */
        image?: string;
        /** `filter[environment]=ID` environment filtering by ID.  Submit the ID of the environment you wish to filter for and the return will be any containers in that environment.
         */
        environment?: string;
        /** `filter[tags]=tagone,tagtwo,tagthree` container filtering using server tags. If the container has the tags you submit it will be part of the return.
         */
        tags?: string;
        /** `filter[stack]=ID` stack filtering by ID.  Submit the ID of the stack you wish to filter for and the return will be any containers deployed associated with 'containers' from the stack.
         */
        stack?: string;
        /** `filter[deployment]=v1,v2` filter containers by deployment(s).
         */
        deployment?: string;
        /** The start date from when to pull the containers */
        "range-start"?: DateTime;
        /** The end date from when to pull the containers */
        "range-end"?: DateTime;
        /** `filter[deployment_strategy]=strategy` deployment strategy filtering by strategy string.  Submit the deployment strategy (example: "function") of the strategy you wish to filter for and the return will be any containers matching that description.
         */
        deployment_strategy?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateContainerApiResponse =
    /** status 201 Returns a Container. */ {
        data: Container;
    };
export type CreateContainerApiArg = {
    /** Parameters for creating a new Container. */
    body: {
        /** A user defined name for the Container. */
        name: string;
        identifier?: Identifier | null;
        /** An identifier for the Environment this Container will be deployed to. */
        environment_id: string;
        /** An identifier for the Image used to create this Container. */
        image_id: string;
        /** A boolean where true represents this Container is stateful. */
        stateful: boolean;
        config: ContainerConfig;
        /** When set to true, prevents this Container from being deleted. */
        lock?: boolean;
        deployment?: Deployment | null;
        volumes?: ContainerVolume[];
        /** Custom meta data. Not utilized by Cycle. */
        annotations?: {
            [key: string]: any;
        };
    };
};
export type GetContainerApiResponse = /** status 200 Returns a Container. */ {
    data: Container;
    includes?: ContainerIncludes;
};
export type GetContainerApiArg = {
    /** The ID of the requested Container. */
    containerId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("instances_count" | "domains" | "ips")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "images"
        | "stack_builds"
        | "stacks"
        | "environments"
    )[];
};
export type UpdateContainerApiResponse =
    /** status 200 Returns the updated Container. */ {
        data: Container;
    };
export type UpdateContainerApiArg = {
    /** The ID of the Container. */
    containerId: string;
    /** Parameters for updating a Container. */
    body: {
        /** The name for the Container. */
        name?: string;
        /** The name for the identifier. */
        identifier?: string;
        /** Sets whether Container should be deprecated. */
        deprecate?: boolean;
        /** When set to true, prevents this Container from being deleted. */
        lock?: boolean;
        /** User meta data for the Container. */
        annotation?: {
            [key: string]: string;
        };
    };
};
export type DeleteContainerApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteContainerApiArg = {
    /** The ID of the requested Container. */
    containerId: string;
};
export type GetContainerSummaryApiResponse =
    /** status 200 Returns an Container summary resource. */ {
        /** Contains useful and relevant data/statistics for a container that would otherwise be several separate API calls. */
        data: {
            id: Id;
            state: ContainerState;
            stats?: ResourceCountSummary | null;
        };
    };
export type GetContainerSummaryApiArg = {
    /** The ID of the requested container. */
    containerId: string;
};
export type CreateContainerJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateContainerJobApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** Parameters for creating a new container job. */
    body:
        | ({
              action: "start";
          } & ContainerStartActionTask)
        | ({
              action: "stop";
          } & ContainerStopActionTask)
        | ({
              action: "reconfigure";
          } & ReconfigureContainer)
        | ({
              action: "volumes.reconfigure";
          } & ReconfigureVolumeTask)
        | ({
              action: "reimage";
          } & ReimageContainer)
        | ({
              action: "scale";
          } & ScaleContainer);
};
export type GetContainerServersApiResponse =
    /** status 200 Returns an array of Server IDs / number of Instances of this Container deployed to them. */ {
        data: ServerInstancesSummary;
    };
export type GetContainerServersApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "primary_ip"[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetCompatibleServersApiResponse =
    /** status 200 Returns a list of Servers. */ {
        data: Server[];
        includes?: ServerIncludes;
    };
export type GetCompatibleServersApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("node" | "instances_count")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("location" | "models" | "integrations")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetCompatibleImagesApiResponse =
    /** status 200 Returns a list of compatible Images. */ {
        data: Image[];
    };
export type GetCompatibleImagesApiArg = {
    /** The ID of the requested Container. */
    containerId: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetInstancesApiResponse =
    /** status 200 Returns a list of Container Instances. */ {
        data: Instance[];
        includes?: InstanceIncludes;
    };
export type GetInstancesApiArg = {
    /** The ID of the container this instance is created from. */
    containerId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "servers"
        | "locations"
        | "integrations"
        | "containers"
        | "environments"
    )[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the instance's current state.
         */
        state?: string;
        /** `filter[search]=value` search instances for a value associated with a field on the given instance(s).
         */
        search?: string;
        /** `filter[server]=ID` server filtering by ID. Submit the ID of the server you wish to filter for and the return will be any instances of the container currently deployed to the given server.
         */
        server?: string;
        /** The start date from when to pull the instances */
        "range-start"?: DateTime;
        /** The end date from when to pull the instances */
        "range-end"?: DateTime;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateInstancesApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateInstancesApiArg = {
    /** The ID of the Container this Instance is created from. */
    containerId: string;
    body: {
        /** The ID of the Server the new Instance(s) should be deployed to. */
        server_id: string;
        /** The number of new Instances to be created on the given Server. */
        new_instances: number;
    }[];
};
export type DeleteContainerInstancesApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteContainerInstancesApiArg = {
    /** The ID of the container the instance(s) were created from. */
    containerId: string;
};
export type GetInstanceApiResponse =
    /** status 200 Returns a Container Instance. */ {
        data: Instance;
        includes?: InstanceIncludes;
    };
export type GetInstanceApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "servers"
        | "locations"
        | "integrations"
        | "containers"
        | "environments"
    )[];
};
export type DeleteInstanceApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteInstanceApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
};
export type GenerateInstanceSshCredentialsApiResponse =
    /** status 200 Returns an SSH connection response. */ {
        data: SshConnectionResponse;
    };
export type GenerateInstanceSshCredentialsApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
};
export type ExpireInstanceSshCredentialsApiResponse =
    /** status 200 Returns the number of tokens removed. */ {
        /** The number of tokens expired. */
        data: {
            /** The number of tokens expired. */
            tokens: number;
        };
    };
export type ExpireInstanceSshCredentialsApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
};
export type CreateInstanceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateInstanceJobApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
    /** Parameters for creating a new container instance job. */
    body:
        | ({
              action: "migration.start";
          } & MigrateAction)
        | ({
              action: "migration.revert";
          } & RevertMigrationAction)
        | ({
              action: "volume.extend";
          } & ExtendVolumeAction);
};
export type GetInstanceVolumesApiResponse =
    /** status 200 Returns a list of Container Instance Volumes. */ {
        data: InstanceVolume[];
    };
export type GetInstanceVolumesApiArg = {
    /** The ID of the requested Container. */
    containerId: string;
    /** The ID for the Container Instance. */
    instanceId: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetInstanceTelemetryReportApiResponse =
    /** status 200 Returns an Instance telemetry report. */ {
        data: InstanceTelemetryReport;
    };
export type GetInstanceTelemetryReportApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container instance. */
    instanceId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** The start date from when to pull instance telemetry data */
        "range-start"?: DateTime;
        /** The end date from when to pull instance telemetry data */
        "range-end"?: DateTime;
    };
};
export type GetInstanceTelemetryStreamAuthApiResponse =
    /** status 200 Returns credentials for connecting to an instance telemetry stream. */ {
        /** Credentials for connecting to the instance telemetry stream on compute. */
        data: {
            /** The authentication token passed into the address as a URL parameter (?token). */
            token: string;
            /** The URL address to open a websocket to for streaming instance telemetry data. */
            address: string;
        };
    };
export type GetInstanceTelemetryStreamAuthApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the Container Instance. */
    instanceId: string;
};
export type GetInstanceConsoleStreamAuthApiResponse =
    /** status 200 Returns authorization information necessary for accessing a Container Instance's console. */ {
        data: {
            /** A token used for connecting to the Instance console. */
            token: string;
            /** The protocol and url for connecting to the Instance console. */
            address: string;
        };
    };
export type GetInstanceConsoleStreamAuthApiArg = {
    /** The ID for the Container */
    containerId: string;
    /** The ID of the Instance. */
    instanceId: string;
};
export type CreateFunctionJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateFunctionJobApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** Parameters for creating a new container function job. */
    body: {
        action: "trigger";
    } & TriggerAction;
};
export type GetContainerBackupsApiResponse =
    /** status 200 Returns a list of container backup resources. */ {
        data: ContainerBackup[];
        includes?: ContainerBackupIncludes;
    };
export type GetContainerBackupsApiArg = {
    /** The ID of the requested Container. */
    containerId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "integrations"[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetContainerBackupApiResponse =
    /** status 200 Returns a Container Backup. */ {
        data: ContainerBackup;
        includes?: ContainerBackupIncludes;
    };
export type GetContainerBackupApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container backup. */
    backupId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "integrations"[];
};
export type DeleteContainerBackupApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteContainerBackupApiArg = {
    /** The ID of the container. */
    containerId: string;
    /** The ID of the container backup. */
    backupId: string;
};
export type CreateContainerBackupJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateContainerBackupJobApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID of the backup. */
    backupId: string;
    body: {
        /** The action to take. */
        action: "restore";
        /** Additional information the platform needs to create this Job. */
        contents: {
            /** The ID of the instance this Container Backup is being restored to. */
            instance_id: string;
        };
    };
};
export type GetContainerBackupLogsApiResponse =
    /** status 200 Returns a collection of Container Backup Logs. */ {
        data: ContainerBackupLogs[];
    };
export type GetContainerBackupLogsApiArg = {
    /** The ID of the requested container. */
    containerId: string;
    /** The ID for the container backup. */
    backupId: string;
};
export type GetDnsZonesApiResponse =
    /** status 200 Returns a collection of DNS Zones. */ {
        data: DnsZone[];
        includes?: ZoneIncludes;
    };
export type GetDnsZonesApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the DNS Zone's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateDnsZoneApiResponse =
    /** status 201 Returns the DNS Zone resource. */ {
        data: DnsZone;
    };
export type CreateDnsZoneApiArg = {
    /** Parameters for creating a new DNS Zone. */
    body: {
        /** A boolean where true represents the desire for the origin to be of the type `hosted`. */
        hosted: boolean;
        /** The origin that will be created. */
        origin: string;
        acl?: Acl | null;
    };
};
export type GetDnsZoneApiResponse =
    /** status 200 Returns a DNS zone resource. */ {
        data: DnsZone;
    };
export type GetDnsZoneApiArg = {
    /** The ID of the zone. */
    zoneId: string;
};
export type UpdateDnsZoneApiResponse =
    /** status 200 Returns a DNS zone resource. */ {
        data: DnsZone;
        includes?: ZoneIncludes;
    };
export type UpdateDnsZoneApiArg = {
    /** The ID of the zone. */
    zoneId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
    /** Parameters for updating a DNS Zone. */
    body: {
        /** A boolean where true represents the desire for the origin to be of the type `hosted`. */
        hosted?: boolean | null;
    };
};
export type DeleteDnsZoneApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteDnsZoneApiArg = {
    /** The ID of the zone. */
    zoneId: string;
};
export type UpdateDnsZoneAccessApiResponse =
    /** status 200 Returns a DNS zone. */ {
        data: DnsZone;
        includes?: ZoneIncludes;
    };
export type UpdateDnsZoneAccessApiArg = {
    /** The ID of the zone. */
    zoneId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
    /** Parameters for updating a DNS Zone. */
    body: {
        acl?: Acl | null;
    };
};
export type CreateDnsZoneJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateDnsZoneJobApiArg = {
    /** The ID of the zone. */
    zoneId: string;
    /** Parameters for creating a new DNS zone job. */
    body: {
        /** The action that the job will take. */
        action: "verify";
    };
};
export type GetDnsZoneRecordsApiResponse =
    /** status 200 Returns a collection of DNS Zone Records. */ {
        data: DnsRecord[];
        includes?: RecordIncludes;
    };
export type GetDnsZoneRecordsApiArg = {
    /** The ID of the zone. */
    zoneId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "containers")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the DNS record's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateDnsZoneRecordApiResponse =
    /** status 201 Returns the DNS zone resource. */ {
        data: DnsRecord;
    };
export type CreateDnsZoneRecordApiArg = {
    /** The ID of the Zone. */
    zoneId: string;
    /** Parameters for creating a new DNS Zone Record. */
    body: {
        type: DnsRecordTypes;
        /** A name for the given record where `@` represents the desire to use the root of the origin. */
        name: string;
    };
};
export type UpdateDnsZoneRecordApiResponse =
    /** status 200 Returns a DNS Zone Record. */ {
        data: DnsRecord;
    };
export type UpdateDnsZoneRecordApiArg = {
    /** The ID of the Zone. */
    zoneId: string;
    /** The ID of the record. */
    recordId: string;
    /** Parameters for updating a DNS Zone Record. The name value cannot be updated and is omitted from the properties. */
    body: {
        type: DnsRecordTypes;
    };
};
export type DeleteDnsZoneRecordApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data?: JobDescriptor;
    };
export type DeleteDnsZoneRecordApiArg = {
    /** The ID of the Zone. */
    zoneId: string;
    /** The ID of the DNS Zone Record. */
    recordId: string;
};
export type CreateDnsZoneRecordJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateDnsZoneRecordJobApiArg = {
    /** The ID of the zone. */
    zoneId: string;
    /** The ID of the record. */
    recordId: string;
    /** Parameters for creating a new DNS Zone Record Job. */
    body: {
        /** The action that the job will take. */
        action: "generateCert";
    };
};
export type GetTlsGenerationAttemptsApiResponse =
    /** status 200 Returns a list of TLS certificate generation attempts. */ {
        data: DnstlsAttempt[];
    };
export type GetTlsGenerationAttemptsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[domain]=value` filter the return for TLS attempts by domain.
         */
        domain?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type LookupTlsCertificateApiResponse =
    /** status 200 Returns a TLS certificate bundle. */ {
        data: Certificate;
    };
export type LookupTlsCertificateApiArg = {
    /** The domain to lookup. */
    domain: string;
    /** Whether or not to lookup a *. wildcard certificate that would be applicable for this domain. For example, if you are looking for a certificate for x.test.com, setting this to true will identify a *.test.com certificate that would also be applicable. */
    wildcard?: boolean;
};
export type GetEnvironmentsApiResponse =
    /** status 200 Returns a list of Environments. */ {
        data: Environment[];
        includes?: {
            creators?: CreatorInclude;
        };
    };
export type GetEnvironmentsApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("containers" | "containers_count" | "instances_count")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "stacks")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[creator]=account-ID` filter for environments matching a particular creator, such as `account-ID`.
         */
        creator?: string;
        /** `filter[identifier]=value` List only those environments matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given environment(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the environment's current state.
         */
        state?: string;
        /** `filter[stack_build]=ID` stack build filtering by ID.  Submit the ID of the stack build you wish to filter for and the return sill be any environments that have the stack build deployed to them.
         */
        stack_build?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateEnvironmentApiResponse =
    /** status 201 Returns an Environment. */ {
        data: Environment;
    };
export type CreateEnvironmentApiArg = {
    body: {
        /** A user defined name for the Environment. */
        name: string;
        identifier?: Identifier;
        acl?: Acl | null;
        /** The cluster this Environment is associated with. */
        cluster: string;
        /** Contains details regarding the Environment. */
        about: {
            /** A custom description for this Environment. */
            description: string;
        };
        features: EnvironmentFeatures;
    };
};
export type GetEnvironmentMonitoringTiersApiResponse =
    /** status 200 A list of monitoring tiers. */ {
        data: {
            [key: string]: MonitoringTierDetails;
        };
    };
export type GetEnvironmentMonitoringTiersApiArg = void;
export type GetEnvironmentApiResponse =
    /** status 200 Returns an Environment. */ {
        data: Environment;
        includes?: {
            creators?: CreatorInclude;
        };
    };
export type GetEnvironmentApiArg = {
    /** The ID of the requested environment. */
    environmentId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("containers" | "containers_count" | "instances_count")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "stacks")[];
};
export type UpdateEnvironmentApiResponse =
    /** status 200 Returns an Environment. */ {
        data: Environment;
    };
export type UpdateEnvironmentApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** Parameters for updating an Environment. */
    body: {
        name?: string | null;
        identifier?: string | null;
        version?: string | null;
        about?: EnvironmentAbout | null;
        /** The level of monitoring to enable for this environment. There is a cost associated with higher levels of monitoring. */
        monitoring?: {
            tier: "limited" | "standard" | "premium" | "enterprise";
        };
    };
};
export type DeleteEnvironmentApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteEnvironmentApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
};
export type UpdateEnvironmentAccessApiResponse =
    /** status 200 Returns an Environment. */ {
        data: Environment;
    };
export type UpdateEnvironmentAccessApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** Parameters for updating an Environment. */
    body: {
        acl?: Acl | null;
    };
};
export type CreateEnvironmentJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateEnvironmentJobApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** Parameters for creating a new Environment job. */
    body:
        | ({
              action: "start";
          } & EnvironmentStartAction)
        | ({
              action: "stop";
          } & EnvironmentStopAction)
        | ({
              action: "initialize";
          } & EnvironmentInitializeAction)
        | ({
              action: "deployments.reconfigure";
          } & EnvironmentReconfigureDeploymentsAction);
};
export type GetEnvironmentSummaryApiResponse =
    /** status 200 Returns an environment summary object. */ {
        data: EnvironmentSummary;
    };
export type GetEnvironmentSummaryApiArg = {
    /** The ID of the requested environment. */
    environmentId: string;
};
export type GetEnvironmentDeploymentsApiResponse =
    /** status 200 A list of deployments. */ {
        data: {
            /** A map where the key is the version property of a deployment. */
            versions: {
                [key: string]: {
                    /** An array of all tags on this environment that point to this version. */
                    tags: Identifier[];
                    /** The number of containers utilizing this version of this deployment. */
                    containers: number;
                };
            };
        };
    };
export type GetEnvironmentDeploymentsApiArg = {
    /** The ID of the Environment to get the list of deployments for. */
    environmentId: string;
};
export type ExportStackApiResponse =
    /** status 200 Returns a stack spec file. */ {
        data: StackSpec;
    };
export type ExportStackApiArg = {
    /** The ID of the target environment. */
    environmentId: string;
    body: {
        /** Defines which deployment containers to include in the stack export. By default, no containers tagged in a deployment are exported.
        This option includes all containers with the specific, comma separated deployment tags in the final output in addition to the
        containers that are not part of any deployment.
         */
        deployment_tags?: string[];
        /** When set to true, Cycle will resolve image origins and inline them into the stack, essentially making this stack portable outside the hub it's currently used in.
        By default, this is set to false, and the export will just reference existing image sources used by this stack in your hub by ID.
         */
        resolve_origins?: boolean;
    };
};
export type GetScopedVariablesApiResponse =
    /** status 200 Returns a list of Scoped Variables. */ {
        data: ScopedVariable[];
    };
export type GetScopedVariablesApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those environments matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given Scoped Variable(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Scoped Variable's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateScopedVariableApiResponse =
    /** status 201 Returns a Scoped Variable. */ {
        data: ScopedVariable;
    };
export type CreateScopedVariableApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** Parameters for creating a Scoped Variable. */
    body: {
        /** An identifier for this Scoped Variable. */
        identifier: string;
        scope: ScopedVariableScope;
        access?: ScopedVariableAccess;
        /** The source or value of the Scoped Variable. */
        source:
            | ({
                  type: "raw";
              } & RawScopedVariableSource)
            | ({
                  type: "url";
              } & UrlScopedVariableSource);
    };
};
export type GetScopedVariableApiResponse =
    /** status 200 Returns a Scoped Variable. */ {
        data: ScopedVariable;
    };
export type GetScopedVariableApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** The ID of the requested Scoped Variable. */
    scopedVariableId: string;
};
export type UpdateScopedVariableApiResponse =
    /** status 200 Returns a Scoped Variable. */ {
        data: ScopedVariable;
    };
export type UpdateScopedVariableApiArg = {
    /** The ID of the requested endpointnvironment. */
    environmentId: string;
    /** The ID of the requested Scoped Variable. */
    scopedVariableId: string;
    /** Parameters for updating a Scoped Variable. */
    body: {
        /** An identifier, similar to a key in an Environment variable.  Its used when envoking the Scoped Variable. */
        identifier?: string;
        scope?: ScopedVariableScope;
        access?: ScopedVariableAccess;
        /** The source or value of the Scoped Variable. */
        source?:
            | ({
                  type: "raw";
              } & RawScopedVariableSource)
            | ({
                  type: "url";
              } & UrlScopedVariableSource);
    };
};
export type DeleteScopedVariableApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteScopedVariableApiArg = {
    /** The ID of the requested Environment. */
    environmentId: string;
    /** The ID of the requested Scoped Variable. */
    scopedVariableId: string;
};
export type CreateDiscoveryServiceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateDiscoveryServiceJobApiArg = {
    /** The ID of the environment where this discovery service resides. */
    environmentId: string;
    /** Parameters for reconfiguring a discovery service. */
    body: {
        /** The name of the action to perform. */
        action: "reconfigure";
        contents: {
            config?: DiscoveryConfig | null;
            /** A boolean where `true` represents the desire to run the environment discovery service in high availability mode. */
            high_availability?: boolean | null;
            /** A boolean where `true` represents the desire to automatically update the environment discovery service. */
            auto_update?: boolean | null;
        };
    };
};
export type GetLoadBalancerServiceApiResponse =
    /** status 200 Returns a load balancer info resource. */ {
        /** Information about an environments load balancer configuration, state, and availability settings. */
        data: {
            default_config: LoadBalancerConfig;
            default_type: "haproxy" | "v1";
            current_type?: "haproxy" | "v1";
            base_configs?: {
                haproxy: HaProxyConfig;
                v1: V1LbConfig;
            };
            service: LoadBalancerEnvironmentService | null;
        };
    };
export type GetLoadBalancerServiceApiArg = {
    /** The environmentId where the load balancer resides. */
    environmentId: string;
};
export type CreateLoadBalancerServiceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateLoadBalancerServiceJobApiArg = {
    /** The ID of the environment where this load balancer resides. */
    environmentId: string;
    /** Parameters for reconfiguring a load balancer. */
    body: {
        /** The name of the action to perform. */
        action: "reconfigure";
        contents: {
            /** A boolean where `true` represents the desire to run the environment load balancer service in high availability mode. */
            high_availability?: boolean | null;
            /** A boolean representing if this service container is set to autoupdate or not */
            auto_update?: boolean | null;
            config?: LoadBalancerConfig | null;
        };
    };
};
export type GetLoadBalancerTelemetryReportApiResponse =
    /** status 200 Returns the load balancer telemetry report */ {
        data: LoadBalancerTelemetryReport;
    };
export type GetLoadBalancerTelemetryReportApiArg = {
    /** The environmentId where the load balancer resides. */
    environmentId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter: {
        /** The start date from when to pull load balancer telemetry data */
        "range-start"?: DateTime;
        /** The end date from when to pull load balancer telemetry data */
        "range-end"?: DateTime;
        /** The specific controller to fetch telemetry for */
        controller: DateTime;
    };
};
export type GetLoadBalancerLatestTelemetryReportApiResponse =
    /** status 200 Returns the latest load balancer telemetry */ {
        data: LoadBalancerLatestTelemetry;
    };
export type GetLoadBalancerLatestTelemetryReportApiArg = {
    /** The environmentId where the load balancer resides. */
    environmentId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
    ### Required Filter
    On this endpoint, you MUST pass filter[controller].
     */
    filter: {
        /** `filter[controller]=port-123` filter for a specific controller to load telemetry for. THIS FILTER IS REQUIRED.
         */
        controller?: string;
    };
};
export type GetLoadBalancerTelemetryLatestControllersApiResponse =
    /** status 200 Returns information about the latest controllers that produced telemetry. */ {
        data: LoadBalancerLatestControllers;
        includes?: LoadBalancerLatestControllersIncludes;
    };
export type GetLoadBalancerTelemetryLatestControllersApiArg = {
    /** The ID of the environment of the desired load balancer. */
    environmentId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("instances" | "servers" | "containers")[];
};
export type CreateSchedulerServiceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateSchedulerServiceJobApiArg = {
    /** The ID of the environment where this scheduler service resides. */
    environmentId: string;
    /** Parameters for reconfiguring a scheduler service. */
    body: {
        /** The name of the action to perform. */
        action: "reconfigure";
        contents: {
            config?: SchedulerConfig | null;
            /** A boolean where `true` represents the desire to automatically update the environment scheduler service. */
            auto_update?: boolean | null;
        };
    };
};
export type GetVpnServiceApiResponse =
    /** status 200 Returns the VPN service. */ {
        data: VpnInfo;
    };
export type GetVpnServiceApiArg = {
    /** The environmentId where the VPN resides. */
    environmentId: string;
};
export type GetVpnLoginsApiResponse =
    /** status 200 Returns a VPNInfo resource. */ {
        data: VpnLogin[];
    };
export type GetVpnLoginsApiArg = {
    /** The environmentId where the VPN resides. */
    environmentId: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetVpnUsersApiResponse =
    /** status 200 Returns a list of VPN users for the given VPN. */ {
        data: VpnUser[];
    };
export type GetVpnUsersApiArg = {
    /** The environmentId where the VPN service resides. */
    environmentId: string;
};
export type CreateVpnUserApiResponse = /** status 201 Returns a VPN User. */ {
    data: VpnUser;
};
export type CreateVpnUserApiArg = {
    /** The environmentId where the VPN service resides. */
    environmentId: string;
    /** Parameters for creating a new VPN user. */
    body: {
        username: string;
        password: string;
    };
};
export type DeleteVpnUserApiResponse = /** status 200 Returns no data. */ {
    data: string;
};
export type DeleteVpnUserApiArg = {
    /** The environmentId where the VPN service the given user belongs to resides. */
    environmentId: string;
    /** The userId of the user to be deleted. */
    userId: string;
};
export type CreateVpnServiceJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateVpnServiceJobApiArg = {
    /** The ID of the Environment the VPN service resides in. */
    environmentId: string;
    /** The task contents used to build the Environment VPN Job. */
    body:
        | ({
              action: "reset";
          } & VpnResetTask)
        | ({
              action: "reconfigure";
          } & VpnReconfigureTask);
};
export type GetHubsApiResponse = /** status 200 Returns a list of Hubs. */ {
    data: Hub[];
};
export type GetHubsApiArg = {
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those environments matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search hubs for a value associated with a field on the given Hub(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Hub's current state.
         */
        state?: string;
    };
};
export type CreateHubApiResponse = /** status 200 Returns a Hub resource. */ {
    data: Hub;
};
export type CreateHubApiArg = {
    /** Parameters for creating a Hub. */
    body: {
        /** A name for the Hub. */
        name?: string;
        identifier?: Identifier;
        webhooks?: HubWebhooks;
    };
};
export type GetHubApiResponse = /** status 200 Returns an hub resource. */ {
    data?: Hub;
};
export type GetHubApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "memberships"[];
};
export type UpdateHubApiResponse =
    /** status 200 Returns the updated Hub resource. */ {
        data: Hub;
    };
export type UpdateHubApiArg = {
    /** Parameters for updating a hub. */
    body: {
        /** A name for the hub. */
        name?: string;
        webhooks?: HubWebhooks;
    };
};
export type DeleteHubApiResponse =
    /** status 202 Returns a task descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteHubApiArg = void;
export type GetHubCapabilitiesApiResponse =
    /** status 200 Returns a list of capabilities. */ {
        data: Capability[];
        meta: {
            /** Human-readable captions keyed by the platform level capability it describes. */
            captions: {
                [key: string]: string;
            };
        };
    };
export type GetHubCapabilitiesApiArg = void;
export type GetHubUsageApiResponse =
    /** status 200 Returns usage detail points of the Hub over a range of time. */ {
        data: HubUsageDatum[];
    };
export type GetHubUsageApiArg = {
    filter?: object;
};
export type GetHubActivityApiResponse =
    /** status 200 Returns a list of Hub Activity entries. */ {
        data: Activity[];
        includes?: ActivityIncludes;
    };
export type GetHubActivityApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "users"
        | "components"
        | "creators"
        | "images"
        | "stack_builds"
        | "stacks"
        | "environments"
    )[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search activities for a value associated with a field on the given activity(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the activity's current state.
         */
        state?: string;
        /** `filter[user]=ID` user filtering by ID. Submit the ID of the user you wish to filter for and the return will be any activity from that user.
         */
        user?: string;
        /** `filter[environment]=ID` environment filtering by ID. Submit the ID of the environment you wish to filter for and the return will be any activity from that environment.
         */
        environment?: string;
        /** `filter[container]=ID` container filtering by ID. Submit the ID of the container you wish to filter for and the return will be any activity from that container.
         */
        container?: string;
        /** `filter[instance]=ID` instance filtering by ID. Submit the ID of the instance you wish to filter for and the return will be any activity from that instance.
         */
        instance?: string;
        /** `filter[server]=ID` server filtering by ID. Submit the ID of the server you wish to filter for and the return will be any activity from that server.
         */
        server?: string;
        /** `filter[events]=value` filter by event names.
         */
        events?: string;
        /** `filter[verbosity]=integer` filter the activity return by verbosity. The verbosity can be:
          `0` - Activity that users would find useful.
          `1` - Activity that can be useful when tracking down how something happened.
          `2` - Full activity, can be useful in debugging problems.
         */
        verbosity?: number;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetApiKeysApiResponse =
    /** status 200 Returns a collection of API Key resources. */ {
        data: ApiKey[];
    };
export type GetApiKeysApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateApiKeyApiResponse = /** status 201 Returns an API Key. */ {
    data: ApiKey;
};
export type CreateApiKeyApiArg = {
    /** Parameters for creating an API Key. */
    body: {
        /** A name given to the API Key. */
        name: string;
        /** The ID of the role this API Key is a member of. */
        role_id: Id;
        /** An array of approved IPs from which this API Key can be used. */
        ips?: string[];
    };
};
export type GetApiKeyApiResponse =
    /** status 200 Returns an API Key resource. */ {
        data?: ApiKey;
        includes?: ApiKeyIncludes;
    };
export type GetApiKeyApiArg = {
    /** The ID of the API Key. */
    apikeyId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "roles"[];
};
export type UpdateApiKeyApiResponse = /** status 200 Returns an API Key. */ {
    data: ApiKey;
};
export type UpdateApiKeyApiArg = {
    /** The ID of the API Key. */
    apikeyId: string;
    /** Parameters for updating an API Key. */
    body: {
        /** A name given to the API Key. */
        name?: string;
        /** The ID of the role this API Key is a member of. */
        role_id?: Id;
        /** An array of approved IPs from which this API Key can be used. */
        ips?: string[];
    };
};
export type DeleteApiKeyApiResponse = /** status 200 Returns an API Key. */ {
    data: ApiKey;
};
export type DeleteApiKeyApiArg = {
    /** The ID of the API Key. */
    apikeyId: string;
};
export type GetIntegrationApiResponse =
    /** status 200 Details of the specified Integration */ {
        data: Integration;
    };
export type GetIntegrationApiArg = {
    /** The ID of the Integration to retrieve. */
    integrationId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "definition"[];
};
export type UpdateIntegrationApiResponse =
    /** status 200 Returns the updated Integration. */ {
        data: Integration;
    };
export type UpdateIntegrationApiArg = {
    /** The ID of the Integration to update. */
    integrationId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "definition"[];
    body: {
        /** A new name for the Integration. */
        name?: string | null;
        identifier?: Identifier;
        auth?: IntegrationAuth | null;
        /** Updated key-value pairs associated with the Integration. */
        extra?: {
            [key: string]: string;
        };
    };
};
export type DeleteIntegrationApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteIntegrationApiArg = {
    /** The ID of the Integration to delete. */
    integrationId: string;
};
export type GetIntegrationsApiResponse =
    /** status 200 A list of integrations */ {
        data: Integration[];
    };
export type GetIntegrationsApiArg = {
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "definition"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for. Supports filtering by state and a text search.
     */
    filter?: {
        /** `filter[state]=value` Filters integrations by their current state. For example, `filter[state]=active` would return only integrations in an active state.
         */
        state?: string;
        /** `filter[search]=value` Performs a text search across relevant fields of the integrations. For example, `filter[search]=example` would return integrations that have "example" in any of the searchable fields
         */
        search?: string;
        /** `filter[identifier]=value` Filters integrations by their identifier. For example, `filter[identifier]=abstraction` would return only integrations with the abstraction identifier.
         */
        identifier?: string;
        /** `filter[category]=value` Filters integrations by their category. For example, `filter[category]=infrastructure-provider` would return only integrations that are capable of provisioning infrastructure.
         */
        category?: string;
    };
};
export type CreateIntegrationApiResponse =
    /** status 201 Returns the new Integration. */ {
        data: Integration;
    };
export type CreateIntegrationApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "definition"[];
    /** Parameters for creating an Integration. */
    body: {
        /** A name for the Integration. */
        name?: string | null;
        /** Unique vendor for the Integration, subject to validation. */
        vendor: string;
        identifier?: Identifier;
        /** Authentication information for the Integration. */
        auth?: {
            /** The region associated with the Integration. */
            region?: string | null;
            /** The namespace associated with the Integration. */
            namespace?: string | null;
            /** API key for accessing the Integration. */
            api_key?: string | null;
            /** Key ID for accessing the Integration. */
            key_id?: string | null;
            /** Secret for accessing the Integration. */
            secret?: string | null;
            /** Subscription ID for the Integration. */
            subscription_id?: string | null;
            /** Client ID for the Integration. */
            client_id?: string | null;
            /** Base64 encoded configuration for the Integration. */
            base64_config?: string | null;
        };
        /** Additional key-value pairs associated with the Integration. */
        extra?: {
            [key: string]: string;
        };
    };
};
export type GetAvailableIntegrationsApiResponse =
    /** status 200 A map of categories to lists of integration definitions. */ {
        data: {
            "image-builders"?: IntegrationDefinition[];
            "object-storage"?: IntegrationDefinition[];
            "tls-certificate-generation"?: IntegrationDefinition[];
            "infrastructure-provider"?: IntegrationDefinition[];
            billing?: IntegrationDefinition[];
        };
    };
export type GetAvailableIntegrationsApiArg = void;
export type CreateIntegrationJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateIntegrationJobApiArg = {
    /** The ID of the Hub Integration. */
    integrationId: string;
    /** Parameters for creating a new Hub Integration Job. */
    body: {
        /** The type of Job/selected Job action to create. */
        action: "verify";
    };
};
export type GetHubMembersApiResponse =
    /** status 200 Returns a list of Hub Memberships. */ {
        data: HubMembership[];
        includes?: HubMembershipsIncludes;
    };
export type GetHubMembersApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetHubMembershipApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
        includes?: HubMembershipsIncludes;
    };
export type GetHubMembershipApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetHubMemberApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
        includes?: HubMembershipsIncludes;
    };
export type GetHubMemberApiArg = {
    /** The ID for the given Hub member. */
    memberId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
};
export type UpdateHubMemberApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
    };
export type UpdateHubMemberApiArg = {
    /** The ID for the given member. */
    memberId: string;
    /** Parameters for updating a Hub membership. */
    body: {
        /** The account role. */
        role_id?: Id;
        permissions?: Permissions;
    };
};
export type DeleteHubMemberApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteHubMemberApiArg = {
    /** The ID of the given Hub Member. */
    memberId: string;
};
export type GetHubMemberAccountApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
        includes?: HubMembershipsIncludes;
    };
export type GetHubMemberAccountApiArg = {
    /** The ID of the member's Account. */
    accountId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "capabilities"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("senders" | "hubs" | "accounts" | "roles")[];
};
export type GetHubInvitesApiResponse =
    /** status 200 Returns a list of Memberships. */ {
        data: HubMembership[];
        includes?: HubMembershipsIncludes;
    };
export type GetHubInvitesApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "capabilities"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("senders" | "hubs" | "accounts")[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateHubInviteApiResponse =
    /** status 201 Returns a Hub Membership. */ {
        data: HubMembership;
    };
export type CreateHubInviteApiArg = {
    /** Parameters for creating a Hub invite. */
    body: {
        /** The email address of the invitee. */
        recipient?: string;
        /** The account role. */
        role_id?: Id;
        /** The Environment permissions the invitee will have */
        permissions?: {
            /** Boolean value that indicates the user has access to all Environments */
            all_environments: boolean;
            /** A list of objects that describe the specific environments the invitee will have access to */
            environments: {
                /** The ID of the Environment */
                id: string;
                /** Boolean indicating the invitee has manage rights to Environment */
                manage: boolean;
            }[];
        };
    };
};
export type DeleteHubInviteApiResponse =
    /** status 200 Returns a Hub Membership. */ {
        data: HubMembership;
    };
export type DeleteHubInviteApiArg = {
    /** The ID of the Hub Invite. */
    inviteId: string;
};
export type GetHubNotificationSocketAuthApiResponse =
    /** status 200 Returns the credentials necessary for upgrading this connection into a web socket. */ {
        data: {
            /** A token used for connecting to the Hub notification pipeline websocket API. */
            token: string;
        };
    };
export type GetHubNotificationSocketAuthApiArg = void;
export type GetRolesApiResponse =
    /** status 200 Returns a list of Hub Roles. */ {
        data: Role[];
    };
export type GetRolesApiArg = {
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    filter?: object;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "counts"[];
};
export type CreateRoleApiResponse = /** status 201 Returns a Hub Role. */ {
    data: Role;
};
export type CreateRoleApiArg = {
    /** Parameters for creating a new Hub Role. */
    body: {
        /** A name given to the Role. */
        name?: string | null;
        identifier: string;
        /** The list of platform level capabilities assigned to this Role. */
        capabilities?: {
            all: boolean;
            specific: Capability[];
        };
        /** An integer between 0 and 10 that indicates the Role hierarchy. An account can only edit a Role that is less than their rank. The 'owner' Role is rank 10. */
        rank: number;
        /** Custom user-defined properties for storing extra information on the Role. Not utilized by Cycle. */
        extra?: {
            [key: string]: string;
        };
    };
};
export type GetRoleApiResponse = /** status 200 Returns a Role. */ {
    data?: Role;
};
export type GetRoleApiArg = {
    /** The ID of the Role. */
    roleId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "counts"[];
};
export type UpdateRoleApiResponse =
    /** status 200 Returns the updated Hub Role. */ {
        data: Role;
    };
export type UpdateRoleApiArg = {
    /** The ID of the Role. */
    roleId: string;
    body: {
        /** A name given to the Role. */
        name?: string | null;
        identifier: string;
        /** The list of platform level capabilities assigned to this Role. */
        capabilities?: {
            /** If true, the Role has all capabilities. */
            all: boolean;
            specific: Capability[];
        };
        rank: number;
        /** Custom user-defined properties for storing extra information on the Role. Not utilized by Cycle. */
        extra?: {
            [key: string]: string;
        };
    };
};
export type DeleteRoleApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteRoleApiArg = {
    /** The ID of the Role. */
    roleId: string;
};
export type GetDefaultHubRolesApiResponse =
    /** status 200 Returns a list of the default roles/capabilities for a hub. */ {
        data: {
            name: string;
            identifier: Identifier;
            /** The role marked as root has full moderation control over all roles. */
            root: boolean;
            capabilities: {
                /** If true, the role has all capabilities. */
                all: boolean;
                specific: Capability[];
            };
        }[];
    };
export type GetDefaultHubRolesApiArg = void;
export type GetImagesApiResponse = /** status 200 Returns a list of Images. */ {
    data: Image[];
    includes?: ImageIncludes2;
};
export type GetImagesApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "containers_count"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "stack_builds"
        | "stacks"
        | "sources"
        | "integrations"
    )[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those images matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given Image(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Image's current state.
         */
        state?: string;
        /** `filter[source_type]=value` filter images by the Image source's type.  Can be: `direct`, `stack-build`, or `bucket`
         */
        source_type?: string;
        /** `filter[source_id]=ID` Image filtering by source ID.  Submit the ID of the Image source you wish to filter for and the return will be any Images created from that source.
         */
        source_id?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateImageApiResponse = /** status 201 Returns an Image. */ {
    data: Image;
};
export type CreateImageApiArg = {
    /** Parameters for creating a new Image. */
    body: {
        /** A name for the Image. */
        name?: string;
        /** The ID for the Image source to be used. */
        source_id: string;
        /** A build object, holding information important to the Image build. */
        build?: {
            /** An object holding key value build time arguments needed for the Image during build time. */
            args?: {
                [key: string]: string;
            };
        };
        /** An override object to be used for a single Image create request. */
        override?: {
            /** For Image sources with `docker-hub` or `docker-registry` origin types. A target to be used for overridding the default target - should include an Image and a tag. */
            target?: string;
            /** For Image sources with `docker-file` origin types. A URL pointing to a .tar.gz file of a repo with a Dockerfile in it - can be used instead of linking Cycle directly to a repository. */
            targz_url?: string;
        };
    };
};
export type GetImageApiResponse = /** status 200 Returns a Image. */ {
    data: Image;
    includes?: ImageIncludes2;
};
export type GetImageApiArg = {
    /** The ID of the image. */
    imageId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "containers_count"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creators"
        | "stack_builds"
        | "stacks"
        | "sources"
        | "integrations"
    )[];
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type UpdateImageApiResponse = /** status 200 Returns an Image. */ {
    data: Image;
};
export type UpdateImageApiArg = {
    /** The ID of the image. */
    imageId: string;
    /** Parameters for creating a new image. */
    body: {
        /** A name for the image. */
        name?: string;
    };
};
export type DeleteImageApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteImageApiArg = {
    /** The ID of the image. */
    imageId: string;
};
export type GetImageBuildLogApiResponse =
    /** status 200 Returns an Image's build log. */ {
        data: ImageBuildLog;
    };
export type GetImageBuildLogApiArg = {
    /** The ID of the Image. */
    imageId: string;
};
export type CreateImagesJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateImagesJobApiArg = {
    /** Parameters for creating a new Images Job. */
    body: {
        /** The action is the Job type to create. */
        action: "prune";
        /** Additional contents needed by the platform to create the Job. */
        contents: {
            /** A list of IDs to be pruned. */
            source_ids: string[];
        };
    };
};
export type CreateImageJobApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateImageJobApiArg = {
    /** The ID of the requested Image. */
    imageId: string;
    /** Parameters for creating a new Image job. */
    body: {
        action: "import";
    };
};
export type GetImageSourcesApiResponse =
    /** status 200 Returns a list of Image Sources. */ {
        data: ImageSource[];
        includes?: SourceIncludes;
    };
export type GetImageSourcesApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "images_count"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "integrations")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those image sources matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given Image Source(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Image Source's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateImageSourceApiResponse =
    /** status 201 Returns an Image Source. */ {
        data: ImageSource;
    };
export type CreateImageSourceApiArg = {
    /** Parameters for creating an Image Source. */
    body: {
        /** A name for the Image Source. */
        name?: string;
        identifier?: Identifier;
        acl?: Acl | null;
        builder?: {
            integration_id: HybridIdentifier;
        };
        type: ImageSourceType;
        origin: ImageOrigin;
        /** User defined information about the Image Source. */
        about?: {
            /** A description of the Image Source. */
            description: string | null;
        };
    };
};
export type GetImageSourceApiResponse =
    /** status 200 Returns an Image Source. */ {
        data: ImageSource;
        includes?: SourceIncludes;
    };
export type GetImageSourceApiArg = {
    /** The ID of the Image Source. */
    sourceId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "images_count"[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "integrations")[];
};
export type UpdateImageSourceApiResponse =
    /** status 200 Returns an Image Source. */ {
        data: ImageSource;
    };
export type UpdateImageSourceApiArg = {
    /** The ID of the Image Source. */
    sourceId: string;
    /** Parameters for updating an image source. */
    body: {
        /** A name for the image source. */
        name?: string;
        identifier?: Identifier | null;
        origin?: ImageOrigin;
        builder?: {
            integration_id?: HybridIdentifier | null;
        };
        /** User defined information about the image source. */
        about?: {
            /** A description of the image source. */
            description: string | null;
        };
    };
};
export type DeleteImageSourceApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteImageSourceApiArg = {
    /** The ID of the Image Source. */
    sourceId: string;
};
export type UpdateImageSourceAccessApiResponse =
    /** status 200 Returns an Image Source. */ {
        data: ImageSource;
    };
export type UpdateImageSourceAccessApiArg = {
    /** The ID of the Image Source. */
    sourceId: string;
    /** Parameters for updating an image source. */
    body: {
        acl?: Acl | null;
    };
};
export type GetInfrastructureSummaryApiResponse =
    /** status 200 Returns an infrastructure summary. */ {
        data: InfrastructureSummary;
    };
export type GetInfrastructureSummaryApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[cluster]=value` return an infrastructure summary only for the specified cluster.
         */
        cluster?: string;
    };
};
export type GetDeploymentStrategiesApiResponse =
    /** status 200 Returns available deployment strategies. */ {
        data: {
            "resource-density"?: DeploymentStrategy;
            "high-availability"?: DeploymentStrategy;
            "first-available"?: DeploymentStrategy;
            node?: DeploymentStrategy;
            edge?: DeploymentStrategy;
            manual?: DeploymentStrategy;
            function?: DeploymentStrategy;
        };
    };
export type GetDeploymentStrategiesApiArg = void;
export type GetAutoScaleGroupsApiResponse =
    /** status 200 Returns a list of Auto-Scale Groups. */ {
        data: AutoScaleGroup[];
        includes?: AutoScaleGroupIncludes;
    };
export type GetAutoScaleGroupsApiArg = {
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("integrations" | "models" | "locations")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search for a value associated with a field on the given auto-scale group(s).
         */
        search?: string;
        /** `filter[cluster]=value` return a list of auto-scale groups that are associated with the specified cluster.
         */
        cluster?: string;
        /** `filter[identifier]=value` list only those auto-scale groups matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the provider's current state.
         */
        state?: string;
    };
};
export type CreateAutoScaleGroupApiResponse =
    /** status 201 Returns an Auto-Scale Group. */ {
        data: AutoScaleGroup;
    };
export type CreateAutoScaleGroupApiArg = {
    /** Parameters for creating an Auto-Scale Group */
    body: {
        name: string;
        identifier: string;
        acl?: Acl | null;
        cluster: string;
        scale: AutoScaleGroupScale;
        infrastructure: AutoScaleGroupInfrastructure;
    };
};
export type GetAutoScaleGroupApiResponse =
    /** status 200 Returns an Auto-Scale Group. */ {
        data: AutoScaleGroup;
        includes?: AutoScaleGroupIncludes;
    };
export type GetAutoScaleGroupApiArg = {
    /** The ID for the given Auto-Scale Group. */
    groupId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("integrations" | "models" | "locations")[];
};
export type UpdateAutoScaleGroupApiResponse =
    /** status 200 Returns an Auto-Scale Group. */ {
        data: AutoScaleGroup;
    };
export type UpdateAutoScaleGroupApiArg = {
    /** The ID for the given Auto-Scale Group. */
    groupId: string;
    /** Parameters for creating an Auto-Scale Group */
    body: {
        name?: string | null;
        identifier?: string | null;
        scale?: AutoScaleGroupScale | null;
        infrastructure?: AutoScaleGroupInfrastructure | null;
    };
};
export type DeleteAutoScaleGroupApiResponse =
    /** status 202 Returns a task descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteAutoScaleGroupApiArg = {
    /** The ID for the given autoscale group. */
    groupId: string;
};
export type UpdateAutoScaleGroupAccessApiResponse =
    /** status 200 Returns an Auto-Scale Group. */ {
        data: AutoScaleGroup;
    };
export type UpdateAutoScaleGroupAccessApiArg = {
    /** The ID for the given Auto-Scale Group. */
    groupId: string;
    /** Parameters for creating an Auto-Scale Group */
    body: {
        acl?: Acl | null;
    };
};
export type GetProviderServersApiResponse =
    /** status 200 A list of currently provisioned servers associated with a Provider Integration. */ {
        data: ProviderServerModel[];
    };
export type GetProviderServersApiArg = {
    /** The vendor for the given provider. Example `gcp`, `equinix-metal`, `abstraction`, etc. Can also use a Provider Integration ID. */
    providerVendor: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[location_ids]=value` List only those provider servers matching this location. May return multiple results.
         */
        location_ids?: string;
    };
};
export type GetProviderLocationsApiResponse =
    /** status 200 A list of locations this Provider Integration supports. */ {
        data: ProviderLocation[];
    };
export type GetProviderLocationsApiArg = {
    /** The vendor for the given Provider Integration. Example `gcp`, `equinix-metal`, `abstraction`, etc. Can also use the Integration ID. */
    providerVendor: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetClustersApiResponse =
    /** status 200 Returns a list of clusters. */ {
        data: Cluster[];
    };
export type GetClustersApiArg = {
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateClusterApiResponse = /** status 201 Returns a cluster. */ {
    data: Cluster;
};
export type CreateClusterApiArg = {
    /** Parameters for creating a new cluster. */
    body: {
        identifier: Identifier;
        acl?: Acl | null;
    };
};
export type GetClusterApiResponse = /** status 200 Returns a cluster. */ {
    data: Cluster;
};
export type GetClusterApiArg = {
    /** The ID of the requested cluster. */
    clusterId: string;
};
export type UpdateClusterApiResponse = /** status 200 Returns a cluster. */ {
    data: Cluster;
};
export type UpdateClusterApiArg = {
    /** The ID of the requested cluster. */
    clusterId: string;
    /** Parameters for updating a cluster. */
    body: object;
};
export type DeleteClusterApiResponse =
    /** status 202 Returns a job descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteClusterApiArg = {
    /** The ID of the requested cluster. */
    clusterId: string;
};
export type UpdateClusterAccessApiResponse =
    /** status 200 Returns a cluster. */ {
        data: Cluster;
    };
export type UpdateClusterAccessApiArg = {
    /** The ID of the requested cluster. */
    clusterId: string;
    /** Parameters for updating a cluster. */
    body: {
        acl?: Acl | null;
    };
};
export type GetServersApiResponse =
    /** status 200 Returns a list of Servers. */ {
        data: Server[];
        includes?: ServerIncludes;
    };
export type GetServersApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("node" | "instances_count")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("locations" | "models" | "integrations")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the provider's current state.
         */
        state?: string;
        /** `filter[tags]=tagone,tagtwo,tagthree` filtering by Server tag.  Enter one or more tags (comma separated) and the return will include servers that match any tags in the list.
         */
        tags?: string;
        /** `filter[cluster]=clusterIdentifier` filtering by cluster.  Enter the cluster identifier and the return will include servers belonging to the specified cluster.
         */
        cluster?: string;
        /** `filter[providers]=providerone,providertwo` filtering by provider.  Enter one or more providers (commas separated) and the return will include servers that match any providers in the list.
         */
        providers?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateServerApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateServerApiArg = {
    /** Parameters for creating a Server. */
    body: {
        /** The existing or new cluster this infrastructure should be provisioned in. */
        cluster: string;
        /** An array of servers to provision. */
        servers: {
            /** The ID of the provider integration to use for this Server. */
            integration_id: Id;
            /** The model ID of the Server. */
            model_id: string;
            /** The location ID of the Server. */
            location_id: string;
            /** The number of this Server at this location to deploy. */
            quantity: number;
            /** An array of hostnames for the given servers. */
            hostnames?: string[];
            advanced?: {
                /** Advanced options to apply to the provisioning of a server. */
                provision_options?: {
                    /** For providers that support setting this value dynamically; A number representing the GB size of the volume to be attached to the server. */
                    attached_storage_size?: number | null;
                    /** A reservation ID to be used to provision a server that has been reserved at the provider. */
                    reservation_id?: string | null;
                    /** For providers that support this setting, this option will encrypt storage for server. */
                    encrypt_storage?: boolean | null;
                };
                zone?: string | null;
            }[];
        }[];
    };
};
export type GetServerApiResponse = /** status 200 Returns a Server. */ {
    data: Server;
    includes?: ServerIncludes;
};
export type GetServerApiArg = {
    /** The ID for the given Server. */
    serverId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("locations" | "models" | "integrations")[];
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("node" | "instances_count")[];
};
export type UpdateServerApiResponse = /** status 200 Returns a Server. */ {
    data: Server;
};
export type UpdateServerApiArg = {
    /** The ID for the given Server. */
    serverId: string;
    /** Parameters for updating a Server. */
    body: {
        /** Server constriants. */
        constraints: {
            /** A list of Server tags. */
            tags?: string[];
            /** Server constraints for the given Server. */
            allow?: {
                /** A boolean where true represents the Server can accept containers with no tags set. */
                pool: boolean;
                /** A boolean where true represents the Server being a target for service containers. */
                services: boolean;
                /** A boolean where true represents the desire for the Server to allow the overcommitting of shares. */
                overcommit: boolean;
                /** An integer that describes the capability of a server to overcommit shares.  This will allow more containers to run on a given server based on the servers available CPU cores. 1 CPU core = 10 shares by default. */
                overcommit_multiple?: number | null;
            };
        };
    };
};
export type DeleteServerApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteServerApiArg = {
    /** The ID of the Server. */
    serverId: string;
    /** The option field is a key-value object, where the key is the option, and the value is a boolean. For example, `?option[force]=true` */
    options?: {
        [key: string]: string;
    };
};
export type GetServerTelemetryApiResponse =
    /** status 200 Returns a list of telemetry points. */ {
        data: ServerStatsTelemetry[];
    };
export type GetServerTelemetryApiArg = {
    /** The ID for the given Server. */
    serverId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** The start date from when to pull Server telemetry data */
        "range-start"?: DateTime;
        /** The end date from when to pull Server telemetry data */
        "range-end"?: DateTime;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetServerInstancesApiResponse =
    /** status 200 Returns a list of Instances present on this Server. */ {
        data: Instance[];
        includes?: InstanceIncludes;
    };
export type GetServerInstancesApiArg = {
    /** The ID for the given server. */
    serverId: string;
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: (
        | "creator"
        | "servers"
        | "locations"
        | "integrations"
        | "containers"
        | "environments"
    )[];
};
export type CreateServerJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateServerJobApiArg = {
    /** The ID for the given Server. */
    serverId: string;
    /** Parameters for creating the new Server Job. */
    body:
        | ({
              action: "sharedfs.reconfigure";
          } & ReconfigureSharedFs)
        | ({
              action: "features.reconfigure";
          } & ReconfigureServerAction)
        | ({
              action: "restart";
          } & RestartServerAction)
        | ({
              action: "compute.restart";
          } & RestartComputeServiceAction)
        | ({
              action: "compute.spawner.restart";
          } & RestartComputeSpawner)
        | ({
              action: "evacuation.start";
          } & EvacuateServer)
        | ({
              action: "evacuation.reset";
          } & EvacuateServerReset);
};
export type GetServerTagsApiResponse =
    /** status 200 Returns a list of Server Tags. */ ServerTags;
export type GetServerTagsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[cluster]=clusterone,clustertwo` filtering by cluster.  Enter one or more clusters (commas separated) and the return will include tags from servers that match any cluster(s) in the list.
         */
        cluster?: string;
    };
};
export type GetServerUsageApiResponse =
    /** status 200 Returns usage data for the Server. */ {
        data: ServerUsageDatum;
    };
export type GetServerUsageApiArg = {
    /** The ID for the given server. */
    serverId: string;
};
export type GetServerConsoleApiResponse =
    /** status 200 Returns console credentials for the Server. */ {
        data: {
            /** The websocket target URL. */
            address: string;
            /** The authentication token for the console socket. It should be appended as the URL parameter "token" to the address. */
            token: string;
        };
    };
export type GetServerConsoleApiArg = {
    /** The ID of the server to connect to. */
    serverId: string;
};
export type GetInfrastructureIpPoolsApiResponse =
    /** status 200 A list of IP Pools. */ {
        data: InfrastructureIpPool[];
        includes?: PoolIncludes;
    };
export type GetInfrastructureIpPoolsApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("servers" | "integrations" | "locations")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[available]=true` filter for IPs that are available for use.
         */
        available?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the IP's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetIpPoolApiResponse = /** status 200 An IP Pool. */ {
    data: InfrastructureIpPool;
    includes?: PoolIncludes;
};
export type GetIpPoolApiArg = {
    /** The ID for the given pool. */
    poolId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("servers" | "integrations" | "locations")[];
};
export type DeleteIpPoolApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteIpPoolApiArg = {
    /** The ID for the given IP Pool. */
    poolId: string;
};
export type GetPoolIPsApiResponse = /** status 200 A list of IPs. */ {
    data: Ip[];
};
export type GetPoolIPsApiArg = {
    /** The ID of the IP Pool to fetch the IPs of. */
    poolId: string;
};
export type GetJobsApiResponse = /** status 200 Returns a list of Jobs. */ {
    data: Job[];
    includes?: JobIncludes;
};
export type GetJobsApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** The start date from when to fetch Jobs */
        "range-start"?: DateTime;
        /** The end date from when to fetch Jobs */
        "range-end"?: DateTime;
        /** `filter[search]=value` search jobs for a value associated with a field on the given job(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the job's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type GetJobApiResponse = /** status 200 A Job resources. */ {
    data: Job;
};
export type GetJobApiArg = {
    /** The ID for the given Job. */
    jobId: string;
};
export type GetLatestJobsApiResponse =
    /** status 200 Returns a list of Jobs. */ {
        data: Job[];
    };
export type GetLatestJobsApiArg = void;
export type GetMetricsApiResponse =
    /** status 200 Returns an array of metrics. */ {
        data: Metric[];
    };
export type GetMetricsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
    
    To search via labels, use `label:<label key>` as the filter. For example in URL parameters, `filter[label:test]=abc`.
     */
    filter?: {
        /** Specify the environment associated with the metrics. */
        environment_id?: Id;
        /** Specify the pipeline associated with the metrics. */
        pipeline_id?: Id;
        /** Specify the stack associated with the metrics. */
        stack_id?: Id;
        /** Specify the cluster associated with the metrics. */
        cluster?: string;
        /** Specify the metric to filter by. */
        metric?: string;
        /** Filter by the type of metric. */
        type?:
            | "gauge"
            | "count"
            | "counter"
            | "rate"
            | "histogram"
            | "distribution"
            | "set";
        /** Filter by tags associated with the metrics */
        tags?: string;
        /** The start date from when to pull metrics. Providing 'time' in the post-body criteria field will override this filter. */
        "range-start"?: DateTime;
        /** The end date from when to pull metrics. Providing 'time' in the post-body criteria field will override this filter. */
        "range-end"?: DateTime;
        [key: string]: any;
    };
};
export type GenerateAggregatedMetricsApiResponse =
    /** status 200 Returns an object matching the shape of the output of the aggregated pipeline output. */ {
        data: {
            [key: string]: any;
        }[];
    };
export type GenerateAggregatedMetricsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** The start date from when to pull metrics. Providing 'time' in the post-body criteria field will override this filter. */
        "range-start"?: DateTime;
        /** The end date from when to pull metrics. Providing 'time' in the post-body criteria field will override this filter. */
        "range-end"?: DateTime;
    };
    /** The mongo syntax aggregate pipeline for querying metrics and formulating a report. */
    body: {
        /** The criteria to add to the first match. Cycle will automatically provide the hub ID. */
        criteria?: {
            [key: string]: any;
        };
        /** The aggregation pipeline. Supports Mongo-style syntax and operators. */
        pipeline?: {
            [key: string]: any;
        }[];
    };
};
export type GetEventsApiResponse =
    /** status 200 Returns an array of events. */ {
        data: Event[];
    };
export type GetEventsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
    
    To search via labels, use `label:<label key>` as the filter. For example in URL parameters, `filter[label:test]=abc`.
     */
    filter?: {
        /** Specify the environment associated with the events. */
        environment_id?: Id;
        /** Specify the pipeline associated with the events. */
        pipeline_id?: Id;
        /** Specify the stack associated with the events. */
        stack_id?: Id;
        /** Specify the container associated with the events. */
        container_id?: Id;
        /** Specify the cluster associated with the events. */
        cluster?: string;
        /** Specify the event to filter by. */
        event?: EventType;
        /** Filter by the type of event. */
        type?:
            | "info"
            | "warning"
            | "error"
            | "success"
            | "alert"
            | "notice"
            | "audit";
        /** Filter by tags associated with the events. */
        tags?: string;
        /** The start date from when to pull events. Providing 'time' in the post-body criteria field will override this filter. */
        "range-start"?: DateTime;
        /** The end date from when to pull events. Providing 'time' in the post-body criteria field will override this filter. */
        "range-end"?: DateTime;
        [key: string]: any;
    };
};
export type GenerateAggregatedEventsApiResponse =
    /** status 200 Returns an object matching the shape of the output of the aggregated pipeline output. */ {
        data: {
            [key: string]: any;
        }[];
    };
export type GenerateAggregatedEventsApiArg = {
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** The start date from when to pull events. Providing 'time' in the post-body criteria field will override this filter. */
        "range-start"?: DateTime;
        /** The end date from when to pull events. Providing 'time' in the post-body criteria field will override this filter. */
        "range-end"?: DateTime;
    };
    /** The mongo syntax aggregate pipeline for querying events and formulating a report. */
    body: {
        /** The criteria to add to the first match. Cycle will automatically provide the hub ID. */
        criteria?: {
            [key: string]: any;
        };
        /** The aggregation pipeline. Supports Mongo-style syntax and operators. */
        pipeline?: {
            [key: string]: any;
        }[];
    };
};
export type AggregateLogsApiResponse =
    /** status 200 Returns an array of log lines. */ {
        data: LogLine[];
    };
export type AggregateLogsApiArg = {
    /** Parameters for aggregating logs. */
    body: {
        scope: {
            /** The type of resource used to narrow log aggregation. */
            type: "container" | "instance" | "environment";
            /** The ID or IDs used to narrow log aggregation. */
            ids?: Id[] | null;
            /** The date range used to narrow log aggregation. */
            date_range?: null | {
                /** The start date from when to fetch logs. */
                start?: DateTime;
                /** The end date from when to fetch logs. */
                end?: DateTime;
            };
            /** Context window indicates how many log lines after a match to return in the response.
            This allows greater context around the matched lines.
             */
            context_window?: number | null;
        };
        search?: {
            /** The input type used to query logs. When set to regexp, a regex expression will be used to find a log line with a message matching the expression.  When set to 'raw', the aggregate will look for an exact string match. */
            type: "raw" | "regexp";
            /** The search input is a string input that is used to in the aggregate to narrow the log line results. */
            match: string;
        }[];
        /** Indicates that only locally cached log lines should be queried.  If true, logs in object storage will not be returned. */
        local?: boolean | null;
        /** Describes the maximum number of log lines the query will return. */
        limit?: number | null;
    };
};
export type GetMonitoringMonitorsApiResponse =
    /** status 200 Returns and array of monitor location information. */ {
        data: Monitor[];
    };
export type GetMonitoringMonitorsApiArg = void;
export type GetPipelinesApiResponse =
    /** status 200 Returns a list of Pipelines. */ {
        data: Pipeline[];
        includes?: PipelineIncludes;
    };
export type GetPipelinesApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "name" | "components")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those pipelines matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given pipelines(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Pipeline's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreatePipelineApiResponse = /** status 201 Returns a Pipeline. */ {
    data: Pipeline;
};
export type CreatePipelineApiArg = {
    /** Parameters for creating a new Pipeline. */
    body: {
        /** A name for the Pipeline. */
        name: string;
        identifier?: Identifier;
        /** Setting to true enables variable and other advanced logic support on this Pipeline. This is a one-way toggle. Once set to true, it cannot be set back to false. */
        dynamic?: boolean;
        /** An array of stages. */
        stages?: PipelineStage[];
        acl?: Acl | null;
        /** A boolean where true signifies the Pipeline is disabled. */
        disable?: boolean;
    };
};
export type GetPipelineApiResponse = /** status 200 Returns a Pipeline. */ {
    data: Pipeline;
    includes?: PipelineIncludes;
};
export type GetPipelineApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "name" | "components")[];
};
export type UpdatePipelineApiResponse =
    /** status 200 Returns a Pipeline resource. */ {
        data: Pipeline;
    };
export type UpdatePipelineApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** Parameters for updating a Pipeline. */
    body: {
        /** A name for the Pipeline. */
        name?: string;
        /** Setting to true enables variable and other advanced logic support on this Pipeline. This is a one-way toggle. Once set to true, it cannot be set back to false. */
        dynamic?: boolean;
        /** An array of stages. */
        stages?: PipelineStage[];
        /** A boolean where true signifies the Pipeline is disabled. */
        disable?: boolean;
    };
};
export type DeletePipelineApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeletePipelineApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
};
export type UpdatePipelineAccessApiResponse =
    /** status 200 Returns a pipeline resource. */ {
        data: Pipeline;
    };
export type UpdatePipelineAccessApiArg = {
    /** The ID of the pipeline. */
    pipelineId: string;
    /** Parameters for updating a pipeline. */
    body: {
        acl?: Acl | null;
    };
};
export type GetPipelineRunsApiResponse =
    /** status 200 Returns a list of Pipeline runs. */ {
        data: PipelineRun[];
        includes?: {
            creators?: CreatorInclude;
        };
    };
export type GetPipelineRunsApiArg = {
    /** The ID of the pipeline. */
    pipelineId: string;
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
};
export type GetPipelineRunApiResponse =
    /** status 200 Returns a Pipeline Run. */ {
        data: PipelineRun;
        includes?: {
            creators?: CreatorInclude;
        };
    };
export type GetPipelineRunApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** The ID for the pipeline run. */
    runId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
};
export type CreatePipelineJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreatePipelineJobApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** Parameters for creating a new Pipeline job. */
    body:
        | ({
              action: "trigger";
          } & TriggerPipelineAction)
        | ({
              action: "rerun";
          } & RerunPipelineAction);
};
export type ClonePipelineApiResponse = /** status 201 Returns a Pipeline. */ {
    data: Pipeline;
};
export type ClonePipelineApiArg = {
    /** Parameters for creating a new Pipeline job. */
    body: {
        /** The name of the cloned pipeline. */
        name: string;
        identifier: Identifier;
        pipeline_id: Id;
    };
};
export type TriggerPipelineApiResponse = /** status 201 Returns a Pipeline. */ {
    data: Pipeline;
};
export type TriggerPipelineApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** Parameters for triggering a pipeline. */
    body: {
        /** The trigger key secret. */
        secret: string;
        /** A map of variables to pass into the pipeline when it runs. */
        variables: {
            [key: string]: string;
        };
        advanced?: {
            /** Sub queue allows multiple concurrent pipeline runs. */
            sub_queue?: string | null;
        };
    };
};
export type GetPipelineTriggerKeysApiResponse =
    /** status 200 Returns a list of Trigger Keys. */ {
        data: TriggerKey[];
    };
export type GetPipelineTriggerKeysApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search for a value associated with a field on the given Trigger Key(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Trigger Key's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreatePipelineTriggerKeyApiResponse =
    /** status 201 Returns a Trigger Key. */ {
        data: TriggerKey;
    };
export type CreatePipelineTriggerKeyApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** Parameters for creating a new Pipeline Trigger Key. */
    body: {
        /** A name for the Trigger Key. */
        name?: string;
        /** An array of ips this Trigger Key is usable from. */
        ips?: string[];
    };
};
export type GetPipelineTriggerKeyApiResponse =
    /** status 200 Returns a Trigger Key. */ {
        data?: TriggerKey;
    };
export type GetPipelineTriggerKeyApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** The ID of the Trigger Key. */
    triggerKeyId: string;
};
export type UpdatePipelineTriggerKeyApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type UpdatePipelineTriggerKeyApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** The ID of the Trigger Key. */
    triggerKeyId: string;
    body: {
        /** Set name of Trigger Key */
        name?: string;
        /** List of IP Restrictions */
        ips?: string[];
    };
};
export type DeletePipelineTriggerKeyApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeletePipelineTriggerKeyApiArg = {
    /** The ID of the Pipeline. */
    pipelineId: string;
    /** The ID of the Trigger Key. */
    triggerKeyId: string;
};
export type GetNetworksApiResponse =
    /** status 200 Returns a list of Networks. */ {
        data: Network[];
        includes?: NetworkIncludes;
    };
export type GetNetworksApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "environments")[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search for a value associated with a field on the given Network(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Network's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateNetworkApiResponse = /** status 201 Returns a Network. */ {
    data: Network;
    includes?: NetworkIncludes;
};
export type CreateNetworkApiArg = {
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "environments")[];
    /** Parameters for creating a new Network. */
    body: {
        /** The name of the Network. */
        name: string;
        /** A Network identifier used to construct http calls that specifically use this Network over another. */
        identifier: string;
        acl?: Acl | null;
        /** The infrastructure Cluster the Environments belonging to this Network belong to. */
        cluster: string;
        /** An array of Environment Ids */
        environments: string[];
    };
};
export type GetNetworkApiResponse = /** status 200 Returns a Network. */ {
    data: Network;
    includes?: NetworkIncludes;
};
export type GetNetworkApiArg = {
    /** The ID of the Network. */
    networkId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "environments")[];
};
export type UpdateNetworkApiResponse = /** status 200 Returns a Network. */ {
    data: Network;
    includes?: NetworkIncludes;
};
export type UpdateNetworkApiArg = {
    /** The ID of the Network. */
    networkId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "environments")[];
    /** Parameters for updating a Network. */
    body: {
        /** The name of the Network. */
        name?: string;
    };
};
export type DeleteNetworkApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteNetworkApiArg = {
    /** The ID of the Network. */
    networkId: string;
};
export type UpdateNetworkAccessApiResponse =
    /** status 200 Returns a Network. */ {
        data: Network;
        includes?: NetworkIncludes;
    };
export type UpdateNetworkAccessApiArg = {
    /** The ID of the Network. */
    networkId: string;
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: ("creators" | "environments")[];
    /** Parameters for updating a Network. */
    body: {
        acl?: Acl | null;
    };
};
export type CreateNetworkJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateNetworkJobApiArg = {
    /** The ID of the Network. */
    networkId: string;
    /** Parameters for creating a new Network Job. */
    reconfigureSdnNetwork: {
        /** The Job to do. */
        action: "reconfigure";
        /** Additional information needed for the Job. */
        contents: {
            /** An array of environment identifiers for the Network. */
            environment_ids: string[];
        };
    };
};
export type GetSearchIndexApiResponse =
    /** status 200 Returns an indexed search matrix. */ {
        data: Index;
    };
export type GetSearchIndexApiArg = void;
export type GetStacksApiResponse = /** status 200 Returns a list of Stacks. */ {
    data: Stack[];
    includes?: StackIncludes2;
};
export type GetStacksApiArg = {
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: ("containers_count" | "builds_count")[];
    /** A comma separated list of include values. Included resources will show up under the root document's `include` field, with the key being the id of the included resource. In the case of applying an include to a collection of resources, if two resources share the same include, it will only appear once in the return. */
    include?: "creators"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[identifier]=value` List only those stacks matching this identifier. May return multiple results.
         */
        identifier?: string;
        /** `filter[search]=value` search for a value associated with a field on the given Stack(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Stack's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateStackApiResponse = /** status 201 Returns a Stack. */ {
    data: Stack;
};
export type CreateStackApiArg = {
    /** Parameters for creating a new Stack. */
    body: {
        /** A name for the Stack. */
        name: string;
        identifier?: Identifier;
        acl?: Acl | null;
        /** A map of default variable values used when building this Stack. A variable can be added anywhere in a Stack using the format `{{var}}` where `var` would be a key in this map. */
        variables?: {
            [key: string]: string;
        };
        source: StackSource;
    };
};
export type GetStackApiResponse = /** status 200 Returns a Stack resource. */ {
    data: Stack;
};
export type GetStackApiArg = {
    /** The ID of the Stack */
    stackId: string;
};
export type UpdateStackApiResponse =
    /** status 200 Returns the updated Stack. */ {
        data: Stack;
    };
export type UpdateStackApiArg = {
    /** The ID of the Stack */
    stackId: string;
    /** Parameters for updating a Stack. */
    body: {
        /** A name for the Stack. */
        name?: string;
        /** A map of default variable values used when building this Stack. A variable can be added anywhere in a Stack using the format `{{var}}` where `var` would be a key in this map. */
        variables?: {
            [key: string]: string;
        };
        source?: StackSource;
    };
};
export type DeleteStackApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteStackApiArg = {
    /** The ID of the Stack */
    stackId: string;
};
export type UpdateStackAccessApiResponse =
    /** status 200 Returns the updated Stack. */ {
        data: Stack;
    };
export type UpdateStackAccessApiArg = {
    /** The ID of the Stack */
    stackId: string;
    /** Parameters for updating a Stack. */
    body: {
        acl?: Acl | null;
    };
};
export type CreateStackJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateStackJobApiArg = {
    /** The ID of the Stack */
    stackId: string;
    /** Parameters for creating a new Stack Job. */
    pruneStackBuilds: {
        /** The Job to do. */
        action: "prune";
    };
};
export type LookupStackBuildApiResponse =
    /** status 200 Returns a Stack Build. */ {
        data: StackBuild;
    };
export type LookupStackBuildApiArg = {
    /** The ID of the Build. */
    buildId: string;
};
export type GetStackBuildsApiResponse =
    /** status 200 Returns a list of Stack Builds. */ {
        data: StackBuild[];
    };
export type GetStackBuildsApiArg = {
    /** The ID of the Stack. */
    stackId: string;
    /** A comma separated list of meta values. Meta values will show up under a resource's `meta` field. In the case of applying a meta to a collection of resources, each resource will have it's own relevant meta data. In some rare cases, meta may not apply to individual resources, and may appear in the root document. These will be clearly labeled. */
    meta?: "containers_count"[];
    /** ## Filter Field
    The filter field is a key-value object, where the key is what you would like to filter, and the value is the value you're filtering for.
     */
    filter?: {
        /** `filter[search]=value` search for a value associated with a field on the given Stack Build(s).
         */
        search?: string;
        /** `filter[state]=value1,value2` state filtering will allow you to filter by the Stack Build's current state.
         */
        state?: string;
    };
    /** An array of sort values. To sort descending, put a `-` in front of the value, e.g. `-id`. */
    sort?: string[];
    /** In a list return, the data associated with the page number and size returned. 20 results per page, page 2 would be `page[size]=20&page[number]=2` */
    page?: {
        /** The page to jump to */
        number?: number;
        /** The number of resources returned per page. */
        size?: number;
    };
};
export type CreateStackBuildApiResponse =
    /** status 201 Returns a Stack Build. */ {
        data: StackBuild;
    };
export type CreateStackBuildApiArg = {
    /** The ID of the Stack */
    stackId: string;
    /** Parameters for creating a new Stack Build. */
    body: {
        about?: StackBuildAbout;
        instructions?: StackBuildInstructions;
    };
};
export type GetStackBuildApiResponse =
    /** status 200 Returns a Stack Build. */ {
        data: StackBuild;
    };
export type GetStackBuildApiArg = {
    /** The ID of the Stack. */
    stackId: string;
    /** The ID of the Build. */
    buildId: string;
};
export type DeleteStackBuildApiResponse =
    /** status 200 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type DeleteStackBuildApiArg = {
    /** The ID of the Stack. */
    stackId: string;
    /** The ID of the Build. */
    buildId: string;
};
export type CreateStackBuildJobApiResponse =
    /** status 202 Returns a Job Descriptor. */ {
        data: JobDescriptor;
    };
export type CreateStackBuildJobApiArg = {
    /** The ID of the Stack. */
    stackId: string;
    /** The ID of the Build. */
    buildId: string;
    /** Parameters for creating a new Stack Build Job. */
    body:
        | ({
              action: "generate";
          } & GenerateStackBuild)
        | ({
              action: "deploy";
          } & DeployStackBuild);
};
export type LookupIdentifierApiResponse =
    /** status 200 Returns the ID of the requested resource from the identifier string. */ {
        data: {
            id: string;
        };
    };
export type LookupIdentifierApiArg = {
    /** A base64 encoded resource identifier string. */
    identifier: string;
    /** The type of resource to lookup from the identifier string. */
    "desired-component":
        | "cluster"
        | "environment"
        | "image-source"
        | "stack"
        | "server"
        | "container";
};
export type LookupComponentsApiResponse =
    /** status 200 Returns an array of components requested. */ {
        data: ComponentIncludes;
    };
export type LookupComponentsApiArg = {
    /** ABC */
    body: {
        components: {
            type: ResourceType;
            id: string;
        }[];
    };
};
export type Id = string;
export type DateTime = string;
export type State = {
    changed: DateTime;
    /** An error, if any, that has occurred for this resource. */
    error?: {
        /** Details about the error that has occurred. */
        message?: string;
        /** The timestamp of when the error was encountered. */
        time?: DateTime;
    };
};
export type AccountState = {
    /** The current state of the account. */
    current: "new" | "live" | "suspending" | "purging" | "deleting" | "deleted";
} & State;
export type Account = {
    id: Id;
    /** The first and last name associated with the Account. */
    name: {
        /** The first name of the Account owner. */
        first: string;
        /** The last name of the Account owner. */
        last: string;
    };
    /** Information about the email address associated with the Account. */
    email: {
        /** The email address. */
        address: string;
        /** If true, the email has been verified and the Account is active. */
        verified: boolean;
        /** The date the email was added to the Account */
        added: DateTime;
    };
    /** Two factor auth verification information. */
    two_factor_auth?: {
        /** A boolean representing if the Account has verified with two-factor authentication. */
        verified: boolean;
    };
    /** Indicates whether or not Cycle employees have authorization to log in to this Account in a support capacity. */
    allow_support_login?: boolean;
    state: AccountState;
    /** A collection of timestamps for each event in the Account's lifetime. */
    events: {
        /** The timestamp of when the image was created. */
        created: DateTime;
        /** The timestamp of when the image was updated. */
        updated: DateTime;
        /** The timestamp of when the image was deleted. */
        deleted: DateTime;
        /** The timestamp of when the Account was last accessed. */
        last_login: DateTime;
    };
};
export type Capability =
    | "api-keys-manage"
    | "apionly-jobs-view"
    | "apionly-notifications-listen"
    | "autoscale-groups-manage"
    | "autoscale-groups-view"
    | "billing-credits-view"
    | "billing-invoices-pay"
    | "billing-invoices-view"
    | "billing-methods-manage"
    | "billing-services-manage"
    | "billing-services-view"
    | "containers-backups-manage"
    | "containers-backups-view"
    | "containers-console"
    | "containers-deploy"
    | "containers-instances-migrate"
    | "containers-lock"
    | "containers-ssh"
    | "containers-manage"
    | "containers-view"
    | "containers-functions-trigger"
    | "containers-volumes-manage"
    | "containers-volumes-view"
    | "dns-certs-view"
    | "dns-manage"
    | "dns-view"
    | "environments-deployments-manage"
    | "environments-manage"
    | "environments-scopedvariables-manage"
    | "environments-scopedvariables-view"
    | "environments-services-manage"
    | "environments-view"
    | "environments-vpn"
    | "environments-vpn-manage"
    | "hubs-delete"
    | "hubs-integrations-manage"
    | "hubs-integrations-view"
    | "hubs-invites-manage"
    | "hubs-invites-send"
    | "hubs-members-manage"
    | "hubs-members-view"
    | "hubs-roles-manage"
    | "hubs-roles-view"
    | "hubs-usage-view"
    | "hubs-update"
    | "hubs-auditlog-view"
    | "images-manage"
    | "images-sources-manage"
    | "images-sources-view"
    | "images-view"
    | "ips-manage"
    | "servers-console"
    | "servers-decommission"
    | "servers-login"
    | "clusters-manage"
    | "clusters-view"
    | "servers-provision"
    | "servers-manage"
    | "servers-view"
    | "monitor-manage"
    | "monitor-view"
    | "pipelines-manage"
    | "pipelines-trigger"
    | "pipelines-view"
    | "sdn-networks-manage"
    | "sdn-networks-view"
    | "security-manage"
    | "security-view"
    | "stacks-builds-deploy"
    | "stacks-builds-manage"
    | "stacks-manage"
    | "stacks-view";
export type Error = {
    /** The HTTP response code. */
    status?:
        | 400
        | 401
        | 403
        | 404
        | 409
        | 415
        | 422
        | 500
        | 501
        | 502
        | 503
        | 504;
    /** A Cycle standard error code. */
    code?:
        | "400.invalid_syntax"
        | "401.auth_invalid"
        | "401.auth_expired"
        | "401.no_cookie"
        | "401.unauthorized_application"
        | "403.mismatch"
        | "403.wrong_hub"
        | "403.not_ready"
        | "403.expired"
        | "403.restricted_portal"
        | "403.permissions"
        | "403.wrong_scope"
        | "403.invalid_ip"
        | "403.invalid_state"
        | "403.not_approved"
        | "403.not_allowed"
        | "403.platform_disabled"
        | "403.2fa_required"
        | "403.2fa_failed"
        | "403.new_application_capabilities"
        | "403.tier_restricted"
        | "404.hub"
        | "404.hub.invitation"
        | "404.sdn_network"
        | "404.environment"
        | "404.environment.scoped-variable"
        | "404.hub.api_key"
        | "404.hub.provider"
        | "404.hub.integration"
        | "404.uri"
        | "404.provider"
        | "404.stack"
        | "404.survey"
        | "404.survey_response"
        | "404.notification"
        | "404.stack_build"
        | "404.image"
        | "404.image.source"
        | "404.image.build_log"
        | "404.job"
        | "404.billing.order"
        | "404.billing.service"
        | "404.billing.credit"
        | "404.billing.invoice"
        | "404.billing.tier"
        | "404.billing.support"
        | "404.billing.payment_method"
        | "404.billing.promo_code"
        | "404.node"
        | "404.infrastructure.location"
        | "404.infrastructure.ips.pool"
        | "404.infrastructure.provider"
        | "404.infrastructure.server"
        | "404.infrastructure.model"
        | "404.account"
        | "404.container"
        | "404.container.backup"
        | "404.vpn_account"
        | "404.instance"
        | "404.pipeline"
        | "404.pipeline.run"
        | "404.pipeline.key"
        | "404.dns.zone"
        | "404.dns.record"
        | "404.cluster"
        | "404.platform_build"
        | "404.cycleos_build"
        | "404.email_verification"
        | "404.hub.membership"
        | "404.announcement"
        | "404.ha_service_session"
        | "404.sales_lead"
        | "409.duplicate_found"
        | "415.invalid_content_type"
        | "422.missing_argument"
        | "422.invalid_argument"
        | "422.invalid_input"
        | "422.max_exceeded"
        | "422.not_compatible"
        | "422.already_exists"
        | "429.rate_limiting"
        | "500.database"
        | "500.database_insert"
        | "500.database_update"
        | "500.database_remove"
        | "500.jobd"
        | "500.unknown"
        | "500.dev"
        | "500.email"
        | "500.payment_gateway"
        | "503.not_ready"
        | "503.not_enabled"
        | "503.dependency_not_enabled"
        | "504.not_available";
    /** The main text describing the error. */
    title?: string;
    /** A more detailed description of the error. */
    detail?: string;
    /** A [JSON pointer](https://tools.ietf.org/html/rfc6901/) describing the source of an error. */
    source?: string;
    /** Additional entries on the error object to provide extra details. */
    extra?: {
        /** If the error occured because of a lack of permission (403), this will list the specific capability that the Role/API Key is missing. */
        capability?: Capability;
    };
};
export type ErrorEnvelope = {
    error: Error;
    data: null;
};
export type TaskStep = {
    /** A short description of the step. */
    caption: string;
    /** A more verbose description. */
    description: string;
    /** A timestamp for when the step started. */
    started: DateTime;
    /** A timestamp for when the step completed. */
    completed: DateTime;
};
export type JobDescriptor = {
    /** The action that was taken. */
    action: string;
    /** Contains some basic information about the job associated with this task. */
    job?: {
        /** The ID of the job */
        id: string;
        /** Describes if the job has been accepted */
        accepted: boolean;
        /** Describes the queue this job is a part of. */
        queue: string;
        schedule: DateTime;
        parallel: {
            sub_queue?: string | null;
            tasks: boolean;
        };
        tasks: {
            /** A short description of the task. */
            caption: string;
            /** The API function called. */
            header: string;
            /** An array of job task steps. */
            steps: TaskStep[];
            /** Input information used for the job tasks. */
            input: {
                [key: string]: any;
            };
        }[];
    };
};
export type AccountLoginInfo = {
    id: Id;
    /** The IP of the request used during login. */
    ip: string;
};
export type AccountLogin = {
    id: Id;
    account: AccountLoginInfo;
    /** A timestamp of the time the login occurred. */
    time: DateTime;
    /** The type of login mechanism used. */
    type: "password" | "employee";
    /** A boolean where true reflects that the login was successful. */
    success: boolean;
};
export type AccountEmployeeLogin = AccountLogin & {
    type?: "employee";
    /** If this was an employee login, contains information about who accessed the Account. */
    employee?: {
        id: Id;
        ip: string;
    };
};
export type AccountPasswordLogin = AccountLogin & {
    type?: "password";
};
export type HubId = string;
export type MembershipState = {
    /** The current state of the membership. */
    current: "pending" | "accepted" | "declined" | "revoked" | "deleted";
} & State;
export type HubMembershipInvitation = {
    /** Information about the origin account of the invitation. */
    sender: {
        id: Id;
        /** The account type. */
        type: string;
    };
    /** The email address for the invitations recipient. */
    recipient: string;
    /** A collection of timestamps for each event in the invitation's lifetime. */
    events: {
        /** The timestamp of when the invitation was created. */
        created: DateTime;
        /** The timestamp of when the invitation was updated. */
        updated: DateTime;
        /** The timestamp of when the invitation was deleted. */
        deleted: DateTime;
        /** The timestamp of when the invitation was accepted. */
        accepted: DateTime;
        /** The timestamp of when the invitation was declined. */
        declined: DateTime;
        /** The timestamp of when the invitation was revoked. */
        revoked: DateTime;
    };
};
export type HubMembership = {
    id: Id;
    /** An ID for the account associated with the given membership. */
    account_id?: string;
    hub_id: HubId;
    /** A collection of timestamps for each event in the membership's lifetime. */
    events: {
        /** The timestamp of when the membership was created. */
        created?: DateTime;
        /** The timestamp of when the membership was updated. */
        updated?: DateTime;
        /** The timestamp of when the membership was deleted. */
        deleted?: DateTime;
        /** The timestamp of when the membership was accepted. */
        joined: DateTime;
    };
    role_id: Id;
    /** Preference information set by the user for the membership. */
    preferences?: {
        portal: {
            notifications: {
                jobs: {
                    apikey_alerts: boolean;
                };
            };
        };
        email: {
            notificaitons?: {
                server: {
                    new: boolean | null;
                    offline: boolean | null;
                };
            };
        };
    };
    state: MembershipState;
    invitation: HubMembershipInvitation;
    /** If this member is a Cycle employee, their employee ID will be listed here. Cycle employee accounts do not consume a "membership" for a given hub. */
    cycle?: {
        /** The ID of the employee this membership is associated with. */
        employee_id: string;
    };
};
export type Events = {
    [key: string]: DateTime;
};
export type PublicAccount = {
    /** The first and last name of an account owner */
    name: {
        /** The first name of the account owner */
        first: string;
        /** The last name of the account owner */
        last: string;
    };
    /** Email information for an account */
    email: {
        /** The email address associated with the account. */
        address: string;
    };
    /** Id information for an account */
    id: string;
    events?: Events & {
        last_login?: DateTime;
    };
};
export type Identifier = string;
export type CreatorScope = {
    id: Id;
    type:
        | "account"
        | "environment"
        | "platform"
        | "platform-pipeline"
        | "employee"
        | "api-key"
        | "visitor";
};
export type HubEvents = {
    /** The timestamp of when the hub was created. */
    created: DateTime;
    /** The timestamp of when the hub was updated. */
    updated: DateTime;
    /** The timestamp of when the hub was deleted. */
    deleted: DateTime;
    /** The timestamp of when the hub had the first converted. */
    converted?: DateTime;
    /** The timestamp of when the hub had the first provider added. */
    first_order?: DateTime;
    /** The timestamp of when the hub had the first provider added. */
    first_provider?: DateTime;
    /** The timestamp of when the hub had the first_server deployed. */
    first_server?: DateTime;
    /** The timestamp of when the hub had the first environment deployed. */
    first_environment?: DateTime;
    /** The timestamp of when the hub had the first image deployed. */
    first_image?: DateTime;
    /** The timestamp of when the hub had the first container deployed. */
    first_container?: DateTime;
};
export type HubState = {
    /** The current state of the hub. */
    current:
        | "new"
        | "configuring"
        | "live"
        | "inactive"
        | "deleting"
        | "deleted";
} & State;
export type HubWebhooks = {
    /** A webhook that is called any time a server is deployed to this hub. The payload will be a `Server` object. */
    server_deployed: string | null;
    /** A webhook that is called any time a server in this hub is deleted. The payload will be a `Server` object. */
    server_deleted: string | null;
};
export type BillingTerm = {
    /** A timestamp describing the start of a billing term. */
    start: DateTime;
    /** A timestamp describing the end of a billing term. */
    end: DateTime;
    /** The term renewal period. */
    renew: ("once" | "monthly" | "yearly") | null;
};
export type HubBillingProfile = {
    term: BillingTerm;
    /** A boolean where true represents this account being approved to use a prepaid card for billing transactions. */
    allow_prepaid?: boolean;
    /** A boolean where true represents invoices have been paused on this hub for one reason or another. */
    pause_invoices: boolean;
    /** A boolean where true means this billing is disabled on this hub. */
    disable?: boolean;
    /** Information about the plan associated with this hub. */
    plans: {
        /** An ID referencing the pricing tier applied to this hub. */
        tier_id: string | null;
        /** An ID referencing the support plan applied to this hub. */
        support_id: string | null;
    };
    /** An array of email addresses to whom the billing invoices will be sent to. If left empty, they will be sent to the owner of this hub. */
    emails: string[];
};
export type HubsMeta = {
    membership?: HubMembership;
};
export type Hub = {
    id: Id;
    /** A human readable slugged identifier for this hub. */
    identifier: Identifier;
    /** A name for the hub. */
    name: string;
    creator: CreatorScope;
    events: HubEvents;
    state: HubState;
    webhooks: HubWebhooks;
    billing: HubBillingProfile | null;
    meta?: HubsMeta;
};
export type RoleMeta = {
    counts?: {
        members: number;
        api_keys: number;
    };
};
export type Role = {
    id: string;
    name?: string | null;
    /** The Role marked as root has full moderation control over all Roles. */
    root: boolean;
    /** A reference to the original identifier of the default Role this Role was built from.  A value of null means it is a fully custom Role. */
    default?: Identifier | null;
    /** An integer between 0 and 10 that indicates the Role hierarchy. An account can only edit a Role that is less than their rank. The 'owner' Role is rank 10. */
    rank: number;
    identifier: Identifier;
    creator: CreatorScope;
    /** The list of platform level capabilities assigned to this Role. */
    capabilities: {
        /** If true, the Role has all capabilities. */
        all: boolean;
        specific: Capability[];
    };
    /** Custom user-defined properties for storing extra information on the Role. Not utilized by Cycle. */
    extra?: {
        [key: string]: string;
    };
    hub_id: HubId;
    state: {
        /** The current state of the Role. */
        current: "live";
    } & State;
    /** Timestamps for each event in the Role's lifetime. */
    events: {
        /** The timestamp of when the Role source was created. */
        created: DateTime;
        /** The timestamp of when the Role source was updated. */
        updated: DateTime;
        /** The timestamp of when the Role source was deleted. */
        deleted: DateTime;
    };
    meta?: RoleMeta;
};
export type HubMembershipsIncludes = {
    /** A record with an ID mapped to a public account for the account that sent this memberships initial invite. */
    senders?: {
        /** A record with an ID mapped to a public account. */
        accounts?: {
            [key: string]: PublicAccount;
        };
    };
    /** A record with an ID mapped to a public account. */
    accounts?: {
        [key: string]: PublicAccount;
    };
    /** A record with an ID mapped to a hub resource. */
    hubs?: {
        [key: string]: Hub;
    };
    /** A map of Roles relevant to this Hub Membership, keyed by the Role ID. */
    roles?: {
        [key: string]: Role;
    };
    /** A map of Roles relevant to this Hub Membership, keyed by the Role ID. */
    "roles:identifiers"?: {
        [key: string]: Id;
    };
};
export type TwoFactorAuthSetup = {
    qr: string;
    secret: string;
};
export type TwoFactorAuthRecovery = {
    recovery_codes: string[];
    totp_passcode: string;
};
export type AnnoucementUpdate = {
    id: Id;
    /** The text describing the update to the announcement. */
    message: string;
    creator: CreatorScope;
    /** A timestamp of when the update took place. */
    time: DateTime;
};
export type Announcement = {
    id: Id;
    /** A description of the event being announced. */
    title: string;
    /** A description of the announcement */
    description: string;
    updates: AnnoucementUpdate[];
    affected_integrations?: string[];
    creator: CreatorScope;
    state: {
        /** The current state of the method. */
        current: "live" | "deleting" | "deleted";
    } & State;
    /** The priority or severity of the announcement. */
    priority: "notice" | "low" | "medium" | "high" | "severe";
    /** A collection of timestamps for each event in the announcement's lifetime. */
    events: {
        /** The timestamp of when the announcement was created. */
        created: DateTime;
        /** The timestamp of when the announcement was updated. */
        updated: DateTime;
        /** The timestamp of when the announcement was deleted. */
        deleted: DateTime;
        /** The timestamp of when the announcement was resolved. */
        resolved: DateTime;
    };
};
export type BillingCreditYml = {
    id: Id;
    hub_id: HubId;
    /** Some extra information about the billing credit. */
    description: string;
    /** The account the credit was issued to. */
    account_id: string;
    /** The amount of credit. */
    amount: number;
    /** The amount of the credit that remains after being applied to invoices. */
    amount_remaining: number;
    /** Information on when the billing credit expires. */
    expires: {
        /** A timestamp of when the billing credit expires. */
        date?: DateTime;
    };
    /** A collection of timestamps for each event in the billing order's lifetime. */
    events: {
        /** The timestamp of when the billing order was created. */
        created: DateTime;
        /** The timestamp of when the billing order was updated. */
        updated: DateTime;
        /** The timestamp of when the billing order was deleted. */
        deleted: DateTime;
    };
    state: {
        /** The current state of the credit. */
        current: "new" | "live" | "expired";
    } & State;
};
export type BillingSummary = {
    /** The ID of the billing service, the summary pertains to. */
    service_id: string;
    /** The billing summary title. */
    title: string;
    /** The plan type. */
    type: "tier" | "support";
    term: BillingTerm;
    /** 1/10th of a cent (mills). */
    price: number;
    /** 1/10th of a cent (mills). */
    discount: number;
};
export type PaymentGateway = "stripe";
export type BillingRefund = {
    id: Id;
    /** A timestamp for the payment. */
    time: DateTime;
    /** Some information about the payment refund. */
    description: string;
    /** 1/10th of a cent. */
    amount: number;
    gateway: PaymentGateway;
};
export type PaymentResult = {
    /** The status of a payment. */
    status?: "success" | "processing" | "cancelled" | "error";
    /** A description of the error that took place when processing the payment. */
    error: string;
};
export type BillingPayment = {
    id: Id;
    /** A timestamp for the payment. */
    time: DateTime;
    /** Some information about the payment. */
    description: string;
    /** 1/10th of a cent. */
    amount: number;
    /** 1/10th of a cent. */
    amount_refunded: number;
    refunds: BillingRefund[];
    /** The ID associated with the billing method used. */
    method_id: string;
    result: PaymentResult;
    gateway: PaymentGateway;
};
export type BillingInvoiceCredit = {
    id: Id;
    /** Credit associated with an invoice. */
    associated_credit: {
        id: Id;
        /** The amount of the credit. */
        amount: number;
    };
    /** A timestamp for when the invoice credit was created. */
    time: DateTime;
    /** A description of the invoice credit. */
    description: string;
    /** The amount of credit for the invoice. */
    amount: number;
};
export type LateFee = {
    id: Id;
    /** A timestamp of when the late fee was created. */
    time: DateTime;
    /** A description of the late fee. */
    description: string;
    /** 1/10th of a cent. */
    amount: number;
};
export type InvoiceState = {
    /** The current state of the invoice. */
    current:
        | "new"
        | "billing"
        | "billed"
        | "processing"
        | "partially-paid"
        | "awaiting-funds"
        | "paid"
        | "refunding"
        | "refunded"
        | "crediting"
        | "credited"
        | "voiding"
        | "voided"
        | "uncollectible";
} & State;
export type InvoiceMeta = {
    /** The amount due for a given invoice. */
    due?: number;
};
export type BillingInvoice = {
    id: Id;
    hub_id: HubId;
    /** A boolean where true represents the invoice is approved for collection. */
    approved: boolean;
    services?: BillingSummary[];
    payments?: BillingPayment[];
    credits?: BillingInvoiceCredit[];
    late_fees?: LateFee[];
    /** The amount in charges generated through the licesnse agreement + overages for the billing period covered by the invoice. */
    charges: number;
    /** A timestamp of when the invoice is due to be paid. */
    due?: DateTime;
    /** A timestamp of when the invoice is overdue. */
    overdue?: DateTime;
    /** The number of failed payment attempts for the invoice. */
    failed_payment_attempts: number;
    /** A collection of timestamps for each event in the billing order's lifetime. */
    events: {
        /** The timestamp of when the billing order was created. */
        created: DateTime;
        /** The timestamp of when the billing order was updated. */
        updated: DateTime;
        /** The timestamp of when the billing order was deleted. */
        deleted: DateTime;
        /** The timestamp of when the billing order billed. */
        billed: DateTime;
        /** The timestamp of when the billing order paid. */
        paid: DateTime;
        /** The timestamp of when the billing order payment was attempted. */
        payment_attempt?: DateTime;
        /** The timestamp of when the billing order credited. */
        credited: DateTime;
        /** The timestamp of when the billing order voided. */
        voided: DateTime;
    };
    state: InvoiceState;
    meta?: InvoiceMeta;
};
export type BillingMethodAddress = {
    /** The country this billing method is associated with. */
    country: string;
    /** The zip code this billing method is associated with. */
    zip: string;
};
export type StripeCreditCard = {
    type: "stripe-credit-card";
    details: {
        /** The name on the credit card. */
        name: string;
        /** The brand of card. */
        brand: string;
        expiration: {
            /** The month the billing method expires. */
            month: number;
            /** The year the billing method expires. */
            year: number;
        };
        /** The last 4 digits of the card. */
        last_4: string;
    };
};
export type StripeUsBankAcct = {
    type: "stripe-us-bank-acct";
    details: {
        /** The name of the owner of the bank account. */
        name: string;
        /** The last 4 numbers of the bank account number. */
        last_4: string;
    };
};
export type MethodSource =
    | ({
          type: "stripe-credit-card";
      } & StripeCreditCard)
    | ({
          type: "stripe-us-bank-acct";
      } & StripeUsBankAcct);
export type Mandate = {
    /** The date when the mandate was accepted */
    accepted: DateTime;
    /** The IP address at the time the mandate was accepted. */
    ip: string;
    /** The user agent of the browser when the mandate was accepted. */
    user_agent: string;
    /** The email associated with the account that accepted the mandate */
    email: string;
};
export type BillingMethod = {
    id: Id;
    /** A name for the billing method. */
    name: string;
    /** A boolean where true represents this billing method is set to primary for a given hub. */
    primary: boolean;
    address: BillingMethodAddress;
    creator: CreatorScope;
    source: MethodSource;
    mandate?: Mandate;
    state: {
        /** The current state of the method. */
        current: "live" | "pending-verification" | "deleting" | "deleted";
    } & State;
    /** A collection of timestamps for each event in the credit card's lifetime. */
    events: {
        /** The timestamp of when the credit card was created. */
        created: DateTime;
        /** The timestamp of when the credit card was updated. */
        updated: DateTime;
        /** The timestamp of when the credit card was deleted. */
        deleted: DateTime;
    };
};
export type UserScope = {
    /** The type of user that created the resource. */
    type:
        | "account"
        | "environment"
        | "platform"
        | "platform-pipeline"
        | "employee"
        | "api-key"
        | "visitor";
    id: Id;
};
export type BillingAmount = {
    /** 1/10th of a cent. */
    mills: number;
    /** The length of the term. */
    term: "once" | "monthly" | "yearly";
};
export type ItemPlan = {
    /** Information about the support component of the billing item. */
    support?: {
        id: Id;
        /** The support contract name. */
        name: string;
        /** The price of the support contract (monthly). */
        price: number;
    };
    /** Information about the tier component of the billing item. */
    tier?: {
        id: Id;
        /** The support contract name. */
        name: string;
        price: BillingAmount;
    };
};
export type OrderItem = {
    id: string;
    service: ItemPlan;
    description: string;
    price: BillingAmount;
    discount?: {
        id: string;
        amount: BillingAmount;
        expires: DateTime;
    };
    net_price: number;
};
export type OrderState = {
    /** The current state of the zone. */
    current: "new" | "processed" | "deleting" | "deleted";
} & State;
export type Order = {
    hub_id: string;
    creator: UserScope;
    promo_code_id: string | null;
    term: BillingTerm;
    approved: boolean;
    items: OrderItem[];
    total_price: number;
    /** A collection of timestamps for each event in the orders lifetime. */
    events: {
        /** The timestamp of when the order expires. */
        expires: DateTime;
    };
    state: OrderState;
};
export type PromoCode = {
    /** The promo "code". */
    code: string;
    expires: DateTime;
    /** The amount of credit the promo code offers. */
    credit: {
        amount?: BillingAmount;
        expires?: {
            interval: string;
            number: number;
        };
    };
    state: {
        /** The current state of the promo code. */
        current: "live" | "deleted";
    } & State;
};
export type PromoCodeIncludes = {
    [key: string]: PromoCode;
};
export type BillingOrderIncludes = {
    promo_codes?: PromoCodeIncludes;
};
export type BillingOrder = {
    id: Id;
    hub_id: HubId;
    creator: CreatorScope;
    /** An ID associated with a promo code used with the order. */
    promo_code_id: string | null;
    term: BillingTerm;
    /** A boolean where true represents the order is approved to be paid by the user. */
    approved: boolean;
    items: ItemPlan[];
    /** 1/10th of a cent. */
    total_price: number;
    /** A collection of timestamps for each event in the billing order's lifetime. */
    events: {
        /** The timestamp of when the billing order was created. */
        created: DateTime;
        /** The timestamp of when the billing order was updated. */
        updated: DateTime;
        /** The timestamp of when the billing order was deleted. */
        deleted: DateTime;
        /** The timestamp of when the billing order expires. */
        expires: DateTime;
    };
    state: {
        /** The current state of the order. */
        current: "new" | "processed" | "deleting" | "deleted";
    } & State;
};
export type SupportPlanFeatures = {
    /** A boolean where true indicates the contract includes support for engineering. */
    engineering_support: boolean;
    /** A boolean where true represents the contract has an uptime SLA agreement. */
    uptime_sla: boolean;
    /** The time in which this support contract guarantees response time. */
    guaranteed_response_time: string | null;
};
export type SupportPlan = {
    /** The name of the support plan. */
    name: string;
    price: BillingAmount;
    /** Information about the billing support plan that describes the plan. */
    description: string;
    features: SupportPlanFeatures;
    /** A boolean where true represents the plan is a long term contract, not just month to month. */
    contract: boolean;
    /** This returns true if it shows up. */
    default: boolean;
};
export type BillingServers = {
    /** The number of servers included in the tier price */
    included: number;
    /** A boolean indicating if there is a hard server limit on the tier */
    hard_cap: boolean;
    /** An object describing the additonal cost of servers exceeding the included server count */
    additional: {
        mills: number;
        term: "once" | "monthly" | "yearly";
    };
};
export type BillingMembers = {
    /** The number of members included in the tier price */
    included: number;
    /** A boolean indicating if there is a hard member limit on the tier */
    hard_cap: boolean;
    /** An object describing the additonal cost of members exceeding the included member count */
    additional: {
        mills: number;
        term: "once" | "monthly" | "yearly";
    };
};
export type BillingRam = {
    /** The GB of image storage included in tier */
    included_gb: number;
    /** Additional cost for image storage exceeding included_gb */
    additional_gb: {
        mills: number;
        term: "once" | "monthly" | "yearly";
    };
    /** A boolean indicating if there is a hard limit on the image storage */
    hard_cap: boolean;
};
export type BillingImageStorage = {
    /** The GB of image storage included in tier */
    included_gb: number;
    /** Additional cost for image storage exceeding included_gb */
    additional_gb: {
        mills: number;
        term: "once" | "monthly" | "yearly";
    };
    /** A boolean indicating if there is a hard limit on the image storage */
    hard_cap: boolean;
};
export type BillingBuilds = {
    parallel: number;
    /** The number of CPU Cores availiable for builds */
    cpu_cores: number;
    /** The amount of RAM availiable for builds */
    ram_gb: number;
    /** The maximum number of builds processed per day */
    max_daily_builds: number | null;
};
export type TierPlan = {
    id: string;
    name: string;
    code: string;
    generation: number;
    usable: boolean;
    price: BillingAmount;
    servers: BillingServers;
    members: BillingMembers;
    max_nodes: number | null;
    max_members: number | null;
    advanced_features: {
        gpu: boolean;
        ial: boolean;
        deployments: boolean;
        autoscale: boolean;
        geodns: boolean;
    };
    max_daily_api_requests: number | null;
    ram: BillingRam;
    image_storage: BillingImageStorage;
    builds: BillingBuilds;
    hidden: boolean;
    /** An array of hub IDs with access to the tier. */
    hubs?: Identifier[] | null;
    description: string;
};
export type AssociatedDiscount = {
    id: Id;
    amount: BillingAmount;
    /** A timestamp of when the discount expires. */
    expires: DateTime;
};
export type BillingServiceState = {
    /** The current state of the billing service. */
    current: "active";
} & State;
export type BillingService = {
    id: Id;
    hub_id: HubId;
    creator: CreatorScope;
    /** Billing service title. */
    title: string;
    order: Order;
    item: ItemPlan;
    discount: AssociatedDiscount;
    price: BillingAmount;
    term: BillingTerm;
    /** A collection of timestamps for each event in the billing service's lifetime. */
    events: {
        /** The timestamp of when the billing service was created. */
        created: DateTime;
        /** The timestamp of when the billing service was updated. */
        updated: DateTime;
        /** The timestamp of when the billing service was deleted. */
        deleted: DateTime;
        /** The timestamp of when the billing service was last billed. */
        last_billed: DateTime;
    };
    state: BillingServiceState;
};
export type BillingOverage = {
    term: BillingTerm;
    /** Used for gen 1 pricing. */
    ram: {
        /** The number of hours of overage. */
        gb_hours: number;
        /** The cost of the overage. */
        cost: number;
    };
};
export type IpNet = {
    /** The IP address. */
    ip: string;
    /** The CIDR notation, describing the range of IP addresses. */
    cidr: string;
};
export type LegacyNetwork = {
    /** The IPv4 subnet Id. */
    subnet: number;
    /** IPv4 information available from environments using legacy networking. */
    ipv4?: IpNet | null;
};
export type ContainerEnvironmentSummary = {
    id: Id;
    /** The cluster this environment is associated with. */
    cluster: string;
    /** The private network subnet ID for this container and its instances. */
    container_subnet?: string | null;
    ipv6?: IpNet | null;
    legacy: LegacyNetwork | null;
};
export type ServiceContainerIdentifier =
    | "discovery"
    | "vpn"
    | "loadbalancer"
    | "scheduler";
export type ContainerImageSummary = {
    id: Id | null;
    /** An image that is packaged with Cycle directly, such as the global load balancer. */
    extension: {
        identifier: Identifier;
    };
    /** If a service container, the identifier specifying which service it is. */
    service: ServiceContainerIdentifier | null;
};
export type ContainerStackSummary = {
    id?: Id;
    /** The ID of the stack build this container is associated with. */
    build_id?: string;
    /** The container identifier, usually the key to the container section of a stack file. */
    identifier?: string;
};
export type ContainerNetwork = {
    /** The public network settings for the given container */
    public: "enable" | "egress-only" | "disable";
    /** The hostname for the given container. */
    hostname: string;
    /** An array of port mappings for the container. */
    ports?: string[];
};
export type DeploymentStrategyName =
    | "resource-density"
    | "high-availability"
    | "first-available"
    | "node"
    | "edge"
    | "manual"
    | "function";
export type Duration = string;
export type ContainerDeploy = {
    /** The number of initial desired instances for a given container. */
    instances: number;
    /** The deployment strategy to use when scaling the given container. */
    strategy?: DeploymentStrategyName;
    /** Configuration options for containers using the 'function' deployment strategy. */
    function?: {
        /** The maximum number of instances that Cycle can pre-allocate (includes auto-scaled instances). */
        max_pool_size?: number | null;
        /** For each shard (scheduler), the maximum number of tasks it can run in parallel. */
        max_shard_concurrency?: number | null;
        /** The maximum amount of time a function instance can run before timing out. */
        max_runtime?: Duration | null;
        /** The maximum amount of time Cycle will wait for an instance to be available. */
        max_queue_time?: Duration | null;
    };
    stateful?: {
        options?: {
            use_base_hostname?: boolean | null;
        };
    };
    /** Settings that give more granular control over deployment targets and conditions. */
    constraints?: {
        /** Constraint settings related to which nodes an instance can be deployed to. */
        node?: {
            /** Lists of server tags that formally declair servers that match the criteria for deployment. */
            tags: {
                /** A list of tags where a server matching any tags from the list is qualified as a deployment target. */
                any?: string[];
                /** A list of tags where a server matching all tags from the list is the only scenario where the server is qualified as a deployment target. */
                all?: string[];
            };
        };
        secrets?: string[];
    };
    /** Configuration for what to do during container shutdown. */
    shutdown?: {
        /** How long the platform will wait for a container to stop gracefully. */
        graceful_timeout?: Duration | null;
        /** Process signal sent to the container process. */
        signals?: (
            | "SIGTERM"
            | "SIGINT"
            | "SIGUSR1"
            | "SIGUSR2"
            | "SIGHUP"
            | "SIGQUIT"
        )[];
    };
    /** Configurations for container startup. */
    startup?: {
        /** How long the platform will wait before sending the start signal to the given container. */
        delay?: Duration;
    };
    /** Configurations for container updates. */
    update?: {
        /** When set, Cycle will pick a random time from `0 - this duration`, and stagger the instances so they all start at different times (up to the time specified here). */
        stagger?: Duration;
    };
    /** Configurations for container restart events. */
    restart?: {
        /** Policy for what should happen in the event of a container dying. */
        condition: "always" | "never" | "failure";
        /** How long the platform will wait before trying to restart the container. */
        delay: Duration;
        /** The amount of times the platform will attempt the restart policies. */
        max_attempts: number;
    };
    /** Configuration for determining the health of a container. */
    health_check?: {
        /** The command that will be run to verify the health of the container. */
        command: string;
        /** The number of times the platform will retry the command before marking the container unhealthy. */
        retries: number;
        /** How long to wait before performing an initial health check when the instance starts. The `state.health.healthy` field of the instance will be `null`` until the first check is performed. */
        delay: Duration | null;
        /** How long to wait between restarts. */
        interval: Duration;
        /** How long before a health check attempt times out. */
        timeout: Duration;
        /** A boolean where `true` represents the desire for a container to restart if unhealthy. */
        restart: boolean;
    };
    /** Configuration settings for container telemetery reporting. */
    telemetry?: {
        /** How long telemetry data should be retained. */
        retention: Duration;
        /** The duration between samples. */
        interval: Duration;
        /** A boolean where true disables all telemetry reporting for this container. */
        disable: boolean;
    };
};
export type ScaleThresholdMetricRam = {
    type: "ram";
    details: {
        /** The target average RAM usage of all instances of this container. Going above this threshold will trigger a scaling event. This threshold must be greater than 25MB. */
        usage: string;
    };
};
export type ScaleThresholdMetricCpu = {
    type: "cpu";
    details: {
        utilization: number;
    };
};
export type WebhookUrl = string;
export type ScaleThresholdMetricCustom = {
    type: "custom";
    details: {
        webhook: WebhookUrl;
    };
};
export type ScaleThresholdMetricNetworkConnections = {
    type: "network-connections";
    details: {
        connections_total: number;
    };
};
export type ScaleThresholdMetricNetworkRequests = {
    type: "network-requests";
    details: {
        requests_total: number;
    };
};
export type ScaleThresholdMetricNetworkThroughput = {
    type: "network-throughput";
    details: {
        private: boolean;
        /** The limit (maximum) amount of throughput each instance of the given container can use before triggering a scaling event. */
        bandwidth: string;
    };
};
export type ScaleThresholdMetric =
    | ({
          type: "ram";
      } & ScaleThresholdMetricRam)
    | ({
          type: "cpu";
      } & ScaleThresholdMetricCpu)
    | ({
          type: "custom";
      } & ScaleThresholdMetricCustom)
    | ({
          type: "network-connections";
      } & ScaleThresholdMetricNetworkConnections)
    | ({
          type: "network-requests";
      } & ScaleThresholdMetricNetworkRequests)
    | ({
          type: "network-throughput";
      } & ScaleThresholdMetricNetworkThroughput);
export type ContainerScale = {
    /** The autoscaling group describes which servers should be deployed */
    autoscale_group: string | null;
    /** Describes how many instances should be running */
    instances: {
        /** Maximum additional instances the auto-scaler will run at any time */
        max: number;
        /** Minimum number of instances per server */
        max_server: number;
        /** Minimum amount of time an instance will live */
        min_ttl: Duration;
    };
    /** Duration in which the auto-scaler will watch for changes */
    window: Duration;
    /** An array of rules that dictate when a scaling event will be triggered */
    thresholds: ScaleThresholdMetric[];
};
export type SeccompRule = {
    capabilities?: {
        includes?: string;
        excludes?: string;
    };
    /** LinuxSyscall is used to match a syscall in Seccomp */
    syscall?: {
        names: string[];
        action: string;
        errnoRet?: number;
        args?: {
            index: number;
            value: number;
            valueTwo?: number;
            op: string;
        }[];
    };
};
export type ContainerRuntime = {
    /** A command that will be run in place of the images defined startup command. */
    command?: {
        /** System path for the command. */
        path?: string | null;
        /** Arguments to pass to the command. */
        args?: string | null;
    };
    /** Namespaces the given container will have access to. */
    namespaces?: (
        | "ipc"
        | "pid"
        | "uts"
        | "network"
        | "mount"
        | "user"
        | "cgroup"
    )[];
    /** A record of environment variables for the given container. */
    environment_vars?: {
        [key: string]: string;
    };
    /** Selecting this option will give this container full permissions on the server. This is not recommended and increases the likelihood of your server being compromised. */
    privileged: boolean;
    /** A list of linux kernel capabilites for the given container. */
    capabilities?: (
        | "CAP_CHOWN"
        | "CAP_FSETID"
        | "CAP_DAC_OVERRIDE"
        | "CAP_FOWNER"
        | "CAP_SETFCAP"
        | "CAP_SETGID"
        | "CAP_SETUID"
        | "CAP_KILL"
        | "CAP_MKNOD"
        | "CAP_NET_BIND_SERVICE"
        | "CAP_NET_RAW"
        | "CAP_AUDIT_WRITE"
        | "CAP_SYS_CHROOT"
        | "CAP_SETPCAP"
        | "CAP_DAC_READ_SEARCH"
        | "CAP_NET_ADMIN"
        | "CAP_NET_BROADCAST"
        | "CAP_SYS_ADMIN"
        | "CAP_SYS_MODULE"
        | "CAP_SYS_NICE"
        | "CAP_SYS_PACCT"
        | "CAP_SYS_PTRACE"
        | "CAP_SYS_RAWIO"
        | "CAP_SYS_RESOURCE"
        | "CAP_SYS_BOOT"
        | "CAP_SYS_TIME"
        | "CAP_SYS_TTY_CONFIG"
        | "CAP_SYSLOG"
        | "CAP_AUDIT_CONTROL"
        | "CAP_AUDIT_READ"
        | "CAP_IPC_LOCK"
        | "CAP_IPC_OWNER"
        | "CAP_LINUX_IMMUTABLE"
        | "CAP_MAC_ADMIN"
        | "CAP_MAC_OVERRIDE"
        | "CAP_BLOCK_SUSPEND"
        | "CAP_LEASE"
        | "CAP_WAKE_ALARM"
    )[];
    /** Configure the working directory for the given container. */
    workdir?: string;
    /** A record of sysctl fields and values for a given container. */
    sysctl?: {
        [key: string]: string;
    };
    /** A record of rlimits and their values. */
    rlimits?: {
        [key: string]: {
            /** The hard limit for the rlimit. */
            hard?: number;
            /** The soft limit for the rlimit. */
            soft?: number;
        };
    };
    seccomp?: {
        disable?: boolean;
        rules?: SeccompRule[];
    };
    host?: {
        expose_proc?: boolean | null;
    };
    rootfs?: {
        /** Enabling this option will set the containers filesystem to readonly. Volumes associated with the container will not be affected by this. */
        readonly?: boolean;
    };
    devices?: {
        /** The size of the shared host memory device (/dev/shm). */
        shm_size?: string | null;
    };
};
export type ContainerResources = {
    /** Configurations settings related to CPU usage. */
    cpu: {
        /** A share represents 1/10th of the available compute time on a single thread. */
        shares?: {
            /** The limit (maximum) amount of shares each instance of a container can use. */
            limit: number;
            /** The reserve (allocation) number of shares for each instance of a given container. */
            reserve: number;
        };
        cpus?: string;
    };
    /** Configuration settings for limits and reserves of RAM resources for the given container. */
    ram: {
        /** The limit (maximum) amount of RAM each instance of the given container can use. */
        limit?: string;
        /** The reserve (allocation) of RAM given to each instance of the container. */
        reserve?: string;
    };
};
export type HybridIdentifier = string;
export type ContainerIntegrations = {
    /** Webhooks that can be set for specific event reporting and advanced container configurations. */
    webhooks?: {
        /** Container events that can be configured to report to a webhook. */
        events?: {
            /** A webhook to hit when a container deploy event happens. */
            deploy?: string;
            /** A webhook to hit when a container start event happens. */
            start?: string;
            /** A webhook to hit when a container stop event happens. */
            stop?: string;
        };
        /** An endpoint that the platform will react out to with a get request.  This endpoint serves a partial container config which can modify `runtime`, `resources`, and `integrations` configurations. */
        config?: string;
    };
    /** Inject remotely hosted files into container instances at runtime. */
    files?: {
        /** The http endpoint that is hosting the files to be used. */
        source: string;
        /** The path in the container for these files to be injected. */
        destination: string;
    }[];
    /** Configuration settings to embed a TLS certificate in the container. */
    lets_encrypt?: {
        /** A boolean where true represents the desire for a container to have its corresponding TLS certificate(s) injected into the configured path(s). */
        enable: boolean;
        /** Path where the `certificate.cert` will be saved. */
        certificate_path?: string;
        /** Path where the `certificate.chain` will be saved. */
        chain_path?: string;
        /** Path where the `certificate.key` will be saved. */
        key_path?: string;
        /** Path where the `certificate.bundle` will be saved. */
        bundle_path?: string;
        /** File path where any remaining certificate files will be stored. */
        additional_certs_path?: string;
    };
    /** Automated backups configuration for the given container. */
    backups?: {
        integration_id?: HybridIdentifier;
        /** Configuration settings for each backup. */
        backup: {
            /** The command to run for the backup. */
            command: string;
            /** How long the backup will attempt to run before timing out. */
            timeout?: Duration | null;
            /** A cron string that configures how often the backup will run. */
            cron_string?: string;
        };
        /** Configuration settings for restoring from a backup. */
        restore: {
            /** The command to run for restoring from a backup. */
            command: string;
            /** The time in seconds for the restore to attempt to complete before timing out. */
            timeout?: Duration;
        };
        /** How long the platform will keep backups. Default is 1 year. */
        retention: Duration | null;
    };
    shared_file_systems?: {
        [key: string]: {
            writable: boolean;
            mount_point: string;
        };
    };
    /** When enabled, allows more customization to be applied to logging for the container. */
    logs?: {
        /** A tag used for applying log filters and analytics. */
        groups?: Identifier[];
    };
};
export type ContainerConfig = {
    network: ContainerNetwork;
    deploy: ContainerDeploy;
    scale?: ContainerScale;
    runtime?: ContainerRuntime;
    resources?: ContainerResources;
    integrations?: ContainerIntegrations;
};
export type Version = string;
export type Deployment = {
    /** A version string representing the deployment.
     */
    version: Version;
};
export type ContainerVolume = {
    /** A boolean where true marks the volume as read only. */
    read_only: boolean;
    /** Configuration for settings local to the container filesystem. */
    local?: {
        /** The maximum size this volume can grow to. Container volumes on Cycle are thinly provisioned, meaning this isn't an allocation - the volume will only use the space it needs up to this size. */
        max_size: string;
        /** A boolean where true signifies using the largest drive over 2TB for the target server. */
        storage_pool?: boolean;
    };
    /** The mountpoint path for the container. */
    destination: string;
    /** Configuration settings for remotely accessing the container volume. */
    remote_access?: {
        /** A boolean where true represents this container volume being open to remote access connections over SFTP. */
        enable: boolean;
        ips?: string[];
        /** Call out to a webhook to authenticate usernames/passwords if an organization manages their own accounts */
        webhook?: string;
        /** Password configuration settings for the remote access of the container volume. */
        password: {
            /** The hashing algorithm used to has the password. */
            algorithm: "raw" | "sha512" | "md5";
            /** The raw or hashed password. */
            data: string;
        };
    };
};
export type VolumeSummary = {
    id: Id;
    /** A unique hash for the given volume. */
    hash: string;
    config: ContainerVolume;
};
export type ContainerState = {
    /** The current state of the container. */
    current:
        | "new"
        | "starting"
        | "running"
        | "function"
        | "stopping"
        | "stopped"
        | "deleting"
        | "deleted";
    /** The desired state of the container. */
    desired?:
        | "new"
        | "starting"
        | "running"
        | "function"
        | "stopping"
        | "stopped"
        | "deleting"
        | "deleted";
} & State;
export type ResourceCountSummary = {
    /** A count of this resource, grouped by state. */
    state: {
        [key: string]: number;
    };
    /** The total number of this resource */
    total: number;
    /** The total number of this resource available, less any deleted ones. */
    available: number;
};
export type DnsRecordTypes = {
    /** A DNS A record */
    a?: {
        /** The IPv4 address that the A record should map to. */
        ip: string;
    };
    /** A DNS AAAA record */
    aaaa?: {
        /** The IPv6 address that the AAAA record should map to. */
        ip: string;
    };
    /** A DNS CNAME record */
    cname?: {
        /** The domain string the record resolves to. */
        domain: string;
    };
    /** A DNS NS record */
    ns?: {
        /** The domain of the nameserver for this record. */
        domain: string;
    };
    /** A DNS MX record */
    mx?: {
        /** The priority setting for this mx record. */
        priority: number;
        /** The domain this mx record points to. */
        domain: string;
    };
    /** A DNS TXT record. */
    txt?: {
        /** The value for this TXT record. */
        value: string;
    };
    /** A DNS ALIAS record. */
    alias?: {
        /** The domain string returned from the DNS server when this alias record is requested. */
        domain: string;
    };
    /** A DNS SRV record. */
    srv?: {
        /** Teh weight configured for this record - breaks ties for priority. */
        weight: number;
        /** The priority for the record. */
        priority: number;
        /** The port number for the service. */
        port: number;
        /** The domain for the record. */
        domain: string;
    };
    /** A DNS CAA record. */
    caa?: {
        /** The ASCII string that represents the identifier of the property represented by the record. */
        tag: string;
        /** The value associated with the tag. */
        value: string;
    };
    /** A LINKED record is a record special to Cycle.  It represents a URL that points to a specific container or deployment of a container, however the IP address mapping in handled automatically by the platform. */
    linked?: {
        /** Features associated with this record. */
        features: {
            /** TLS properties of the record. */
            tls: {
                /** A boolean, where true represents this record will be paired with a TLS certificate automatically maintained by the platform. */
                enable: boolean;
            };
            /** Options for the GeoDNS LINKED record feature. */
            geodns: {
                /** If enabled, Cycle will attempt to match inbound requests to the closest load balancer geographically. */
                enable: boolean;
            };
            wildcard?: {
                /** If enabled, subdomains will resolve for wildcard records. If disabled, only the primary domain will resolve. */
                resolve_sub_domains: boolean;
            };
        };
    } & (
        | {
              /** The ID of the container this record is related to. */
              container_id?: string;
          }
        | {
              /** Information about the deployment this record points to. */
              deployment?: {
                  /** The ID of the environment with the deployment tag mapping we want to reference. */
                  environment_id: Id;
                  /** Describes which container and which tagged deployment this record should target. */
                  match: {
                      /** The identifier of the container in the environment this record should point to. */
                      container: Identifier;
                      /** The deployment tag that this record should point to. The tags are set on the root of an environment and map to a deployment version. */
                      tag?: Identifier | null;
                  };
              };
          }
    );
};
export type TlsCertificateSummary = {
    id: Id;
    generated: DateTime;
    /** A value where true represents that the certificate is using a shared wildcard cert. */
    wildcard_child: boolean;
};
export type DnsRecordState = {
    /** The current state of the record. */
    current: "pending" | "live" | "deleting" | "deleted";
} & State;
export type DnsRecord = {
    id: Id;
    hub_id: HubId;
    creator: CreatorScope;
    /** A unique identifier for the zone */
    zone_id: string;
    /** A name used for the record, where `@` signifies the use of the root domain. */
    name: string;
    /** The name of the record and the origin as a domain name. */
    resolved_domain: string;
    type: DnsRecordTypes;
    /** TLS features for the record. */
    features: {
        certificate: TlsCertificateSummary | null;
    };
    state: DnsRecordState;
    events: Events;
};
export type IpState = {
    /** The current state of the IP. */
    current: "assigning" | "assigned" | "releasing" | "available";
} & State;
export type Ip = {
    id: Id;
    hub_id: HubId;
    /** The type of IP protocol this IP is. */
    kind: "ipv4" | "ipv6";
    /** Information about the assignment of this IP. */
    assignment?: {
        container_id: string;
        instance_id: string;
        environment_id: string;
    };
    /** A unique identifier that associates the IP with an IP pool. */
    pool_id: string;
    /** The IP address. */
    address: string;
    /** The IP gateway. */
    gateway: string;
    /** The CIDR for the IP. */
    cidr: string;
    state: IpState;
};
export type ContainersMeta = {
    instances_count?: ResourceCountSummary;
    domains?: {
        /** The fully qualified domain name. */
        fqdn: string;
        record?: DnsRecord;
    }[];
    /** An array of IP resources. */
    ips?: Ip[];
};
export type Container = {
    id: Id;
    /** A human readable slugged identifier for this container. Usually used as a key in a stack file. */
    identifier: Identifier;
    /** A user defined name for the container resource. */
    name: string;
    creator: CreatorScope;
    environment: ContainerEnvironmentSummary;
    hub_id: HubId;
    image: ContainerImageSummary;
    stack?: ContainerStackSummary | null;
    config: ContainerConfig;
    deployment?: Deployment | null;
    /** The number of instances for a given container. */
    instances: number;
    volumes?: VolumeSummary[];
    /** Custom meta data for a given container */
    annotations?: {
        [key: string]: any;
    };
    /** The role of a given container if it has one. */
    role?: "orchestrator" | null;
    /** A boolean where true signifies the container is stateful. */
    stateful: boolean;
    /** A boolean where true signifies the container is marked as deprecated. */
    deprecate: boolean;
    /** When set to true, prevents this container from being deleted. */
    lock: boolean;
    state: ContainerState;
    /** A collection of timestamps for each event in the container's lifetime. */
    events: {
        /** The timestamp of when the container was created. */
        created: DateTime;
        /** The timestamp of when the container was updated. */
        updated: DateTime;
        /** The timestamp of when the container was deleted. */
        deleted: DateTime;
        /** The timestamp of when the container was started. */
        started: DateTime;
    };
    meta?: ContainersMeta;
};
export type EnvironmentAbout = {
    /** A custom description for this environment. */
    description: string;
    /** If true, this environment has been marked as a favorite. */
    favorite: boolean;
};
export type EnvironmentState = {
    /** The current state of the environment. */
    current: "new" | "live" | "cloning" | "deleting" | "deleted";
} & State;
export type EnvironmentFeatures = {
    /** Whether or not legacy networking mode is enabled on this environment. */
    legacy_networking: boolean;
    /** The level of monitoring to enable for this environment. There is a cost associated with higher levels of monitoring. */
    monitoring?: {
        tier: "limited" | "standard" | "premium" | "enterprise";
    };
};
export type HaProxyConfigSet = {
    /** Settings that describe how incoming traffic to the load balancer is handled. */
    frontend: {
        /** The type of traffic expected by the load balancer for this port. Can be either:
         - tcp: Traffic is forwarded without any parsing or additional manipulation.
         - http: Traffic is treated as web traffic. If a LINKED record is configured for a container exposing this port, the domain will be parsed and it will be forwarded to the proper container. This allows multiple services to run on port 80 in the same environment. */
        mode: "tcp" | "http";
        /** The number of simultaneous connections that can be processed at a time. */
        max_connections: number | null;
        /** Various options for handling timeouts when communicating with the client. */
        timeouts: {
            /** The number of seconds the load balancer will wait for a response from a client before disconnecting. */
            client_secs: number | null;
            /** The number of milliseconds the load balancer will wait for a client to send it data when one direction is already closed. This is particularly useful to avoid keeping connections in a waiting state for too long when clients do not disconnect cleanly. */
            client_fin_ms: number | null;
            /** The number of milliseconds the load balancer will wait for a new HTTP request to start coming after a response was set. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20http-request) for more information. (`http` mode only) */
            http_keep_alive_ms: number | null;
            /** The number of milliseconds the load balancer will wait for a complete HTTP request. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20http-request) for more information. (`http` mode only) */
            http_request_ms: number | null;
        };
    };
    /** Settings related to how the load balancer routes connections to container instances. */
    backend: {
        /** How connections are balanced across your container instances. Can be one of the following:
         - `roundrobin`: Each container instance is used in turns.
         - `static-rr`: Each container instance is used in turns, but is faster than Round Robin at the expense of being less dynamic.
         - `leastconn`: Routes traffic to the instance with the least number of active connections.
         - `first`: Routes traffic to the first available instance.
         - `source`: The same client IP always reaches the same container instance as long as no instance goes down or up. */
        balance: "roundrobin" | "static-rr" | "leastconn" | "first" | "source";
        /** Various options for handling timeouts when communicating with a container instance behind the load balancer. */
        timeouts: {
            /** The number of seconds the load balancer will wait for a response from the container instance. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20server) for more information. */
            server_secs: number | null;
            /** The number of milliseconds the load balancer will wait for the server to send data when one direction is already closed. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20server-fin) for more information. */
            server_fin_ms: number | null;
            /** The number of milliseconds the load balancer will wait for a successful connection to a container instance. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20connect) for more information. */
            connect_ms: number | null;
            /** The number of milliseconds the load balancer will hold connections in a queue when the maximum number of connections has been reached. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20queue) for more information. */
            queue_ms: number | null;
            /** The number of milliseconds the load balancer will allow for inactivity on a bidirectional tunnel. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20tunnel) for more information. */
            tunnel_secs: number | null;
        };
    };
};
export type HaProxyConfig = {
    /** Settings that are applied to any port that is not overridden in the following ports section. */
    default: HaProxyConfigSet;
    /** An object that defines how HAProxy will act on a specific port. The key is a custom port, and the value is the same settings object found under `default` above. */
    ports: {
        [key: string]: HaProxyConfigSet;
    };
};
export type HaProxyLbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean;
    type: "haproxy";
    details: HaProxyConfig | null;
    /** Binds the load balancer to the host server IP address.
    
    **Pros**: This allows for significantly lower cost (utilizing fewer IPv4 addresses), and enables building out a true edge network with lower latency.
    **Cons**: Only 1 environment is allowed on the host. This is because the load balancer is the only ingress point for an environment, and if it is sharing
    the same IP as the host, that host can only operate under that environment.
     */
    bind_host?: boolean | null;
};
export type WafConfig = {
    rules: {
        /** Some information about the rule. */
        description: string;
        /** A boolean that indicates if the rule should be active or skipped. */
        skip: boolean;
        /** A string that describes if the role should allow or deny traffic based on the conditions. */
        type: "allow" | "deny";
        /** A string that describes if both attributes need to be true (match all) or if only one of the conditions needs to be true (match any). */
        match: "any" | "all";
        /** An array of the specific conditions for the rule. */
        conditions: {
            /** A string that describes the match type for the condition. */
            type:
                | "ip-match"
                | "geo-match"
                | "url-match"
                | "method-match"
                | "header-match";
            /** A string that indicates the range of values relative to the value property. */
            operator: "==" | "!=" | ">" | "<" | ">=" | "<=";
            /** The value corresponding to the condition type. */
            value: string;
        }[];
    }[];
};
export type TcpTransportConfig = {
    type: "tcp";
    details: {
        connections: {
            [key: string]: any;
        };
        /** Configuration options for how telemetry is handled on the load balancer. */
        telemetry: {
            [key: string]: any;
        };
    };
};
export type HttpTransportConfig = {
    type: "http";
    details: {
        /** Defines extra configuration options connections to the load balancer */
        connections: {
            /** Maximum number of simultaneous connections (via http/2) per connection. */
            max_idle_conns_per_connection: number | null;
        };
        /** Configuration options for how telemetry is handled. */
        telemetry: {
            /** Determines how many URLs the load balancer will track at one time. Defaults to 150. */
            max_trackable_urls?: number | null;
            /** Whether or not to track invalid requests. An invalid request is a request that came in that no router existed for. Usually this means bot requests. Defaults to false. */
            track_invalid_requests?: boolean | null;
            /** An array of paths to exclude from tracking. */
            ignore_paths?: string[];
        };
    };
};
export type UdpTransportConfig = {
    type: "udp";
    details: {
        /** Configuration options for how telemetry is handled on the load balancer. */
        telemetry: {
            [key: string]: any;
        };
    };
};
export type TcpRouterConfig = {
    type: "tcp";
    details: {
        [key: string]: any;
    };
};
export type UdpRouterConfig = {
    type: "udp";
    details: {
        [key: string]: any;
    };
};
export type HttpRouterConfig = {
    type: "http";
    details: {
        /** Defines a built-in redirect for HTTP mode routers */
        redirect?: {
            /** If enabled and a sibling controller exists for port 443, requests will be auto redirected to it. Essentially sets up automatic TLS redirection for this router. */
            auto_https_redirect: boolean;
            /** If true, any request comes in with "www" prefix will be permanently redirected to the same path without www. */
            remove_www: boolean;
            /** The port to redirect traffic to. */
            port?: number | null;
            /** The scheme to redirect to. (i.e. `https`) */
            scheme?: string | null;
            /** A specific URL to redirect to. */
            url?: string | null;
        };
        forward?: {
            scheme?: string | null;
            /** Allows the load balancer to modify content before it reaches the user. */
            content_mod?: {
                /** An array that describes a list of replacement match/value pairs. */
                replace?: {
                    /** String that will be replaced. */
                    match: string;
                    /** Replacement value. */
                    value: string;
                }[];
            };
            /** The URL to forward the request to.
            
            Given a path match of `^/example/(.*)$`, a route such as `/example/wow.jpg` would be forwarded as /wow.jpg.
             */
            url?: string;
        };
        proxy?: {
            /** The domain that this router will proxy. */
            domain?: string | null;
            /** The full URL that this router will proxy. */
            url?: string | null;
            /** Allows the load balancer to modify content before it reaches the user. */
            content_mod?: {
                /** An array that describes a list of replacement match/value pairs. */
                replace?: {
                    /** String that will be replaced. */
                    match: string;
                    /** Replacement value. */
                    value: string;
                }[];
            };
        };
        caching?: {
            files?: {
                /** Regex string that describes the files to cache. */
                match: string;
                /** Time string that describes the time to live. */
                ttl: Duration;
            }[];
        };
    };
};
export type V1LbRouterConfig = {
    /** The ruleset for this router to be selected. If both `domains`` and `internal_port` are null, then this match acts as a wildcard and will match all. */
    match: {
        /** The specific domains to match against. */
        domains: string[] | null;
        /** The specific ports to match against. */
        internal_ports: number[] | null;
        path?: string | null;
        /** Match traffic destined (or not destined) for a particular container. */
        containers?: {
            /** Match any traffic that would be routed to one of these containers. */
            include?: HybridIdentifier[] | null;
            /** Match any traffic that would NOT be routed to one of these containers. */
            exclude?: HybridIdentifier[] | null;
        };
    };
    /** How to route the traffic to the destination.
    `random`: Pick a valid destination at random.
    `round-robin`: Send each request to the 'next' destination on the list, restarting from the beginning when the last destination is used.
     */
    mode: "random" | "round-robin";
    config: {
        /** If a request comes in from the same origin, ensure it hits the same destination. */
        sticky_sessions: boolean;
        /** If a destination is unavailable, retry up to [x] times, instead of immediately failing with a 503/504 error. */
        destination_retries: number;
        destination_prioritization?: ("latency" | "random") | null;
        /** TLS termination configuration. If null, the platform will use the default configuration. Port 443 by default has TLS termination enabled. */
        tls?: {
            /** [Advanced] Change the domain the controller listens on.
             */
            server_name?: string | null;
            /** If enabled, accept TLS traffic with an invalid certificate. This is usually done for development/testing, and is not recommended for production use. */
            allow_insecure?: boolean | null;
            /** A PEM encoded string of certificates. */
            client_cert_auth?: string | null;
            /** Defines how to validate the connecting TLS certificate.
            `none`: Do not require a TLS certificate to be sent
            `request`: Asks the client to send a TLS certificate, but does not require nor validate it.
            `require`: Requires a certificate be sent for the request to be valid, but does not validate the certificate.
            `require-verify`: Requires both that the client send a certificate, and that the certificate is valid. This is required when using https.
             */
            client_auth?:
                | ("none" | "request" | "require" | "require-verify")
                | null;
        };
        /** Defines how the length of various sorts of timeouts when communicating with the destination. */
        timeouts: {
            /** The duration the load balancer will wait before timing out while attempting to connect to the destination. */
            destination_connection: Duration;
        };
        extension?:
            | null
            | (
                  | ({
                        type: "tcp";
                    } & TcpRouterConfig)
                  | ({
                        type: "udp";
                    } & UdpRouterConfig)
                  | ({
                        type: "http";
                    } & HttpRouterConfig)
              );
    };
};
export type V1LbController = {
    /** A human-readable identifier for this controller. It will default to the port, i.e. `port-443`, but can be renamed to anything, such as the service this controller represents. */
    identifier: string;
    /** The port inbound trafic is accepted on. */
    port: number;
    waf?: WafConfig | null;
    /** Defines how traffic comes in to the load balancer, and how the load balancer handles it. */
    transport?: {
        /** When true, this controller is disabled and will not be used. */
        disable: boolean;
        /** The kind of traffic (http/tcp/udp) that will be sent to the load balancer. */
        mode: "tcp" | "udp" | "http";
        /** Defines how the transport for this controller operates. */
        config: {
            /** Enable/disable performance mode. If enabled, some telemetry will be disabled to dedicate full processing to handling requests.
            You will not see per-request breakdowns or URL logging if performance mode is enabled.
             */
            performance: boolean;
            /** Defines how traffic gets into the load balancer. */
            ingress: {
                tls?: {
                    /** Enables or disables TLS. */
                    enable: boolean;
                };
            };
            /** Defines settings for various types of timeouts. */
            timeouts: {
                /** The total amount of time a connection can be idle before being killed. */
                idle: Duration;
            };
            /** Verbosity describes the level of logging detail for the controller */
            verbosity: "low" | "normal" | "high" | "debug";
            /** Extended configurations for the specified transport mode (http/tcp) */
            extension?:
                | ({
                      type: "tcp";
                  } & TcpTransportConfig)
                | ({
                      type: "http";
                  } & HttpTransportConfig)
                | ({
                      type: "udp";
                  } & UdpTransportConfig);
        };
        /** Defines where traffic is sent. Many can be defined per controller. */
        routers: V1LbRouterConfig[];
    };
};
export type V1LbConfig = {
    /** A configuration for a specific port. */
    controllers: V1LbController[];
    controller_template?: V1LbController | null;
    waf?: WafConfig | null;
};
export type V1LbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean;
    type: "v1";
    details: V1LbConfig;
    /** Binds the load balancer to the host server IP address.
    
    **Pros**: This allows for significantly lower cost (utilizing fewer IPv4 addresses), and enables building out a true edge network with lower latency.
    **Cons**: Only 1 environment is allowed on the host. This is because the load balancer is the only ingress point for an environment, and if it is sharing
    the same IP as the host, that host can only operate under that environment.
     */
    bind_host?: boolean | null;
};
export type DefaultLbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean;
    type: "default";
    details: HaProxyConfig | V1LbConfig | null;
};
export type LoadBalancerConfig =
    | ({
          type: "haproxy";
      } & HaProxyLbType)
    | ({
          type: "v1";
      } & V1LbType)
    | ({
          type: "default";
      } & DefaultLbType);
export type LoadBalancerEnvironmentService = {
    /** Whether or not the loadbalancer service is enabled. */
    enable: boolean;
    /** The ID of the loadbalancer service container */
    container_id: string | null;
    /** A boolean representing if this service container is set to high availability mode or not. */
    high_availability: boolean;
    /** A boolean representing if this service container is set to autoupdate or not */
    auto_update?: boolean;
    config: LoadBalancerConfig | null;
};
export type DiscoveryConfig = {
    empty_set_delay?: Duration | null;
    /** A mapping of hostnames to IP addresses for custom internal resolutions. Acts as a custom /etc/resolv.conf file that works environment wide. */
    hosts?: {
        [key: string]: {
            /** The IPv4 address the discovery server should return to any container instance requesting this hostname. */
            ipv4?: string[];
            /** The IPv6 address the discovery server should return to any container instance requesting this hostname. */
            ipv6?: string[];
        };
    };
    /** A list of custom DNS resolver strings.  Can specifify domains or ips. */
    custom_resolvers?: string[];
};
export type DiscoveryEnvironmentService = {
    /** Whether or not the discovery service is enabled. */
    enable: boolean;
    /** The ID of the discovery service container */
    container_id: string;
    /** A boolean representing if this service container is set to high availability mode or not. */
    high_availability: boolean;
    /** A boolean where `true` represents the desire to automatically update the environment discovery service. */
    auto_update?: boolean;
    /** The config object for the discovery service. */
    config: DiscoveryConfig | null;
};
export type VpnEnvironmentService = {
    /** Whether or not the VPN service is enabled. */
    enable: boolean;
    /** The ID of the VPN service container */
    container_id: string;
    /** A boolean representing if this service container is set to autoupdate or not */
    auto_update?: boolean;
    /** The config object for the VPN service. */
    config: {
        /** If true, routes all traffic through the VPN, even non-Cycle traffic. */
        allow_internet: boolean;
        /** Auth configuration for the VPN. */
        auth: {
            /** A webhook endpoint to hit. Will be passed the login credentials provided to the user, and should return a 200 status if the login is permitted. */
            webhook: string | null;
            /** If true, allows any Cycle account with access to the environment to log in to the VPN using their Cycle email and password. */
            cycle_accounts: boolean;
            /** If true, allows the custom VPN accounts to log in to the VPN. */
            vpn_accounts?: boolean;
        };
    };
};
export type SchedulerAccessKey = {
    /** The name given to this access key */
    name: string;
    /** The access key secret. This should be submitted with requests to a publicly accessible scheduler service. */
    secret: string;
    /** Whitelisted IPs that are allowed to make requests to the scheduler service. */
    ips?: string[];
};
export type SchedulerConfig = {
    /** If true, this scheduler will be accessible over the public internet. It will enable a LINKED record to be pointed to the scheduler container, and inbound requests to trigger function containers.
     */
    public: boolean;
    access_keys?: SchedulerAccessKey[];
};
export type SchedulerEnvironmentService = {
    /** Whether or not the scheduler service is enabled. */
    enable: boolean;
    /** The ID of the scheduler service container. */
    container_id: string | null;
    /** A boolean representing if the scheduler service container is set to high availability. mode or not. As of Jan 2024, this will always be false because HA is not availiable for the scheduler service. */
    high_availability?: boolean;
    /** A boolean where `true` represents the desire to automatically update the environment scheduler service. */
    auto_update?: boolean;
    config?: SchedulerConfig | null;
};
export type EnvironmentServices = {
    loadbalancer: LoadBalancerEnvironmentService | null;
    discovery?: DiscoveryEnvironmentService | null;
    vpn?: VpnEnvironmentService | null;
    scheduler?: SchedulerEnvironmentService | null;
};
export type Acl = {
    roles?: {
        [key: string]: {
            /** Can the user see / use this resource? */
            view: boolean;
            /** Can the user edit the state / name / identifier of this resource? */
            modify: boolean;
            /** Can the user delete / update ACLs for this resource? */
            manage: boolean;
        };
    };
};
export type PrivateNetwork = {
    /** The vxlan tag added to each packet to help identify the network. */
    vxlan_tag: number;
    /** The subnet ID. */
    subnet: string;
    ipv6: any & IpNet;
    legacy: LegacyNetwork | null;
};
export type EnvironmentDeploymentTags = {
    [key: string]: Version;
};
export type ContainerImageSummary2 = {
    id: Id;
    service: ("discovery" | "loadbalancer" | "vpn") | null;
};
export type EnvironmentMeta = {
    containers_count?: ResourceCountSummary;
    instances_count?: ResourceCountSummary;
    containers?: {
        id: Id;
        /** A user defined name for the container resource. */
        name: string;
        state: ContainerState;
        image: ContainerImageSummary2;
        environment: ContainerEnvironmentSummary;
    }[];
};
export type Environment = {
    id: Id;
    /** A human readable slugged identifier for this environment. */
    identifier: Identifier;
    /** A user defined name for the environment resource. */
    name: string;
    /** The cluster this environment is associated with. */
    cluster: string;
    about: EnvironmentAbout;
    creator: CreatorScope;
    hub_id: HubId;
    state: EnvironmentState;
    /** A collection of timestamps for each event in the environment's lifetime. */
    events: {
        /** The timestamp of when the environment was created. */
        created: DateTime;
        /** The timestamp of when the environment was updated. */
        updated: DateTime;
        /** The timestamp of when the environment was deleted. */
        deleted: DateTime;
    };
    features: EnvironmentFeatures;
    services: EnvironmentServices;
    acl?: Acl | null;
    private_network?: PrivateNetwork | null;
    /** A map of custom tags to deployment versions.
     */
    deployments?: {
        tags: EnvironmentDeploymentTags;
    };
    meta?: EnvironmentMeta;
};
export type Permissions = {
    /** A boolean, where true represents this API key is authorized to make requests that involve all of a hubs environments */
    all_environments: boolean;
    /** An environment ID and a boolean representing management configuration for an API key */
    environments: {
        id: Id;
        /** A boolean, where true represents the API keys ability to make changes to the environment components */
        manage: boolean;
    }[];
};
export type ApiKeyCreator = {
    id: Id;
    /** The name of the API key */
    name: string;
    hub_id: Id;
    /** The API key secret */
    secret?: string;
    permissions: Permissions;
    /** The API key capabilities list */
    capabilities: {
        all: boolean;
        specific: string[];
    };
    ips: string[];
    state: {
        current: "live" | "deleting" | "deleted";
    } & State;
    events: Events;
};
export type CreatorInclude = {
    /** Included creators that are public accounts, keyed by ID. */
    accounts?: {
        [key: string]: PublicAccount;
    };
    /** Included creators that are employees of Cycle, keyed by ID. */
    employees?: {
        [key: string]: PublicAccount;
    };
    /** Included creators that are not Cycle accounts, keyed by ID. */
    visitors?: {
        [key: string]: PublicAccount;
    };
    /** Included creators that are Cycle environments (usually automatically created resources), keyed by ID. */
    environments?: {
        [key: string]: Environment;
    };
    /** Included creators that are Cycle API Keys, keyed by ID. */
    api_keys?: {
        [key: string]: ApiKeyCreator;
    };
};
export type ExistingSource = {
    /** The ID of the image source this image should be built from. */
    source_id?: Id;
};
export type DockerHubOrigin = {
    type: "docker-hub";
    details: {
        existing?: ExistingSource | null;
        /** The DockerHub target string. ex - `mysql:5.7` */
        target: string;
        /** For authentication, a username. */
        username?: string;
        /** For authentication, a token. */
        token?: string;
    };
};
export type HttpSourceCredentials = {
    type: "http";
    /** Authentication credentails for the Dockerfile image source type when authenticating over HTTP. */
    credentials: {
        /** For authentication, the username. */
        username: string;
        /** For authentication, the password. */
        password: string;
    };
};
export type SshSourceCredentials = {
    type: "ssh";
    /** Authentication credentials for the Dockerfile image source type when authenticating with SSH. */
    credentials: {
        /** The username for the repo service, that is used to authenticate an ssh key. */
        username: string;
        /** The passphrase used for the key. */
        passphrase: string;
        /** A pem encoded private key. */
        private_key: string;
    };
};
export type RepoSourceType = {
    /** The URL of the repository. */
    url: string;
    /** An optional branch arguement.  Default value is `master`. */
    branch?: string;
    auth?:
        | null
        | (
              | ({
                    type: "http";
                } & HttpSourceCredentials)
              | ({
                    type: "ssh";
                } & SshSourceCredentials)
          );
    /** Repository reference information. */
    ref?: {
        /** The type of reference being used. */
        type: string;
        /** The value for the given reference type. */
        value: string;
    };
};
export type DockerfileCredentails = {
    /** The url the resource is located at. */
    url?: string;
    /** A username for authentication. */
    username?: string;
    /** A token for authentication. */
    token?: string;
}[];
export type DockerFileOrigin = {
    type: "docker-file";
    details: {
        existing?: ExistingSource | null;
        repo?: RepoSourceType | null;
        /** An endpoint that serves the tar file. */
        targz_url?: string | null;
        /** The path to the directory to use as the context when building the image. */
        context_dir?: string | null;
        /** The path to the Dockerfile to be used for buiding the image. */
        build_file?: string | null;
        credentials?: DockerfileCredentails | null;
    };
};
export type DockerRegistryOrigin = {
    type: "docker-registry";
    details: {
        existing?: ExistingSource;
        /** The image name on the registry. */
        target: string;
        /** The url of the remote registry. */
        url: string;
        /** For authentication, a username. */
        username?: string;
        /** For authentication, a token. */
        token?: string;
        /** For authentication, a password. */
        password?: string;
    };
};
export type RegistryAuthUser = {
    type: "user";
    details: {
        username?: string;
        token?: string;
    };
};
export type RegistryAuthProvider = {
    type: "provider";
    details: {
        flavor: "ecr";
        credentials: {
            region?: string;
            namespace?: string;
            api_key?: string;
            secret?: string;
            subscription_id?: string;
            client_id?: string;
            /** A base64'd string of additional configuration options. */
            config?: string;
        };
    };
};
export type RegistryAuthWebhook = {
    type: "webhook";
    details: {
        url: string;
    };
};
export type RegistryAuth =
    | ({
          type: "user";
      } & RegistryAuthUser)
    | ({
          type: "provider";
      } & RegistryAuthProvider)
    | ({
          type: "webhook";
      } & RegistryAuthWebhook);
export type OciRegistryOrigin = {
    type: "oci-registry";
    details: {
        existing?: ExistingSource;
        /** The image name on the registry. */
        target: string;
        /** The url of the remote registry. */
        url: string;
        auth?: RegistryAuth | null;
    };
};
export type CycleUploadOrigin = {
    type?: "cycle-upload";
    details?: {
        /** The date-time at which the authorization token for uploading this image expires. */
        expires: DateTime;
        /** The token that is required by the factory to accept an upload for this image. */
        token: string;
    };
};
export type CycleSourceOrigin = {
    type: "cycle-source";
    details: {
        /** The ID referencing the image source where this image originated. */
        source_id: Id;
    };
};
export type NoneOrigin = {
    type: "none";
};
export type ImageOrigin =
    | ({
          type: "docker-hub";
      } & DockerHubOrigin)
    | ({
          type: "docker-file";
      } & DockerFileOrigin)
    | ({
          type: "docker-registry";
      } & DockerRegistryOrigin)
    | ({
          type: "oci-registry";
      } & OciRegistryOrigin)
    | ({
          type: "cycle-upload";
      } & CycleUploadOrigin)
    | ({
          type: "cycle-source";
      } & CycleSourceOrigin)
    | ({
          type: "none";
      } & NoneOrigin);
export type StackImageSource = {
    type?: "stack-build";
    details?: {
        id: string;
        stack_id: string;
        containers: Identifier[];
        origin: ImageOrigin;
    };
    override?: {
        target?: string;
        targz_url?: string;
    };
};
export type DirectImageSource = {
    type?: "direct";
    details?: {
        id: string;
        origin: ImageOrigin;
    };
    override?: {
        target?: string;
        targz_url?: string;
    };
};
export type BucketImageSource = {
    type?: "bucket";
    details?: {
        id: string;
        origin: ImageOrigin;
    };
    override?: {
        target?: string;
        targz_url?: string;
    };
};
export type ImageState = {
    /** The current state of the image. */
    current:
        | "new"
        | "uploading"
        | "downloading"
        | "building"
        | "verifying"
        | "saving"
        | "live"
        | "deleting"
        | "deleted";
} & State;
export type ImageMeta = {
    containers_count?: ResourceCountSummary;
};
export type Image = {
    id: Id;
    hub_id: HubId;
    /** A user defined name for the image. */
    name: string;
    /** The image size in bytes. */
    size: number;
    /** An object that holds information about the image. */
    about?: {
        /** A description of the image. */
        description: string | null;
    };
    /** Describes where the image is hosted. */
    backend: {
        /** The provider where this image is hosted. */
        provider: string;
        /** The size of the image in bytes. */
        size: number;
        /** A file name for the image, used by the platform. */
        file_name: string;
        /** A file id for the image, used by the platform. */
        file_id: string;
    };
    /** Any restrictions or requirements needed to run this image as a container. */
    requires: {
        nvidia_gpu?: boolean;
    };
    /** Any additional build details for this image */
    build?: {
        /** Arguments to pass to the builder during a build of this image. */
        args?: {
            [key: string]: string;
        };
    };
    /** Configuration options regarding the builder used to create/import this Image. */
    builder?: {
        /** The ID of the Integration used to do the build. */
        integration_id: Id;
    };
    /** Configuration settings for the image. */
    config: {
        /** The linux user this image runs its processes as. */
        user: string;
        /** The port settings for the image. */
        ports: {
            /** Host Port */
            host: number;
            container: number;
            type: string;
        }[];
        /** Image defined environment variables for the image. */
        env: {
            [key: string]: string;
        };
        /** Image labels. */
        labels: {
            [key: string]: string;
        };
        /** The CMD array used to start the container. */
        command: string[];
        /** An entrypoint command. */
        entrypoint: string[];
        /** Volumes information for the given image. */
        volumes: {
            /** The path where the volume is mounted. */
            path?: string;
            /** Permission settings for the volume. */
            mode?: "ro" | "rw";
        }[];
        /** The working directory for the image. */
        workdir: string;
        /** A set command to be run if a signal is called. */
        signal_stop: string;
    };
    source?:
        | ({
              type: "stack-build";
          } & StackImageSource)
        | ({
              type: "direct";
          } & DirectImageSource)
        | ({
              type: "bucket";
          } & BucketImageSource);
    creator?: CreatorScope;
    /** Information about the Factory service that built/imported the Image into Cycle. */
    factory?: {
        /** The node holding the factory service that was responsible for building the image. */
        node_id: string;
        /** A date timestamp for when the node cached the image. */
        cached: DateTime;
        /** A date timestamp for when the node acknowledged the image import job. */
        acknowledged: DateTime;
    };
    state: ImageState;
    /** A collection of timestamps for each event in the image's lifetime. */
    events: {
        /** The timestamp of when the image was created. */
        created: DateTime;
        /** The timestamp of when the image was updated. */
        updated: DateTime;
        /** The timestamp of when the image was deleted. */
        deleted: DateTime;
    };
    meta?: ImageMeta;
};
export type ImageIncludes = {
    [key: string]: Image;
};
export type StackVariable = string;
export type StackSpecScopedVariableUrlSource = {
    /** The type of scoped variable. */
    type: "url";
    details:
        | {
              /** The URL to call to fetch the value. */
              url: string | StackVariable;
              /** Additional headers that can be attached to the URL request. Useful for adding meta-data to third-party services. */
              headers:
                  | {
                        [key: string]: any;
                    }
                  | StackVariable;
              /** A URL that can be provided to authenticate with a third party secret service. Cycle will make a request to this URL before fetching the secret URL, and use the response as the value of an Authorization header when requesting the secret. */
              auth_token_url: (string | null) | StackVariable;
          }
        | StackVariable;
};
export type StackSpecScopedVariableRawSource = {
    /** The type of scoped variable. */
    type: "raw";
    details:
        | {
              /** The value of the variable. */
              value: string | StackVariable;
              /** A boolean where true represents the text the user is entering will be multi line. */
              blob: boolean | StackVariable;
              secret?:
                  | {
                        /** A string describing the IV Hex associated with the encryption of the variable. */
                        iv?: string | StackVariable;
                        /** A user specified hint that will suggest what the encryption key might be */
                        hint?: string | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecScopedVariable = {
    identifier: Identifier | StackVariable;
    scope:
        | {
              /** Describes the containers that have access to this scoped variable. */
              containers:
                  | {
                        /** If true, all containers in the environment will have access to this variable. */
                        global: boolean | StackVariable;
                        /** A list of container IDs that are granted access to this variable. */
                        ids?: string[] | StackVariable;
                        /** A list of container identifiers that are granted access to this variable. */
                        identifiers?: Identifier[] | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
    access:
        | {
              /** Grants access to this variable from within a container as an environment variable. */
              env_variable?:
                  | {
                        /** The environment variable inside the container that stores the value of the variable. */
                        key: string | StackVariable;
                    }
                  | StackVariable
                  | null;
              /** Grants access to this variable over the Internal API. */
              internal_api?:
                  | {
                        /** Sets the duration that this variable can be accessed over the Internal API, after container start. Provides additional security as sensitive data can only be accessed for a limited time. */
                        duration?: Duration | StackVariable | null;
                    }
                  | StackVariable;
              /** Grants access to this variable as a file inside the container. */
              file?:
                  | {
                        /** When true, Cycle will interpret this variable as a base-64 encoded string, and decode it before writing it to the file inside the container. */
                        decode: boolean | StackVariable;
                        /** The absolute path to write the variable to (including file name). If `null`, it will be written to `/var/run/cycle/variables/{variable-identifier}`. */
                        path: (string | null) | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
    source?:
        | (
              | ({
                    type: "url";
                } & StackSpecScopedVariableUrlSource)
              | ({
                    type: "raw";
                } & StackSpecScopedVariableRawSource)
          )
        | StackVariable
        | null;
};
export type StackSpecImageOrigin =
    | ({
          type: "docker-hub";
      } & DockerHubOrigin)
    | ({
          type: "docker-file";
      } & DockerFileOrigin)
    | ({
          type: "docker-registry";
      } & DockerRegistryOrigin)
    | ({
          type: "oci-registry";
      } & OciRegistryOrigin)
    | ({
          type: "cycle-source";
      } & CycleSourceOrigin);
export type StackSpecContainerImage = {
    /** The human-readable name of this image. */
    name?: (string | null) | StackVariable;
    /** Instructions on how to fetch or build this image. */
    origin: StackSpecImageOrigin | StackVariable;
    /** Additional details applied when building an image. */
    build?:
        | {
              /** A map of build arguments applied to the image at build time. */
              args:
                  | {
                        [key: string]: string;
                    }
                  | StackVariable;
          }
        | StackVariable;
    /** A specific builder to use. By default, Cycle uses its factory service and a standard build command to build images, but this can be enhanced by using an image builder integration. */
    builder?:
        | {
              /** The ID of the integration to use when building the image. The integration must support image building to be compatible. */
              integration_id: HybridIdentifier;
          }
        | StackVariable;
};
export type StackSpecContainerConfigNetwork = {
    /** The level of public network access this container should have. */
    public: ("enable" | "disable" | "egress-only") | StackVariable;
    /** The hostname of the container. This is how it can be referenced by other containers in the same environment. */
    hostname: string | StackVariable;
    /** A list of port mappings on this container. */
    ports?: string[] | StackVariable;
};
export type StackSpecContainerConfigDeploy = {
    /** The number of desired instances to deploy. */
    instances: number | StackVariable;
    /** The strategy Cycle will apply when deploying instances of this container.
    - ** resource-density **: Cycle will distribute instances across servers to maintain balanced resource usage. - ** high-availability **: Cycle will deploy instances over servers with an emphasis on geographic and physical separation - ** first-available **: Cycle will deploy one instance to every node that matches the specified criteria. (default) - ** node **: Cycle will deploy one instance to every node that matches the specified criteria. - ** edge **: Cycle will prioritize geographic distribution of instances. - ** function **: Every ingress request/connection receives its own instance.  - ** manual **: Cycle will not make any decisions on where instances are deployed. Instead, instances must be deployed manually using the portal or API.
     */
    strategy?:
        | (
              | "resource-density"
              | "manual"
              | "high-availability"
              | "first-available"
              | "node"
              | "edge"
              | "function"
          )
        | StackVariable;
    /** Configuration options for containers using the 'function' deployment strategy. */
    function?:
        | {
              /** The maximum number of instances that Cycle can pre-allocate (includes auto-scaled instances). */
              max_pool_size?: (number | null) | StackVariable;
              /** For each shard (scheduler), the maximum number of tasks it can run in parallel. */
              max_shard_concurrency?: (number | null) | StackVariable;
              /** The maximum amount of time a function instance can run before timing out. */
              max_runtime?: Duration | StackVariable | null;
              /** The maximum amount of time Cycle will wait for an instance to be available. */
              max_queue_time?: Duration | StackVariable | null;
          }
        | StackVariable;
    /** Configuration options for stateful containers. */
    stateful?:
        | {
              /** Stateful container options. */
              options: {
                  /** When enabled, instances will utilize stateless base hostnames instead of being prefixed with a unique ID. */
                  use_base_hostname?: boolean | null;
              };
          }
        | StackVariable;
    /** Configuration options that provide the ability to set restrictions on which nodes instances of this container are able to be deployed to. (i.e. if you have a GPU container, it should only go on nodes with a GPU). */
    constraints?:
        | {
              node?: {
                  /** Tags applied to a node. Cycle generates some automatically, but additional, custom tags can be applied on a per-node basis. */
                  tags:
                      | {
                            /** If a node has at least one of these tags, it is considered a valid deployment target for this container. */
                            any?: string[] | StackVariable;
                            /** A node must have **ALL** of these tags to be considered a valid deployment target for this container. */
                            all?: string[] | StackVariable;
                        }
                      | StackVariable;
              };
          }
        | StackVariable;
    /** Configuration options for how this container behaves during shutdown. */
    shutdown?:
        | {
              /** How long the platform will wait for a container to stop gracefully. */
              graceful_timeout?: Duration | StackVariable | null;
              signals?:
                  | (
                        | "SIGTERM"
                        | "SIGINT"
                        | "SIGUSR1"
                        | "SIGUSR2"
                        | "SIGHUB"
                        | "SIGKILL"
                        | "SIGQUIT"
                    )[]
                  | StackVariable;
          }
        | StackVariable;
    /** Configuration options for how this container behaves during startup. */
    startup?:
        | {
              /** How long the platform will wait before sending the start signal to the given container. */
              delay?: Duration | StackVariable | null;
          }
        | StackVariable;
    /** Configurations for how the container behaves during updates. */
    update?:
        | {
              /** When set, Cycle will pick a random time from `0 - this duration`, and stagger the instances so they all start at different times (up to the time specified here). */
              stagger?: Duration | StackVariable | null;
          }
        | StackVariable;
    /** Configuration options for how Cycle should handle restarting this container (i.e. in case the process inside the container dies). */
    restart?:
        | {
              /** Under what circumstances Cycle should try to restart this container. */
              condition: ("always" | "never" | "failure") | StackVariable;
              /** How long the platform will wait between restart attempts. */
              delay: Duration | StackVariable;
              /** The maximum number of restart attempts Cycle will make. */
              max_attempts: number | StackVariable;
          }
        | StackVariable;
    /** Configuration options for automated container health checks. */
    health_check?:
        | {
              /** The command or script to run to verify the health of the container. This script is run inside the container by Cycle.
        This command accepts two types of entries:
        - The first is a reference to a script that already lives in the container filesystem. This can be defined by giving the full path to the script as the value.   - The second format is an inline script.  If you need the code to execute within a shell, wrap the commands in escaped quotes like this `"\"curl -s -o /dev/console -w \"%{http_code}\" http://localhost:3000/_health | grep '200'  && exit 0 || exit 1\""`.  Do not use the `/bin/sh -c <commands>` format, this will not be accepted.
         */
              command: string | StackVariable;
              /** The number of times to retry the command before marking an instance unhealthy. */
              retries: number | StackVariable;
              /** How long to wait between running health checks. */
              interval: Duration | StackVariable;
              /** How long before a health check attempt times out. */
              timeout: Duration | StackVariable;
              /** A boolean where true represents the desire for the container to restart if any instance is unhealthy. */
              restart: boolean | StackVariable;
              /** How long to wait after a container start event before running health checks. */
              delay?: Duration | StackVariable | null;
          }
        | StackVariable;
    /** Configuration options for how the instance telemetry (CPU usage, etc) is handled. */
    telemetry?:
        | {
              /** How long telemetry data should be retained. */
              retention?: Duration | StackVariable | null;
              /** The duration between samples. */
              interval?: Duration | StackVariable | null;
              /** A URL where Cycle will send telemetry data to. The payload will be an instance resource snapshot. */
              webhook?: (string | null) | StackVariable;
              /** If true, Cycle will not aggregate telemetry for this container's instances. */
              disable: boolean | StackVariable;
          }
        | StackVariable;
};
export type StackSpecContainerScaleThresholdRam = {
    type: "ram";
    details: {
        /** The target average RAM usage of all instances of this container. Going above this threshold will trigger a scaling event. This threshold must be greater than 25MB. */
        usage: string;
    };
};
export type StackSpecContainerScaleThresholdCpu = {
    type: "cpu";
    details: {
        utilization: number;
    };
};
export type StackSpecContainerScaleThresholdCustom = {
    type: "custom";
    details: {
        webhook: WebhookUrl;
    };
};
export type StackSpecContainerScaleThresholdNetworkConnections = {
    type: "network-connections";
    details: {
        connections_total: number;
    };
};
export type StackSpecContainerScaleThresholdNetworkRequests = {
    type: "network-requests";
    details: {
        requests_total: number;
    };
};
export type StackSpecContainerScaleThresholdNetworkThroughput = {
    type: "network-throughput";
    details: {
        private: boolean;
        /** The limit (maximum) amount of throughput each instance of the given container can use before triggering a scaling event. */
        bandwidth: string;
    };
};
export type StackSpecContainerScaleThreshold =
    | ({
          type: "ram";
      } & StackSpecContainerScaleThresholdRam)
    | ({
          type: "cpu";
      } & StackSpecContainerScaleThresholdCpu)
    | ({
          type: "custom";
      } & StackSpecContainerScaleThresholdCustom)
    | ({
          type: "network-connections";
      } & StackSpecContainerScaleThresholdNetworkConnections)
    | ({
          type: "network-requests";
      } & StackSpecContainerScaleThresholdNetworkRequests)
    | ({
          type: "network-throughput";
      } & StackSpecContainerScaleThresholdNetworkThroughput);
export type StackSpecContainerConfigScaling = {
    /** The identifier of the auto-scaling group assigned to this container. The auto-scale group determines which infrastructure this container can spin up if it needs more resources to meet demand. Setting it to `null` will limit auto-scaling to only instances. */
    autoscale_group: Identifier | StackVariable | null;
    instances:
        | {
              /** Maximum additional instances the auto-scaler will run at any time. */
              max: number | StackVariable;
              /** Minimum number of instances per server. */
              max_server: number | StackVariable;
              /** Minimum amount of time an instance will live. */
              min_ttl: Duration | StackVariable;
          }
        | StackVariable;
    /** Duration in which the auto-scaler will watch for changes. */
    window: Duration | StackVariable;
    /** An array of rules that dictate when a scaling event will be triggered. */
    thresholds: StackSpecContainerScaleThreshold[] | StackVariable;
};
export type StackSpecContainerConfigRuntime = {
    /** The working directory to execute the command in. */
    workdir?: string | StackVariable;
    /** The command to execute when this container starts. Will override the default specified in the container. */
    command?:
        | {
              path?: string | StackVariable;
              args?: string | StackVariable;
          }
        | StackVariable;
    /** A map of environment variables that will be injected into the container. */
    environment_vars?:
        | {
              [key: string]: string | StackVariable;
          }
        | StackVariable;
    /** Container namespaces to apply. By default, all are applied. Removing/changing this can have security implications. */
    namespaces?:
        | ("ipc" | "pid" | "uts" | "network" | "mount" | "user" | "cgroup")[]
        | StackVariable;
    /** Sysctl options to apply. */
    sysctl?:
        | {
              [key: string]: string | StackVariable;
          }
        | StackVariable;
    /** RLIMIT options to apply. */
    rlimits?:
        | {
              [key: string]: {
                  hard: number | StackVariable;
                  soft: number | StackVariable;
              };
          }
        | StackVariable;
    /** Configuration options for seccomp. Cycle enables seccomp by default. */
    seccomp?:
        | {
              disable: boolean | StackVariable;
              rules:
                  | (
                        | {
                              capabilities:
                                  | {
                                        includes: string | StackVariable;
                                        excludes: string | StackVariable;
                                    }
                                  | StackVariable;
                              syscall:
                                  | {
                                        names: string[];
                                        action:
                                            | "SCMP_ACT_KILL"
                                            | "SCMP_ACT_KILL_PROCESS"
                                            | "SCMP_ACT_KILL_THREAD"
                                            | "SCMP_ACT_TRAP"
                                            | "SCMP_ACT_ERRNO"
                                            | "SCMP_ACT_TRACE"
                                            | "SCMP_ACT_ALLOW"
                                            | "SCMP_ACT_LOG"
                                            | "SCMP_ACT_NOTIFY";
                                        errnoRet?: number;
                                        args?: {
                                            index: number;
                                            value: number;
                                            valuetwo?: number;
                                            op:
                                                | "SCMP_CMP_NE"
                                                | "SCMP_CMP_LT"
                                                | "SCMP_CMP_LE"
                                                | "SCMP_CMP_EQ"
                                                | "SCMP_CMP_GE"
                                                | "SCMP_CMP_GT"
                                                | "SCMP_CMP_MASKED_EQ";
                                        }[];
                                    }
                                  | StackVariable;
                          }
                        | StackVariable
                    )[]
                  | StackVariable;
          }
        | StackVariable;
    /** Configuration options regarding the underlying host. */
    host?:
        | {
              /** If true, Cycle will mount the `/proc` directory into the container, giving it access to the host metrics. This is useful if you're running i.e. a monitoring agent. */
              expose_proc?: (boolean | null) | StackVariable;
          }
        | StackVariable;
    /** If true, the container process will run in fully-privileged mode. **WARNING** This is considered insecure, and should only be done if you know what you're doing. */
    privileged?: boolean | StackVariable;
    /** Additional Linux kernel capabilities to apply to this container process. */
    capabilities?:
        | (
              | "CAP_CHOWN"
              | "CAP_FSETID"
              | "CAP_DAC_OVERRIDE"
              | "CAP_FOWNER"
              | "CAP_SETFCAP"
              | "CAP_SETGID"
              | "CAP_SETUID"
              | "CAP_KILL"
              | "CAP_MKNOD"
              | "CAP_NET_BIND_SERVICE"
              | "CAP_NET_RAW"
              | "CAP_AUDIT_WRITE"
              | "CAP_SYS_CHROOT"
              | "CAP_SETPCAP"
              | "CAP_DAC_READ_SEARCH"
              | "CAP_NET_ADMIN"
              | "CAP_NET_BROADCAST"
              | "CAP_SYS_ADMIN"
              | "CAP_SYS_MODULE"
              | "CAP_SYS_NICE"
              | "CAP_SYS_PACCT"
              | "CAP_SYS_PTRACE"
              | "CAP_SYS_RAWIO"
              | "CAP_SYS_RESOURCE"
              | "CAP_SYS_BOOT"
              | "CAP_SYS_TIME"
              | "CAP_SYS_TTY_CONFIG"
              | "CAP_SYSLOG"
              | "CAP_AUDIT_CONTROL"
              | "CAP_AUDIT_READ"
              | "CAP_IPC_LOCK"
              | "CAP_IPC_OWNER"
              | "CAP_LINUX_IMMUTABLE"
              | "CAP_MAC_ADMIN"
              | "CAP_MAC_OVERRIDE"
              | "CAP_BLOCK_SUSPEND"
              | "CAP_LEASE"
              | "CAP_WAKE_ALARM"
          )[]
        | StackVariable;
    /** Configuration options for the root filesystem. */
    rootfs?:
        | {
              /** If true, the container's filesystem will be read-only. */
              readonly: boolean | StackVariable;
          }
        | StackVariable;
};
export type StackSpecContainerConfigResources = {
    cpu:
        | {
              shares?:
                  | {
                        limit: number | StackVariable;
                        reserve: number | StackVariable;
                    }
                  | StackVariable;
              cpus?: string;
          }
        | StackVariable;
    ram:
        | {
              limit?: string | StackVariable;
              reserve?: string | StackVariable;
              swappiness?: number | StackVariable;
          }
        | StackVariable;
};
export type StackSpecContainerConfigIntegrations = {
    /** Enable additional webhooks that Cycle will call out to during the course of a container's lifetime. All webhooks send a payload as an object containing the instance, container, server, and environment IDs. */
    webhooks?:
        | {
              /** Webhooks that are triggered during a container event. */
              events?:
                  | {
                        /** Cycle will call this endpoint when the container is deployed. */
                        deploy?: (string | null) | StackVariable;
                        /** Cycle will call this endpoint when the container is started. */
                        start?: (string | null) | StackVariable;
                        /** Cycle will call this endpoint when the container is stopped. */
                        stop?: (string | null) | StackVariable;
                    }
                  | StackVariable;
              /** The webhook to hit when the container's configuration is changed. */
              config?: (string | null) | StackVariable;
          }
        | StackVariable;
    /** When enabled, this integration will configure Let's Encrypt certificates that will be injected into the container at runtime. The certificates will be managed by the platform and renewed automatically. */
    lets_encrypt?:
        | {
              enable: boolean | StackVariable;
              certificate_path?: (string | null) | StackVariable;
              chain_path?: (string | null) | StackVariable;
              key_path?: (string | null) | StackVariable;
              bundle_path?: (string | null) | StackVariable;
              additional_certs_path?: (string | null) | StackVariable;
          }
        | StackVariable;
    /** When enabled, Cycle will fetch and inject remote files into the container at the specified destination during runtime. */
    files?:
        | {
              source: StackVariable | string;
              destination: string | StackVariable;
          }[]
        | StackVariable;
    /** When enabled, Cycle will automatically manage backups of this container. This is only available for stateful containers. */
    backups?:
        | {
              /** An identifier of an integration that supports backups. All backups will be sent to this destination. */
              integration_id?: HybridIdentifier | StackVariable;
              /** Configuration options for how the container should be backed up. */
              backup:
                  | {
                        /** The command to run to capture a backup. The output sent to `STDOUT` will be captured and sent to the specified integration. */
                        command: string | StackVariable;
                        /** How long the backup will attempt to run before timing out. */
                        timeout: Duration | StackVariable | null;
                        /** A cron string describing how often to run the backup command. */
                        cron_string: (string | null) | StackVariable;
                    }
                  | StackVariable;
              /** Configuration options for how the backup should be restored. */
              restore:
                  | {
                        command: string | StackVariable;
                        /** The time in seconds for the restore to attempt to complete before timing out. */
                        timeout: Duration | StackVariable | null;
                    }
                  | StackVariable;
              /** How long the platform will keep backups. Default is 1 year. */
              retention: Duration | StackVariable | null;
          }
        | StackVariable;
    /** When enabled, Cycle will mount a shared host directory into this container. The directory will be shared with all other containers that mount it. */
    shared_file_systems?:
        | {
              [key: string]:
                  | {
                        writable: boolean | StackVariable;
                        mount_point: StackVariable | string;
                    }
                  | StackVariable;
          }
        | StackVariable;
    /** When enabled, allows more customization to be applied to logging for the container. */
    logs?:
        | {
              /** A tag used for applying log filters and analytics. */
              groups?: Identifier[] | StackVariable;
          }
        | StackVariable;
};
export type StackSpecContainerVolume = {
    /** Configuration options for local volumes. */
    local?:
        | {
              /** The maximum size this volume can grow to. Container volumes on Cycle are thinly provisioned, meaning this isn't an allocation - the volume will only use the space it needs up to this size. */
              max_size: string | StackVariable;
              /** A boolean where true signifies using the largest drive over 2TB for the target server. */
              storage_pool?: boolean | StackVariable;
          }
        | StackVariable;
    /** The path this volume should be mounted at inside the container. */
    destination: string | StackVariable;
    /** If true, the container will be unable to write data to the volume. */
    read_only: boolean | StackVariable;
    /** Configuration options for setting up remote access to this volume via SFTP. */
    remote_access?:
        | {
              /** If true, this volume will be accessible over SFTP. */
              enable: boolean | StackVariable;
              /** A list of IPs that SFTP access will be limited to. */
              ips?: string[] | StackVariable;
              /** If set, Cycle will call out to this URL for authentication. Anything other than a 200 response will be considered a validation failure. */
              webhook?: string | StackVariable;
              /** The password used for logging in to this volume via SFTP. */
              password:
                  | {
                        /** The algorithm the password is encoded with. `raw` means the password is plain-text. */
                        algorithm: ("raw" | "sha512" | "md5") | StackVariable;
                        /** The password string. */
                        data: string | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecContainer = {
    /** The human-readable name of this container. */
    name: string;
    /** Details about the image used for this container. */
    image: StackSpecContainerImage | StackVariable;
    annotations?:
        | {
              [key: string]: any;
          }
        | StackVariable;
    /** Whether or not to mark the container as stateful when deployed. Stateful containers can utilize volumes (stateful data) and are generally used for running databases or other data management applications. */
    stateful: boolean | StackVariable;
    config:
        | {
              network: StackSpecContainerConfigNetwork | StackVariable;
              deploy: StackSpecContainerConfigDeploy | StackVariable;
              /** Configuration options for auto-scaling. */
              scaling?: StackSpecContainerConfigScaling | StackVariable | null;
              runtime?: StackSpecContainerConfigRuntime | StackVariable | null;
              resources?:
                  | StackSpecContainerConfigResources
                  | StackVariable
                  | null;
              integrations?:
                  | StackSpecContainerConfigIntegrations
                  | StackVariable
                  | null;
          }
        | StackVariable;
    /** The role applied to this container. **Not yet implemented** */
    role?: "conductor";
    /** A list of configurations for volumes that will be attached to the container. Only applicable if the container is set to `stateful`. */
    volumes?: StackSpecContainerVolume[] | StackVariable;
    /** If true, the container is marked as `deprecated`, and cannot be started anymore. Deprecated containers also don't count toward resource utilization. */
    deprecate?: boolean | StackVariable;
    /** If true, the container is marked as `locked` and cannot be deleted in any way until the lock is lifted. */
    lock?: boolean | StackVariable;
};
export type StackService = {
    /** Whether or not this service should be enabled. */
    enable?: boolean;
    /** Whether or not Cycle should run multiple instances of this service for high availability. */
    high_availability?: boolean;
    /** Whether or not Cycle should automatically update this service when a new version is released. */
    auto_update?: boolean;
};
export type StackSpecDiscoveryConfig = {
    empty_set_delay?: Duration | StackVariable | null;
    /** A custom mapping of hosts - for forced resolution of specific IPs for a domain. */
    hosts?:
        | {
              [key: string]: {
                  ipv4?: string[] | StackVariable;
                  ipv6?: string[] | StackVariable;
              };
          }
        | StackVariable;
    /** A list of custom DNS resolver strings.  Can specifify domains or ips. */
    custom_resolvers?: string[] | StackVariable | null;
};
export type StackSpecDiscoveryService = {
    service?: StackService | StackVariable | null;
    config?: StackSpecDiscoveryConfig | StackVariable | null;
};
export type StackSpecHaProxyConfigSet = {
    frontend:
        | {
              /** The type of traffic expected by the load balancer for this port. Can be either:
         - tcp: Traffic is forwarded without any parsing or additional manipulation.
         - http: Traffic is treated as web traffic. If a LINKED record is configured for a container exposing this port, the domain will be parsed and it will be forwarded to the proper container. This allows multiple services to run on port 80 in the same environment. */
              mode: ("tcp" | "http") | StackVariable;
              /** The number of simultaneous connections that can be processed at a time. */
              max_connections: (number | null) | StackVariable;
              /** Various options for handling timeouts when communicating with the client. */
              timeouts:
                  | {
                        /** The number of seconds the load balancer will wait for a response from a client before disconnecting. */
                        client_secs: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will wait for a client to send it data when one direction is already closed. This is particularly useful to avoid keeping connections in a waiting state for too long when clients do not disconnect cleanly. */
                        client_fin_ms: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will wait for a new HTTP request to start coming after a response was set. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20http-request) for more information. (`http` mode only) */
                        http_keep_alive_ms: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will wait for a complete HTTP request. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20http-request) for more information. (`http` mode only) */
                        http_request_ms: (number | null) | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
    backend:
        | {
              /** How connections are balanced across your container instances. Can be one of the following:
         - `roundrobin`: Each container instance is used in turns.
         - `static-rr`: Each container instance is used in turns, but is faster than Round Robin at the expense of being less dynamic.
         - `leastconn`: Routes traffic to the instance with the least number of active connections.
         - `first`: Routes traffic to the first available instance.
         - `source`: The same client IP always reaches the same container instance as long as no instance goes down or up. */
              balance:
                  | (
                        | "roundrobin"
                        | "static-rr"
                        | "leastconn"
                        | "first"
                        | "source"
                    )
                  | StackVariable;
              /** Various options for handling timeouts when communicating with a container instance behind the load balancer. */
              timeouts:
                  | {
                        /** The number of seconds the load balancer will wait for a response from the container instance. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4.2-timeout%20server) for more information. */
                        server_secs: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will wait for the server to send data when one direction is already closed. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20server-fin) for more information. */
                        server_fin_ms: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will wait for a successful connection to a container instance. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20connect) for more information. */
                        connect_ms: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will hold connections in a queue when the maximum number of connections has been reached. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20queue) for more information. */
                        queue_ms: (number | null) | StackVariable;
                        /** The number of milliseconds the load balancer will allow for inactivity on a bidirectional tunnel. See the [HAProxy Docs](https://cbonte.github.io/haproxy-dconv/1.7/configuration.html#4-timeout%20tunnel) for more information. */
                        tunnel_secs: (number | null) | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecHaProxyConfig = {
    /** Settings that are applied to any port that is not overridden in the following ports section. */
    default: StackSpecHaProxyConfigSet | StackVariable;
    /** An object that defines how HAProxy will act on a specific port. The key is a custom port, and the value is the same settings object found under `default` above. */
    ports:
        | {
              [key: string]: StackSpecHaProxyConfigSet | StackVariable;
          }
        | StackVariable;
};
export type StackSpecHaProxyLbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean | StackVariable;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean | StackVariable;
    type: "haproxy";
    details: StackSpecHaProxyConfig | StackVariable | null;
    /** Binds the load balancer to the host server IP address.
    
    **Pros**: This allows for significantly lower cost (utilizing fewer IPv4 addresses), and enables building out a true edge network with lower latency.
    **Cons**: Only 1 environment is allowed on the host. This is because the load balancer is the only ingress point for an environment, and if it is sharing
    the same IP as the host, that host can only operate under that environment.
     */
    bind_host?: (boolean | null) | StackVariable;
};
export type StackSpecWafConfig = {
    rules:
        | (
              | {
                    /** Some information about the rule. */
                    description: string | StackVariable;
                    /** A boolean that indicates if the rule should be active or skipped. */
                    skip: boolean | StackVariable;
                    /** A string that describes if the role should allow or deny traffic based on the conditions. */
                    type: ("allow" | "deny") | StackVariable;
                    /** A string that describes if both attributes need to be true (match all) or if only one of the conditions needs to be true (match any). */
                    match?: ("any" | "all") | StackVariable;
                    /** An array of the specific conditions for the rule. */
                    conditions:
                        | (
                              | {
                                    /** A string that describes the match type for the condition. */
                                    type:
                                        | (
                                              | "ip-match"
                                              | "geo-match"
                                              | "url-match"
                                              | "method-match"
                                              | "header-match"
                                          )
                                        | StackVariable;
                                    /** A string that indicates the range of values relative to the value property. */
                                    operator:
                                        | (
                                              | "=="
                                              | "!="
                                              | ">"
                                              | "<"
                                              | ">="
                                              | "<="
                                          )
                                        | StackVariable;
                                    /** The value corresponding to the condition type. */
                                    value: string | StackVariable;
                                }
                              | StackVariable
                          )[]
                        | StackVariable;
                }
              | StackVariable
          )[]
        | StackVariable;
};
export type StackSpecTcpTransportConfig = {
    type: "tcp";
    details:
        | {
              connections:
                  | {
                        [key: string]: any;
                    }
                  | StackVariable;
              /** Configuration options for how telemetry is handled on the load balancer. */
              telemetry:
                  | {
                        [key: string]: any;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecHttpTransportConfig = {
    type: "http";
    details:
        | {
              connections:
                  | {
                        max_idle_conns_per_connection:
                            | (number | null)
                            | StackVariable;
                    }
                  | StackVariable;
              telemetry:
                  | {
                        /** Determines how many URLs the load balancer will track at one time. Defaults to 150. */
                        max_trackable_urls?: (number | null) | StackVariable;
                        /** Whether or not to track invalid requests. An invalid request is a request that came in that no router existed for. Usually this means bot requests. Defaults to false. */
                        track_invalid_requests?:
                            | (boolean | null)
                            | StackVariable;
                        /** An array of paths to exclude from tracking. */
                        ignore_paths?: string[] | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecUdpTransportConfig = {
    type: "udp";
    details:
        | {
              /** Configuration options for how telemetry is handled on the load balancer. */
              telemetry:
                  | {
                        [key: string]: any;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecTcpRouterConfig = {
    type: "tcp";
    details:
        | {
              [key: string]: any;
          }
        | StackVariable;
};
export type StackSpecUdpRouterConfig = {
    type: "udp";
    details:
        | {
              [key: string]: any;
          }
        | StackVariable;
};
export type StackSpecHttpRouterConfig = {
    type: "http";
    details:
        | {
              /** Defines a built-in redirect for HTTP mode routers */
              redirect?:
                  | {
                        /** If enabled and a sibling controller exists for port 443, requests will be auto redirected to it. Essentially sets up automatic TLS redirection for this router. */
                        auto_https_redirect: boolean | StackVariable;
                        /** If true, any request comes in with "www" prefix will be permanently redirected to the same path without www. */
                        remove_www: boolean | StackVariable;
                        /** The port to redirect traffic to. */
                        port?: (number | null) | StackVariable;
                        /** The scheme to redirect to. (i.e. `https`) */
                        scheme?: (string | null) | StackVariable;
                        /** A specific URL to redirect to. */
                        url?: (string | null) | StackVariable;
                    }
                  | StackVariable;
              forward?:
                  | {
                        scheme?: (string | null) | StackVariable;
                        /** Allows the load balancer to modify content before it reaches the user. */
                        content_mod?:
                            | {
                                  /** An array that describes a list of replacement match/value pairs. */
                                  replace?:
                                      | (
                                            | {
                                                  /** String that will be replaced. */
                                                  match: string | StackVariable;
                                                  /** Replacement value. */
                                                  value: string | StackVariable;
                                              }
                                            | StackVariable
                                        )[]
                                      | StackVariable;
                              }
                            | StackVariable;
                        /** The URL to forward the request to.
            
            Given a path match of `^/example/(.*)$`, a route such as `/example/wow.jpg` would be forwarded as /wow.jpg.
             */
                        url?: string | StackVariable;
                    }
                  | StackVariable;
              proxy?:
                  | {
                        /** The domain that this router will proxy. */
                        domain?: (string | null) | StackVariable;
                        /** The full URL that this router will proxy. */
                        url?: string | StackVariable | null;
                        /** Allows the load balancer to modify content before it reaches the user. */
                        content_mod?:
                            | {
                                  /** An array that describes a list of replacement match/value pairs. */
                                  replace?:
                                      | (
                                            | {
                                                  /** String that will be replaced. */
                                                  match: string | StackVariable;
                                                  /** Replacement value. */
                                                  value: string | StackVariable;
                                              }
                                            | StackVariable
                                        )[]
                                      | StackVariable;
                              }
                            | StackVariable;
                    }
                  | StackVariable;
              caching?:
                  | {
                        files?:
                            | (
                                  | {
                                        /** Regex string that describes the files to cache. */
                                        match: string | StackVariable;
                                        /** Time string that describes the time to live. */
                                        ttl: Duration | StackVariable;
                                    }
                                  | StackVariable
                              )[]
                            | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecV1LbRouterConfig = {
    match:
        | {
              /** The specific domains to match against. */
              domains: string[] | StackVariable;
              /** The specific ports to match against. */
              internal_ports: number[] | StackVariable;
              path?: (string | null) | StackVariable;
              /** Match traffic destined (or not destined) for a particular container. */
              containers?:
                  | {
                        /** Match any traffic that would be routed to one of these containers. */
                        include?: HybridIdentifier[] | StackVariable;
                        /** Match any traffic that would NOT be routed to one of these containers. */
                        exclude?: HybridIdentifier[] | StackVariable;
                    }
                  | StackVariable;
          }
        | StackVariable;
    /** How to route the traffic to the destination.
    `random`: Pick a valid destination at random.
    `round-robin`: Send each request to the 'next' destination on the list, restarting from the beginning when the last destination is used.
     */
    mode: ("random" | "round-robin") | StackVariable;
    config:
        | {
              /** If a request comes in from the same origin, ensure it hits the same destination. */
              sticky_sessions: boolean | StackVariable;
              /** If a destination is unavailable, retry up to [x] times, instead of immediately failing with a 503/504 error. */
              destination_retries: number | StackVariable;
              destination_prioritization?:
                  | ("latency" | "random")
                  | StackVariable
                  | null;
              /** TLS termination configuration. If null, the platform will use the default configuration. Port 443 by default has TLS termination enabled. */
              tls?:
                  | {
                        /** [Advanced] Change the domain the controller listens on.
                         */
                        server_name?: (string | null) | StackVariable;
                        /** If enabled, accept TLS traffic with an invalid certificate. This is usually done for development/testing, and is not recommended for production use. */
                        allow_insecure?: (boolean | null) | StackVariable;
                        /** A PEM encoded string of certificates. */
                        client_cert_auth?: (string | null) | StackVariable;
                        /** Defines how to validate the connecting TLS certificate.
            `none`: Do not require a TLS certificate to be sent
            `request`: Asks the client to send a TLS certificate, but does not require nor validate it.
            `require`: Requires a certificate be sent for the request to be valid, but does not validate the certificate.
            `require-verify`: Requires both that the client send a certificate, and that the certificate is valid. This is required when using https.
             */
                        client_auth?:
                            | (
                                  | "none"
                                  | "request"
                                  | "require"
                                  | "require-verify"
                              )
                            | StackVariable
                            | null;
                    }
                  | StackVariable;
              /** Defines how the length of various sorts of timeouts when communicating with the destination. */
              timeouts:
                  | {
                        /** The duration the load balancer will wait before timing out while attempting to connect to the destination. */
                        destination_connection: Duration | StackVariable;
                    }
                  | StackVariable;
              extension?:
                  | null
                  | (
                        | ({
                              type: "tcp";
                          } & StackSpecTcpRouterConfig)
                        | ({
                              type: "udp";
                          } & StackSpecUdpRouterConfig)
                        | ({
                              type: "http";
                          } & StackSpecHttpRouterConfig)
                    );
          }
        | StackVariable;
};
export type StackSpecV1LbController = {
    /** A human-readable identifier for this controller. It will default to the port, i.e. `port-443`, but can be renamed to anything, such as the service this controller represents. */
    identifier: string | StackVariable;
    /** The port inbound trafic is accepted on. */
    port: number | StackVariable;
    waf?: StackSpecWafConfig | StackVariable | null;
    /** Defines how traffic comes in to the load balancer, and how the load balancer handles it. */
    transport?:
        | {
              /** When true, this controller is disabled and will not be used. */
              disable: boolean | StackVariable;
              /** The kind of traffic (http/tcp/udp) that will be sent to the load balancer. */
              mode: ("tcp" | "udp" | "http") | StackVariable;
              /** Defines how the transport for this controller operates. */
              config:
                  | {
                        /** Enable/disable performance mode. If enabled, some telemetry will be disabled to dedicate full processing to handling requests.
            You will not see per-request breakdowns or URL logging if performance mode is enabled.
             */
                        performance: boolean | StackVariable;
                        /** Defines how traffic gets into the load balancer. */
                        ingress:
                            | {
                                  tls?:
                                      | {
                                            /** Enables or disables TLS. */
                                            enable: boolean | StackVariable;
                                        }
                                      | StackVariable;
                              }
                            | StackVariable;
                        /** Defines settings for various types of timeouts. */
                        timeouts:
                            | {
                                  /** The total amount of time a connection can be idle before being killed. */
                                  idle: Duration | StackVariable;
                              }
                            | StackVariable;
                        /** Verbosity describes the level of logging detail for the controller */
                        verbosity:
                            | ("low" | "normal" | "high" | "debug")
                            | StackVariable;
                        /** Extended configurations for the specified transport mode (http/tcp) */
                        extension?:
                            | (
                                  | ({
                                        type: "tcp";
                                    } & StackSpecTcpTransportConfig)
                                  | ({
                                        type: "http";
                                    } & StackSpecHttpTransportConfig)
                                  | ({
                                        type: "udp";
                                    } & StackSpecUdpTransportConfig)
                              )
                            | StackVariable;
                    }
                  | StackVariable;
              /** Defines where traffic is sent. Many can be defined per controller. */
              routers:
                  | (StackSpecV1LbRouterConfig | StackVariable)[]
                  | StackVariable;
          }
        | StackVariable;
};
export type StackSpecV1LbConfig = {
    /** A configuration for a specific port. */
    controllers: StackSpecV1LbController[] | StackVariable;
    controller_template?: StackSpecV1LbController | StackVariable | null;
    waf?: StackSpecWafConfig | StackVariable | null;
};
export type StackSpecV1LbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean | StackVariable;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean | StackVariable;
    type: "v1";
    details: StackSpecV1LbConfig | StackVariable;
    /** Binds the load balancer to the host server IP address.
    
    **Pros**: This allows for significantly lower cost (utilizing fewer IPv4 addresses), and enables building out a true edge network with lower latency.
    **Cons**: Only 1 environment is allowed on the host. This is because the load balancer is the only ingress point for an environment, and if it is sharing
    the same IP as the host, that host can only operate under that environment.
     */
    bind_host?: (boolean | null) | StackVariable;
};
export type StackSpecDefaultLbType = {
    /** Allow / disallow traffic to be routed via IPv4. */
    ipv4: boolean;
    /** Allow / disallow traffic to be routed via IPv6. */
    ipv6: boolean;
    type: "default";
    details: StackSpecHaProxyConfig | StackSpecV1LbConfig | null;
};
export type StackSpecLoadBalancerConfig =
    | ({
          type: "haproxy";
      } & StackSpecHaProxyLbType)
    | ({
          type: "v1";
      } & StackSpecV1LbType)
    | ({
          type: "default";
      } & StackSpecDefaultLbType);
export type StackSpecLoadBalancerService = {
    service?: StackService | StackVariable | null;
    config?: StackSpecLoadBalancerConfig | StackVariable | null;
};
export type StackSpecSchedulerAccessKey = {
    /** The name given to this access key. */
    name: string | StackVariable;
    /** The access key secret. This should be submitted with requests to a publicly accessible scheduler service. */
    secret: string | StackVariable;
    /** Whitelisted IPs that are allowed to make requests to the scheduler service. */
    ips?: string[] | StackVariable;
};
export type StackSpecSchedulerConfig = {
    /** If true, this scheduler will be accessible over the public internet. It will enable a LINKED record to be pointed to the scheduler container, and inbound requests to trigger function containers. */
    public: boolean | StackVariable;
    access_keys?: StackSpecSchedulerAccessKey[] | StackVariable;
};
export type StackSpecSchedulerService = {
    service?: StackService | StackVariable | null;
    config?: StackSpecSchedulerConfig | StackVariable | null;
};
export type StackSpecVpnConfig = {
    auth:
        | {
              /** The endpoint to hit when attempting to authorize a VPN account. If the endpoint returns a 200 response, access is granted. Otherwise it is denied. */
              webhook?: string | StackVariable;
              /** If true, any account with access to this environment on Cycle can use their Cycle credentials to log in to this VPN. */
              cycle_accounts: boolean | StackVariable;
              /** If true, Cycle will allow custom accounts to be created for logging into this VPN. */
              vpn_accounts: boolean | StackVariable;
          }
        | StackVariable;
    allow_internet: boolean | StackVariable;
};
export type StackSpecVpnService = {
    service?: StackService | StackVariable | null;
    config?: StackSpecVpnConfig | StackVariable | null;
};
export type StackSpec = {
    /** The version of the Cycle stack file used. */
    version: "1.0";
    about?:
        | {
              /** A custom, user-defined version of the stack. */
              version: string;
              /** Custom, user-defined details about this stack. */
              description: string;
          }
        | StackVariable;
    /** Describes variables that are assigned to one or more containers at runtime. Can be assigned as an environment variable, written as a file inside the container(s), or accessed over the internal API. */
    scoped_variables?: StackSpecScopedVariable[] | StackVariable;
    containers:
        | {
              [key: string]: StackSpecContainer;
          }
        | StackVariable;
    /** Settings for any auxillary services deployed as part of the environment, such as load balancer and discovery services. */
    services?:
        | {
              discovery?: StackSpecDiscoveryService | StackVariable | null;
              loadbalancer?:
                  | StackSpecLoadBalancerService
                  | StackVariable
                  | null;
              scheduler?: StackSpecSchedulerService | StackVariable | null;
              vpn?: StackSpecVpnService | StackVariable | null;
          }
        | StackVariable;
    /** Additional meta info about the stack. */
    annotations?:
        | {
              [key: string]: any;
          }
        | StackVariable;
};
export type StackGitCommit = {
    /** The commit hash for the given stack build. */
    hash: string;
    /** The commit message for the given stack build hash. */
    message: string;
    /** The time the commit was made. */
    time: DateTime;
    /** Inforamtion about the author of the commit. */
    author: {
        /** The commit authors name. */
        name: string;
        /** The commit authors email address. */
        email: string;
    };
};
export type StackBuildInstructions = {
    /** Git information specifics. */
    git?: {
        /** The type of information the user is passing. */
        type: "hash" | "tag" | "branch";
        /** The actual value to be passed. */
        value: string;
    };
    /** Custom variables applied to the stack during build. Any place in the stack where a `{{variable}}` is used is replaced with the value of the variable supplied in this map. */
    variables?: {
        [key: string]: string;
    };
};
export type StackBuildState = {
    /** The current state of the stack build. */
    current:
        | "new"
        | "importing"
        | "building"
        | "verifying"
        | "saving"
        | "live"
        | "deploying"
        | "deleting"
        | "deleted";
} & State;
export type StackBuildMeta = {
    containers_count?: ResourceCountSummary;
};
export type StackBuild = {
    id: Id;
    hub_id: HubId;
    /** An identifier for the stack related to this build. */
    stack_id: string;
    spec?: StackSpec | null;
    /** Information about the stack build. */
    about: {
        /** Information about the version of the stack. */
        version: string;
        /** Information describing the stack */
        description: string;
        git_commit?: StackGitCommit;
    };
    instructions: StackBuildInstructions;
    /** A collection of timestamps for each event in the stack build's lifetime. */
    events: {
        /** The timestamp of when the stack build was created. */
        created: DateTime;
        /** The timestamp of when the stack build was updated. */
        updated: DateTime;
        /** The timestamp of when the stack build was deleted. */
        deleted: DateTime;
    };
    state: StackBuildState;
    meta?: StackBuildMeta;
};
export type StackBuildInclude = {
    [key: string]: StackBuild;
};
export type StackRepoSource = {
    type: "git-repo";
    details: {
        /** Specify which file in the repo contains the stack spec. By default, it is `cycle.json` in the root of the repo. */
        stack_file?: string | null;
        /** The URL of the repository. */
        url: string;
        /** An optional branch arguement.  Default value is `master`. */
        branch?: string;
        /** Authentication information for the repository. */
        auth?:
            | ({
                  type: "http";
              } & HttpSourceCredentials)
            | ({
                  type: "ssh";
              } & SshSourceCredentials);
        /** Repository reference information. */
        ref?: {
            /** The type of reference being used. */
            type: string;
            /** The value for the given reference type. */
            value: string;
        };
    };
};
export type StackRawSource = {
    type: "raw";
    details: StackSpec | null;
};
export type StackSource =
    | ({
          type: "git-repo";
      } & StackRepoSource)
    | ({
          type: "raw";
      } & StackRawSource);
export type StackState = {
    /** The current state of the stack. */
    current: "live" | "deleting" | "deleted";
} & State;
export type StackMeta = {
    containers_count?: ResourceCountSummary;
    builds_count?: ResourceCountSummary;
};
export type Stack = {
    id: Id;
    /** A human readable slugged identifier for this stack. */
    identifier: Identifier;
    /** A user defined name for the stack resource. */
    name: string;
    hub_id: HubId;
    creator: UserScope;
    source: StackSource;
    state: StackState;
    /** A map of default variable values used when building this stack. A variable can be added anywhere in a stack using the format `{{var}}` where `var` would be a key in this map. */
    variables?: {
        [key: string]: string;
    };
    acl?: Acl | null;
    /** A collection of timestamps for each event in the Stacks lifetime. */
    events: {
        /** The timestamp of when the stack was created. */
        created: DateTime;
        /** The timestamp of when the stack was updated. */
        updated: DateTime;
        /** The timestamp of when the stack was deleted. */
        deleted: DateTime;
        /** The timestamp for the last build of the stack. */
        last_build: DateTime;
    };
    meta?: StackMeta;
};
export type StackIncludes = {
    [key: string]: Stack;
};
export type IdentifierIncludes = {
    [key: string]: Id[];
};
export type EnvironmentInclude = {
    [key: string]: Environment;
};
export type ContainerIncludes = {
    creators?: CreatorInclude;
    images?: ImageIncludes;
    stack_builds?: StackBuildInclude;
    stacks?: StackIncludes;
    "stacks:identifiers"?: IdentifierIncludes;
    environments?: EnvironmentInclude;
    "environments:identifiers"?: IdentifierIncludes;
};
export type ContainerStartActionTask = {
    /** The name of the action to perform. */
    action: "start";
};
export type ContainerStopActionTask = {
    /** The name of the action to perform. */
    action: "stop";
};
export type ReconfigureContainer = {
    /** The action to take. */
    action: "reconfigure";
    contents: ContainerConfig;
};
export type ReconfigureVolumeTask = {
    /** The action to take. */
    action: "volumes.reconfigure";
    /** An array of volume objects to be reconfigured. */
    contents: VolumeSummary[];
};
export type ReimageContainer = {
    /** The action to take. */
    action: "reimage";
    /** Additional information needed to complete the job. */
    contents: {
        /** The ID of the image to use when reimaging. */
        image_id: string;
        /** A boolean where true signifies the image being used to reimage does not have to have compatibility with the image being replaced. */
        allow_incompatible?: boolean;
        /** A boolean where true signifies the user wishes to overwrite the current runtime configuration for the given container during the reimage process. */
        overwrite_runtime_config?: boolean;
    };
};
export type ScaleContainer = {
    /** The action to take. */
    action: "scale";
    /** Additional information needed to complete the job. */
    contents: {
        /** The number of desired instances to scale to. */
        instances: number;
    };
};
export type ServerInstancesSummary = {
    server_id: Id;
    instances: number;
};
export type ServerSharedFileSystems = {
    mounts?: {
        [key: string]: {
            /** String describing the server mount type. */
            type: string;
            /** Comma separated string describing the server mount options. */
            options: string;
            /** String describing the server mount source. */
            source: string;
        };
    };
    /** An object describing directory identifiers with value {}. */
    directories?: {
        [key: string]: any;
    };
};
export type ServerProvider = {
    /** The vendor for the provider this server is deployed from. */
    vendor: string;
    /** The model of the server that is deployed. */
    model: string;
    /** A location where the server is deployed. */
    location: string;
    /** A provider specific identifier for the server. */
    server: string;
    /** The IP's assigned to this server during provisioning. */
    init_ips?: string[];
    /** The mac address for the server. */
    mac_addr?: string;
    extra?: {
        [key: string]: any;
    };
    provision_options?: {
        attachesd_storage_size?: number;
        reservation_id?: string;
    };
};
export type ServerFeatures = {
    /** A boolean where true means the server accepts incoming SFTP connections through the remote volume integration. */
    sftp: boolean;
    /** The size of the base volume (where Cycle stores container images on this server). */
    base_volume_gb?: number | null;
};
export type ServerConstraints = {
    /** A list of server tags to put on the given server. */
    tags: string[];
    /** Settings for the server, things that are or are not allowed. */
    allow: {
        /** A boolean where true means - allow containers with no tags specified to be deployed to this server. */
        pool: boolean;
        /** A boolean where true means - allow service containers to be deployed to this container. */
        services: boolean;
        /** A boolean where true means - allow twice the normal amount of CPU shares to be allocated to containers deployed to this server. */
        overcommit: boolean;
    };
};
export type ServerState = {
    /** The current state of the server. */
    current:
        | "new"
        | "provisioning"
        | "configuring"
        | "live"
        | "deleting"
        | "deleted";
} & State;
export type NodeState = {
    /** The current state of the node. */
    current: "new" | "offline" | "authorizing" | "online" | "decommissioned";
    changed: DateTime;
} & State;
export type ServerStatsNetwork = {
    /** Network interfaces for a given server. */
    interfaces?: {
        [key: string]: {
            /** The interface name. */
            interface?: string;
            /** Flags for the given interface. */
            flags?: string;
            /** The maximum transmission unit for the interface. */
            mtu?: number;
            /** The mac address for the interface. */
            mac_addr?: string;
            /** An array of IP addresses associated with the interface. */
            addrs?: string[];
        };
    };
    /** The public IPv4 address used to connect to this server. */
    external_ipv4: string;
    /** The public IPv6 address used to connect to this server. */
    external_ipv6: string;
};
export type ServerStatsUptime = {
    /** The amount in seconds the server has been up (resets on restart). */
    seconds?: number;
    /** CPU idle time. */
    cpu_idle?: number;
};
export type ServerStatsCpuUsage = {
    user?: number;
    nice?: number;
    system?: number;
    idle?: number;
    iowait?: number;
    irq?: number;
    soft_irq?: number;
    steal?: number;
    guest?: number;
    guest_nice?: number;
};
export type ServerStatsCpu = {
    /** The number of CPU's or vCPU's for a given server. */
    cores?: number;
    /** An array of processor information objects. */
    processors?: {
        /** The model of the processor. */
        model?: string;
        /** The speed of the processor. */
        speed?: number;
    }[];
    usage?: ServerStatsCpuUsage;
};
export type ServerStatsLoad = {
    /** The 1 minute load average. */
    avg1m: number;
    /** The 5 minute load average. */
    avg5m: number;
    /** the 15 minute load average. */
    avg15m: number;
};
export type ServerStatsRam = {
    /** The available RAM on the server. */
    available: number;
    /** The free RAM on the server. */
    free: number;
    /** The total RAM on the server. */
    total: number;
};
export type ServerStatsStorage = {
    /** An array of volume group objects. */
    volume_groups: {
        [key: string]: {
            /** A name for the volume group. */
            name: string;
            /** A number representing the aggregate group volume size in megabytes. */
            size: number;
            /** The actual volumes. */
            volumes: {
                [key: string]: {
                    /** The name of the volume. */
                    name: string;
                    /** The size in megabytes of the volume. */
                    size: number;
                    /** The percent of the volumes size being used by data. */
                    data_percent: number;
                    /** The percent of the volumes size being used by meta data. */
                    meta_percent: number;
                    /** The name of the volume pool this volume is associated with. */
                    pool: string;
                };
            };
        };
    };
    /** Records that show information about mounted filesystems where the key is the path to the mount. */
    mounts: {
        [key: string]: {
            /** The device assocaited with the mount. */
            device?: string;
            /** The type of filesystem in use by the mount. */
            type: string;
            /** The path the mount is associated with. */
            mountpoint?: string;
            /** The total amount of storage in KB available at this mount. */
            total: number;
            /** The amount of uninitialized storage in KB. */
            free: number;
            /** The amount of storage being used in KB. */
            used: number;
        };
    };
};
export type ServerStatsOs = {
    /** The currently installed release version of the OS. */
    release?: string;
    /** The currently installed kernel version. */
    kernel?: string;
    /** The hostname as set by the given server's OS. */
    hostname?: string;
};
export type ServerStatsVersions = {
    /** A list of records about Cycle services. */
    services: {
        [key: string]: string;
    };
};
export type NodeMetaStats = {
    network: ServerStatsNetwork;
    uptime: ServerStatsUptime;
    cpu: ServerStatsCpu;
    load: ServerStatsLoad;
    ram: ServerStatsRam;
    storage: ServerStatsStorage;
    os: ServerStatsOs;
    versions: ServerStatsVersions;
};
export type ServerMeta = {
    /** Infrormation about the status of the server. */
    node?: {
        /** The last time this server checked in with the platform. */
        last_checkin: DateTime;
        /** An array a drivers associated with the given server. */
        drivers: string[];
        state: NodeState;
        stats: NodeMetaStats;
        /** A boolean where true means the platform is reporting the server as healthy. */
        healthy: boolean;
        /** A boolean where true means the server is online and reachable by the platform. */
        online: boolean;
    };
    instances_count?: ResourceCountSummary;
};
export type Server = {
    id: Id;
    hub_id: HubId;
    /** An identifier for the location this server is deployed to. */
    location_id: string;
    /** An identifier for the model of server that is deployed. */
    model_id: string;
    /** An identifier for the node. */
    node_id: string;
    shared_file_systems: ServerSharedFileSystems;
    /** The server hostname. */
    hostname: string;
    creator: CreatorScope;
    provider: ServerProvider;
    /** The cluster the given server is deployed to. */
    cluster: string;
    features: ServerFeatures;
    constraints: ServerConstraints;
    autoscale?: {
        group_id: Id;
    };
    /** Set to true when a server is created as part of an auto-scale event. */
    ephemeral: boolean;
    /** Details about a server's evacuation status. When an evacuation is in progress, no new container instances will be permitted on the server. */
    evacuate?: {
        /** The time when this server began evacuating instances. */
        started: DateTime;
    };
    state: ServerState;
    /** A collection of timestamps for each event in the server's lifetime. */
    events: {
        /** The timestamp of when the server was created. */
        created: DateTime;
        /** The timestamp of when the server was updated. */
        updated: DateTime;
        /** The timestamp of when the server was deleted. */
        deleted: DateTime;
        /** The timestamp of when the latest instance was deployed to this server. */
        latest_instance: DateTime;
        /** Information about the provisioning of the server. */
        provisioning: {
            /** A timestamp of when the server started provisioning. */
            started: DateTime;
            /** A timestamp of when the server failed provisioning. */
            failed: DateTime;
            /** A timestamp of when the server completed provisioning. */
            completed: DateTime;
        };
    };
    meta?: ServerMeta;
};
export type ProviderLocationGeography = {
    /** The latitude of the given provider datacenter. */
    latitude: number;
    /** The longitude of the given provider datacenter. */
    longitude: number;
    /** The city the datacenter resides in. */
    city: string;
    /** The state the datacenter resides in. */
    state: string;
    /** The country the datacenter resides in. */
    country: string;
    /** The region the datacenter resides in. */
    region: string;
};
export type LocationProvider = {
    /** An identifier for the given provider */
    identifier: string;
    /** The ID of the provider integration associated with this location. */
    integration_id?: Id;
    /** A location name returned from the provider. */
    location: string;
    /** A location code returned from the provider. */
    code: string;
    availability_zones?: string[];
};
export type ProviderLocation = {
    id: string;
    /** A name for the location. */
    name: string;
    geographic?: ProviderLocationGeography | null;
    provider: LocationProvider;
    /** A boolean where true means the locaiton is supported by the platform. */
    compatible: boolean;
    /** Additional information about available and supported features of the provider location. */
    features: {
        /** Available features for the provider location. */
        available: string[];
        /** Supported features for the provider location. */
        supported: string[];
    };
    /** An abbreviation for the given provider locaiton. */
    abbreviation: string;
    /** Additional information about the given provider locaiton. */
    annotations: {
        [key: string]: any;
    };
};
export type LocationIncludes = {
    [key: string]: ProviderLocation;
};
export type CpuServerSpec = {
    /** The number of CPUs. */
    count: number;
    /** The number of cores. */
    cores?: number;
    /** The number of threads. */
    threads?: number;
    /** The type of the CPU. */
    type: string;
    /** A boolean where true represents the CPU is shared. */
    shared?: boolean;
    /** Extra information about the CPU if there is any. */
    extra?: {
        [key: string]: string;
    };
};
export type GpuServerSpec = {
    /** The number of GPUs. */
    count: number;
    /** The number of cores. */
    cores?: number;
    /** The amount of virtaul RAM for the GPU. */
    vram_gb?: number;
    /** The type of the GPU. */
    type: string;
    /** A boolean where true represents the GPU is shared. */
    shared?: boolean;
    /** Extra information about the GPU if there is any. */
    extra?: {
        [key: string]: string;
    };
};
export type MemoryServerSpec = {
    /** A number that represents the memory resources in GB. */
    size_gb: number;
    /** The type of the memory resources. */
    type: string;
    /** Extra inforamtion about the memory resources. */
    extra: {
        [key: string]: string;
    };
};
export type StorageServerSpec = {
    /** The number of storage devices for a given server. */
    count: number;
    /** The total amount of storage on a given server, in GB. */
    size_gb: number;
    /** The type of the storage device(s). */
    type: string;
    /** Extra information about the storage resources for a given server. */
    extra: {
        [key: string]: string;
    };
};
export type NetworkServerSpec = {
    /** The number of network resources for a given server. */
    count: number;
    /** The scope of the interfaces. */
    scope: "public" | "private" | "shared";
    /** The network throughput in MB(per second). */
    throughput: number;
    /** The type of the NIC. */
    type: string;
};
export type FeaturesServerSpec = {
    /** The type of RAID supported, if any. */
    raid: string | null;
    /** Features specific to AWS. */
    aws?: {
        /** A boolean where true indicates this is a machine that can support a much higher networking throughput. */
        ena_support: boolean;
        /** A boolean where true indicates this is a machine that can support higher storage throughput. */
        ebs_optimized: boolean;
    };
};
export type ServerSpecs = {
    cpu: CpuServerSpec;
    gpu: GpuServerSpec;
    memory: MemoryServerSpec;
    storage: StorageServerSpec[];
    network: NetworkServerSpec[];
    features: FeaturesServerSpec;
};
export type ProviderServerSpec = {
    /** A provider identifier */
    identifier: string;
    integration_id?: Id | null;
    /** A category for the server. */
    category: string;
    /** A class for the server. */
    class?: string;
    /** The model of the server. */
    model: string;
    /** A list of location ID's this server is available. */
    locations: string[];
    availability_zones?: {
        [key: string]: string[];
    };
};
export type ProviderServerModel = {
    id: Id;
    /** A name for the server. */
    name: string;
    /** A description of the server. */
    description: string;
    specs: ServerSpecs;
    provider: ProviderServerSpec;
    price: BillingAmount;
    /** A boolean where true signifies this server is compatible with the platform. */
    compatible: boolean;
    /** A boolean where true signifies the server has a limited amount of resources and should only be used for very lightweight workloads. */
    low_resource: boolean;
    /** An array of location IDs where this server type is available. */
    location_ids: string[];
};
export type ServerModelIncludes = {
    [key: string]: ProviderServerModel;
};
export type IntegrationAuth = {
    /** The region associated with the Integration. */
    region?: string | null;
    /** The namespace associated with the Integration. */
    namespace?: string | null;
    /** API key for accessing the Integration. */
    api_key?: string | null;
    /** Key ID for accessing the Integration. */
    key_id?: string | null;
    /** Secret for accessing the Integration. */
    secret?: string | null;
    /** Subscription ID for the Integration. */
    subscription_id?: string | null;
    /** Client ID for the Integration. */
    client_id?: string | null;
    /** Base64 encoded configuration for the Integration. */
    base64_config?: string | null;
};
export type IntegrationState = {
    /** The current state of the integration. */
    current: "new" | "verifying" | "live" | "deleting" | "deleted";
} & State;
export type IntegrationDefinition = {
    vendor: string;
    name: string;
    supports_verification: boolean;
    supports_multiple: boolean;
    /** A list of additional features supported by this Integration. */
    features?: string[];
    /** A list of functionality that this integration extends. i.e. ["backups"] */
    extends?: string[];
    /** Additional configuration options that are available when using this Integration. These describe additional functionality that Cycle may utilize. */
    extended_configuration?: {
        options?: {
            title?: string;
            key?: string;
            type?: string;
        }[];
    };
    fields?: {
        extra?: {
            [key: string]: {
                regex?: string | null;
                required: boolean;
                description: string;
            };
        };
        auth?: {
            [key: string]: {
                regex?: string | null;
                required: boolean;
                description: string;
            };
        };
    };
    url: string;
    public: boolean;
    usable: boolean;
    /** If true, the Integration can be edited. Otherwise, to make a change it will need to be deleted and recreated. */
    editable: boolean;
};
export type IntegrationMeta = {
    /** The full Integration definition associated with this Integration. */
    definition?: IntegrationDefinition;
};
export type Integration = {
    id: Id;
    /** Name of the Integration. */
    name?: string | null;
    /** Which vendor this Integration is associated with. */
    vendor: string;
    identifier: Identifier;
    /** Authentication information for the Integration, can be null. */
    auth?: IntegrationAuth | null;
    /** Additional key-value pairs associated with the Integration. */
    extra?: {
        [key: string]: string;
    };
    creator: CreatorScope;
    /** Identifier of the hub associated with the Integration. */
    hub_id: Id;
    state: IntegrationState;
    /** A collection of timestamps for each event in the Integration's lifetime. */
    events: {
        /** The timestamp of when the Integration was created. */
        created: DateTime;
        /** The timestamp of when the Integration was updated. */
        updated: DateTime;
        /** The timestamp of when the Integration was deleted. */
        deleted: DateTime;
    };
    meta?: IntegrationMeta | null;
};
export type IntegrationsIncludes = {
    [key: string]: Integration;
};
export type ServerIncludes = {
    locations?: LocationIncludes;
    models?: ServerModelIncludes;
    integrations?: IntegrationsIncludes;
};
export type EnvironmentNetworkSummary = {
    id: Id;
    network_subnet: string;
    subnet: string;
    ipv6: IpNet | null;
    legacy: {
        host: number;
        subnet: number;
        ipv4: IpNet | null;
    };
    mac_addr: string;
    vxlan_tag: number;
};
export type InstanceProvider = {
    /** The vendor of the hub provider integration related to the server this instance runs on. */
    vendor: string;
    /** An ID of the provider Hub integration that this instance's host node is related to. */
    integration_id: Id;
    /** The identifier of the location related to the server this instance runs on. */
    location: string;
};
export type InstanceState = {
    /** The current state of the instance. */
    current:
        | "new"
        | "starting"
        | "reimaging"
        | "migrating"
        | "running"
        | "stopping"
        | "stopped"
        | "failed"
        | "deleting"
        | "deleted";
    /** information about the health of the instance. */
    health?: {
        /** Describes the healthiness of the instance. Health checks can be configured at the container level.
        - `true`: The instance is considered healthy.
        - `false`: The instance is considered unhealthy.
        - `null`: The instance has not yet reported its health, or a health check has not yet been performed.
         */
        healthy: boolean | null;
        /** A timestamp of the last time the instance health was updated. */
        updated: DateTime;
    };
} & State;
export type InstanceAutoScale = {
    min_ttl: DateTime;
};
export type MigrationInstance = {
    /** The ID of the instance. */
    instance_id: string;
    /** The ID of the server. */
    server_id: string;
};
export type InstanceMigration = {
    to?: MigrationInstance | null;
    from?: MigrationInstance | null;
    /** A timestamp of when the migration was started. */
    started?: DateTime;
    /** A timestamp of when the migration was completed. */
    completed?: DateTime;
    /** A key used by the platform to verify the migration. */
    key: string;
    /** A boolean where true represents the volumes for the instance should be copied to the new server as well. */
    copy_volumes: boolean;
};
export type Instance = {
    id: Id;
    creator: CreatorScope;
    hub_id: HubId;
    /** A container identifier for the container that is associated with this instance. */
    container_id: string;
    /** A location identifier that's associated with the server this instance is deployed to. */
    location_id: string;
    environment: EnvironmentNetworkSummary;
    /** If the instance is stateful, additional details relating to its stateful properties. */
    stateful?: {
        id: Id;
        /** The base hostname for the given instance. */
        base_hostname: string;
    };
    /** If the instance is a function, additional details relating to its function properties */
    function?: {
        /** The seed used for this function. */
        seed: number;
    };
    provider: InstanceProvider;
    /** An identifier for the server this instance is deployed to. */
    server_id: Id;
    /** The state as it relates to the following. * `active` - instance can be started or stopped. * `purge` - instance should be deleted. * `hibernate` - instance is active but not allowed to run. * `configuring` - this instance is not allowed to start yet */
    ready_state: "active" | "purge" | "hibernate" | "configuring";
    /** The timestamp of when the instance was purged. */
    purge_time?: DateTime | null;
    /** The hostname of the instance. */
    hostname: string;
    /** The type of service this instance is within the environment, if any. */
    service?: ServiceContainerIdentifier;
    state: InstanceState;
    autoscale?: InstanceAutoScale | null;
    migration?: InstanceMigration | null;
    deployment?: Deployment | null;
    /** A collection of timestamps for each event in the instance's lifetime. */
    events: {
        /** The timestamp of when the instance was created. */
        created: DateTime;
        /** The timestamp of when the instance was updated. */
        updated: DateTime;
        /** The timestamp of when the instance was deleted. */
        deleted: DateTime;
    };
};
export type ServerIncludes2 = {
    [key: string]: Server;
};
export type ContainersIncludes = {
    [key: string]: Container;
};
export type InstanceIncludes = {
    creators?: CreatorInclude;
    servers?: ServerIncludes2;
    locations?: LocationIncludes;
    integrations?: IntegrationsIncludes;
    "integrations:identifiers"?: IdentifierIncludes;
    containers?: ContainersIncludes;
    "containers:identifiers"?: IdentifierIncludes;
    environments?: EnvironmentInclude;
    "environments:identifiers"?: IdentifierIncludes;
};
export type SshToken = {
    id: Id;
    /** The ID of the instance being connected to. */
    instance_id: string;
    /** The ID of the container from which the instance was created. */
    container_id: string;
    hub_id: HubId;
    creator: CreatorScope;
    /** A collection of timestamps for each event in the SSH token's lifetime. */
    events: {
        /** The timestamp of when the SSH token was created. */
        created: DateTime;
        /** The timestamp of when the SSH token was used. */
        used: DateTime;
        /** The timestamp of when the SSH token expires. */
        expires: DateTime;
    };
    /** A boolean where true represents the token as being a valid token to be used for connection. */
    valid: boolean;
};
export type SshConnectionResponse = {
    token: SshToken;
    /** The SSH connection response secret. */
    secret: string;
    /** The SSH connection response address. */
    address: string;
};
export type MigrateAction = {
    /** The name of the action to perform. */
    action: "migration.start";
    contents: {
        /** The ID of the server being migrated to. */
        destination_server_id: string;
        /** Information about additonal configuration settings used when migrating a stateful instance. */
        stateful?: {
            /** A boolean where true represents the desire to copy the contents of the volume for a given stateful instance with the migration. */
            copy_volumes: boolean;
        };
    };
};
export type RevertMigrationAction = {
    /** The name of the action to perform. */
    action: "migration.revert";
};
export type ExtendVolumeAction = {
    /** The name of the action to perform. */
    action: "volume.extend";
    contents: {
        /** The ID of the volume that will be extended through this task. */
        volume_id: string;
        /** The amount of storage to extend the volume by. Container volumes on Cycle are thinly provisioned, meaning this isn't an allocation - the volume will only use the space it needs up to this size. */
        extend_size: string;
    };
};
export type DeployedVolume = {
    /** The ID of the deployed volume. */
    id: string;
    /** The ID of the server the instance is deployed to. */
    server_id: string;
    /** The ID of the container the instance was created from. */
    container_id: string;
    /** The ID for the volume withing the context of the container. */
    container_volume_id: string;
    /** The ID for the container instance this volume is attached to. */
    instance_id: string;
    /** A hash used as part of the identification mechanisms for the platform and API. */
    hash: string;
    /** The path where the volume is mounted on the instnace filesystem. */
    path: string;
    /** Storage information for the volume. */
    storage: {
        /** The amount of volume storage used, in MB's */
        used: number;
        /** The total amount of volume storage available, in MB's. */
        total: number;
    };
};
export type InstanceVolumeSftp = {
    /** The hostname for connecting to the volume over SFTP. */
    host: string;
    /** The port number to use when connecting. */
    port: number;
    /** The username to use when connecting. */
    username: string;
    /** Password configuration settings for the remote access of the container volume. */
    password: {
        /** The hashing algorithm used to has the password. */
        algorithm: "raw" | "sha512" | "md5";
        /** The raw or hashed password. */
        data: string;
    };
};
export type InstanceVolume = {
    id: Id;
    config: ContainerVolume;
    deployed: DeployedVolume;
    sftp: InstanceVolumeSftp;
};
export type InstanceTelemetryReportRequest = {
    /** The ID of the instance this telemetry reporting pertains to. */
    instance_id: string;
    /** A timestamp of when the snapshot began. */
    start: DateTime;
    /** A timestamp of when the snapshot ended. */
    end: DateTime;
};
export type InstanceTelemetryCpuSnapshot = {
    /** The total number of cores on the originating node the instance is running on. */
    num_cores: number;
    /** Usage statistics. */
    usage: {
        /** Total amount of VPU time since last restart. */
        total: number;
        /** The amount of time in nanoseconds used by the kernel since last restart. */
        kernel: number;
        /** The amount of time in nanoseconds taken up for user processes. */
        user: number;
    };
    /** Throttling statistics. */
    throttling: {
        /** The amount of periods that have passed since the last restart. */
        periods?: number;
        /** How many times the instance has been throttled for using all its alloted CPU during a period. */
        throttled_periods?: number;
        /** The amount of time in nanoseconds this instnace has spent with throttled CPU resources. */
        throttled_time?: number;
    };
};
export type MemoryData = {
    /** The number in bytes of memory being used by the instance at the time of the snapshot. */
    usage?: number;
    /** The highest amoun tof memory usage since the last restart. */
    max_usage?: number;
    /** The number of times the memory liimit was exceeded for the instance. */
    fail_count?: number;
    /** The maximum number of bytes of memory this instance has acess to. */
    limit?: number;
};
export type InstanceTelemetryMemorySnapshot = {
    /** The number of bytes of page cache memory being used by this instance. */
    cache?: number;
    /** The memory usage data. */
    usage?: MemoryData;
    /** Memory swap usage data. */
    swap_usage?: MemoryData;
    /** Kernal memory usage. */
    kernel_usage?: MemoryData;
    /** Kernel TCP buffer usage. */
    kernel_tcp_usage?: MemoryData;
};
export type InstanceTelemetryProcessesSnapshot = {
    /** The current number of running processes in the instance. */
    current?: number;
    /** The maximum number of running processes for the instance. */
    limit?: number;
};
export type InstanceTelemetryNetworkSnapshot = {
    /** An array of network interfaces attached to this instance. */
    interfaces: {
        name: string;
        rx_bytes: number;
        tx_bytes: number;
    }[];
};
export type HugeTlb = {
    /** The number of bytes being consumed by huge pages of all sizes. */
    usage?: number;
    /** The maximum number of bytes allowed to be used for huge pages for this instance. */
    max?: number;
    /** The number of times the hugeTLB memory limit has been exceeded by this instance. */
    fail_count?: number;
};
export type InstanceTelemetryResourceSnapshot = {
    /** A timestamp of the time this snapshot was captured at. */
    time: DateTime;
    hub_id?: HubId | null;
    container_id?: Id | null;
    instance_id?: Id | null;
    environment_id?: Id | null;
    server_id?: Id | null;
    cluster?: Identifier | null;
    cpu: InstanceTelemetryCpuSnapshot;
    memory: InstanceTelemetryMemorySnapshot;
    processes: InstanceTelemetryProcessesSnapshot;
    network: InstanceTelemetryNetworkSnapshot;
    hugetlb?: HugeTlb;
};
export type InstanceTelemetryReport = {
    request: InstanceTelemetryReportRequest;
    /** An array of telemetry snapshots. */
    snapshots: InstanceTelemetryResourceSnapshot[];
};
export type FunctionRuntimeVariables = {
    /** Environment variables to inject into the container. */
    environment_variables?: {
        [key: string]: string;
    };
};
export type TriggerAction = {
    /** The name of the action to perform. */
    action: "trigger";
    contents: {
        /** A custom token used for identifying and managing a claim. Can be any valid string 5 characters or more. */
        token: string;
        runtime_variables: FunctionRuntimeVariables;
    };
};
export type ContainerBackupTarget = {
    integration_id: HybridIdentifier;
    /** The target for where to store the Container Backup on the destination service. */
    path: string;
    file_id: string;
    /** The total size of the backup. */
    size: number;
};
export type ContainerBackupState = {
    /** The current state of the Container Backup. */
    current: "saving" | "live" | "deleting" | "deleted";
} & State;
export type ContainerBackup = {
    id: Id;
    hub_id: HubId;
    /** The ID of the given container. */
    container_id: string;
    /** The ID of the instance the backup belongs to. */
    instance_id: string;
    target: ContainerBackupTarget;
    state: ContainerBackupState;
    /** A collection of timestamps for each event in the backup's lifetime. */
    events: {
        /** The timestamp of when the backup was created. */
        created: DateTime;
        /** The timestamp of when the backup was updated. */
        updated: DateTime;
        /** The timestamp of when the backup was deleted. */
        deleted: DateTime;
    };
};
export type ContainerBackupIncludes = {
    integrations?: IntegrationsIncludes;
};
export type ContainerBackupLogs = {
    id: Id;
    /** An identifier for the container. */
    container_id: string;
    /** An identifier for the instance. */
    instance_id: string;
    /** An identifier for the backup. */
    backup_id: string;
    hub_id: HubId;
    /** The type of the backup log. */
    type: "restore" | "backup";
    /** The log. */
    log: string;
    error?: {
        message: string;
        message_internal?: string;
        stack?: {
            file: string;
            function: string;
            line: number;
        }[];
    };
    /** A collection of timestamps for each event in the backup log's lifetime. */
    events: {
        /** The timestamp of when the backup log was created. */
        created: DateTime;
        /** The timestamp of when the backup log was updated. */
        updated: DateTime;
        /** The timestamp of when the backup log was deleted. */
        deleted: DateTime;
    };
};
export type DnsZoneState = {
    /** The current state of the zone. */
    current:
        | "new"
        | "pending"
        | "verifying"
        | "live"
        | "disabled"
        | "deleting"
        | "deleted";
} & State;
export type DnsZone = {
    id: Id;
    hub_id: HubId;
    creator: CreatorScope;
    /** The origin for the given DNS zone. */
    origin: string;
    /** A boolean where true represents this zone is a hosted zone. */
    hosted: boolean;
    acl?: Acl | null;
    state: DnsZoneState;
    /** A collection of timestamps for each event in the DNS zone's lifetime. */
    events: {
        /** The timestamp of when the DNS zone was created. */
        created: DateTime;
        /** The timestamp of when the DNS zone was updated. */
        updated: DateTime;
        /** The timestamp of when the DNS zone was deleted. */
        deleted: DateTime;
        /** The timestamp of when the DNS zone was last_verification. */
        last_verification: DateTime;
        /** The timestamp of when the DNS zone was verified. */
        verified: DateTime;
    };
};
export type ZoneIncludes = {
    creators?: CreatorInclude;
};
export type RecordIncludes = {
    creators?: CreatorInclude;
    containers?: ContainersIncludes;
    "containers:identifiers"?: IdentifierIncludes;
};
export type DnstlsAttempt = {
    id: Id;
    hub_id: HubId;
    /** A list of domains associated with the attempt. */
    domains: string[];
    /** A timestamp for when the attempt took place. */
    time: DateTime;
    /** A boolean where true means the attept was successful at creating the TLS certificate. */
    success: boolean;
    /** If success is false this property will be populated with an error. */
    error?: string;
};
export type Certificate = {
    id: Id;
    hub_id: HubId;
    /** A list of domains associated with the certificate. */
    domains: string[];
    /** A collection of timestamps for each event in the certificate's lifetime. */
    events: {
        /** [unused] */
        created?: DateTime;
        /** [unused] */
        updated?: DateTime;
        /** [unused] */
        deleted?: DateTime;
        /** When the certificate was generated; useful for calculating expiration (+90 days) */
        generated?: DateTime;
    };
    /** The certificate bundle */
    bundle: string;
    /** The private key for the certificate */
    private_key: string;
};
export type MonitoringTierDetails = {
    /** Whether or not this tier is a selectable monitoring tier for an environment. A disabled tier may be either one coming in the future, or a legacy tier that is no longer available, but saved for historical reasons. */
    enabled: boolean;
    /** Details on how metrics are handled for this tier. */
    metrics: {
        service_granularity: Duration;
        container_telemetry_granularity: Duration;
        retention_period: Duration;
        downsample_period: Duration;
        /** Whether or not custom user-submitted metrics are supported on this tier. */
        custom: boolean;
    };
    /** Details on how events are handled for this tier. */
    events: {
        retention_period: Duration;
        /** Whether or not custom user-submitted events are supported on this tier. */
        custom: boolean;
    };
    /** Details on how logs are handled for this tier. */
    logs: {
        /** Whether or not log analysis is enabled on this tier. */
        analysis: boolean;
        /** Whether or not log aggregation is enabled on this tier. */
        aggregation: boolean;
        /** Whether or not custom user-submitted logs are supported on this tier. */
        custom: boolean;
    };
    features: {
        public_ping_monitor: boolean;
    };
    /** The monthly cost (in mills) of enabling this tier on an environment. */
    cost_mills: number;
};
export type EnvironmentStartAction = {
    /** The name of the action to perform. */
    action: "start";
};
export type EnvironmentStopAction = {
    /** The name of the action to perform. */
    action: "stop";
};
export type EnvironmentInitializeAction = {
    /** The name of the action to perform. */
    action: "initialize";
};
export type EnvironmentReconfigureDeploymentsAction = {
    /** The action to take. */
    action: "deployments.reconfigure";
    contents: {
        tags: EnvironmentDeploymentTags;
    };
};
export type EnvironmentServiceContainerSummary = {
    /** Whether or not the service is enabled. */
    enable: boolean;
    container_id?: Id | null;
    state: ContainerState;
    /** Whether or not the service is deployed in high availability */
    high_availability: boolean;
    /** Whether or not the service will be automatically updated as new versions are released. Otherwise, the service will need to be restarted manually. */
    auto_update?: boolean;
};
export type EnvironmentSummary = {
    id: Id;
    state: EnvironmentState;
    /** An object containing information about the service containers associated with this container. Each key is the name of a service, one of `discovery`, `vpn`, or `loadbalancer`. */
    services: {
        loadbalancer?: EnvironmentServiceContainerSummary | null;
        discovery?: EnvironmentServiceContainerSummary | null;
        vpn?: EnvironmentServiceContainerSummary | null;
        scheduler?: EnvironmentServiceContainerSummary | null;
    };
    /** Statistics about the containers and instances associated with this environment. */
    stats: {
        containers: ResourceCountSummary;
        instances: ResourceCountSummary;
    };
};
export type ScopedVariableScope = {
    /** Information about the assignment of the scoped variable to different containers in the environment. */
    containers: {
        /** A boolean where true represents the scoped variables is globally assigned to all current and future containers in the environment. */
        global: boolean;
        /** An array of container IDs, where each container identified will have access to the scoped variable. */
        ids: string[];
        /** An array of container identifiers, where each container identfied will have access to the scoped variable. */
        identifiers: string[];
    };
};
export type ScopedVariableAccess = {
    /** When set to true, this scoped variable is set as an environment variable inside the container. */
    env_variable?: {
        /** The name of the environment variable set on the target container. */
        key: string;
    };
    /** If set, this scoped variable will be available over the internal API. Contains settings for accessing this variable over the internal API. */
    internal_api?: {
        /** Duration is a time string that the internal API will serve that variable after runtime starts. */
        duration?: Duration | null;
    };
    /** File is an object that describes a path to mount the file to inside the container. */
    file?: {
        /** When true, Cycle will interpret this variable as a base-64 encoded string, and decode it before passing it into the container. */
        decode: boolean;
        /** The path to mount the file to inside the container. */
        path: string | null;
    };
};
export type UrlScopedVariableSource = {
    /** The type of source value, can be either `raw` or `url`. */
    type: "url";
    details: {
        /** The URL to call to produce the value. */
        url: string;
        /** Additional headers that can be attached to the URL request. Useful for adding metadata to third-party services. */
        headers: {
            [key: string]: any;
        };
        /** A URL that can be provided to authenticate with a third party secret service. Cycle will make a request to this URL before fetching the secret URL, and use the response as the value of an Authorization header when requesting the secret. */
        auth_token_url: string | null;
    };
};
export type RawScopedVariableSource = {
    /** The type of source value, can be either `raw` or `url`. */
    type: "raw";
    details: {
        /** The value of the variable. */
        value: string;
        /** A boolean where true represents the text the user is entering will be multi line. */
        blob: boolean;
        secret?: {
            /** A string describing the IV Hex associated with the encryption of the variable */
            iv?: string;
            /** A user specified hint that will suggest what the encryption key might be */
            hint?: string | null;
        };
    };
};
export type ScopedVariableState = {
    /** The current state of the scoped variable. */
    current: "live" | "deleting" | "deleted";
} & State;
export type ScopedVariable = {
    id: Id;
    creator: CreatorScope;
    hub_id: HubId;
    /** An identifier used to reference the environment this resource is scoped to. */
    environment_id: string;
    identifier: Identifier;
    scope: ScopedVariableScope;
    access: ScopedVariableAccess;
    source:
        | null
        | (
              | ({
                    type: "url";
                } & UrlScopedVariableSource)
              | ({
                    type: "raw";
                } & RawScopedVariableSource)
          );
    state: ScopedVariableState;
    /** A collection of timestamps for each event in the Scoped Variable's lifetime. */
    events: {
        /** The timestamp of when the scoped variable was created. */
        created: DateTime;
        /** The timestamp of when the scoped variable was updated. */
        updated: DateTime;
        /** The timestamp of when the scoped variable was deleted. */
        deleted: DateTime;
    };
};
export type Range = {
    start: DateTime;
    end: DateTime;
};
export type LoadBalancerTelemetryControllerMetrics = {
    disconnects: {
        no_error: number;
        request_invalid: number;
        timeout_idle: number;
        router_none: number;
        router_nomatch: number;
        destination_unavailable: number;
        unknown_error: number;
    };
    connections: number;
    requests: number;
    bytes_transmitted: number;
    bytes_received: number;
};
export type LoadBalancerTelemetryReport = {
    created: DateTime;
    range: Range;
    snapshots: {
        time: DateTime;
        controller: Identifier;
        metrics: LoadBalancerTelemetryControllerMetrics;
    }[];
};
export type LoadBalancerTelemetryRouterMetrics = {
    destinations: {
        [key: string]: {
            connections: {
                success: number;
                unavailable: number;
                errors?: {
                    [key: string]: number;
                };
                bytes_transmitted?: number;
                bytes_received?: number;
            };
            requests: {
                total: number;
                responses?: {
                    [key: string]: number;
                };
                errors?: {
                    [key: string]: number;
                };
            };
            latency_ms?: number[];
            instance_id: string;
            container_id: string;
        };
    };
};
export type LoadBalancerTelemetryUrlRequestHandler = {
    /** The number of hits to a specific URL handler. */
    hits: number;
    /** The cumulative ms of response time across all hits. */
    timing_ms: number;
};
export type LoadBalancerTelemetryUrlMetrics = {
    destinations: {
        [key: string]: {
            requests: {
                /** The host name of the URL request. */
                host: string;
                /** The HTTP method of the URL request. */
                method: string;
                /** The path portion of the inbound URL request. */
                path: string;
                /** The number of requests to a this URL. */
                total: number;
                /** The date of the most recent hit to the URL. */
                last_hit: DateTime;
                /** An object describing the relative breakdown of proxy, cache, forward, and redirect URL responses. */
                handlers: {
                    /** Object containing hit count and timing data for proxy responses. */
                    proxy: LoadBalancerTelemetryUrlRequestHandler;
                    /** Object containing hit count and timing data for cache responses. */
                    cache: LoadBalancerTelemetryUrlRequestHandler;
                    /** Object containing hit count and timing data for forward responses. */
                    forward: LoadBalancerTelemetryUrlRequestHandler;
                    /** Object containing hit count and timing data for redirect responses. */
                    redirect: LoadBalancerTelemetryUrlRequestHandler;
                };
                /** An object where the key is the response type and the value is the number of hits with that response. */
                responses?: {
                    [key: string]: number;
                };
                /** An object where the key is the error type and the value is the number of hits with that error. */
                errors?: {
                    [key: string]: number;
                };
            };
        };
    };
};
export type LoadBalancerTelemetrySnapshot = {
    time: DateTime;
    started: DateTime;
    version?: string;
    dataset_id: number;
    current: {
        router?: LoadBalancerTelemetryRouterMetrics | null;
        urls?: LoadBalancerTelemetryUrlMetrics | null;
        metrics: LoadBalancerTelemetryControllerMetrics;
    };
    cumulative: {
        router?: LoadBalancerTelemetryRouterMetrics | null;
        urls?: LoadBalancerTelemetryUrlMetrics | null;
        metrics: LoadBalancerTelemetryControllerMetrics;
    };
};
export type LoadBalancerLatestTelemetryInstance = {
    id: Id;
    hub_id: HubId;
    environment_id: Id;
    container_id: Id;
    instance_id: Id;
    server_id: Id;
    cluster: Identifier;
    time: DateTime;
    controller: Identifier;
    latest?: LoadBalancerTelemetrySnapshot | null;
    snapshots?: LoadBalancerTelemetrySnapshot[];
};
export type LoadBalancerLatestTelemetryController = {
    time: DateTime;
    controller: Identifier;
    instances?: LoadBalancerLatestTelemetryInstance[];
};
export type LoadBalancerLatestTelemetry = {
    created: DateTime;
    controllers?: LoadBalancerLatestTelemetryController[];
};
export type LoadBalancerLatestControllers = {
    created: DateTime;
    controllers?: LoadBalancerLatestTelemetryController[];
};
export type ImageSourceType = "stack-build" | "direct" | "bucket";
export type ImageSourceAbout = {
    /** Some information about the image source resource. */
    description?: string | null;
};
export type ImageSourceState = {
    /** The current state of the image source. */
    current: "live" | "deleting" | "deleted";
} & State;
export type ImageSourceMeta = {
    images_count?: {
        /** Number of image sources */
        total: number;
        state: {
            /** Number of images in this source with state new */
            new: number;
            /** Number of images in this source with state downloading */
            downloading: number;
            /** Number of images in this source with state building */
            building: number;
            /** Number of images in this source with state verifying */
            verifying: number;
            /** Number of images in this source with state saving */
            saving: number;
            /** Number of images in this source with state live */
            live: number;
            /** Number of images in this source with state deleting */
            deleting: number;
        };
    };
};
export type ImageSource = {
    id: Id;
    /** A human readable slugged identifier for this image source. */
    identifier: Identifier;
    type: ImageSourceType;
    hub_id: HubId;
    /** A name for the image source resource. */
    name: string;
    about?: ImageSourceAbout;
    origin: ImageOrigin;
    /** Configuration options regarding the builder used to create/import Images using this Image Source. */
    builder?: {
        /** The ID or Identifier of the Integration used to do the build. */
        integration_id?: HybridIdentifier | null;
    };
    creator: CreatorScope;
    acl?: Acl | null;
    state: ImageSourceState;
    /** A collection of timestamps for each event in the image source's lifetime. */
    events: {
        /** The timestamp of when the image source was created. */
        created: DateTime;
        /** The timestamp of when the image source was updated. */
        updated: DateTime;
        /** The timestamp of when the image source was deleted. */
        deleted: DateTime;
    };
    meta?: ImageSourceMeta;
};
export type ImageSourceIncludes = {
    [key: string]: ImageSource;
};
export type LoadBalancerLatestControllersIncludes = {
    creators?: CreatorInclude;
    stack_builds?: StackBuildInclude;
    stacks?: StackIncludes;
    sources?: ImageSourceIncludes;
};
export type VpnInfo = {
    /** A url associated with the VPN service. */
    url: string;
    service: VpnEnvironmentService | null;
};
export type VpnLogin = {
    id: Id;
    /** The username used when logging in. */
    username: string;
    /** The ID of the environment the VPN service is in. */
    environment_id: string;
    /** The IP address of the machine the user has logged in from. */
    ip: string;
    /** The timestamp for when the login occured. */
    time: DateTime;
    /** A boolean where true means the login attempt was successful. */
    success: boolean;
};
export type VpnUser = {
    id: Id;
    /** The username for the login. */
    username: string;
    creator: CreatorScope;
    /** A timestamp of the last time the user logged into the VPN. */
    last_login: DateTime;
    hub_id: HubId;
    /** An identifier for the environment the VPN service is associated with. */
    environment_id: string;
    /** A collection of timestamps for each event in the VPN user's lifetime. */
    events: {
        /** The timestamp of when the VPN user was created. */
        created: DateTime;
        /** The timestamp of when the VPN user was updated. */
        updated: DateTime;
        /** The timestamp of when the VPN user was deleted. */
        deleted: DateTime;
    };
};
export type VpnResetTask = {
    /** The name of the action to perform. */
    action: "reset";
};
export type VpnReconfigureTask = {
    /** The action to take. */
    action: "reconfigure";
    /** Additional information the platform needs to create this job. */
    contents: {
        /** A boolean where true means the VPN service is enabled. */
        enable?: boolean;
        /** A boolean where `true` represents the desire to run the environment vpn service in high availability mode. */
        high_availability?: boolean | null;
        /** A boolean representing if this service container is set to autoupdate or not */
        auto_update?: boolean | null;
        /** The config object for the VPN service, in this case without the required fields normally found in a VPN config object. */
        config?: {
            /** If true, routes all traffic through the VPN, even non-Cycle traffic. */
            allow_internet?: boolean;
            /** Auth configuration for the VPN. */
            auth?: {
                /** A webhook endpoint to hit. Will be passed the login credentials provided to the user, and should return a 200 status if the login is permitted. */
                webhook: string | null;
                /** If true, allows any Cycle account with access to the environment to log in to the VPN using their Cycle email and password. */
                cycle_accounts: boolean;
                /** If true, allows the custom VPN accounts to log in to the VPN. */
                vpn_accounts?: boolean;
            };
        };
    };
};
export type HubUsageDatum = {
    time: string;
    servers: number;
    members: number;
    environments: number;
    containers: number;
};
export type ActivityContext = {
    /** An ID for the given resource. */
    environment_id?: string;
    /** An ID for the given resource. */
    container_id?: string;
    /** The identifier of a specific cluster. */
    cluster?: string;
    /** An ID for the given resource. */
    instance_id?: string;
    /** An ID for the given resource. */
    server_id?: string;
    /** An ID for the given resource. */
    stack_id?: string;
    /** An ID for the given resource. */
    dns_zone_id?: string;
};
export type ActivitySession = {
    /** URL endpoint assocaited with the activity context - does not include domain. */
    url: string;
    /** The IP of the account associated with the session. */
    ip: string;
    token: {
        application_id: Id;
        application_capabilities_version: number;
    };
    /** The API key ID. */
    api_key: string | null;
};
export type ActivityDetail = {
    id?: Id;
    /** A number describing the amount of the resource that exists. */
    number?: number;
    /** Additional information. */
    string?: string;
};
export type ActivityChange = {
    /** A description of the resource that was changed. */
    component: string;
    before?: ActivityDetail;
    after?: ActivityDetail;
};
export type ActivitySecurity = {
    /** A risk level assessed by the platform. Depending on the nature of the incident, this may change even if the event type is the same.
     */
    risk: "info" | "low" | "medium" | "high" | "critical";
    /** From where the platform has determined this security event originated from. */
    surface: "network" | "service" | "fs" | "api";
    /** How the platform has handled this security event. */
    event: "suggestion" | "notice" | "prevention" | "detection" | "reaction";
    /** The type of attack the platform has determined has occurred. */
    attack:
        | "none"
        | "auth-failure"
        | "brute-force"
        | "exploit-vulnerability"
        | "social-engineer"
        | "service-interruption"
        | "access-elevation";
};
export type ActivityMonitor = {
    /** The severity of the event.
     */
    level: "info" | "low" | "medium" | "high" | "critical";
    /** How the platform has handled this monitor event. */
    event: "suggestion" | "notice" | "prevention" | "detection" | "reaction";
    /** The current state of the monitored resource */
    state: "none" | "unknown" | "unreachable" | "flux" | "recovered";
};
export type Activity = {
    id: Id;
    hub_id: HubId;
    /** Userscope */
    user: {
        /** The type of user. */
        type:
            | "account"
            | "environment"
            | "platform"
            | "platform-pipeline"
            | "employee"
            | "api-key"
            | "visitor";
        /** The given user's ID. */
        id: string;
    };
    /** A number representing the detail level (verbosity) of this activity.
    
    ## Levels
    - 0: activity that other users would find useful
    - 1: activity that can be useful in tracking down how a user did something
    - 2: full activity, can be useful in debugging problems
     */
    verbosity: number;
    context: ActivityContext;
    session: ActivitySession | null;
    /** An array of changes. */
    changes: ActivityChange[];
    /** A record of additional annotations for the activity. */
    annotations: {
        [key: string]: any;
    };
    /** An object describing a given activity error. */
    error: {
        /** The error message. */
        message: string;
    };
    /** An object holding information about a component. */
    component: {
        id: Id;
        /** The type of component. */
        type: string;
    };
    /** An array of tags that denote things such as a type of security event, or can be user-provided. */
    tags?: string[];
    /** A status for the given activity. */
    status: "info" | "warn" | "request" | "success" | "error" | "alert";
    security?: ActivitySecurity;
    monitor?: ActivityMonitor;
    /** The activity event. */
    event:
        | "hub.images.prune"
        | "hub.update"
        | "hub.create"
        | "hub.task.delete"
        | "hub.task.images.prune"
        | "environment.services.discovery.reconfigure"
        | "environment.services.lb.reconfigure"
        | "environment.services.vpn.reconfigure"
        | "environment.services.scheduler.reconfigure"
        | "environment.delete"
        | "environment.initialize"
        | "environment.start"
        | "environment.stop"
        | "environment.create"
        | "environment.update"
        | "environment.task.delete"
        | "environment.services.discovery.task.reconfigure"
        | "environment.services.lb.task.reconfigure"
        | "environment.services.vpn.task.reconfigure"
        | "environment.services.scheduler.task.reconfigure"
        | "environment.services.vpn.user.create"
        | "environment.services.vpn.login"
        | "environment.services.vpn.reset"
        | "environment.services.vpn.task.reset"
        | "environment.task.initialize"
        | "environment.task.start"
        | "environment.task.stop"
        | "environment.task.deployments.reconfigure"
        | "environment.deployments.reconfigure"
        | "environment.deployments.prune"
        | "environment.deployment.start"
        | "environment.deployment.stop"
        | "environment.scoped-variable.delete"
        | "environment.scoped-variable.update"
        | "environment.scoped-variable.task.delete"
        | "environment.scoped-variable.create"
        | "image.delete"
        | "image.import"
        | "image.create"
        | "image.update"
        | "image.task.delete"
        | "image.task.import"
        | "image.source.delete"
        | "image.source.create"
        | "image.source.update"
        | "image.source.task.delete"
        | "billing.invoice.task.void"
        | "billing.invoice.task.credit"
        | "billing.invoice.task.refund"
        | "billing.invoice.pay"
        | "billing.invoice.task.pay"
        | "billing.order.confirm"
        | "billing.order.task.confirm"
        | "billing.method.update"
        | "billing.method.delete"
        | "billing.method.task.delete"
        | "billing.method.create"
        | "hub.apikey.update"
        | "hub.apikey.delete"
        | "hub.apikey.create"
        | "hub.role.update"
        | "hub.role.delete"
        | "hub.role.create"
        | "hub.role.task.delete"
        | "hub.membership.delete"
        | "hub.membership.create"
        | "hub.membership.update"
        | "hub.integration.create"
        | "hub.integration.update"
        | "hub.integration.delete"
        | "hub.inactive"
        | "container.initialize"
        | "container.task.start"
        | "container.start"
        | "container.task.stop"
        | "container.stop"
        | "container.task.reconfigure"
        | "container.reconfigure"
        | "container.task.volumes.reconfigure"
        | "container.function.trigger"
        | "container.function.task.trigger"
        | "container.volumes.reconfigure"
        | "container.create"
        | "container.restart"
        | "container.task.reimage"
        | "container.reimage"
        | "container.update"
        | "container.task.delete"
        | "container.delete"
        | "container.task.scale"
        | "container.scale"
        | "container.instances.create"
        | "container.instances.delete"
        | "container.instances.autoscale.up"
        | "container.instances.autoscale.down"
        | "container.instance.healthcheck.restarted"
        | "container.instance.volume.extend"
        | "container.instance.task.volume.extend"
        | "container.instance.healthcheck.failed"
        | "container.instance.error"
        | "container.instance.ssh.login"
        | "container.instance.migration.start"
        | "container.instance.migration.revert"
        | "container.instance.delete"
        | "container.instance.task.migration.revert"
        | "container.instance.task.migration.start"
        | "container.backup.create"
        | "container.backup.restore"
        | "container.backup.delete"
        | "container.backup.task.delete"
        | "container.backup.task.restore"
        | "dns.zone.verify"
        | "dns.zone.delete"
        | "dns.zone.task.verify"
        | "dns.zone.update"
        | "dns.zone.task.delete"
        | "dns.zone.create"
        | "dns.zone.record.delete"
        | "dns.zone.record.cert.generate"
        | "dns.zone.record.cert.generate.auto"
        | "dns.zone.record.task.cert.generate"
        | "dns.zone.record.update"
        | "dns.zone.record.task.delete"
        | "dns.zone.record.create"
        | "stack.update"
        | "stack.task.delete"
        | "stack.delete"
        | "stack.create"
        | "stack.task.prune"
        | "stack.prune"
        | "stack.build.create"
        | "stack.build.generate"
        | "stack.build.deploy"
        | "stack.build.delete"
        | "stack.build.task.delete"
        | "stack.build.task.generate"
        | "stack.build.task.deploy"
        | "infrastructure.provider.update"
        | "infrastructure.provider.task.delete"
        | "infrastructure.provider.create"
        | "infrastructure.provider.task.verify"
        | "infrastructure.server.task.delete"
        | "infrastructure.server.task.restart"
        | "infrastructure.server.services.sftp.auth"
        | "infrastructure.server.live"
        | "infrastructure.server.delete"
        | "infrastructure.server.restart"
        | "infrastructure.server.compute.restart"
        | "infrastructure.server.compute.spawner.restart"
        | "infrastructure.server.features.reconfigure"
        | "infrastructure.server.sharedfs.reconfigure"
        | "infrastructure.server.provision"
        | "infrastructure.server.console"
        | "infrastructure.server.update"
        | "infrastructure.server.task.provision"
        | "infrastructure.server.ssh.token"
        | "infrastructure.server.task.features.reconfigure"
        | "infrastructure.server.task.sharedfs.reconfigure"
        | "infrastructure.server.services.sftp.lockdown"
        | "infrastructure.server.services.internal-api.throttle"
        | "infrastructure.server.evacuation.start"
        | "infrastructure.server.task.evacuation.start"
        | "infrastructure.server.evacuation.reset"
        | "infrastructure.server.task.evacuation.reset"
        | "infrastructure.autoscale.group.create"
        | "infrastructure.autoscale.group.update"
        | "infrastructure.autoscale.group.task.delete"
        | "infrastructure.autoscale.group.delete"
        | "infrastructure.cluster.create"
        | "infrastructure.cluster.update"
        | "infrastructure.cluster.delete"
        | "infrastructure.ips.pool.task.delete"
        | "sdn.network.update"
        | "sdn.network.task.delete"
        | "sdn.network.create"
        | "sdn.network.task.reconfigure"
        | "pipeline.delete"
        | "pipeline.trigger"
        | "pipeline.update"
        | "pipeline.task.delete"
        | "pipeline.create"
        | "pipeline.task.trigger"
        | "pipeline.run.completed"
        | "pipeline.key.update"
        | "pipeline.key.delete"
        | "pipeline.key.create";
    /** A timestamp for when the activity took place. */
    time: DateTime;
};
export type IpPoolProvider = {
    /** A vendor for a provider. */
    vendor: string;
    /** ID of the provider integration used to provision the IP. */
    integration_id: Id;
    /** Information about the location of the provider this pool is associated with. */
    location: string;
    /** A reservation identifier associated with the pool reservation. */
    reservation: string;
    /** A server identifier associated with the pool. */
    server: string;
    /** An identifier linked to the server assingment of the IP pool. */
    server_assignment: string;
};
export type PoolState = {
    /** The current state of the pool. */
    current: "live" | "releasing" | "released";
} & State;
export type InfrastructureIpPool = {
    id: Id;
    hub_id: HubId;
    /** An ID associated with a server resource. */
    server_id: string;
    /** An ID associated with a location resource. */
    location_id: string;
    /** The type of IP pool. */
    kind: "ipv4" | "ipv6";
    provider: IpPoolProvider;
    /** A boolean where true represents the pool as a floating IP pool. */
    floating: boolean;
    /** Data about IPs in the pool. */
    ips: {
        /** The total number of IPs in the pool. */
        total: number;
        /** Of the total number of IP's how many are available to be assigned. */
        available: number;
    };
    /** Network information about the pool. */
    block: {
        /** A cidr for the pool. */
        cidr: string;
        /** A gateway for the pool. */
        gateway: string;
        /** A netmask for the pool. */
        netmask: string;
        /** A network for the pool. */
        network: string;
    };
    state: PoolState;
};
export type ApiKeyState = {
    /** The current state of the API key. */
    current:
        | "new"
        | "configuring"
        | "live"
        | "inactive"
        | "deleting"
        | "deleted";
} & State;
export type ApiKey = {
    id: Id;
    hub_id: HubId;
    /** A name for the API key. */
    name: string;
    creator: CreatorScope;
    /** The API key secret. */
    secret: string;
    role_id: Id;
    /** An array of IP's this API key can make calls from. */
    ips: string[];
    state: ApiKeyState;
    /** A collection of timestamps for each event in the API key's lifetime. */
    events: {
        /** The timestamp of when the API key was created. */
        created: DateTime;
        /** The timestamp of when the API key was updated. */
        updated: DateTime;
        /** The timestamp of when the API key was deleted. */
        deleted: DateTime;
    };
};
export type NetworkState = {
    /** The current state of the network. */
    current: "live" | "deleting" | "deleted";
} & State;
export type NetworkPrivacySettings = {
    /** A tag used to ensure proper routing. */
    vxlan_tag: number;
    /** The subnet of the private network. */
    subnet: string;
    /** A number used to ensure a MAC address exists for each network. */
    mac_addr_suffix: number;
    /** IPv6 infromation for the network. */
    ipv6: {
        /** The networks IPv6 base. */
        ip: string;
        /** The private network CIDR. */
        cidr: string;
    };
};
export type Network = {
    id: Id;
    /** The name of the network. */
    name: string;
    /** A network identifier used to construct http calls that specifically use this network over another. */
    identifier: string;
    /** The infrastructure cluster the environments belonging to this network belong to. */
    cluster: string;
    creator: CreatorScope;
    hub_id: HubId;
    state: NetworkState;
    private_network?: NetworkPrivacySettings | null;
    /** An array of environments and timestamps. */
    environments?: {
        id: Id;
        /** A timestamp of when the environment was added. */
        added: DateTime;
    }[];
    acl?: Acl | null;
    /** A collection of timestamps for each event in the network's lifetime. */
    events: {
        /** The timestamp of when the image was created. */
        created: DateTime;
        /** The timestamp of when the image was updated. */
        updated: DateTime;
        /** The timestamp of when the image was deleted. */
        deleted: DateTime;
    };
};
export type FluidIdentifier = string;
export type ImageCreateStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "image.create";
    details: {
        name?: string | null;
        source: FluidIdentifier;
        /** Optional build-time options for when this image is built on pipeline run. */
        build?: {
            /** Build args passed into the container image build process during pipeline run. */
            args?: {
                [key: string]: string;
            };
        };
        /** An override object to be used for a single image create request. */
        override?: {
            /** For image sources with `docker-hub` or `docker-registry` origin types. A target to be used for overridding the default target - should include an image and a tag. */
            target?: string | null;
            /** For image sources with `docker-file` origin types. A URL pointing to a .tar.gz file of a repo with a Dockerfile in it - can be used instead of linking Cycle directly to a repository. */
            targz_url?: string | null;
        };
    };
};
export type ImageImportStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "image.import";
    details: {
        image: FluidIdentifier;
    };
};
export type ImagePruneStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "images.prune";
    details: {
        source_ids: Id[];
    };
};
export type ImageCreateImportStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "image.create-import";
    details: {
        name?: string | null;
        source: FluidIdentifier;
        /** Optional build-time options for when this image is built on pipeline run. */
        build?: {
            /** Build args passed into the container image build process during pipeline run. */
            args?: {
                [key: string]: string;
            };
        };
        /** An override object to be used for a single image create request. */
        override?: {
            /** For image sources with `docker-hub` or `docker-registry` origin types. A target to be used for overridding the default target - should include an image and a tag. */
            target?: string | null;
            /** For image sources with `docker-file` origin types. A URL pointing to a .tar.gz file of a repo with a Dockerfile in it - can be used instead of linking Cycle directly to a repository. */
            targz_url?: string | null;
        };
    };
};
export type ContainerCreateStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.create";
    details: {
        name: string;
        identifier?: string | null;
        environment: FluidIdentifier;
        image: FluidIdentifier;
        stateful: boolean;
        /** Additional information about a container */
        annotations?: {
            [key: string]: any;
        };
        config: ContainerConfig;
        volumes: ContainerVolume[];
        deployment?: Deployment | null;
    };
};
export type ContainerStartStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.start";
    details: {
        container: FluidIdentifier;
    };
};
export type ContainerStopStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.stop";
    details: {
        container: FluidIdentifier;
    };
};
export type ContainerDeleteStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.delete";
    details: {
        container: FluidIdentifier;
    };
};
export type ContainerReimageStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.reimage";
    details: {
        container: FluidIdentifier;
        image: FluidIdentifier;
        options?: {
            /** A boolean where true signifies the image being used to reimage does not have to have compatibility with the image being replaced. */
            allow_incompatible: boolean;
            /** A boolean where true signifies the user wishes to overwrite the current runtime configuration for the given container during the reimage process. */
            overwrite_runtime_config: boolean;
        };
    };
};
export type ContainerRestartStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.restart";
    details: {
        container: FluidIdentifier;
    };
};
export type VariableString = string;
export type ContainerFunctionTriggerStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "container.function.trigger";
    details: {
        /** The container to trigger. */
        container: FluidIdentifier;
        /** A custom token used for identifying and managing a claim. Can be any valid string. */
        token?: VariableString | null;
        runtime_variables: FunctionRuntimeVariables;
    };
};
export type EnvironmentCreateStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.create";
    details: {
        name: string;
        about?: EnvironmentAbout | null;
        cluster: string;
        features: EnvironmentFeatures;
    };
};
export type EnvironmentStartStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.start";
    details: {
        environment: FluidIdentifier;
    };
};
export type EnvironmentStopStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.stop";
    details: {
        environment: FluidIdentifier;
    };
};
export type EnvironmentDeleteStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.delete";
    details: {
        environment: FluidIdentifier;
    };
};
export type EnvironmentDeploymentStartStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.deployment.start";
    details: {
        environment: FluidIdentifier;
        tag?: string | null;
        version?: string | null;
    };
};
export type EnvironmentDeploymentStopStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.deployment.stop";
    details: {
        environment: FluidIdentifier;
        tag?: string | null;
        version?: string | null;
    };
};
export type EnvironmentDeploymentsTagStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.deployments.tag";
    details: {
        environment: FluidIdentifier;
        tag: Identifier;
        /** The selector for which deployment to apply the new tag to. */
        deployment:
            | {
                  /** Targets the deployment with this exact version. */
                  version?: Version;
              }
            | {
                  /** Targets the deployment with this exact tag applied to it. */
                  tag?: string;
              };
    };
};
export type EnvironmentDeploymentsPruneStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.deployments.prune";
    details: {
        environment: FluidIdentifier;
    };
};
export type EnvironmentDeploymentHealthyWatchStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "environment.deployment.healthy.watch";
    details: {
        environment: FluidIdentifier;
        tag?: string | null;
        version?: string | null;
        /** The maximum amount of time to wait for the deployment to become healthy before failing this step. */
        max_wait?: Duration | null;
    };
};
export type StackBuildCreateStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "stack.build.create";
    details: {
        stack: FluidIdentifier;
        /** Additional instructions used when generating this stack build. */
        instructions?: {
            /** Git information specifics. */
            git?: {
                /** The type of information the user is passing. */
                type: "branch" | "hash" | "tag";
                /** The actual value to be passed. */
                value: string;
            };
            /** Custom variables applied to the stack during build. Any place in the stack where a `{{variable}}` is used is replaced with the value of the variable supplied in this map. */
            variables?: {
                [key: string]: string;
            };
        };
        /** Information about the stack build. */
        about?: {
            /** A user defined version of the build. */
            version: string;
            /** A user defined description for the build. */
            description: string;
        };
    };
};
export type StackBuildGenerateStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "stack.build.generate";
    details: {
        build: FluidIdentifier;
    };
};
export type StackDeployContainersObject = {
    /** A boolean where true represents the container is to be reimaged. */
    reimage: boolean;
    /** A boolean where true represents the container is to be reconfigured. */
    reconfigure: boolean;
};
export type StackBuildDeploymentUpdates = {
    /** A map of the container names to update within the environment. */
    containers: {
        [key: string]: StackDeployContainersObject;
    };
    /** An object that describes configuration options for scoped variables on stack build. If null, scoped variables will be ignored during deployment events. */
    scoped_variables: {
        /** If set to true, any scoped variables that are new to the environment will be created when deployed. */
        add_new: boolean;
        /** When deploying to the environment, any scoped variables defined in the build that match an existing scoped variable in the environment will cause the existing scoped variable to be updated to the new value. */
        replace_existing: boolean;
    };
};
export type StackBuildDeployStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "stack.build.deploy";
    details: {
        build: FluidIdentifier;
        environment: FluidIdentifier;
        update?: StackBuildDeploymentUpdates | null;
        deployment?: Deployment | null;
    };
};
export type StackPruneStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "stack.prune";
    details: {
        stack: FluidIdentifier;
        criteria?: {
            expire?: Duration | null;
        };
    };
};
export type SleepStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "sleep";
    details: {
        /** Total duration to run this step for, before moving on to the next step. */
        duration?: Duration;
    };
};
export type WebhookStepOptions = {
    max_attempts?: number | null;
    /** How long to wait between retries on wait. */
    interval?: Duration | null;
    fail_on?: {
        /** If true, will fail on any codes NOT defined in the http_codes array. */
        not: boolean;
        /** A list of http response codes that will trigger a failure. */
        http_codes?: number[];
    };
    retry_on?: {
        /** If true, will retry on any codes NOT defined in the http_codes array. */
        not: boolean;
        /** A list of http response codes that will trigger a retry. */
        http_codes?: number[];
    };
};
export type WebhookPostStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "webhook.post";
    details: {
        /** The url to submit a POST request to. */
        url: string;
        /** An optional map of headers to send with the request. */
        headers?: {
            [key: string]: string;
        };
        options?: WebhookStepOptions | null;
        /** An optional POST body to send with the request. Cannot be used with `from``. */
        body?: string | null;
        /** Takes the output from a previous step and uses it as the body for the POST request. Cannot be used with `body`. String format is <stageIdentifier/stepIdentifier> */
        from?: string | null;
    };
};
export type WebhookGetStep = {
    /** An identifier for the step. */
    identifier?: string;
    options?: {
        skip?: boolean;
    };
    /** The action that the step takes. */
    action: "webhook.get";
    details: {
        /** The url to submit a POST request to. */
        url: string;
        /** An optional map of headers to send with the request. */
        headers?: {
            [key: string]: string;
        };
        options?: WebhookStepOptions | null;
    };
};
export type PipelineStep =
    | ({
          action: "image.create";
      } & ImageCreateStep)
    | ({
          action: "image.import";
      } & ImageImportStep)
    | ({
          action: "images.prune";
      } & ImagePruneStep)
    | ({
          action: "image.create-import";
      } & ImageCreateImportStep)
    | ({
          action: "container.create";
      } & ContainerCreateStep)
    | ({
          action: "container.start";
      } & ContainerStartStep)
    | ({
          action: "container.stop";
      } & ContainerStopStep)
    | ({
          action: "container.delete";
      } & ContainerDeleteStep)
    | ({
          action: "container.reimage";
      } & ContainerReimageStep)
    | ({
          action: "container.restart";
      } & ContainerRestartStep)
    | ({
          action: "container.function.trigger";
      } & ContainerFunctionTriggerStep)
    | ({
          action: "environment.create";
      } & EnvironmentCreateStep)
    | ({
          action: "environment.start";
      } & EnvironmentStartStep)
    | ({
          action: "environment.stop";
      } & EnvironmentStopStep)
    | ({
          action: "environment.delete";
      } & EnvironmentDeleteStep)
    | ({
          action: "environment.deployment.start";
      } & EnvironmentDeploymentStartStep)
    | ({
          action: "environment.deployment.stop";
      } & EnvironmentDeploymentStopStep)
    | ({
          action: "environment.deployments.tag";
      } & EnvironmentDeploymentsTagStep)
    | ({
          action: "environment.deployments.prune";
      } & EnvironmentDeploymentsPruneStep)
    | ({
          action: "environment.deployment.healthy.watch";
      } & EnvironmentDeploymentHealthyWatchStep)
    | ({
          action: "stack.build.create";
      } & StackBuildCreateStep)
    | ({
          action: "stack.build.generate";
      } & StackBuildGenerateStep)
    | ({
          action: "stack.build.deploy";
      } & StackBuildDeployStep)
    | ({
          action: "stack.prune";
      } & StackPruneStep)
    | ({
          action: "sleep";
      } & SleepStep)
    | ({
          action: "webhook.post";
      } & WebhookPostStep)
    | ({
          action: "webhook.get";
      } & WebhookGetStep);
export type PipelineStage = {
    /** A stage identifier. */
    identifier: string;
    /** An array of steps for the stage. */
    steps: PipelineStep[];
    /** Additonal options for the stage. */
    options?: {
        /** A boolean where true indicates the given stage should be skipped when the pipeline is run. */
        skip?: boolean;
    };
};
export type PipelineState = {
    /** The current state of the pipeline. */
    current: "live" | "acquiring" | "deleting" | "deleted";
} & State;
export type Pipeline = {
    id: Id;
    identifier?: Identifier;
    /** A name for the pipeline. */
    name: string;
    creator: CreatorScope;
    hub_id: HubId;
    /** A boolean where true signifies the pipeline is disabled. */
    disable: boolean;
    /** Setting to true enables variable and other advanced logic support on this pipeline. This is a one-way toggle. Once set to true, it cannot be set back to false. */
    dynamic: boolean;
    /** An array of stages. */
    stages?: PipelineStage[];
    acl?: Acl | null;
    /** A collection of timestamps for each event in the pipeline's lifetime. */
    events: {
        /** The timestamp of when the pipeline was created. */
        created: DateTime;
        /** The timestamp of when the pipeline was updated. */
        updated: DateTime;
        /** The timestamp of when the pipeline was deleted. */
        deleted: DateTime;
        /** The timestamp of when the pipeline was last run. */
        last_run: DateTime;
    };
    state: PipelineState;
};
export type TriggerKeyState = {
    /** The current state of the trigger key. */
    current: "live" | "deleting" | "deleted";
} & State;
export type TriggerKey = {
    id: Id;
    /** The name of the trigger key. */
    name: string;
    /** The secret used when calling the trigger key programmatically. */
    secret: string;
    creator: CreatorScope;
    hub_id: HubId;
    /** The ID for the pipeline related to this trigger key. */
    pipeline_id: string;
    state: TriggerKeyState;
    /** A collection of timestamps for each event in the trigger key's lifetime. */
    events: {
        /** The timestamp of when the trigger key was created. */
        created: DateTime;
        /** The timestamp of when the trigger key was updated. */
        updated: DateTime;
        /** The timestamp of when the trigger key was deleted. */
        deleted: DateTime;
    };
    /** An array of ips this trigger key is usable from. */
    ips: string[];
};
export type AutoScaleGroupScale = {
    down?: {
        /** The minimum TTL for the server once deployed through an autoscale event. */
        min_ttl?: Duration;
        /** The amount of time between last instance deployed and when the server can begin to get deleted. */
        inactivity_period?: Duration;
        method?: "fifo" | "lifo";
    };
    up?: {
        maximum?: number;
    };
};
export type AutoScaleGroupInfrastructure = {
    models: {
        /** The integration identifier for infrastructure provider used. */
        provider: string;
        /** The ID of the integration associated with this auto-scale group infrastructure. */
        integration_id?: Id;
        model_id: string;
        priority: number;
        locations: {
            id: string;
            availability_zones: string[];
        }[];
    }[];
};
export type AutoScaleGroup = {
    id: string;
    name: string;
    identifier: string;
    creator: CreatorScope;
    hub_id: string;
    cluster: string;
    scale?: AutoScaleGroupScale;
    acl?: Acl | null;
    infrastructure: AutoScaleGroupInfrastructure;
    state: {
        /** The current state of the auto-scale group. */
        current: "new" | "live" | "deleting" | "deleted";
    } & State;
    /** A collection of timestamps for each event in the auto-scale group's lifetime. */
    events: {
        /** The timestamp of when the auto-scale group was created. */
        created: DateTime;
        /** The timestamp of when the auto-scale group was updated. */
        updated: DateTime;
        /** The timestamp of when the auto-scale group was deleted. */
        deleted: DateTime;
        /** The timestamp of when the auto-scale group was last synced. */
        last_sync: DateTime;
    };
};
export type PipelineRunEvents = {
    /** The timestamp of when the pipeline run was started. */
    started: DateTime;
    /** The timestamp of when the pipeline run was queued. */
    queued: DateTime;
    /** The timestamp of when the pipeline run was finished. */
    finished: DateTime;
};
export type ResourceType =
    | "account"
    | "image.source"
    | "image"
    | "container"
    | "container.instance"
    | "hub"
    | "hub.api_key"
    | "hub.membership"
    | "hub.role"
    | "sdn.network"
    | "dns.zone"
    | "dns.zone.record"
    | "pipeline"
    | "pipeline.run"
    | "stack"
    | "stack.build"
    | "infrastructure.provider"
    | "infrastructure.autoscale.group"
    | "infrastructure.server"
    | "infrastructure.cluster"
    | "infrastructure.ips.pool"
    | "billing.invoice"
    | "billing.method"
    | "environment"
    | "environment.scoped-variable";
export type PipelineRunStep = {
    identifier: string;
    action:
        | "environment.create"
        | "environment.start"
        | "environment.stop"
        | "environment.delete"
        | "environment.deployments.prune"
        | "environment.deployments.tag"
        | "environment.deployment.start"
        | "environment.deployment.stop"
        | "environment.deployment.healthy.watch"
        | "image.create"
        | "image.import"
        | "images.prune"
        | "image.create-import"
        | "container.create"
        | "container.reimage"
        | "container.start"
        | "container.stop"
        | "container.restart"
        | "container.delete"
        | "container.function.trigger"
        | "stack.build.create"
        | "stack.build.deploy"
        | "stack.build.generate"
        | "stack.prune"
        | "sleep"
        | "webhook.post"
        | "webhook.get";
    events: PipelineRunEvents;
    success: boolean;
    components?: {
        type: ResourceType;
        id: string;
    }[];
    /** An error, if any, that has occurred for this step. */
    error?: {
        /** Details about the error that has occurred. */
        message: string;
    };
};
export type PipelineRunState = {
    /** The current state of the pipeline run. */
    current: "new" | "queued" | "running" | "deleting" | "deleted";
} & State;
export type PipelineRun = {
    id: Id;
    creator: CreatorScope;
    hub_id: HubId;
    /** The ID for the pipeline this run belongs to. */
    pipeline_id: string;
    /** An array of pipeline stages. */
    stages: {
        /** A stage identifier. */
        identifier: string;
        steps: PipelineRunStep[];
        events: PipelineRunEvents;
    }[];
    state: PipelineRunState;
    events: PipelineRunEvents;
    /** A map of variable values used during pipeline run. */
    variables?: {
        [key: string]: string;
    };
};
export type ComponentIncludes = {
    [key: string]:
        | Container
        | Instance
        | Environment
        | Image
        | ImageSource
        | Server
        | InfrastructureIpPool
        | Integration
        | Stack
        | StackBuild
        | DnsZone
        | DnsRecord
        | ApiKey
        | Network
        | HubMembership
        | Pipeline
        | TriggerKey
        | ScopedVariable
        | Hub
        | BillingInvoice
        | BillingMethod
        | AutoScaleGroup
        | PipelineRun
        | Role
        | PublicAccount;
};
export type ActivityIncludes = {
    users?: CreatorInclude;
    components?: ComponentIncludes;
};
export type ApiKeyIncludes = {
    /** A map of roles relevant to this API key, keyed by the role ID. */
    roles?: {
        [key: string]: Role;
    };
    /** A map of roles relevant to this API key, keyed by the role ID. */
    "roles:identifiers"?: {
        [key: string]: Id;
    };
};
export type ImageIncludes2 = {
    creators?: CreatorInclude;
    stack_builds?: StackBuildInclude;
    stacks?: StackIncludes;
    "stacks:identifiers"?: IdentifierIncludes;
    sources?: ImageSourceIncludes;
    "sources:identifiers"?: IdentifierIncludes;
    integrations?: IntegrationsIncludes;
    "integrations:identifiers"?: IdentifierIncludes;
};
export type ImageBuildLog = {
    id: Id;
    hub_id: HubId;
    /** The ID of the image the build log pertains to. */
    image_id: string;
    /** The log itself. */
    log: string;
    /** A collection of timestamps for each event in the build log's lifetime. */
    events: {
        /** The timestamp of when the build log was created. */
        created: DateTime;
        /** The timestamp of when the build log was updated. */
        updated: DateTime;
        /** The timestamp of when the build log was deleted. */
        deleted: DateTime;
    };
};
export type SourceIncludes = {
    creators?: CreatorInclude;
    integrations?: IntegrationsIncludes;
    "integrations:identifiers"?: IdentifierIncludes;
};
export type InfrastructureResourceSummary = {
    /** Information about RAM Resources. */
    ram: {
        /** The total amount of RAM in MBsr. */
        total_mb: number;
        /** The total amount of allocated RAM in MBs. */
        allocated_mb: number;
        /** The total amount of used RAM in MBs. */
        used_mb: number;
        /** The total amount of provisioned RAM in MBs. */
        provisioned_mb?: number;
    };
    /** Information about CPU resources. */
    cpu: {
        /** The number of CPU cores. */
        cores: number;
        /** Information about CPU shares. */
        shares: {
            /** The number of shares allocated. */
            allocated: number;
            /** The total number of shares available. */
            total: number;
        };
        /** The ratio of shares allocated to total shares. */
        share_ratio?: number;
    };
};
export type ClusterVersionServerCount = {
    agent: {
        [key: string]: number;
    };
    "agent-spawner": {
        [key: string]: number;
    };
    compute: {
        [key: string]: number;
    };
    "compute-spawner": {
        [key: string]: number;
    };
};
export type InfrastructureSummary = {
    hub_id: HubId;
    /** The number of servers on the cluster */
    servers: number;
    /** Statistics about image usage. */
    images: {
        /** Image tier information. */
        tier: {
            /** A value in MB representing the total MB of space on the infrastructure available for images. */
            total_mb: number;
            /** A value in MB representing the amount of allocated space for images. */
            allocated_mb: number;
            /** A value in MB representing the total MB of space images are taking up out of the total megabytes available. */
            used_mb: number;
        };
    };
    /** Records pointing to information about clusters that make up this hubs infrastructure. */
    clusters: {
        [key: string]: {
            /** The name of the cluster */
            name: string;
            /** Information about the aggregate resources of the cluster. */
            resources: InfrastructureResourceSummary;
            /** Whether Cycle has determined this cluster to be healthy or not. */
            healthy: boolean;
            versions: ClusterVersionServerCount;
            instances: ResourceCountSummary;
            servers: {
                count: number;
                providers: {
                    [key: string]: {
                        count: number;
                        locations?: {
                            [key: string]: number;
                        };
                        models?: {
                            [key: string]: number;
                        };
                        resources?: InfrastructureResourceSummary;
                    };
                };
                /** IDs of all servers in this cluster. */
                server_ids?: Id[];
            };
        };
    };
    /** A timestamp of when the infrastructure was last updated. */
    updated: DateTime;
};
export type DeploymentStrategy = {
    /** The human-friendly name of the deployment strategy. */
    name: string;
    /** A boolean where true signifies a strategy is either not yet or no longer available. */
    disabled: boolean;
    /** A description for the deployment strategy. */
    description: string;
};
export type ServerModelsIncludes = {
    [key: string]: ProviderServerModel;
};
export type AutoScaleGroupIncludes = {
    integrations?: IntegrationsIncludes;
    models?: ServerModelsIncludes;
    locations?: LocationIncludes;
};
export type ClusterState = {
    /** The current state of the cluster. */
    current: "live" | "deleting" | "deleted";
} & State;
export type Cluster = {
    id: string;
    /** A human readable slugged identifier for this cluster. */
    identifier: Identifier;
    creator: CreatorScope;
    hub_id: HubId;
    acl?: Acl | null;
    state: ClusterState;
    /** A collection of timestamps for each event in the cluster's lifetime. */
    events: {
        /** The timestamp of when the cluster was created. */
        created: DateTime;
        /** The timestamp of when the cluster was updated. */
        updated: DateTime;
        /** The timestamp of when the cluster was deleted. */
        deleted: DateTime;
    };
};
export type ServerStatsCpuUsageTelemetry = {
    user?: number;
    nice?: number;
    system?: number;
    idle?: number;
    iowait?: number;
    irq?: number;
    soft_irq?: number;
    steal?: number;
    guest?: number;
    guest_nice?: number;
};
export type ServerStatsRamTelemetry = {
    /** The available RAM on the server. */
    available: number;
    /** The free RAM on the server. */
    free: number;
    /** The total RAM on the server. */
    total: number;
};
export type ServerStatsTelemetry = {
    /** The timestamp for when the telemetery data was collected. */
    time: DateTime;
    cpu_usage?: ServerStatsCpuUsageTelemetry;
    load: ServerStatsLoad;
    ram: ServerStatsRamTelemetry;
    /** Telemetry data for the base volume of a given server. */
    storage_base: {
        /** The amount of storage currently used in the base volume. */
        used: number;
        /** The amount of free space on the base volume. */
        free: number;
        /** The total amount of space on the base volume. */
        total: number;
    };
    /** User data storage across all devices. */
    storage_pool: {
        /** The percentage of storage used by user data. */
        data_percent: number;
        /** The percentage of storage used by the file allocation stables, etc. */
        meta_percent: number;
        /** The total amount of storage available. */
        total: number;
    };
};
export type ReconfigureSharedFs = {
    /** The action to take. */
    action: "sharedfs.reconfigure";
    contents: {
        mounts?: {
            [key: string]: {
                /** String describing the server mount type. */
                type: string;
                /** Comma separated string describing the server mount options. */
                options: string;
                /** String describing the server mount source. */
                source: string;
            };
        };
        /** An object describing directory identifiers with value {}. */
        directories?: {
            [key: string]: any;
        };
    };
};
export type ReconfigureServerAction = {
    /** The action to take. */
    action: "features.reconfigure";
    /** Supplemental information needed to perform the action. */
    contents: {
        /** A boolean where true represents the desire for the server to accept incoming SFTP requests for container volumes. */
        sftp: boolean;
        /** A number in GB for how big the base volume should be.  This cannot be lower than the currently set value for the server. */
        base_volume_gb: number | null;
    };
};
export type RestartServerAction = {
    /** The action to take. */
    action: "restart";
};
export type RestartComputeServiceAction = {
    /** The action to take. */
    action: "compute.restart";
};
export type RestartComputeSpawner = {
    /** The action to take. */
    action: "compute.spawner.restart";
};
export type EvacuateServer = {
    /** The action to take. */
    action: "evacuation.start";
    contents: {
        destination: {
            server_id?: Id | null;
        };
    };
};
export type EvacuateServerReset = {
    /** The action to take. */
    action: "evacuation.reset";
};
export type ServerTags = {
    /** An array of server tags. */
    data: string[];
};
export type ServerUsageDatum = {
    /** A timestamp for when the usage data was collected. */
    time: DateTime;
    /** The number of nodes the data is pulled from. */
    nodes: number;
    /** A number in GB representing memory. */
    memory_gb: number;
};
export type PoolIncludes = {
    creators?: CreatorInclude;
    servers?: ServerIncludes2;
    integrations?: IntegrationsIncludes;
    locations?: LocationIncludes;
};
export type TaskState = {
    changed?: DateTime;
    /** The current state of the task. */
    current?: "pending" | "error" | "running" | "completed";
};
export type JobTask = {
    id: Id;
    /** A short description of the task. */
    caption: string;
    /** The API function called. */
    header: string;
    /** A collection of timestamps for each event in the job's lifetime. */
    events: {
        /** The timestamp of when the task was queued. */
        queued: DateTime;
        /** The timestamp of when the task was completed. */
        completed: DateTime;
        /** The timestamp of when the task was started. */
        started: DateTime;
    };
    /** An array of job task steps. */
    steps: TaskStep[];
    state: TaskState;
    /** Input information used for the job tasks. */
    input: {
        [key: string]: any;
    };
    /** Output informaiton used for the job tasks. */
    output: {
        [key: string]: string;
    };
    /** An error object describing issues with the job. */
    error: {
        /** An error message */
        message: string;
    };
};
export type JobState = {
    /** The current state of the job. */
    current:
        | "new"
        | "queued"
        | "error"
        | "scheduled"
        | "expired"
        | "running"
        | "completed";
    changed?: DateTime;
    /** An error, if any, that has occurred for this job. */
    error: {
        /** Details about the error that has occurred. */
        message: string;
    };
};
export type Job = {
    id: Id;
    hub_id: HubId;
    /** A dash separated string showing the environment_id where the job is taking place. */
    queue: string;
    /** A short description of the job. */
    caption: string;
    /** A collection of timestamps for each event in the job's lifetime. */
    events: {
        /** The timestamp of when the job was queued. */
        queued: DateTime;
        /** The timestamp of when the job was completed. */
        completed: DateTime;
        /** The timestamp of when the job was started. */
        started: DateTime;
    };
    /** A timestamp of when the job was scheduled. */
    schedule: DateTime;
    /** An array of job tasks. */
    tasks: JobTask[];
    /** A timestamp for when the job expires. */
    expires: DateTime;
    /** A combination of the individual task details and job details. */
    hash: string;
    /** A boolean where true represents the job is set to prevent duplicates of itself quickly after creation. */
    limit_duplicates: boolean;
    creator: CreatorScope;
    state: JobState;
};
export type JobIncludes = {
    creators?: CreatorInclude;
};
export type Metric = {
    /** When the metric was submitted to the platform. */
    time: DateTime;
    /** Additional key-values that can be used for querying in an aggregation pipeline. Often useful for filtering. */
    labels?: {
        [key: string]: string;
    };
    /** Additional tags associated with the metric. */
    tags?: string[];
    /** An array of recorded data points for this metric. */
    points: (DateTime | number)[][];
    /** Information about this metric, such as the type of metric, the data it represents, and critical common information such as hub ID and associated cluster. */
    metadata: {
        /** The data this metric represents. Cycle provides several built-in metrics, and, tier allowing, hubs can submit custom metrics as well.
        
        Here is a non-exhaustive list of some of the metrics provided by the platform automatically:
          - container.instances.num
          - discovery.resolutions.cache_hits
          - discovery.resolutions.lookups
          - discovery.resolutions.not-founds
          - environment.containers.num
          - environment.instances.num
          - environments.num
          - images.num
          - images.storage_kb
          - infrastructure.resources.cpu.cores
          - infrastructure.resources.cpu.usage.idle
          - infrastructure.resources.cpu.usage.iowait
          - infrastructure.resources.cpu.usage.nice
          - infrastructure.resources.cpu.usage.soft_irq
          - infrastructure.resources.cpu.usage.steal
          - infrastructure.resources.cpu.usage.system
          - infrastructure.resources.cpu.usage.user
          - infrastructure.resources.ram.available_kb
          - infrastructure.resources.ram.free_kb
          - infrastructure.resources.ram.total_kb
          - infrastructure.servers.num
          - lb.controller.connections
          - lb.controller.disconnects.destination_unavailable
          - lb.controller.disconnects.no_error
          - lb.controller.disconnects.timeout_idle
          - lb.controller.disconnects.timeout_init
          - lb.controller.disconnects.total
          - lb.controller.disconnects.unknown_error
          - lb.controller.received_kb
          - lb.controller.requests
          - lb.controller.requests.total
          - lb.controller.requests.url.handlers.forward.hits
          - lb.controller.requests.url.handlers.forward.timing_ms
          - lb.controller.requests.url.handlers.redirect.hits
          - lb.controller.requests.url.handlers.redirect.timing_ms
          - lb.controller.router.destinations.connections.invalid
          - lb.controller.router.destinations.connections.received_kb
          - lb.controller.router.destinations.connections.success
          - lb.controller.router.destinations.connections.transmitted_kb
          - lb.controller.router.destinations.connections.unavailable
          - lb.controller.router.destinations.latency
          - lb.controller.router.destinations.requests.total
          - lb.controller.transmitted_kb
          - memberships.num
          - pipeline.runs.num
          - stack.builds.num
         */
        metric: string;
        /** The type of data represented by the metric.
          - **gauge**: represents a single numerical value that can arbitrarily go up and down.
          - **count**: represents a counter that resets periodically.
          - **counter**: represents a counter that only increases.
          - **rate**: represents the rate of change of a counter per second.
          - **histogram**: tracks the statistical distribution of a set of values.
          - **distribution**: aggregates data across multiple hosts and applications.
          - **set**: tracks the number of unique elements in a group.
         */
        type:
            | "gauge"
            | "count"
            | "counter"
            | "rate"
            | "histogram"
            | "distribution"
            | "set";
        /** The originator of this metric. */
        origin: string;
        hub_id?: Id;
        /** The specific resource ID and type associated with this metric. */
        component?: {
            id: Id;
            type: string;
        };
        /** The cluster associated with this metric */
        cluster?: string | null;
    };
};
export type EventType =
    | "api.security_violation"
    | "console.ssh.login"
    | "console.ssh.login.failed"
    | "container.instance.backup.completed"
    | "container.instance.backup.failed"
    | "container.instance.delete.failed"
    | "container.instance.error"
    | "container.instance.restart.max_restarts"
    | "container.instance.function.max_runtime"
    | "container.instance.healthcheck.failed"
    | "container.instance.healthcheck.recovered"
    | "container.instance.volume.extend.failed"
    | "container.instance.healthcheck.restarted"
    | "container.instance.migration.failed"
    | "container.instance.migration.completed"
    | "container.instance.migration.failed"
    | "container.instance.network.interfaces.create.failed"
    | "container.instance.restart.failed"
    | "container.instance.start.failed"
    | "container.instance.start.privileged"
    | "container.instance.stop.failed"
    | "container.instances.autoscale.down"
    | "container.instances.autoscale.up"
    | "container.reconfigured.privileged"
    | "container.volumes.base.create.failed"
    | "container.volumes.create.failed"
    | "environment.service.auto_update"
    | "environment.service.lb.ips.sync.failed"
    | "environment.service.vpn.login.failed"
    | "infrastructure.cluster.resources.ram.full"
    | "infrastructure.server.compute.volumes.base.reconfigured"
    | "infrastructure.server.compute.full_restart"
    | "infrastructure.server.compute.sharedfs.mounts.mount"
    | "infrastructure.server.compute.sharedfs.mounts.mount.failed"
    | "infrastructure.server.compute.soft_restart"
    | "infrastructure.server.image.download.failed"
    | "infrastructure.server.internal_api.throttled"
    | "infrastructure.server.manifest.sync.failed"
    | "infrastructure.server.mesh.connect.failed"
    | "infrastructure.server.neighbor.reachable"
    | "infrastructure.server.neighbor.rebuild"
    | "infrastructure.server.neighbor.unreachable"
    | "infrastructure.server.neighbor.upgraded"
    | "infrastructure.server.resources.load.high"
    | "infrastructure.server.resources.ram.full"
    | "infrastructure.server.resources.storage.volumes.base.full"
    | "infrastructure.server.resources.storage.cycle_pool.full"
    | "infrastructure.server.sftp.lockdown"
    | "infrastructure.server.sftp.login"
    | "infrastructure.server.sftp.login.failed"
    | "infrastructure.server.evacuation.failed"
    | "infrastructure.server.evacuation.completed";
export type Event = {
    /** When the event was submitted to the platform. */
    time: DateTime;
    /** Additional key-values that can be used for querying in an aggregation pipeline. Often useful for filtering. */
    labels?: {
        [key: string]: string;
    };
    /** Additional tags associated with the event. */
    tags?: string[];
    /** A message describing what happened. */
    text?: string;
    /** Information about this event, such as the type of event, the data it represents, and critical common information such as hub ID and associated cluster. */
    metadata: {
        /** Describes what this event is.
         */
        event: EventType;
        /** The type of event.
          - **info**: represents an informational message.
          - **warning**: represents a warning message indicating a potential issue.
          - **error**: represents an error message indicating a failure or critical issue.
          - **success**: represents a success message indicating a successful operation.
          - **alert**: represents a critical message indicating a severe issue.
          - **notice**: represents a notice message indicating a noteworthy event.
          - **audit**: represents a notice message indicating a message that would be needed for audits
         */
        type:
            | "info"
            | "warning"
            | "error"
            | "success"
            | "alert"
            | "notice"
            | "audit";
        /** Describes how important this event is. */
        priority: "critical" | "high" | "medium" | "normal" | "low";
        /** The originator of this event. */
        origin: string;
        hub_id?: Id;
        /** The specific resource ID and type associated with this event. */
        component?: {
            id: Id;
            type: string;
        };
        /** The cluster associated with this event */
        cluster?: string | null;
    };
};
export type LogLine = {
    /** The time the log line occurred. */
    time: DateTime;
    /** The source that generated the log line. */
    source: string;
    /** The message associated with the log line. */
    message: string;
    /** The container instance that the log line originated from. */
    instance_id?: string | null;
    /** A randomly generated ID that is assigned to all lines in the same context window.
    The first line with a specific ID is the log line that matches the query, and the following
    log lines are included due to the the set context window in the aggregate.
     */
    context_window?: string;
};
export type Monitor = {
    /** The ID of the node this monitor is running on. */
    node_id: string;
    /** The country code of where this monitor is located. */
    country_short: string;
    /** The full name of the country where this monitor is located. */
    country: string;
    /** The name of the region (state, province, prefecture, etc.) where this monitor is located. */
    region: string;
    /** The name of the city where this monitor is located. */
    city: string;
    /** The latitude where this monitor is located. */
    latitude: number;
    /** The longitude where this monitor is located. */
    longitude: number;
};
export type PipelineIncludes = {
    creators?: CreatorInclude;
    /** A name value. */
    name?: string;
    /** A record of resources that can be associated with the pipeline. */
    components?: {
        [key: string]:
            | Container
            | Environment
            | Stack
            | StackBuild
            | Image
            | ImageSource;
    };
    "components:identifiers"?: IdentifierIncludes;
};
export type TriggerPipelineAction = {
    /** The requested action to perform. */
    action: "trigger";
    contents?: {
        /** A map of variables to pass into the pipeline when it runs. */
        variables?: {
            [key: string]: string;
        };
    };
};
export type RerunPipelineAction = {
    /** The requested action to perform. */
    action: "rerun";
    contents?: {
        /** The ID of the run to retry. */
        run_id?: Id;
    };
};
export type NetworkIncludes = {
    creators?: CreatorInclude;
    environments?: EnvironmentInclude;
};
export type IndexComponent = {
    /** ID of the component */
    id: string;
    /** Name of the component */
    name: string;
    identifier?: Identifier;
    /** State of the component */
    state: string;
    events: Events;
    /** related strings for fuzzy search */
    context_blobs: string[];
    /** Component associations */
    associations: {
        /** Associated container Id */
        container?: string;
        /** Associated environment Id */
        environment?: string;
        /** Associated image source Id */
        "image-source"?: string;
        /** Associated image Id */
        image?: string;
        /** Associated stack Id */
        stack?: string;
        /** Associated server Id */
        server?: string;
        /** Associated provider location Id */
        "provider-location"?: string;
    };
};
export type Index = {
    /** The timestampof when the index was generated */
    generated: DateTime;
    /** Id describing the Hub */
    hub_id: string;
    containers: {
        [key: string]: IndexComponent;
    };
    environments: {
        [key: string]: IndexComponent;
    };
    image_sources: {
        [key: string]: IndexComponent;
    };
    dns_zones: {
        [key: string]: IndexComponent;
    };
    stacks: {
        [key: string]: IndexComponent;
    };
    servers: {
        [key: string]: IndexComponent;
    };
};
export type StackIncludes2 = {
    creators?: CreatorInclude;
};
export type StackBuildAbout = {
    /** A user defined version of the build. */
    version: string;
    /** A user defined description for the build. */
    description: string;
    git_commit?: StackGitCommit;
};
export type GenerateStackBuild = {
    /** The job to do. */
    action: "generate";
};
export type DeployStackBuild = {
    /** The action the job takes. */
    action: "deploy";
    /** Additional information needed for the job. */
    contents: {
        /** The ID for the environment this stack build will be deployed to. */
        environment_id: string;
        deployment?: Deployment | null;
        update?: StackBuildDeploymentUpdates | null;
    };
};
export const {
    useGetAccountQuery,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useGetAccountLoginsQuery,
    useChangePasswordMutation,
    useResetPasswordMutation,
    useGetAccountInvitesQuery,
    useUpdateAccountInviteMutation,
    useGetAccountMembershipsQuery,
    useGetTwoFactorAuthSetupMutation,
    useEnableTwoFactorAuthMutation,
    useDisableTwoFactorAuthMutation,
    useRecoverTwoFactorAuthMutation,
    useGetAnnouncementsQuery,
    useGetCreditsQuery,
    useGetCreditQuery,
    useGetInvoicesQuery,
    useGetInvoiceQuery,
    useCreateInvoiceJobMutation,
    useGetBillingMethodsQuery,
    useCreateBillingMethodMutation,
    useGetBillingMethodQuery,
    useUpdateBillingMethodMutation,
    useDeleteBillingMethodMutation,
    useCreateBillingMethodJobMutation,
    useGetOrdersQuery,
    useCreateOrderMutation,
    useGetBillingOrderQuery,
    useUpdateBillingOrderMutation,
    useCreateOrderJobMutation,
    useGetBillingSupportPlansQuery,
    useGetBillingTiersQuery,
    useGetBillingServicesQuery,
    useGetBillingServiceQuery,
    useGetBillingOveragesQuery,
    useGetContainersQuery,
    useCreateContainerMutation,
    useGetContainerQuery,
    useUpdateContainerMutation,
    useDeleteContainerMutation,
    useGetContainerSummaryQuery,
    useCreateContainerJobMutation,
    useGetContainerServersQuery,
    useGetCompatibleServersQuery,
    useGetCompatibleImagesQuery,
    useGetInstancesQuery,
    useCreateInstancesMutation,
    useDeleteContainerInstancesMutation,
    useGetInstanceQuery,
    useDeleteInstanceMutation,
    useGenerateInstanceSshCredentialsMutation,
    useExpireInstanceSshCredentialsMutation,
    useCreateInstanceJobMutation,
    useGetInstanceVolumesQuery,
    useGetInstanceTelemetryReportQuery,
    useGetInstanceTelemetryStreamAuthQuery,
    useGetInstanceConsoleStreamAuthQuery,
    useCreateFunctionJobMutation,
    useGetContainerBackupsQuery,
    useGetContainerBackupQuery,
    useDeleteContainerBackupMutation,
    useCreateContainerBackupJobMutation,
    useGetContainerBackupLogsQuery,
    useGetDnsZonesQuery,
    useCreateDnsZoneMutation,
    useGetDnsZoneQuery,
    useUpdateDnsZoneMutation,
    useDeleteDnsZoneMutation,
    useUpdateDnsZoneAccessMutation,
    useCreateDnsZoneJobMutation,
    useGetDnsZoneRecordsQuery,
    useCreateDnsZoneRecordMutation,
    useUpdateDnsZoneRecordMutation,
    useDeleteDnsZoneRecordMutation,
    useCreateDnsZoneRecordJobMutation,
    useGetTlsGenerationAttemptsQuery,
    useLookupTlsCertificateQuery,
    useGetEnvironmentsQuery,
    useCreateEnvironmentMutation,
    useGetEnvironmentMonitoringTiersQuery,
    useGetEnvironmentQuery,
    useUpdateEnvironmentMutation,
    useDeleteEnvironmentMutation,
    useUpdateEnvironmentAccessMutation,
    useCreateEnvironmentJobMutation,
    useGetEnvironmentSummaryQuery,
    useGetEnvironmentDeploymentsQuery,
    useExportStackMutation,
    useGetScopedVariablesQuery,
    useCreateScopedVariableMutation,
    useGetScopedVariableQuery,
    useUpdateScopedVariableMutation,
    useDeleteScopedVariableMutation,
    useCreateDiscoveryServiceJobMutation,
    useGetLoadBalancerServiceQuery,
    useCreateLoadBalancerServiceJobMutation,
    useGetLoadBalancerTelemetryReportQuery,
    useGetLoadBalancerLatestTelemetryReportQuery,
    useGetLoadBalancerTelemetryLatestControllersQuery,
    useCreateSchedulerServiceJobMutation,
    useGetVpnServiceQuery,
    useGetVpnLoginsQuery,
    useGetVpnUsersQuery,
    useCreateVpnUserMutation,
    useDeleteVpnUserMutation,
    useCreateVpnServiceJobMutation,
    useGetHubsQuery,
    useCreateHubMutation,
    useGetHubQuery,
    useUpdateHubMutation,
    useDeleteHubMutation,
    useGetHubCapabilitiesQuery,
    useGetHubUsageQuery,
    useGetHubActivityQuery,
    useGetApiKeysQuery,
    useCreateApiKeyMutation,
    useGetApiKeyQuery,
    useUpdateApiKeyMutation,
    useDeleteApiKeyMutation,
    useGetIntegrationQuery,
    useUpdateIntegrationMutation,
    useDeleteIntegrationMutation,
    useGetIntegrationsQuery,
    useCreateIntegrationMutation,
    useGetAvailableIntegrationsQuery,
    useCreateIntegrationJobMutation,
    useGetHubMembersQuery,
    useGetHubMembershipQuery,
    useGetHubMemberQuery,
    useUpdateHubMemberMutation,
    useDeleteHubMemberMutation,
    useGetHubMemberAccountQuery,
    useGetHubInvitesQuery,
    useCreateHubInviteMutation,
    useDeleteHubInviteMutation,
    useGetHubNotificationSocketAuthQuery,
    useGetRolesQuery,
    useCreateRoleMutation,
    useGetRoleQuery,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useGetDefaultHubRolesQuery,
    useGetImagesQuery,
    useCreateImageMutation,
    useGetImageQuery,
    useUpdateImageMutation,
    useDeleteImageMutation,
    useGetImageBuildLogQuery,
    useCreateImagesJobMutation,
    useCreateImageJobMutation,
    useGetImageSourcesQuery,
    useCreateImageSourceMutation,
    useGetImageSourceQuery,
    useUpdateImageSourceMutation,
    useDeleteImageSourceMutation,
    useUpdateImageSourceAccessMutation,
    useGetInfrastructureSummaryQuery,
    useGetDeploymentStrategiesQuery,
    useGetAutoScaleGroupsQuery,
    useCreateAutoScaleGroupMutation,
    useGetAutoScaleGroupQuery,
    useUpdateAutoScaleGroupMutation,
    useDeleteAutoScaleGroupMutation,
    useUpdateAutoScaleGroupAccessMutation,
    useGetProviderServersQuery,
    useGetProviderLocationsQuery,
    useGetClustersQuery,
    useCreateClusterMutation,
    useGetClusterQuery,
    useUpdateClusterMutation,
    useDeleteClusterMutation,
    useUpdateClusterAccessMutation,
    useGetServersQuery,
    useCreateServerMutation,
    useGetServerQuery,
    useUpdateServerMutation,
    useDeleteServerMutation,
    useGetServerTelemetryQuery,
    useGetServerInstancesQuery,
    useCreateServerJobMutation,
    useGetServerTagsQuery,
    useGetServerUsageQuery,
    useGetServerConsoleQuery,
    useGetInfrastructureIpPoolsQuery,
    useGetIpPoolQuery,
    useDeleteIpPoolMutation,
    useGetPoolIPsQuery,
    useGetJobsQuery,
    useGetJobQuery,
    useGetLatestJobsQuery,
    useGetMetricsQuery,
    useGenerateAggregatedMetricsQuery,
    useGetEventsQuery,
    useGenerateAggregatedEventsQuery,
    useAggregateLogsMutation,
    useGetMonitoringMonitorsQuery,
    useGetPipelinesQuery,
    useCreatePipelineMutation,
    useGetPipelineQuery,
    useUpdatePipelineMutation,
    useDeletePipelineMutation,
    useUpdatePipelineAccessMutation,
    useGetPipelineRunsQuery,
    useGetPipelineRunQuery,
    useCreatePipelineJobMutation,
    useClonePipelineMutation,
    useTriggerPipelineMutation,
    useGetPipelineTriggerKeysQuery,
    useCreatePipelineTriggerKeyMutation,
    useGetPipelineTriggerKeyQuery,
    useUpdatePipelineTriggerKeyMutation,
    useDeletePipelineTriggerKeyMutation,
    useGetNetworksQuery,
    useCreateNetworkMutation,
    useGetNetworkQuery,
    useUpdateNetworkMutation,
    useDeleteNetworkMutation,
    useUpdateNetworkAccessMutation,
    useCreateNetworkJobMutation,
    useGetSearchIndexQuery,
    useGetStacksQuery,
    useCreateStackMutation,
    useGetStackQuery,
    useUpdateStackMutation,
    useDeleteStackMutation,
    useUpdateStackAccessMutation,
    useCreateStackJobMutation,
    useLookupStackBuildQuery,
    useGetStackBuildsQuery,
    useCreateStackBuildMutation,
    useGetStackBuildQuery,
    useDeleteStackBuildMutation,
    useCreateStackBuildJobMutation,
    useLookupIdentifierQuery,
    useLookupComponentsQuery,
} = injectedRtkApi;
